<template>
  <div class="contentBox">
    <div class="headers">
      <span class="headersTitle">用药计划</span
      ><el-button
        class="headersAdd"
        @click="addList"
        :disabled="disableds"
        v-if="isXinZhen"
        >新增</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%" @row-click="tableList">
      <el-table-column
        prop="drug"
        label="药品名称"
        width="280"
      ></el-table-column>
      <el-table-column prop="medicationDate1" label="早晨">
        <template slot-scope="scope">
          {{ scope.row.medicationDate1 + "(" + scope.row.value1 + ")" }}
        </template>
      </el-table-column>
      <el-table-column prop="medicationDate2" label="中午">
        <template slot-scope="scope">
          {{ scope.row.medicationDate2 + "(" + scope.row.value2 + ")" }}
        </template>
      </el-table-column>
      <el-table-column prop="medicationDate3" label="晚上">
        <template slot-scope="scope">
          {{ scope.row.medicationDate3 + "(" + scope.row.value3 + ")" }}
        </template>
      </el-table-column>
      <el-table-column prop="medicationDate4" label="睡前">
        <template slot-scope="scope">
          {{ scope.row.medicationDate4 + "(" + scope.row.value4 + ")" }}
        </template>
      </el-table-column>
      <el-table-column prop="medicationDate5" label="晨起">
        <template slot-scope="scope">
          {{ scope.row.medicationDate5 + "(" + scope.row.value5 + ")" }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="quantity" label="数量"> </el-table-column> -->
      <el-table-column prop="take" label="方式"> </el-table-column>
    </el-table>
    <el-dialog :visible.sync="dialogVisible" width="30%" @close="closeDialog">
      <el-form ref="formOne" :model="formOne" label-width="10px">
        <el-form-item>
          药品名称：<el-autocomplete
            v-model="formOne.drug"
            :fetch-suggestions="querySearchAsync"
            :popper-append-to-body="false"
            :trigger-on-focus="false"
            placeholder="请输入内容"
            @select="handleSelect"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            style="margin-right: 18px"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item>
          <!-- 用 量：<el-input
            v-model="formOne.value"
            placeholder="请输入"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            style="width: 16%; margin-right: 20px"
          ></el-input> -->
          选择用药单位：<el-select
            v-model="formOne.unit"
            style="width: 15%; margin-right: 20px"
            placeholder="请选择"
            @input="$forceUpdate()"
            @change="comPany"
          >
            <el-option
              v-for="item in listName"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
          <!-- <el-checkbox
            label="启用"
            true-label="1"
            false-label="0"
            name="type"
            v-model="formOne.enable"
            @change="everyData"
          ></el-checkbox> -->
        </el-form-item>

        <el-form-item>
          <div>用药时间：</div>
          <div label="" class="xunhuanOne">
            <span class="xunhuan">循环发送：</span>
            <span class="zhou">周</span>
            <el-checkbox-group v-model="doc.doctime" @change="changeDoctime">
              <el-checkbox
                v-for="item in doctimeData"
                :label="item.value"
                true-label
                :key="item.value"
                >{{ item.title }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <!-- <el-row>
            <el-col :span="7">
              <el-form-item label="" class="senOne">
                <el-select
                  v-model="formOne.sendType"
                  placeholder="请选择发送方式"
                  class="sendFirst"
                  @change="sendMethod"
                  @input="$forceUpdate()"
                >
                  <el-option
                    v-for="item in opArea3"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <span>循环发送：</span>
            </el-col>
            <el-col :span="8" v-if="showOne">
              <el-form-item label="" class="senTwo">
                <el-date-picker
                  v-model="formOne.sendTime1"
                  type="date"
                  placeholder="选择日期"
                  class="sendFirst"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                  @input="$forceUpdate()"
                  @change="riqi"
                  :clearable="false"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="13" class="zhouOne">
              <span class="zhou">周</span>
              <el-checkbox-group v-model="doc.doctime" @change="changeDoctime">
                <el-checkbox
                  v-for="item in doctimeData"
                  :label="item.id"
                  true-label
                  :key="item.id"
                  >{{ item.title }}</el-checkbox
                >
              </el-checkbox-group>
            </el-col>
            <el-col :span="6">
            <el-form-item label="">
              <el-time-picker
                v-model="formOne.sendTime2"
                class="sendFirst"
                placeholder="选择时间"
                format="HH:mm:ss"
                value-format="HH:mm:ss"
                @input="$forceUpdate()"
                @change="shijian"
              >
              </el-time-picker>
            </el-form-item>
          </el-col>
          </el-row> -->
          <div v-for="(operate, index) in checkboxs" :key="index">
            <el-checkbox-group
              v-model="forms"
              class="checkGroup"
              @change="formsClick(forms)"
              style="display: inline-block; margin-right: 26px"
            >
              {{ forms }}
              <el-checkbox :label="operate.value" :key="operate.value">
                {{ operate.menu }}
              </el-checkbox>
            </el-checkbox-group>
            <el-time-picker
              v-model="operate.time"
              @change="opers(operate.time, index)"
              format="HH:mm"
              value-format="HH:mm"
              placeholder="任意时间点"
              style="width: 30%"
            >
            </el-time-picker>
            <el-input
              v-model="operate.num"
              placeholder="请输入"
              @input="$forceUpdate()"
              @change="opers(operate.num, index)"
              style="width: 16%; margin-right: 20px; margin-left: 10px"
            ></el-input>
            <span>{{ companyOne }}</span>
          </div>
        </el-form-item>
        <el-form-item>
          用药方式：<el-radio
            v-model="formOne.take"
            label="1"
            @change="$forceUpdate()"
            >餐前</el-radio
          >
          <el-radio v-model="formOne.take" label="2" @change="$forceUpdate()"
            >随餐</el-radio
          >
          <el-radio v-model="formOne.take" label="3" @change="$forceUpdate()"
            >餐后</el-radio
          >
          <el-radio v-model="formOne.take" label="4" @change="$forceUpdate()"
            >随机</el-radio
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancels">取 消</el-button>
        <el-button type="primary" @click="preservations" :disabled="isQuit"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisibles" width="30%">
      <el-form ref="form" :model="form" label-width="10px">
        <el-form-item>
          药品名称：<el-autocomplete
            v-model="form.drug"
            :fetch-suggestions="querySearchAsync"
            :trigger-on-focus="false"
            placeholder="请输入内容"
            @select="handleSelect"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            style="margin-right: 18px"
          ></el-autocomplete>
          <el-checkbox
            label="启用"
            true-label="0"
            false-label="1"
            name="type"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            v-model="form.status"
          ></el-checkbox>
        </el-form-item>
        <el-form-item>
          <!-- 用 量：<el-input
            v-model="form.value"
            placeholder="请输入"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            style="width: 16%; margin-right: 20px"
          ></el-input> -->
          选择用药单位：<el-select
            v-model="form.unit"
            style="width: 15%"
            placeholder="请选择"
            @input="$forceUpdate()"
            @change="comPany"
          >
            <el-option
              v-for="item in listName"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div>用药时间：</div>
          <div label="" class="xunhuanOne">
            <span class="xunhuan">循环发送：</span>
            <span class="zhou">周</span>
            <el-checkbox-group v-model="docOne.doctime" @change="changeDoctime">
              <el-checkbox
                v-for="item in doctimeData"
                :label="item.value"
                true-label
                :key="item.value"
                >{{ item.title }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <!-- <el-row>
            <el-col :span="7">
              <el-form-item label="" class="senOne">
                <el-select
                  v-model="form.sendType"
                  placeholder="请选择发送方式"
                  class="sendFirst"
                  @change="sendMethod"
                  @input="$forceUpdate()"
                >
                  <el-option
                    v-for="item in opArea3"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="showOne">
              <el-form-item label="" class="senTwo">
                <el-date-picker
                  v-model="form.sendTime1"
                  type="date"
                  placeholder="选择日期"
                  class="sendFirst"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                  @input="$forceUpdate()"
                  @change="riqi"
                  :clearable="false"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="13" class="zhouOne" v-if="!showOne">
              <span class="zhou">周</span>
              <el-checkbox-group
                v-model="docOne.doctime"
                @change="changeDoctime"
              >
                <el-checkbox
                  v-for="item in doctimeData"
                  :label="item.id"
                  true-label
                  :key="item.id"
                  >{{ item.title }}</el-checkbox
                >
              </el-checkbox-group>
            </el-col>
            <el-col :span="6">
            <el-form-item label="">
              <el-time-picker
                v-model="formOne.sendTime2"
                class="sendFirst"
                placeholder="选择时间"
                format="HH:mm:ss"
                value-format="HH:mm:ss"
                @input="$forceUpdate()"
                @change="shijian"
              >
              </el-time-picker>
            </el-form-item>
          </el-col>
          </el-row> -->
          <div v-for="(operate, index) in checkboxs" :key="index">
            <el-checkbox-group
              v-model="forms"
              class="checkGroup"
              @change="formsClick(forms)"
              style="display: inline-block; margin-right: 26px"
            >
              <!-- {{ operate.value }} -->
              <el-checkbox :label="operate.value" :key="operate.value">
                {{ operate.menu }}
              </el-checkbox>
            </el-checkbox-group>
            <el-time-picker
              v-model="operate.time"
              @change="opers(operate.time, index)"
              format="HH:mm"
              value-format="HH:mm"
              placeholder="任意时间点"
              style="width: 30%"
            >
            </el-time-picker>
            <el-input
              v-model="operate.num"
              placeholder="请输入"
              @input="$forceUpdate()"
              @change="opers(operate.num, index)"
              style="width: 16%; margin-right: 20px; margin-left: 10px"
            ></el-input>
            <span>{{ companyOne }}</span>
          </div>
        </el-form-item>
        <el-form-item>
          用药方式：<el-radio
            v-model="form.take"
            label="1"
            @change="$forceUpdate()"
            >餐前</el-radio
          >
          <el-radio v-model="form.take" label="2" @change="$forceUpdate()"
            >随餐</el-radio
          >
          <el-radio v-model="form.take" label="3" @change="$forceUpdate()"
            >餐后</el-radio
          >
          <el-radio v-model="form.take" label="4" @change="$forceUpdate()"
            >随机</el-radio
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="preservation">保 存</el-button>
      </span>
    </el-dialog>
    <!-- <el-dialog :visible.sync="SpringFrame" width="30%">
      <el-form ref="form" :model="form" label-width="10px">
        <el-form-item>
          <p>张老三的用药计划</p>
          <el-input
            type="textarea"
            v-model="form.desc"
            style="width: 98%"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="edit">编辑</el-button>
        <el-button type="primary" @click="increase">增加</el-button>
      </span></el-dialog> -->
  </div>
</template>

<script>
import { api } from "../../api1/config";
import { apiShout } from "../../api1/configShout";
export default {
  props: ["textPhone", "endReasonOne", "noTaking", "userId", "isClick"],
  data() {
    return {
      weekList: "",
      doc: {
        doctime: ["1", "2", "3", "4", "5", "6", "7"],
        // doctime: [1, 2, 3, 4, 5, 6, 7],
      },
      docOne: {
        doctime: [],
      },
      doctimeData: [
        {
          value: "1",
          title: "一",
          id: 2,
        },
        {
          value: "2",
          title: "二",
          id: 3,
        },
        {
          value: "3",
          title: "三",
          id: 4,
        },
        {
          value: "4",
          title: "四",
          id: 5,
        },
        {
          value: "5",
          title: "五",
          id: 6,
        },
        {
          value: "6",
          title: "六",
          id: 7,
        },
        {
          value: "7",
          title: "日",
          id: 1,
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      opArea3: [
        {
          code: "1",
          name: "发送一次",
        },
        {
          code: "2",
          name: "循环发送",
        },
      ],
      showOne: true,
      sendMet: null,
      companyOne: "片",
      isXinZhen: true,
      isQuit1: false,
      isQuit: false,
      disableds: false,
      preservationBtn: false,
      dialogVisible: false,
      dialogVisibles: false,
      // SpringFrame: false,
      tableData: [],
      MedicationId: null,
      userIds: null,
      form: {
        medications: [],
        memberId: null,
        mainUniqueId: "",
      },
      formOne: {
        medications: [],
        memberId: null,
        mainUniqueId: "",
        unit: "1",
      },
      checkboxs: [
        {
          value: "1",
          menu: "早晨",
          time: "8:00",
          num: "",
        },
        {
          value: "2",
          menu: "中午",
          time: "11:00",
          num: "",
        },
        {
          value: "3",
          menu: "晚上",
          time: "17:00",
          num: "",
        },
        {
          value: "4",
          menu: "睡前",
          time: "20:00",
          num: "",
        },
        {
          value: "5",
          menu: "晨起",
          time: "6:00",
          num: "",
        },
      ],
      forms: [],
      restaurants: [],
      listName: [],
      formList: {},
      merberid: 7,
      formValue: [],
      formTimes: [],
      aValue: "",
      forList: [],
      timeIndex: null,
      count1: 0,
      count2: 0,
      count3: 0,
      count4: 0,
      count5: 0,
    };
  },
  watch: {
    userId(cc) {
      if (cc == "" || cc == null) {
        this.isXinZhen = false;
      } else {
        this.isXinZhen = true;
      }
    },
    // noTaking(aa) {
    //   if (aa == true) {
    //     this.disableds = false;
    //   } else {
    //     this.disableds = false;
    //   }
    // },
  },
  created() {
    // this.getMemberIds();
  },
  methods: {
    everyData(aa) {
      // console.log("是否选择了每天：", aa);
      if (aa == 1) {
        this.form.date = "";
        this.isDate = true;
      } else {
        this.isDate = false;
        let newDate = new Date();
        let y = newDate.getFullYear();
        let MM = newDate.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = newDate.getDate();
        d = d < 10 ? "0" + d : d;
        let dates = y + "-" + MM + "-" + d;
        this.form.date = dates;
      }
    },
    changeDoctime(bb) {
      console.log("bb", bb);
      this.weekList = bb.join(",");
    },
    shijian(aa) {
      console.log("shijain:", aa);
    },
    riqi(val) {
      console.log("riqi:", val);
    },
    sendMethod(val) {
      console.log("xianzhe:", val);
      this.sendMet = val;
      if (val == 1) {
        this.showOne = true;
        this.form.sendTime2 = "";
        this.form.sendTime1 = "";
      } else {
        this.showOne = false;
        this.form.sendTime2 = "";
      }
    },
    comPany(aa) {
      this.$forceUpdate();
      console.log("opop:", aa);
      if (aa == 1) {
        this.companyOne = "片";
      } else if (aa == 2) {
        this.companyOne = "粒";
      } else if (aa == 3) {
        this.companyOne = "瓶";
      } else if (aa == 4) {
        this.companyOne = "次";
      } else if (aa == 5) {
        this.companyOne = "匙";
      } else if (aa == 6) {
        this.companyOne = "袋";
      } else if (aa == 7) {
        this.companyOne = "盖";
      } else if (aa == 8) {
        this.companyOne = "剂";
      } else if (aa == 9) {
        this.companyOne = "支";
      } else if (aa == 10) {
        this.companyOne = "ml";
      } else if (aa == 11) {
        this.companyOne = "mg";
      } else if (aa == 12) {
        this.companyOne = "单位";
      } else if (aa == 13) {
        this.companyOne = "贴";
      }
    },
    getMemberIds() {
      console.log(this.textPhone, this.endReasonOne, "textPhone");
      let UniqueId = JSON.parse(localStorage.getItem("userIds"));
      let UseridData = UniqueId;
      console.log(UseridData, "查看//////");
      apiShout.getRemindMemberIds({ memberId: UseridData }).then((res) => {
        console.log(res, "根据用户id查询信息");
        this.tableData = res.data.result;
        if (this.tableData != undefined) {
          this.tableData.forEach((element) => {
            if (
              element.medicationDate1 == null ||
              element.medicationDate1 == undefined
            ) {
              element.medicationDate1 = "--";
            }
            if (
              element.medicationDate2 == null ||
              element.medicationDate2 == undefined
            ) {
              element.medicationDate2 = "--";
            }
            if (
              element.medicationDate3 == null ||
              element.medicationDate3 == undefined
            ) {
              element.medicationDate3 = "--";
            }
            if (
              element.medicationDate4 == null ||
              element.medicationDate4 == undefined
            ) {
              element.medicationDate4 = "--";
            }
            if (
              element.medicationDate5 == null ||
              element.medicationDate5 == undefined
            ) {
              element.medicationDate5 = "--";
            }
            if (element.value1 == null || element.value1 == undefined) {
              element.value1 = "--";
            }
            if (element.value2 == null || element.value2 == undefined) {
              element.value2 = "--";
            }
            if (element.value3 == null || element.value3 == undefined) {
              element.value3 = "--";
            }
            if (element.value4 == null || element.value4 == undefined) {
              element.value4 = "--";
            }
            if (element.value5 == null || element.value5 == undefined) {
              element.value5 = "--";
            }
          });
        }
      });
      this.userIds = UniqueId;
      console.log(this.userIds, "输出查看00000");
    },
    formsClick(index) {
      console.log(index, "输出查看");
      this.formValue = index;
      console.log(this.formValue, "查看值是否正确1:");
    },
    opers(val, index) {
      console.log("输出改变时那个吗：", val, index, this.formValue);
      this.aValue = val;
      this.timeIndex = index;
      if (index == 0 && this.formValue.indexOf("1") == -1) {
        this.forms.push("1");
        this.formValue = this.forms;
      }
      if (index == 1 && this.formValue.indexOf("2") == -1) {
        this.forms.push("2");
        this.formValue = this.forms;
      }
      if (index == 2 && this.formValue.indexOf("3") == -1) {
        this.forms.push("3");
        this.formValue = this.forms;
      }
      if (index == 3 && this.formValue.indexOf("4") == -1) {
        this.forms.push("4");
        this.formValue = this.forms;
      }
      if (index == 4 && this.formValue.indexOf("5") == -1) {
        this.forms.push("5");
        this.formValue = this.forms;
      }
      if (val == null) {
        this.formTimes[index].remindDate = "";
        console.log(val, "是空的");
      } else {
        //tianjia
        if (this.formValue.length == 0) {
          this.formTimes = [];
          var content = {};
          content.medicationDate = index + 1;
          content.remindDate = val;
          content.value = index + 1;
          this.formTimes.push(content);
          console.log(this.formTimes, "第几个输出");
        } else {
          for (var i = 0; i < this.formValue.length; i++) {
            this.formTimes.forEach((item, index) => {
              if (item.medicationDate === this.formValue[i]) {
                this.formTimes[index].remindDate = val;
                console.log(this.formTimes[index].remindDate, "这个循序查看");
              }
              // else {
              //   var contents = {};
              //   contents.medicationDate = index + 1;
              //   contents.remindDate = val;
              //   this.formTimes.push(contents);
              //   console.log(this.formTimes, "查看输出循序");
              // }
            });
          }
        }
      }

      // this.formTimes.push(val);
      console.log(this.formTimes, val, index, "查看时间是否正确1:");
    },
    tableList(val) {
      api.smallLei({ dictCode: "drugdosage" }).then((res) => {
        if (res.data.code === 200) {
          this.listName = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
      });
      console.log(this.textPhone, this.endReasonOne, "查看");
      console.log(this.noTaking, "10000000000");
      // if (this.noTaking == false&&this.isClick==true) {
      //   this.dialogVisibles = true;
      // }else if(this.noTaking == true){
      //   this.dialogVisibles = true;
      // }else {
      this.dialogVisibles = true;
      this.MedicationId = val.id;
      console.log(val, "查看点击出来的内容");
      this.checkboxs.forEach((item) => {
        item.time = "";
      });
      // this.form = {};
      apiShout.getRemindIds({ id: this.MedicationId }).then((res) => {
        console.log(res, "查询详情");
        this.form = res.data.result;
        if (res.data.result.week != null) {
          this.docOne.doctime = res.data.result.week.split(",");
        }
        // this.docOne.doctime = this.docOne.doctime.map((item) => Number(item));
        console.log("输出回显：", this.docOne.doctime);
        console.log(this.form.unit, "查询123");
        this.comPany(this.form.unit);
        let formTime = res.data.result.childs;
        let checkArr = [];
        let checkArrOne = [];
        let timeArr = [];
        let item = formTime;
        this.checkboxs = [
          {
            value: "1",
            menu: "早晨",
            time: "8:00",
            num: "",
          },
          {
            value: "2",
            menu: "中午",
            time: "11:00",
            num: "",
          },
          {
            value: "3",
            menu: "晚上",
            time: "17:00",
            num: "",
          },
          {
            value: "4",
            menu: "睡前",
            time: "20:00",
            num: "",
          },
          {
            value: "5",
            menu: "晨起",
            time: "6:00",
            num: "",
          },
        ];
        item.forEach((element) => {
          checkArr.push(element.medicationDate);
          checkArrOne.push(element.value);
          this.checkboxs.forEach((elements) => {
            // console.log(elements,"查看输出的都有哪些；")
            if (elements.value === element.medicationDate) {
              elements.time = element.remindDate;
              elements.num = element.value;
              console.log(elements.time, "输出时间查看");
              // elements.value = element.medicationDate;
              // console.log(elements.value,"输出值查看////")
              //timeArr.push(element.remindDate);
              var content = {};
              content.medicationDate = element.medicationDate;
              content.remindDate = element.remindDate;
              content.value = element.value;
              timeArr.push(content);
              // console.log(elements.time, "查看时间是否正确");
            }
          });
        });
        this.forms = checkArr;
        this.formTimes = timeArr;
        this.formValue = timeArr;
        // this.formsOne=checkArrOne;
        console.log("5656566", this.formTimes, this.forms);
      });
      // }
    },
    querySearchAsync(queryString, cb) {
      console.log(queryString, cb, "123");
      apiShout.getDrugNames({ name: queryString }).then((res) => {
        console.log(res, "搜索显示");
        this.restaurants = res.data.result;
        let formsAll = [];
        for (let i = 0; i < this.restaurants.length; i++) {
          this.formList = this.restaurants[i];
          var formAll = {};
          formAll.value = this.restaurants[i];
          formsAll.push(formAll);
        }
        var restaurants = formsAll;
        var results = restaurants.filter(
          (item) => item.value.indexOf(queryString) >= 0
        );
        // 调用 callback 返回建议列表的数据
        cb(results);
      });
    },
    handleSelect(item) {
      console.log(item, "...");
    },
    //新增
    addList() {
      this.disableds = false;
      this.formOne.drug = "";
      this.forms = [];
      // this.formOne.value = "";
      this.formOne.unit = "1";
      this.formOne.take = "4";
      this.formOne.enable = "1";
      this.formOne.sendType = "1";
      this.showOne = true;
      this.doc.doctime = ["1", "2", "3", "4", "5", "6", "7"];
      // this.doc.doctime = [1, 2, 3, 4, 5, 6, 7];
      this.checkboxs.forEach((element) => {
        element.num = "";
      });
      // this.formOne.status = "";
      this.dialogVisible = true;
      api.smallLei({ dictCode: "drugdosage" }).then((res) => {
        if (res.data.code == 200) {
          console.log(res, "查询");
          this.listName = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
      });
      this.comPany(1);
    },
    //取消
    cancel() {
      this.dialogVisibles = false;
      this.aValue = "";
      // this.SpringFrame = false;
    },
    //取消
    cancels() {
      this.dialogVisible = false;
      this.aValue = "";
      console.log(this.userIds, "123123123123");
      // this.SpringFrame = false;
    },
    //关闭弹框的事件
    closeDialog() {
      this.aValue = ""; //清空数据
      this.formOne.sendTime1 = "";
      this.formOne.sendTime2 = "";
    },
    //新增保存
    preservations() {
      this.$confirm("是否保存此信息?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(this.formValue, "-----------------this.formValue");
          this.form.medications = [];
          let params = [];
          this.formValue.forEach((item) => {
            params.push({
              medicationDate: item,
              remindDate: this.checkboxs[item - 1].time,
              value: this.checkboxs[item - 1].num,
            });
          });
          console.log("0000000000000:", params);
          // console.log(this.formTimes, "-----------------this.formTimes");
          let UniqueIds = JSON.parse(localStorage.getItem("UniqueIdn"));
          this.formOne.mainUniqueId = UniqueIds;
          this.formOne.medications = JSON.stringify(params);
          this.formOne.memberId = this.userIds;
          let numArray = this.doc.doctime.sort((a, b) => a - b);
          // console.log("输出排序的值：", numArray);
          this.formOne.week = numArray.join(",");
          // this.formOne.week = this.doc.doctime.join(",");
          let formParams = JSON.parse(JSON.stringify(this.formOne));

          delete formParams.childs;

          console.log(formParams, "paramsparamsparamsparamsparamsparams");
          if (formParams.take == "") {
            this.$message.error("请勾选药品服用方式");
            return false;
          }
          let ll = JSON.parse(formParams.medications);
          ll.forEach((ele) => {
            if (ele.value == "") {
              this.$message.error("请填写用量");
              return false;
            }
          });
          if (formParams.unit == "" || formParams.value == "") {
            this.$message.error("请填写用量");
            return false;
          }
          if (formParams.drug == "") {
            this.$message.error("请填写药品名称");
            return false;
          }
          if (formParams.medications == "[]") {
            this.$message.error("请填写用药时间");
            return false;
          }
          if (formParams.memberId == null) {
            this.$message.error("用户id不能为空");
            return false;
          }
          if (formParams.week.length == 0) {
            this.$message.error("请选择循环发送");
            return false;
          }
          apiShout.insertReminds(formParams).then((res) => {
            console.log(res, "新增数据");
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "新增成功!",
              });
              this.getMemberIds();
              this.dialogVisible = false;
              this.formTimes = [];
              this.form.medications = [];
              this.checkboxs = [];
              this.checkboxs = [
                {
                  value: "1",
                  menu: "早晨",
                  time: "8:00",
                  num: "",
                },
                {
                  value: "2",
                  menu: "中午",
                  time: "11:00",
                  num: "",
                },
                {
                  value: "3",
                  menu: "晚上",
                  time: "17:00",
                  num: "",
                },
                {
                  value: "4",
                  menu: "睡前",
                  time: "20:00",
                  num: "",
                },
                {
                  value: "5",
                  menu: "晨起",
                  time: "6:00",
                  num: "",
                },
              ];
              console.log("1111:", this.checkboxs);

              // this.SpringFrame = true;
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
    //更新保存
    preservation() {
      this.$confirm("是否保存此信息?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(this.formTimes, "输出");
          console.log(this.aValue, "123123123");
          console.log(this.formValue, "this.formValue");
          this.form.medications = [];
          this.count1 = 0;
          this.count2 = 0;
          this.count3 = 0;
          this.count4 = 0;
          this.count5 = 0;
          if (
            this.aValue == "" &&
            this.formTimes.length == this.formValue.length
          ) {
            // this.formTimes = [];
            this.checkboxs = [
              {
                value: "1",
                menu: "早晨",
                time: "8:00",
                num: "",
              },
              {
                value: "2",
                menu: "中午",
                time: "11:00",
                num: "",
              },
              {
                value: "3",
                menu: "晚上",
                time: "17:00",
                num: "",
              },
              {
                value: "4",
                menu: "睡前",
                time: "20:00",
                num: "",
              },
              {
                value: "5",
                menu: "晨起",
                time: "6:00",
                num: "",
              },
            ];
            let UniqueIds = JSON.parse(localStorage.getItem("UniqueIdn"));
            this.form.mainUniqueId = UniqueIds;
            this.form.medications = [];
            // this.formParams = this.formTimes;
            this.form.medications = JSON.stringify(this.formTimes);
            this.form.memberId = this.userIds;
            let numArray1 = this.docOne.doctime.sort((a, b) => a - b);
            // console.log("输出排序的值：", numArray1);
            this.form.week = numArray1.join(",");
            // this.form.week = this.docOne.doctime.join(",");
            this.formParams = JSON.parse(JSON.stringify(this.form));
            delete this.formParams.childs;
            console.log(this.formParams, "输出");
          } else {
            let params = [];
            let newArr = [];
            for (let i = 0; i < this.formValue.length; i++) {
              if (newArr.indexOf(this.formValue[i]) === -1) {
                newArr.push(this.formValue[i]);
              }
            }
            // console.log('shuchu:',newArr);
            newArr.forEach((item) => {
              params.push({
                medicationDate: item,
                remindDate: this.checkboxs[item - 1].time,
                value: this.checkboxs[item - 1].num,
              });
            });
            console.log("是否有值：", this.formTimes, this.formValue);

            // for (let i = 0; i < this.formValue.length; i++) {
            //   if (this.formValue[i] == 1) {
            //     this.count1 = this.count1 + 1;
            //   }
            //   if (this.formValue[i] == 2) {
            //     this.count2 = this.count2 + 1;
            //   }
            //   if (this.formValue[i] == 3) {
            //     this.count3 = this.count3 + 1;
            //   }
            //   if (this.formValue[i] == 4) {
            //     this.count4 = this.count4 + 1;
            //   }
            //   if (this.formValue[i] == 5) {
            //     this.count5 = this.count5 + 1;
            //   }
            // }
            // if (this.count1 == 2) {
            //   let params1 = [];
            //   params1.push(params.splice(length - 2, 1));
            // }
            // if (this.count2 == 2) {
            //   let params1 = [];
            //   params1.push(params.splice(length - 2, 1));
            // }
            // if (this.count3 == 2) {
            //   let params1 = [];
            //   params1.push(params.splice(length - 2, 1));
            // }
            // if (this.count4 == 2) {
            //   let params1 = [];
            //   params1.push(params.splice(length - 2, 1));
            // }
            // if (this.count5 == 2) {
            //   let params1 = [];
            //   params1.push(params.splice(length - 2, 1));
            // }

            // console.log('101010101:',params1);
            // console.log(this.formTimes, "-----------------this.formTimes");
            let UniqueIds = JSON.parse(localStorage.getItem("UniqueIdn"));
            this.form.mainUniqueId = UniqueIds;
            this.form.medications = JSON.stringify(params);
            this.form.memberId = this.userIds;
            let numArray2 = this.docOne.doctime.sort((a, b) => a - b);
            // console.log("输出排序的值：", numArray2);
            this.form.week = numArray2.join(",");

            // this.form.week = this.docOne.doctime.join(",");
            this.formParams = JSON.parse(JSON.stringify(this.form));
            delete this.formParams.childs;
            console.log(
              this.formParams,
              "paramsparamsparamsparamsparamsparams"
            );
          }
          if (this.formParams.take == "") {
            this.$message.error("请勾选药品服用方式");
            return false;
          }
          let ll = JSON.parse(this.formParams.medications);
          ll.forEach((ele) => {
            if (ele.value == "") {
              this.$message.error("请填写用量");
              return false;
            }
          });
          // console.log("22222:", ll);
          if (this.formParams.unit == "" || this.formParams.value == "") {
            this.$message.error("请填写用量");
            return false;
          }
          if (this.formParams.drug == "") {
            this.$message.error("请填写药品名称");
            return false;
          }
          if (this.formParams.medications == "[]") {
            this.$message.error("请填写用药时间");
            return false;
          }
          if (this.formParams.week.length == 0) {
            this.$message.error("请选择循环发送");
            return false;
          }
          apiShout.updateReminds(this.formParams).then((res) => {
            console.log(res, "修改数据");
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "更新成功!",
              });
              this.getMemberIds();
              this.dialogVisibles = false;
              this.formTimes = [];
              this.form.medications = [];
              this.aValue = "";
              this.formValue = [];
              this.checkboxs = [];
              this.checkboxs = [
                {
                  value: "1",
                  menu: "早晨",
                  time: "8:00",
                  num: "",
                },
                {
                  value: "2",
                  menu: "中午",
                  time: "11:00",
                  num: "",
                },
                {
                  value: "3",
                  menu: "晚上",
                  time: "17:00",
                  num: "",
                },
                {
                  value: "4",
                  menu: "睡前",
                  time: "20:00",
                  num: "",
                },
                {
                  value: "5",
                  menu: "晨起",
                  time: "6:00",
                  num: "",
                },
              ];
              // this.SpringFrame = true;
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
    //编辑
    edit() {
      this.dialogVisible = true;
    },
    //增加
    increase() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-autocomplete-suggestion {
  width: 312px !important;
}
/deep/ .el-scrollbar {
  overflow: hidden;
  position: relative;
  width: 311px;
}
.contentBox {
  height: auto;
  background: #fff;
  padding: 5px;
  margin: 12px 5px;
}
.headersTitle {
  font-size: 20px;
  font-weight: bold;
}
.headersAdd {
  padding: 8px 10px;
  border: 1px solid #333;
  margin-left: 15px;
  background: #f09c83;
  color: #fff;
}
/deep/ .el-form-item {
  margin-bottom: 0px;
}
/deep/ .el-input__inner {
  margin-left: 0px !important;
}
/deep/ .el-dialog__body {
  font-size: 14px;
  word-break: break-all;
}
/deep/.el-dialog__headerbtn {
  font-size: 20px;
}
/deep/.el-dialog__wrapper {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
}
.senOne {
  margin-right: 10px;
}
.sendFirst {
  width: 85%;
}
.senTwo {
  margin-right: 10px;
}
.zhouOne {
  display: flex;
  // margin-top: 5px;
}
.zhou {
  font-size: 15px;
  margin-right: 5px;
  margin-top: 2px;
}
/deep/ .el-checkbox__label {
  padding-left: 5px;
}
/deep/ .el-checkbox {
  color: #606266;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
  margin-right: 5px;
}
.xunhuan {
  margin-top: 2px;
}
.xunhuanOne {
  display: flex;
}
</style>