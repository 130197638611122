<template>
  <div :style="{ height: clientHeight - 30 + 'px' }" style="overflow-y: scroll">
    <div ref="clink" style="display: none"></div>
    <!-- 最上边 -->
    <div class="center-top" v-if="isListion">
      <div class="top-name"></div>
      <div class="lisitions" v-if="PersonstatusIng">
        {{ this.Personstatus }}
      </div>
      <div class="top-tome">
        <!-- <div class="timer">
          <div class="time-card" data-type="hours" data-max="24">
            <div class="time-card-count">{{ myHours }}</div>
          </div>
          <span class="colon">:</span>
          <div class="time-card" data-type="minutes" data-max="60">
            <div class="time-card-count">{{ myMinutes }}</div>
          </div>
          <span class="colon">:</span>
          <div class="time-card" data-type="seconds" data-max="60">
            <div class="time-card-count">{{ mySeconds }}</div>
          </div>
        </div> -->
      </div>
      <div class="top-tu">
        <audio src="../../assets/6027.mp3" ref="audioLing" loop></audio>
        <div class="top-tu-one">
          <img
            src="../../assets/images/top03.jpg"
            alt=""
            @click="callBackMessage"
            v-if="imgIcon"
            style="margin-left: 10px"
          />
          <el-button
            v-if="imgIcons"
            :disabled="true"
            style="border: none; color: rgb(152, 140, 140); padding: 0px"
          >
            <img src="../../assets/images/top03.jpg" />
          </el-button>
        </div>
        <div class="top-tu-one">
          <img
            src="../../assets/images/手机.png"
            alt=""
            @click="callBackStandby"
            v-if="StandbyIcon"
            style="
              border: none;
              color: rgb(152, 140, 140);
              padding: 0px;
              margin-left: 10px;
              margin-top: -2px;
            "
          />
          <el-button
            v-if="StandbyIcons"
            :disabled="true"
            style="
              border: none;
              color: rgb(152, 140, 140);
              padding: 0px;
              margin-left: 10px;
              margin-top: 5px;
            "
          >
            <img src="../../assets/images/手机.png" />
          </el-button>
        </div>
        <div class="top-tu-one">
          <img
            @click="imgIconBtn"
            v-if="imgIconBtnOne"
            src="../../assets/images/top07.jpg"
            alt=""
          />
          <el-button
            v-if="imgJinJi"
            :disabled="true"
            style="border: none; color: rgb(152, 140, 140); padding: 0px"
          >
            <img src="../../assets/images/top07.jpg" />
          </el-button>
          <el-select
            v-if="imgIconBtns"
            v-model="regions"
            @change="regionsOne(regions)"
            placeholder="请选择紧急联系人"
            style="
              width: 162px;
              height: 38px;
              position: absolute;
              top: 0px;
              margin-left: 12px;
            "
          >
            <el-option
              v-for="item in region"
              :key="item.phone"
              :label="item.userName"
              :value="item.phone"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="top-rig">
        <div class="top-right">
          <span v-if="isFour" class="fourCall">400呼叫</span>
          <span v-if="isServer" class="fourCall">客服直呼</span>
          <span v-if="isServer1" class="fourCall">客服回拨</span>
          <img
            :class="[{ r1: shrinkPacket }, { r2: !shrinkPacket }]"
            src="../../assets/images/ling03.jpg"
            alt=""
          />
        </div>
        <!-- <div class="top-time">00:25</div> -->
        <div class="top-jie">
          <img
            src="../../assets/images/ling05.jpg"
            alt=""
            @click="listion"
            v-if="isAnswer"
          />
        </div>
        <div class="top-zhuangtai">
          <span class="my-zhuangtai">我的状态</span>
          <el-select
            v-model="valuezhuangtai"
            placeholder="请选择"
            style="width: 100px; height: 30px"
            @change="myStatus"
          >
            <el-option
              v-for="item in optionszhuangtai"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @click.native="myStatusValue(item.value)"
            >
            </el-option>
          </el-select>
          <div class="top-tonghua" v-if="isArrangement">
            <div class="timer">
              <div class="time-card" data-type="hours" data-max="24">
                <div class="time-card-count">{{ myHours2 }}</div>
              </div>
              <span class="colon">:</span>
              <div class="time-card" data-type="minutes" data-max="60">
                <div class="time-card-count">{{ myMinutes2 }}</div>
              </div>
              <span class="colon">:</span>
              <div class="time-card" data-type="seconds" data-max="60">
                <div class="time-card-count">{{ mySeconds2 }}</div>
              </div>
            </div>
          </div>
          <div class="top-tonghua" v-if="isArrangement1">
            请稍后，正在整理中...
          </div>
        </div>
      </div>
    </div>
    <!-- 最上边接听之后的 -->
    <div class="center-top" v-if="isListioning">
      <div class="top-name">
        {{ formMember.name
        }}<span style="margin-left: 10px; font-size: 16px">{{ huiPhone }}</span>
      </div>
      <div class="lisitions">接听中</div>
      <!-- <div class="top-tome">
        <div class="timer">
          <div class="time-card" data-type="hours" data-max="24">
            <div class="time-card-count">{{ myHours1 }}</div>
          </div>
          <span class="colon">:</span>
          <div class="time-card" data-type="minutes" data-max="60">
            <div class="time-card-count">{{ myMinutes1 }}</div>
          </div>
          <span class="colon">:</span>
          <div class="time-card" data-type="seconds" data-max="60">
            <div class="time-card-count">{{ mySeconds1 }}</div>
          </div>
        </div>
      </div> -->
      <div class="top-tu">
        <div class="top-tu-one">
          <img
            src="../../assets/images/images/jingyin_03.jpg"
            alt=""
            @click="jinyin"
            v-if="isYIN"
            style="margin-left: 10px"
          />
          <img
            src="../../assets/取消静音.png"
            alt=""
            @click="jinyin"
            v-if="isJIN"
            style="margin-left: 10px"
          />
        </div>
        <div class="top-tu-one">
          <img
            src="../../assets/images/images/zhuanjie_05.jpg"
            alt=""
            @click="zhuanjie"
            v-if="zhuanjieimg"
          />
          <el-button
            v-if="zhuanjieimgs"
            :disabled="true"
            style="border: none; color: rgb(152, 140, 140); padding: 0px"
          >
            <img src="../../assets/images/images/zhuanjie_05.jpg" />
          </el-button>
        </div>
        <div class="top-tu-one">
          <img
            src="../../assets/images/images/yiliao_07.jpg"
            alt=""
            @click="yiliaoP"
            v-if="yiliaoPimg"
          />
          <el-button
            v-if="yiliaoPimgs"
            :disabled="true"
            style="border: none; color: rgb(152, 140, 140); padding: 0px"
          >
            <img src="../../assets/images/images/yiliao_07.jpg" />
          </el-button>
        </div>
        <div class="top-tu-one">
          <img
            src="../../assets/images/images/jijiu_09.jpg"
            alt=""
            @click="jijiuP"
            v-if="jijiuPimg"
          />
          <el-button
            v-if="jijiuPimgs"
            :disabled="true"
            style="border: none; color: rgb(152, 140, 140); padding: 0px"
          >
            <img src="../../assets/images/images/jijiu_09.jpg" />
          </el-button>
        </div>
        <div class="top-tu-one">
          <img
            src="../../assets/images/images/jinji_11.jpg"
            alt=""
            @click="jinJiPhone"
            v-if="jinJiPhoneimg"
          />
          <el-button
            v-if="jinJiPhoneimgs"
            :disabled="true"
            style="border: none; color: rgb(152, 140, 140); padding: 0px"
          >
            <img src="../../assets/images/images/jinji_11.jpg" />
          </el-button>
        </div>
        <div class="top-tu-one">
          <img
            src="../../assets/images/images/guaduan_13.jpg"
            alt=""
            v-if="offImgIcon"
            @click="offPhone"
          />
          <el-button
            v-if="offImgIcons"
            :disabled="true"
            style="border: none; color: rgb(152, 140, 140); padding: 0px"
          >
            <img src="../../assets/images/images/guaduan_13.jpg" />
          </el-button>
          <el-button round @click="questBack" v-if="isQues">咨询接回</el-button>
          <el-button round @click="questZhuan" v-if="isQues1"
            >咨询转移</el-button
          >
        </div>
      </div>
      <div class="top-zhuangtai1">
        <span class="my-zhuangtai">我的状态</span>
        <el-select
          v-model="valuezhuangtai"
          placeholder="请选择"
          style="width: 100px; height: 30px"
          :disabled="true"
        >
          <el-option
            v-for="item in optionszhuangtai"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            @click.native="myStatusValue(item.value)"
          >
          </el-option>
        </el-select>
        <div class="top-tonghua">
          <div class="timer">
            <div class="time-card" data-type="hours" data-max="24">
              <div class="time-card-count">{{ myHours2 }}</div>
            </div>
            <span class="colon">:</span>
            <div class="time-card" data-type="minutes" data-max="60">
              <div class="time-card-count">{{ myMinutes2 }}</div>
            </div>
            <span class="colon">:</span>
            <div class="time-card" data-type="seconds" data-max="60">
              <div class="time-card-count">{{ mySeconds2 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 10px" v-if="isDiv"></div>
    <!-- 下边 -->
    <div class="bottom">
      <!-- 下左边 -->
      <div class="bottom-left">
        <div :class="[cusClass == 1 ? 'badge' : 'badges']">{{ count }}</div>
        <div
          :class="[cusClass == 1 ? 'badge1' : 'badge1s']"
          v-if="isShowCommission"
        >
          {{ Allcount }}
        </div>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="常用知识" name="first">
            <div class="search">
              <div class="search">
                <el-input
                  v-model="searchs"
                  @input="handlChange(searchs)"
                  placeholder="请输入搜索内容"
                  style="width: 180px; margin-top: 10px"
                ></el-input>
                <!--<img
                  class="imgs"
                  src="../../assets/images/query.png"
                  alt
                />-->
              </div>
            </div>
            <div class="left">
              <div class="trees">
                <el-container style="height: 500px">
                  <el-aside width="200px" class="aside-tree">
                    <el-tree
                      :data="dataList"
                      :props="defaultProps"
                      @node-click="handleNodeClick"
                      :filter-node-method="filterNode"
                      ref="tree"
                    >
                    </el-tree>
                  </el-aside>
                </el-container>
              </div>
              <div class="right">
                <p v-text="dataTitle" style="font-weight: bold"></p>
                <p v-html="dataLists"></p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="待我办理" name="second" @on-click="secondList">
            <div>
              <el-table
                :data="tableData"
                @row-click="rowClick"
                highlight-current-row
                style="width: 100%"
                height="730"
                :default-sort="{ prop: 'createDate', order: 'descending' }"
              >
                <el-table-column prop="workNumber" label="工单编号" width="100">
                </el-table-column>
                <el-table-column prop="name" label="通话对象" width="100">
                </el-table-column>
                <el-table-column
                  prop="equipmentNo"
                  label="设备号码"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="remainingTime"
                  label="剩余时间"
                  sortable
                  width="120"
                >
                </el-table-column>
                <el-table-column
                  prop="priority"
                  label="优先级"
                  width="100"
                  sortable
                >
                </el-table-column>
                <el-table-column prop="createDate" label="添加时间" width="180">
                </el-table-column>
                <el-table-column label="处理">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEditTwo(scope.$index, scope.row)"
                      >完成</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="通话记录" name="five">
            <div class="tonghua">
              <!-- <i class="iconfont icon-triangle-left"></i> -->
              <el-date-picker
                v-model="timeDateShow"
                type="date"
                placeholder="选择日期"
                style="margin-right: 13px; margin-top: 3px"
                @change="timeDate(timeDateShow)"
              >
              </el-date-picker>
              <!-- <i class="iconfont icon-triangle-right"></i> -->
              当日累计通话：{{ this.totalTime1 }}
            </div>
            <div>
              <el-table
                :data="tableData1"
                height="697"
                style="width: 100%"
                @row-click="rowBtn"
              >
                <el-table-column prop="memberName" label="通话对象" width="130">
                </el-table-column>
                <el-table-column prop="callMode" label="通话方式" width="130">
                </el-table-column>
                <el-table-column prop="startTime" label="开始时间" width="180">
                </el-table-column>
                <el-table-column
                  prop="bridgeDuration"
                  label="通话时长"
                  width="130"
                >
                </el-table-column>
                <el-table-column prop="qiniuUrl" label="回放录音" width="250">
                  <template slot-scope="scope">
                    <audio
                      @play="handlePlay(scope.$index)"
                      name="audioOne"
                      controls
                      controlslist="nodownload"
                      :src="scope.row.qiniuUrl"
                      @input="$forceUpdate()"
                      @change="$forceUpdate()"
                      type="audio/mp3"
                    ></audio>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="全部待办" name="six" v-if="isShowCommission">
            <div>
              <el-table
                :data="tableData2"
                style="width: 100%"
                height="730"
                @row-click="rowClick"
              >
                <el-table-column prop="workNumber" label="工单编号" width="90">
                </el-table-column>
                <el-table-column prop="name" label="通话对象" width="100">
                </el-table-column>
                <el-table-column
                  prop="equipmentNo"
                  label="设备号码"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="remainingTime"
                  label="剩余时间"
                  width="90"
                >
                </el-table-column>
                <el-table-column prop="priority" label="优先级" width="80">
                </el-table-column>
                <el-table-column prop="createDate" label="添加时间" width="180">
                </el-table-column>
                <el-table-column prop="userName" label="关联客服" width="100">
                </el-table-column>
                <el-table-column label="处理">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >完成</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column label="改派">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="govern(scope.$index, scope.row)"
                      >改派</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 下右边 -->
      <div class="bottom-right">
        <el-tabs
          v-model="activeName1"
          type="card"
          @tab-click="handleClickRight"
        >
          <el-tab-pane label="用户信息" name="first">
            <div class="right-big" style="width: 720px">
              <div class="infor-top">
                <div class="baseinfor">基础信息</div>
                <div class="base-niceng" v-if="isYou">
                  <el-select
                    v-model="valuebase"
                    @change="valuebases(valuebase)"
                    placeholder="请选择用户昵称"
                    style="width: 150px; height: 20px"
                  >
                    <el-option
                      v-for="item in optionsbase"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="base-imgs">
                  <el-input
                    v-model="inputsize"
                    placeholder="请输入关键字"
                    style="width: 150px; margin-left: 20px"
                    v-if="isYou"
                  ></el-input>
                </div>
                <el-button
                  @click="resetForm"
                  style="padding: 8px 13px; margin-left: 12px"
                  v-if="isYou"
                  >清空</el-button
                >
                <el-button
                  style="float: right; padding: 8px 13px; margin-right: 15px"
                  @click="refresh"
                  >刷新</el-button
                >
                <el-popover
                  placement="bottom"
                  width="1070"
                  trigger="click"
                  class="isPopover"
                  ref="taPopover"
                >
                  <el-table
                    :data="gridData"
                    @row-click="rowClicks"
                    max-height="380"
                  >
                    <el-table-column
                      width="150"
                      label="IMEI"
                      property="imei"
                    ></el-table-column>
                    <el-table-column
                      width="110"
                      label="使用人昵称"
                      property="name"
                    ></el-table-column>
                    <el-table-column
                      width="130"
                      label="使用人备用电话"
                      property="phone"
                    ></el-table-column>
                    <el-table-column
                      width="130"
                      label="App注册手机号"
                      property="appUserPhone"
                    ></el-table-column>
                    <el-table-column
                      width="100"
                      label="家庭昵称"
                      property="familyName"
                    ></el-table-column>
                    <el-table-column
                      width="100"
                      label="家庭管理员"
                      property="linkOneName"
                    ></el-table-column>
                    <el-table-column
                      width="100"
                      label="管理员电话"
                      property="linkOnePhone"
                    ></el-table-column>
                    <el-table-column
                      width="100"
                      label="紧急联系人"
                      property="linkTwoName"
                    ></el-table-column>
                    <el-table-column
                      width="120"
                      label="紧急联系人电话"
                      property="linkTwoPhone"
                    ></el-table-column>
                  </el-table>
                  <img
                    @click="searchList"
                    class="base-images"
                    src="../../assets/images/query.png"
                    alt
                    slot="reference"
                    v-show="isYou"
                  />
                </el-popover>
              </div>
              <!-- 基本信息 -->
              <div class="base-content">
                <el-form
                  :model="formMember"
                  ref="formMember"
                  :inline="true"
                  :label-position="labelPosition"
                >
                  <el-col :span="10">
                    <el-form-item label="姓 名">
                      <el-input
                        v-model="formMember.name"
                        autocomplete="off"
                        style="width: 140px; margin-left: 11px"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="联系方式">
                      <el-input
                        v-model="formMember.NewsparePhone"
                        autocomplete="off"
                        style="width: 140px; margin-left: 15px"
                        :disabled="true"
                        @input="$forceUpdate()"
                        @change="sphonesOne(formMember.NewsparePhone)"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="家庭ID" class="faimal">
                      <el-input
                        v-model="formMember.familyId"
                        autocomplete="off"
                        :disabled="true"
                        style="width: 125px; margin-left: 15px"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="14">
                    <el-form-item label="紧急联系人">
                      <el-select
                        style="width: 120px"
                        v-model="formMember.emergencyContactOne"
                        @change="
                          emergencyContact(formMember.emergencyContactOne)
                        "
                        :disabled="true"
                      >
                        <el-option
                          v-for="item in ursents"
                          :key="item.userName"
                          :label="item.userName"
                          :value="item.userName"
                        ></el-option>
                      </el-select>
                      <el-input
                        v-model="formMember.emergencyContactOnePhone"
                        autocomplete="off"
                        style="width: 140px"
                        :disabled="true"
                      ></el-input
                      ><br />
                      <el-select
                        style="width: 120px"
                        v-model="formMember.emergencyContactTwo"
                        @change="
                          emergencyContacts(formMember.emergencyContactTwo)
                        "
                        :disabled="true"
                      >
                        <el-option
                          v-for="item in ursents"
                          :key="item.userName"
                          :label="item.userName"
                          :value="item.userName"
                        >
                        </el-option>
                      </el-select>
                      <el-input
                        v-model="formMember.emergencyContactTwoPhone"
                        autocomplete="off"
                        style="width: 140px"
                        :disabled="true"
                      ></el-input
                      ><br />
                      <!--<el-input
                        style="width: 60px"
                        v-model="formMember.emergencyContactThree"
                      ></el-input>
                      <el-input
                        v-model="formMember.emergencyContactThreePhone"
                        autocomplete="off"
                        style="width: 140px"
                      ></el-input>-->
                    </el-form-item> </el-col
                  ><br />
                  <el-col :span="10">
                    <el-form-item label="性 别">
                      <el-select
                        v-model="formMember.sex"
                        placeholder="请选择"
                        style="width: 140px; margin-left: 11px"
                      >
                        <el-option
                          v-for="item in optionssex"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" class="inforbirth">
                    <el-form-item label="生 日">
                      <el-date-picker
                        v-model="formMember.birthday"
                        type="date"
                        placeholder="选择日期"
                        style="width: 140px; margin-left: 11px"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="本机号码" class="inforbirths">
                      <el-input
                        v-model="formMember.localPhone"
                        autocomplete="off"
                        style="width: 140px; margin-left: 15px"
                        :disabled="true"
                        @input="$forceUpdate()"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="销售分组" class="xiao">
                      <el-input
                        v-model="formMember.groupName"
                        autocomplete="off"
                        style="width: 140px; margin-left: 15px"
                        :disabled="true"
                        @input="$forceUpdate()"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-form-item label="登记位置" class="dengji">
                    <el-select
                      v-model="formMember.province"
                      style="width: 90px"
                      @change="provinces(formMember.province)"
                    >
                      <el-option
                        v-for="item in historyProvince"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-model="formMember.city"
                      style="width: 90px"
                      @change="provincesOne(formMember.city)"
                    >
                      <el-option
                        v-for="item in historyOrderCity"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-model="formMember.county"
                      @change="Fcounty(formMember.county)"
                      style="width: 90px"
                    >
                      <el-option
                        v-for="item in historycounty"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-input
                    class="inforInput"
                    type="textarea"
                    :rows="1"
                    placeholder="请输入内容"
                    v-model="formMember.address"
                  >
                  </el-input>
                  <el-col :span="16" class="shiji">
                    <el-form-item label="实际位置">
                      <div class="shijibox">
                        <a
                          :href="
                            'https://uri.amap.com/marker?position=' +
                            this.xinLog +
                            ',' +
                            this.xinLat +
                            '&name=' +
                            this.xinLoc
                          "
                          target="_blank"
                          style="color: #b9b3b3"
                          >{{ formMember.location }}</a
                        >
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <div
                      style="
                        position: absolute;
                        left: 53%;
                        top: 32%;
                        font-size: 15px;
                        width: 310px;
                      "
                      v-if="isSer"
                    >
                      <div
                        style="
                          border: 1px solid #eee;
                          border-radius: 10px;
                          padding: 5px;
                          float: left;
                          text-align: center;
                          width: 33%;
                        "
                      >
                        <div
                          :style="
                            serverType.basicStatus == 2
                              ? 'color:#d78673;'
                              : 'color:#babac1;'
                          "
                        >
                          基础服务
                        </div>
                        <div
                          :style="
                            serverType.basicStatus == 2
                              ? 'color:#d78673;'
                              : 'color:#babac1;'
                          "
                        >
                          {{ serverType.basicEnd }}
                        </div>
                      </div>
                      <div
                        style="
                          border: 1px solid #eee;
                          border-radius: 10px;
                          padding: 5px;
                          float: left;
                          text-align: center;
                          width: 33%;
                        "
                      >
                        <div
                          :style="
                            serverType.cnoStatus == 2
                              ? 'color:#d78673;'
                              : 'color:#babac1;'
                          "
                        >
                          客服服务
                        </div>
                        <div
                          :style="
                            serverType.cnoStatus == 2
                              ? 'color:#d78673;'
                              : 'color:#babac1;'
                          "
                        >
                          {{ serverType.cnoEnd }}
                        </div>
                      </div>
                      <div
                        style="
                          border: 1px solid #eee;
                          border-radius: 10px;
                          padding: 5px;
                          float: left;
                          text-align: center;
                          width: 33%;
                        "
                      >
                        <div
                          :style="
                            serverType.ymStatus == 2
                              ? 'color:#d78673;'
                              : 'color:#babac1;'
                          "
                        >
                          急救服务
                        </div>
                        <div
                          :style="
                            serverType.ymStatus == 2
                              ? 'color:#d78673;'
                              : 'color:#babac1;'
                          "
                        >
                          {{ serverType.ymEnd }}
                        </div>
                      </div>
                      <div
                        style="
                          border: 1px solid #eee;
                          border-radius: 10px;
                          padding: 5px;
                          float: left;
                          text-align: center;
                          width: 33%;
                        "
                      >
                        <div
                          :style="
                            serverType.healthStatus == 2
                              ? 'color:#d78673;'
                              : 'color:#babac1;'
                          "
                        >
                          健康顾问
                        </div>
                        <div
                          :style="
                            serverType.healthStatus == 2
                              ? 'color:#d78673;'
                              : 'color:#babac1;'
                          "
                        >
                          {{ serverType.healthEnd }}
                        </div>
                      </div>
                      <div
                        style="
                          border: 1px solid #eee;
                          border-radius: 10px;
                          padding: 5px;
                          float: left;
                          text-align: center;
                          width: 33%;
                        "
                      >
                        <div
                          :style="
                            serverType.lawStatus == 2
                              ? 'color:#d78673;'
                              : 'color:#babac1;'
                          "
                        >
                          法律咨询
                        </div>
                        <div
                          :style="
                            serverType.lawStatus == 2
                              ? 'color:#d78673;'
                              : 'color:#babac1;'
                          "
                        >
                          {{ serverType.lawEnd }}
                        </div>
                      </div>
                      <div
                        style="
                          border: 1px solid #eee;
                          border-radius: 10px;
                          padding: 5px;
                          float: left;
                          text-align: center;
                          width: 33%;
                        "
                      >
                        <div
                          :style="
                            serverType.careStatus == 2
                              ? 'color:#d78673;'
                              : 'color:#babac1;'
                          "
                        >
                          关爱慰问
                        </div>
                        <div
                          :style="
                            serverType.careStatus == 2
                              ? 'color:#d78673;'
                              : 'color:#babac1;'
                          "
                        >
                          {{ serverType.careEnd }}
                        </div>
                      </div>
                      <div
                        style="
                          border: 1px solid #eee;
                          border-radius: 10px;
                          padding: 5px;
                          float: left;
                          text-align: center;
                          width: 33%;
                        "
                      >
                        <div
                          :style="
                            serverType.contactStatus == 2
                              ? 'color:#d78673;'
                              : 'color:#babac1;'
                          "
                        >
                          呼叫联系人
                        </div>
                        <div
                          :style="
                            serverType.contactStatus == 2
                              ? 'color:#d78673;'
                              : 'color:#babac1;'
                          "
                        >
                          {{ serverType.contactEnd }}
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <!-- <el-col :span="8" class="jihuo">
                    <el-form-item label="激活日期">
                      <el-input
                        v-model="formMembers.joinDate"
                        autocomplete="off"
                        style="width: 110px"
                        :disabled="true"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" class="daoqi">
                    <el-form-item label="到期日期">
                      <el-input
                        v-model="formMembers.leaveDate"
                        :disabled="true"
                        autocomplete="off"
                        style="width: 110px"
                      ></el-input>
                    </el-form-item>
                  </el-col> -->
                </el-form>
              </div>
              <div class="big-center">
                <!-- 生活状况 -->
                <div class="life-status">
                  <div class="life-zhuangtai">生活状况</div>
                  <table>
                    <tr>
                      <td>居住情况</td>
                      <td>
                        <el-select
                          v-model="formMemberInfo.live"
                          style="width: 100px"
                        >
                          <el-option
                            v-for="item in live"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </td>
                    </tr>
                    <tr>
                      <td>智力状态</td>
                      <td>
                        <el-select
                          v-model="formMemberInfo.intellect"
                          style="width: 100px"
                        >
                          <el-option
                            v-for="item in zhiLi"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </td>
                    </tr>
                    <tr>
                      <td>性格特征</td>
                      <td>
                        <el-select
                          v-model="formMemberInfo.character"
                          style="width: 100px"
                        >
                          <el-option
                            v-for="item in xingge"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </td>
                    </tr>
                    <tr class="shenti">
                      <td>身体状态</td>
                      <td>
                        <div class="tdspan">
                          <span
                            >语言：
                            <el-select
                              v-model="formMemberInfo.language"
                              style="width: 100px"
                            >
                              <el-option
                                v-for="item in yuyan"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </span>
                        </div>
                        <div class="tdspan">
                          <span
                            >听力：
                            <el-select
                              v-model="formMemberInfo.hearing"
                              style="width: 100px"
                            >
                              <el-option
                                v-for="item in tingli"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </span>
                        </div>
                        <div class="tdspan">
                          <span
                            >视力：
                            <el-select
                              v-model="formMemberInfo.vision"
                              style="width: 100px"
                            >
                              <el-option
                                v-for="item in shili"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </span>
                        </div>
                        <div class="tdspan">
                          <span>
                            行动：
                            <el-select
                              v-model="formMemberInfo.action"
                              style="width: 100px"
                            >
                              <el-option
                                v-for="item in xingdong"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>慢性病史</td>
                      <td>
                        <!-- <el-input
                          v-model="formMemberInfo.illness"
                          placeholder=""
                          style="width: 30%; display: block"
                          v-if="isZhan1"
                        ></el-input> -->
                        <div
                          class="healLabel"
                          v-html="formMemberInfo.illness"
                          @click="chrIllinse"
                          v-if="isZhan1"
                        ></div>
                        <el-input
                          v-model="inputman"
                          placeholder="请输入慢性病"
                          v-if="isZhan"
                          @change="zhanChange"
                          style="width: 30%"
                        ></el-input>
                      </td>
                    </tr>
                    <tr>
                      <td class="infor-img">
                        <img
                          @click="addInput"
                          src="../../assets/images/add.png"
                          alt=""
                        />
                      </td>
                    </tr>
                  </table>
                  <el-button
                    class="buttons baocun"
                    type="primary"
                    round
                    @click="preservation"
                    :disabled="isSave"
                    >保存</el-button
                  >
                </div>
                <!-- 健康数据 -->
                <!-- <div class="jiangkang">
                  <div class="life-zhuangtai">健康数据</div>
                  <table>
                    <tr>
                      <td>血压</td>
                      <td>{{ forBody.bloodPressure }}</td>
                    </tr>
                    <tr>
                      <td>血糖</td>
                      <td>
                        <div class="tdspan">
                          <span>{{ forBody.bloodSugar }}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>血氧</td>
                      <td>{{ forBody.oxygen }}</td>
                    </tr>
                    <tr>
                      <td>心率</td>
                      <td>{{ forBody.heartRate }}</td>
                    </tr>
                    <tr>
                      <td>体温</td>
                      <td>{{ forBody.temperature }}</td>
                    </tr>
                    <tr>
                      <td>睡眠</td>
                      <td>{{ forBody.sleep }}</td>
                    </tr>
                    <tr>
                      <td>用药安排</td>
                      <td>
                        <div class="tdspan">
                          <span
                            >{{ morningbefor.medicationDate }}
                            {{ morningbefor.drugCode }}
                            {{ morningbefor.unit }}</span
                          >
                        </div>
                        <div class="tdspan">
                          <span
                            >{{ morningafter.medicationDate }}
                            {{ morningafter.drugCode }}
                            {{ morningafter.unit }}</span
                          >
                        </div>
                        <div class="tdspan">
                          <span
                            >{{ lunchbefor.medicationDate }}
                            {{ lunchbefor.drugCode }}
                            {{ lunchbefor.unit }}</span
                          >
                        </div>
                        <div class="tdspan">
                          <span
                            >{{ lunchafter.medicationDate }}
                            {{ lunchafter.drugCode }}
                            {{ lunchafter.unit }}</span
                          >
                        </div>
                        <div class="tdspan">
                          <span
                            >{{ eveningbefor.medicationDate }}
                            {{ eveningbefor.drugCode }}
                            {{ eveningbefor.unit }}</span
                          >
                        </div>
                        <div class="tdspan">
                          <span>晚餐后 xxxx 1颗</span>
                        </div>
                      </td>
                    </tr>
                  </table>
                  <span class="buttons baocun" @click="preservation">保存</span>
                </div> -->
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="工单管理" name="second">
            <div class="history" style="width: 720px">
              <div class="historydan">历史工单</div>
              <ul class="history-ul">
                <li v-for="(item, index) in historyList" :key="index">
                  <div class="suggest">{{ item.type }}</div>
                  <span class="times">{{ item.createDate }}</span
                  ><span class="person">客服</span
                  ><span class="times">{{ item.userName }}</span>
                  <span class="person-id">IMEI</span
                  ><span class="times">{{ item.equipmentNo }}</span>
                  <span class="person-id">ID</span
                  ><span class="times">{{ item.workNumber }}</span>
                  <div class="suggest1">{{ item.typeDetail }}</div>
                  <div class="content">{{ item.describe }}</div>
                </li>
              </ul>
            </div>
            <div class="create-order">
              <div class="historydan1">创建工单</div>
              <span>
                <el-select
                  v-model="valueOrder"
                  placeholder="请选择"
                  style="width: 120px"
                  @change="changexiaLa"
                >
                  <el-option
                    v-for="item in historyOrder"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </span>
              <div class="infor-content" v-if="isXian" ref="multipleTable">
                <div class="inforwrite">
                  <div class="fenglei">{{ danName }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group
                      v-model="zz"
                      @change="checkboxChange(zz)"
                    >
                      <el-checkbox
                        v-for="item in checkList"
                        :key="item.id"
                        :label="item.id"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="his-right">
                  <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入内容"
                    v-model="textarea"
                    maxlength="100"
                    show-word-limit
                  >
                  </el-input>
                  <div class="infor-bottom">
                    <span
                      ><el-checkbox
                        label="需要回电"
                        @change="callChange"
                        v-model="callback1"
                        true-label="1"
                        false-label="0"
                      ></el-checkbox
                    ></span>
                    <span>
                      <el-select
                        v-if="valueType"
                        v-model="valueOrdertime"
                        style="width: 80px"
                        @change="handelChange"
                      >
                        <el-option
                          v-for="item in historyOrdertime"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </span>
                    <span>
                      <el-select
                        v-if="valueType"
                        v-model="valueOrderputong"
                        style="width: 80px"
                        @change="handelChangeHistory"
                      >
                        <el-option
                          v-for="item in historyOrderputong"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </span>
                    <div>
                      <span class="guan" v-if="valueType">
                        <img
                          src="../../assets/images/add.png"
                          alt=""
                          @click="Associatedpersonnel"
                        /><span>{{ usersName }}</span>
                      </span>
                      <el-button type="primary" @click="finish" class="buttons"
                        >提交</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="infor-content" v-if="isXian1">
                <div class="inforwrite">
                  <div class="fenglei">{{ danName1 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group
                      v-model="zz1"
                      @change="checkboxChange1(zz1)"
                    >
                      <el-checkbox
                        v-for="item in checkList1"
                        :key="item.id"
                        :label="item.id"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                  <div class="fenglei">{{ danName2 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group
                      v-model="zz2"
                      @change="checkboxChange2(zz2)"
                    >
                      <el-checkbox
                        v-for="item in checkList2"
                        :key="item.id"
                        :label="item.id"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="his-right">
                  <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入内容"
                    v-model="textarea"
                    maxlength="100"
                    show-word-limit
                  >
                  </el-input>
                  <div class="infor-bottom">
                    <span>回电时间</span>
                    <el-select
                      v-model="valueOrdertimes"
                      style="width: 120px"
                      @change="handelChange1"
                    >
                      <el-option
                        v-for="item in historyOrdertimes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div>
                      <!-- <span class="guan">
                        <img
                          src="../../assets/images/add.png"
                          alt=""
                          @click="Associatedpersonnel"
                        /><span>关联相关人员</span>
                      </span> -->
                      <el-button type="primary" @click="finish1" class="buttons"
                        >提交</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="infor-content" v-if="isXian2">
                <div class="inforwrite">
                  <div class="fenglei">{{ danName3 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group
                      v-model="zz3"
                      @change="checkboxChange3(zz3)"
                    >
                      <el-checkbox
                        v-for="item in checkList3"
                        :key="item.id"
                        :label="item.id"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                  <div class="fenglei">{{ danName4 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group
                      v-model="zz4"
                      @change="checkboxChange4(zz4)"
                    >
                      <el-checkbox
                        v-for="item in checkList4"
                        :key="item.id"
                        :label="item.id"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="his-right">
                  <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入内容"
                    v-model="textarea"
                    maxlength="100"
                    show-word-limit
                  >
                  </el-input>
                  <div class="infor-bottom">
                    <span>回电时间</span>
                    <el-select
                      v-model="valueOrdertimes"
                      style="width: 120px"
                      @change="handelChange2"
                    >
                      <el-option
                        v-for="item in historyOrdertimes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div>
                      <!-- <span class="guan">
                        <img
                          src="../../assets/images/add.png"
                          alt=""
                          @click="Associatedpersonnel"
                        /><span>关联相关人员</span>
                      </span> -->
                      <el-button type="primary" @click="finish2" class="buttons"
                        >提交</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="infor-content" v-if="isXian3">
                <div class="inforwrite">
                  <div class="fenglei">{{ danName5 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group
                      v-model="zz5"
                      @change="checkboxChange5(zz5)"
                    >
                      <el-checkbox
                        v-for="item in checkList5"
                        :key="item.id"
                        :label="item.id"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                  <div class="fenglei">{{ danName6 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group
                      v-model="zz6"
                      @change="checkboxChange6(zz6)"
                    >
                      <el-checkbox
                        v-for="item in checkList6"
                        :key="item.id"
                        :label="item.id"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="his-right">
                  <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入内容"
                    v-model="textarea"
                    maxlength="100"
                    show-word-limit
                  >
                  </el-input>
                  <div class="infor-bottom">
                    <span>回电时间</span>
                    <el-select
                      v-model="valueOrdertimes"
                      style="width: 120px"
                      @change="handelChange3"
                    >
                      <el-option
                        v-for="item in historyOrdertimes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div>
                      <!-- <span class="guan">
                        <img
                          src="../../assets/images/add.png"
                          alt=""
                          @click="Associatedpersonnel"
                        /><span>关联相关人员</span>
                      </span> -->
                      <el-button type="primary" @click="finish3" class="buttons"
                        >提交</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="infor-content" v-if="isXian4">
                <div class="inforwrite">
                  <div class="fenglei">{{ danName7 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group
                      v-model="zz7"
                      @change="checkboxChange7(zz7)"
                    >
                      <el-checkbox
                        v-for="item in checkList7"
                        :key="item.id"
                        :label="item.id"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                  <div class="fenglei">{{ danName8 }}</div>
                  <div class="radio-dan">
                    <el-checkbox-group
                      v-model="zz8"
                      @change="checkboxChange8(zz8)"
                    >
                      <el-checkbox
                        v-for="item in checkList8"
                        :key="item.id"
                        :label="item.id"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="his-right">
                  <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入内容"
                    v-model="textarea"
                    maxlength="100"
                    show-word-limit
                  >
                  </el-input>
                  <div class="infor-bottom">
                    <span
                      ><el-checkbox
                        true-label="1"
                        false-label="0"
                        @change="callChanges"
                        v-model="callback2"
                        >需要回电</el-checkbox
                      >
                    </span>
                    <span>
                      <el-select
                        v-if="valueTypes"
                        v-model="valueOrdertime1"
                        style="width: 80px"
                        @change="handelChange4"
                      >
                        <el-option
                          v-for="item in historyOrdertime1"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </span>
                    <span>
                      <el-select
                        v-if="valueTypes"
                        v-model="valueOrderputong1"
                        style="width: 80px"
                        @change="handelChangeHistory1"
                      >
                        <el-option
                          v-for="item in historyOrderputong1"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </span>
                    <div>
                      <!-- <span class="guan">
                        <img
                          src="../../assets/images/add.png"
                          alt=""
                          @click="Associatedpersonnel"
                        /><span>关联相关人员</span>
                      </span> -->
                      <el-button type="primary" @click="finish4" class="buttons"
                        >提交</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="远程协助"
            name="third"
            style="
              height: 670px;
              overflow-y: scroll;
              scrollbar-width: none;
              -webkit-overflow-scrolling: touch;
            "
            v-if="isTabs"
          >
            <FamilyUser
              ref="familyUsers"
              :famData="famData"
              :eqfamliyId="eqfamliyId"
              :forDataList="forDataList"
              :strequipmentId="strequipmentId"
              :userId="userId"
              :xinfamDataList="xinfamDataList"
              :xinMembenIdaa="xinMembenIdaa"
              :xinFamilyIdaa="xinFamilyIdaa"
              :noTaking="noTaking"
              :isClick="isClick"
            />
            <EquipmentUser
              ref="equipmentUser"
              :famData="famData"
              :forDataList="forDataList"
              :codeMsg="codeMsg"
              :userId="userId"
              :allNew="allNew"
              :equipmentIdStr="equipmentIdStr"
              :wuFamilyId="wuFamilyId"
              @NewmemData="NewmemData"
              @xinList="xinList"
              @xinMembenId="xinMembenId"
              @xinfamData="xinfamData"
              @xinFamilyId="xinFamilyId"
              :noTaking="noTaking"
              :qingkong="qingkong"
              :isClick="isClick"
              :isActiOne="isActiOne"
              @successCodes="successCodes"
            />
            <EquipmentMaintenance
              ref="RefMemberIds"
              :userId="userId"
              :textPhone="textPhone"
              :endReasonOne="endReasonOne"
              :noTaking="noTaking"
              :isClick="isClick"
            />
            <HealthData
              ref="healthData"
              :memData="memData"
              :eqfamliyId="eqfamliyId"
              :userId="userId"
              :forDataList="forDataList"
              :equipmentIdStr="equipmentIdStr"
              :msgList="msgList"
              :xinMembenIdaa="xinMembenIdaa"
              :xinFamilyIdaa="xinFamilyIdaa"
              :noTaking="noTaking"
              @xinList1="xinList1"
              @successCodeOnes="successCodeOnes"
            />
            <Messages :noTaking="noTaking" :userId="userId" ref="RefgetList" />
            <RadioBroadcast ref="RadioBroadcasts" />
            <Weather ref="Weathers" />
          </el-tab-pane>
          <el-tab-pane
            label="远程协助"
            name="fourth"
            style="
              height: 670px;
              overflow-y: scroll;
              scrollbar-width: none;
              -webkit-overflow-scrolling: touch;
            "
            v-if="isTab"
          >
            <FamilyUser
              ref="familyUsersOne"
              :famData="famData"
              :eqfamliyId="eqfamliyId"
              :forDataList="forDataList"
              :strequipmentId="strequipmentId"
              :userId="userId"
              :xinfamDataList="xinfamDataList"
              :xinMembenIdaa="xinMembenIdaa"
              :xinFamilyIdaa="xinFamilyIdaa"
              :noTaking="noTaking"
              :isClick="isClick"
            />
            <EquipmentUser
              ref="equipmentUserOne"
              :famData="famData"
              :forDataList="forDataList"
              :codeMsg="codeMsg"
              :userId="userId"
              :allNew="allNew"
              :equipmentIdStr="equipmentIdStr"
              :wuFamilyId="wuFamilyId"
              @NewmemData="NewmemData"
              @xinList="xinList"
              @xinMembenId="xinMembenId"
              @xinfamData="xinfamData"
              @xinFamilyId="xinFamilyId"
              @successCodes="successCodes"
              :noTaking="noTaking"
              :qingkong="qingkong"
              :isClick="isClick"
              :isActiOne="isActiOne"
            />
            <EquipmentMaintenance
              :userId="userId"
              ref="RefMemberIdsOne"
              :textPhone="textPhone"
              :endReasonOne="endReasonOne"
              :noTaking="noTaking"
              :isClick="isClick"
            />
            <HealthData
              ref="healthDataOne"
              :memData="memData"
              :eqfamliyId="eqfamliyId"
              :userId="userId"
              :forDataList="forDataList"
              :equipmentIdStr="equipmentIdStr"
              :msgList="msgList"
              :xinMembenIdaa="xinMembenIdaa"
              :xinFamilyIdaa="xinFamilyIdaa"
              :noTaking="noTaking"
              @xinList1="xinList1"
              @successCodeOnes="successCodeOnes"
            />
            <Messages
              :noTaking="noTaking"
              :userId="userId"
              ref="RefgetListOne"
            />
            <RadioBroadcast ref="RadioBroadcastsOne" />
            <Weather ref="WeathersOne" />
          </el-tab-pane>
          <el-tab-pane label="生活服务" name="five">生活服务</el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!--<audio ref="audio" src=""></audio>-->
    <audio
      ref="audioLing"
      loop
      src="../../assets/6027.mp3"
      hidden
      muted
    ></audio>
    <!-- 点击关联人员的一个弹框 -->
    <el-dialog :visible.sync="dialogVisible" width="13%">
      <el-table
        :data="tableDataPerson"
        style="width: 100%"
        @cell-click="cellClick"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="name" label="关联客服" width="180">
        </el-table-column>
      </el-table>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>

    <!-- 点击改派的弹框 -->
    <el-dialog :visible.sync="dialogVisible1" width="20%">
      <el-table
        :data="tableDataPerson"
        style="width: 100%"
        @cell-click="cellClick1"
        @current-change="handleCurrentChange"
        :row-class-name="tableRowClassName1"
      >
        <el-table-column prop="name" label="关联客服" width="180">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="quietChange">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 点击待我办理中的完成时的弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleFinish"
      width="30%"
      :before-close="handleCloseFinish"
    >
      <span>确定要完成该工单吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleFinish = false">取 消</el-button>
        <el-button type="primary" @click="quitFinish">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="选择客服" :visible.sync="dialogVisiblePh" width="30%">
      电话号码：<el-input
        v-model="inputPhsZuan"
        placeholder="请输入电话"
        style="width: 300px"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblePh = false">取 消</el-button>
        <el-button type="primary" @click="phQuit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 紧急联系人 -->
    <el-dialog title="提示" :visible.sync="dialogVisiblejinji" width="30%">
      <div>
        <el-select
          v-model="regions"
          @change="regionOnes(regions)"
          placeholder="请选择紧急联系人"
        >
          <el-option
            v-for="item in region"
            :key="item.phone"
            :label="item.userName"
            :value="item.phone"
          ></el-option>
        </el-select>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblejinji = false">取 消</el-button>
        <el-button type="primary" @click="phQuitjinji">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 急救的弹框 -->
    <el-dialog title="提示" :visible.sync="dialogVisiblejijiu" width="30%">
      电话：<el-input
        v-model="inputPhs"
        placeholder="请输入电话"
        style="width: 300px"
        :disabled="true"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblejijiu = false">取 消</el-button>
        <el-button type="primary" @click="phQuitjijiu">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 医疗的弹框 -->
    <el-dialog title="提示" :visible.sync="dialogVisibleyiliao" width="30%">
      电话：<el-input
        v-model="inputPhyiliao"
        placeholder="请输入电话"
        style="width: 300px"
        :disabled="true"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleyiliao = false">取 消</el-button>
        <el-button type="primary" @click="phQuityiliao">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 换机的弹框 -->
    <el-dialog
      :visible.sync="diaReplacement"
      width="40%"
      :close-on-click-modal="false"
      :modal="false"
    >
      <span>
        执行后，新老设备数据将立即交换，此操作不可逆。请务必在确定用户收到新
        设备后再执行此操作。 是否立即执行换机操作？
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="diaReplacement = false">取 消</el-button>
        <el-button type="primary" @click="queitReplay">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="tellStatus"
      :visible.sync="dialogVisibleTell"
      width="40%"
      class="tellLink"
      :show-close="false"
      :before-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      电话号码：<el-input
        v-model="inputPhsTell"
        placeholder="请输入电话"
        style="width: 300px"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelTell(inputPhsTell)" v-if="closeButton"
          >取消转接</el-button
        >
        <el-button @click="closeTell()" v-if="!closeButton">关 闭</el-button>
        <el-button
          type="primary"
          @click="tellQuit(inputPhsTell)"
          v-if="!closeButton"
          >确认转接</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
import { CodeToText } from "element-china-area-data";
import FamilyUser from "./familyuser.vue";
import EquipmentUser from "./equipmentuser.vue";
import EquipmentMaintenance from "./equipmentmaintenance.vue";
import HealthData from "./healthdata.vue";
import Messages from "./message.vue";
import RadioBroadcast from "./radiobroadcast.vue";
import Weather from "./weather.vue";
// import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  inject: ["reload"],
  // computed:{
  //    ...mapState(['match']),
  // },
  components: {
    FamilyUser,
    EquipmentUser,
    EquipmentMaintenance,
    HealthData,
    Messages,
    RadioBroadcast,
    Weather,
  },
  props: ["fourserStatusee", "twoType2", "fourLis", "passTiao"],
  data() {
    return {
      //转接号码
      dialogVisibleTell: false,
      tellStatus: "手机转接",
      inputPhsTell: "",
      diaReplacement: false,
      replayId: null,
      clientHeight: document.body.clientHeight,
      isZhan1: true,
      cusClass: "",
      hereLis: false,
      isSer: false,
      serverType: {},
      isFour: false,
      isServer: false,
      isServer1: false,
      seType: "",
      codeMsg: null,
      isActiOne: false,
      xinLog: "",
      xinLat: "",
      xinLoc: "",
      imgJinJi: true,
      isArrangement: true,
      isArrangement1: false,
      isTab: false,
      isTabs: true,
      checkListData: [],
      zz: [],
      danName: "",
      checkListData1: [],
      zz1: [],
      danName1: "",
      zz2: [],
      danName2: "",
      checkListData2: [],
      zz3: [],
      danName3: "",
      zz4: [],
      danName4: "",
      checkListData3: [],
      zz5: [],
      danName5: "",
      zz6: [],
      danName6: "",
      checkListData4: [],
      zz7: [],
      danName7: "",
      zz8: [],
      danName8: "",
      isComePhone: false,
      tongQian: false,
      isSave: true,
      isYou: false,
      webUserId: "",
      xinMembenIdaa: "",
      xinFamilyIdaa: "",
      eqfamliyId: "",
      equipmentIdStr: "",
      wuFamilyId: "",
      flagdian: false,
      msgList: [],
      xinfamDataList: [],
      isYIN: true,
      isJIN: false,
      isQues: false,
      isQues1: false,
      regions: "",
      regionTong: [],
      region: [],
      usersName: "关联相关人员",
      dialogVisiblePh: false,
      dialogVisiblejinji: false,
      dialogVisiblejijiu: false,
      dialogVisibleyiliao: false,
      inputPhyiliao: "",
      inputPhs: "",
      inputPhsZuan: "",
      endStatus: "0",
      UniqueIds: "",
      inputman: "",
      isZhan: false,
      zhanValue: "",
      lala: 4,
      audioElement: [], // 创建一个数组
      valueType: false,
      valueTypes: false,
      timeDateShow: "",
      dialogVisibleFinish: false, //待我办理的完成
      isShowCommission: true, //全部待办是否显示
      imgIcon: false,
      imgIcons: true,
      offImgIcon: false,
      offImgIcons: false,
      StandbyIcon: false,
      StandbyIcons: true,
      imgIconBtnOne: false,
      imgIconBtns: false,
      isAnswer: false, //接听按钮是否显示
      userName: "", //用户名字
      dialogVisible: false,
      dialogVisible1: false,
      // Personstatus: "空闲中",
      Personstatus: "",
      PersonstatusIng: false,
      inputPhone: "",
      options: {
        isAutoLogin: true, //隐藏登录界面
        showHold: true,
        showConsult: true,
        showTransfer: true,
        showSatisfaction: true,
        showMute: true,
      },
      isListion: true, //是否接听
      isListioning: false, //正在接听
      //待我办理 和全部办理的数
      count: "",
      Allcount: "",
      //通话记录中的录音播放
      isBroadcast: true,
      isPlays: false,
      labelPosition: "right",
      num: "a",
      //最上边的客服状态
      valuezhuangtai: "忙碌",
      optionszhuangtai: [
        {
          value: "0",
          label: "空闲",
        },
        {
          value: "1",
          label: "忙碌",
        },
        {
          value: "2",
          label: "暂离",
        },
        {
          value: "3",
          label: "手机接听",
        },
      ],
      //下左边
      activeName: "first",
      activeName1: "first",
      activeName2: "first",
      valuedate: "2021-10-25",
      input: "",
      tableList: false,
      createId: null,
      tableData: [],
      tableData1: [],
      totalTime1: "", //通话记录总时长
      tableData2: [],
      dataList: [],
      dataLists: "",
      dataTitle: "",
      searchs: "",
      defaultProps: {
        children: "childs",
        label: "title",
      },
      //创建工单中的 信息记录下拉
      type: "",
      checkList: [],
      checkList1: [],
      checkList2: [],
      checkList3: [],
      checkList4: [],
      checkList5: [],
      checkList6: [],
      checkList7: [],
      checkList8: [],
      handleTime: "",
      priority: "",
      historyOrder: [],
      valueOrder: "",
      historyinput: "",
      radio: "1",
      textarea: "",
      historyOrdertime: [
        {
          value: "1",
          label: "1小时",
        },
        {
          value: "2",
          label: "2小时",
        },
        {
          value: "3",
          label: "3小时",
        },
        {
          value: "6",
          label: "6小时",
        },
        {
          value: "24",
          label: "24小时",
        },
        {
          value: "48",
          label: "48小时",
        },
        {
          value: "72",
          label: "72小时",
        },
        {
          value: "0",
          label: "不限制",
        },
      ],
      historyOrdertime1: [
        {
          value: "1",
          label: "1小时",
        },
        {
          value: "2",
          label: "2小时",
        },
        {
          value: "3",
          label: "3小时",
        },
        {
          value: "6",
          label: "6小时",
        },
        {
          value: "24",
          label: "24小时",
        },
        {
          value: "48",
          label: "48小时",
        },
        {
          value: "72",
          label: "72小时",
        },
        {
          value: "0",
          label: "不限制",
        },
      ],
      historyOrdertimes: [
        {
          value: "0",
          label: "不限时间",
        },
        {
          value: "上午（09:00-11:00）",
          label: "上午（09:00-11:00）",
        },
        {
          value: "下午（13:00-17:00）",
          label: "下午（13:00-17:00）",
        },
      ],
      valueOrdertime: "",
      valueOrdertime1: "",
      valueOrdertimes: "",
      historyOrderputong: [
        {
          value: "1",
          label: "紧急",
        },
        {
          value: "2",
          label: "高",
        },
        {
          value: "3",
          label: "普通",
        },
        {
          value: "4",
          label: "低",
        },
      ],
      historyOrderputong1: [
        {
          value: "1",
          label: "紧急",
        },
        {
          value: "2",
          label: "高",
        },
        {
          value: "3",
          label: "普通",
        },
        {
          value: "4",
          label: "低",
        },
      ],
      valueOrderputong: "",
      valueOrderputong1: "",
      gridData: [],
      optionsbase: [
        {
          value: "1",
          label: "昵称",
        },
        {
          value: "3",
          label: "IMEI",
        },
        {
          value: "7",
          label: "本机号码",
        },
        {
          value: "2",
          label: "使用人备用手机",
        },
        {
          value: "4",
          label: "紧急联系人姓名",
        },
        {
          value: "5",
          label: "紧急联系人电话",
        },
        {
          value: "6",
          label: "app注册手机号",
        },
      ],
      valuebase: "",
      inputsize: "",
      formMembers: {}, //信息查询的激活日期等
      formMemberInfo: {
        createDate: null,
        updateDate: null,
        bloodPressureDate: null,
        bloodSugarDate: null,
        heartRateDate: null,
        temperatureDate: null,
        sleepDate: null,
        walkDate: null,
        illness: null,
      }, //信息查询的激活日期等
      formMember: {
        createDate: [],
        updateDate: [],
        joinDate: [],
        leaveDate: [],
        localPhone: "",
      }, //信息查询的基础信息
      ursents: [],
      ursentsTwo: [],
      form: {},
      forBody: {}, //健康数据
      morningbefor: {}, //早餐前
      morningafter: {}, //早餐后
      lunchbefor: {}, //午餐前
      lunchafter: {}, //午餐后
      eveningbefor: {}, //晚餐前
      //性别下拉
      optionssex: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "0",
          label: "女",
        },
      ],
      value: "",
      value1: "", //日期下拉
      province: [], //登记位置
      city: "", //登记位置
      county: "", //登记位置
      historyProvince: [],
      historyOrderCity: [],
      historycounty: [],
      //生活状况
      lifeform: {
        name: "",
        phone: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      //生活状况 居住
      live: [
        {
          value: "2",
          label: "独居（无保姆）",
        },
        {
          value: "1",
          label: "与子女住",
        },
      ],
      valueLive: "",
      zhiLi: [
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "不正常",
        },
      ],
      valuezhiLi: "",
      xingge: [
        {
          value: "1",
          label: "外向/热情",
        },
        {
          value: "2",
          label: "内向",
        },
      ],
      valuexingge: "",
      yuyan: [
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "不正常",
        },
      ],
      valueyuyan: "",
      tingli: [
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "不正常",
        },
      ],
      valuetingli: "",
      shili: [
        {
          value: "2",
          label: "弱",
        },
        {
          value: "1",
          label: "强",
        },
      ],
      valueshili: "",
      xingdong: [
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "不正常",
        },
      ],
      valuexingdong: "",
      manxing: [
        {
          value: "1",
          label: "高血压",
        },
        {
          value: "2",
          label: "糖尿病",
        },
        {
          value: "3",
          label: "高脂血综合征",
        },
      ],
      valuemanxing: "",
      //历史工单
      historyList: [],
      cno: "",
      //创建工单
      isXian: true,
      isXian1: false,
      isXian2: false,
      isXian3: false,
      isXian4: false,
      //铃响
      shrinkPacket: "",
      //来电
      timer: "",
      timer1: "",
      timer2: "",
      timer3: "",
      timer4: "",
      timer5: "",
      timer6: "",
      timer7: "",
      currentTime: 0,
      timeObj: null, // 时间对象,下方会用到
      myHours: "00", // 我定义来接收计算出来的 小时 的
      myMinutes: "00", // 我定义来接收计算出来的 分钟 的
      mySeconds: "00", // 我定义来接收计算出来的 秒钟 的
      currentTime1: 0,
      timeObj1: null, // 时间对象,下方会用到
      myHours1: "00", // 我定义来接收计算出来的 小时 的
      myMinutes1: "00", // 我定义来接收计算出来的 分钟 的
      mySeconds1: "00", // 我定义来接收计算出来的 秒钟 的
      currentTime2: 0,
      timeObj2: null, // 时间对象,下方会用到
      myHours2: "00", // 我定义来接收计算出来的 小时 的
      myMinutes2: "00", // 我定义来接收计算出来的 分钟 的
      mySeconds2: "00", // 我定义来接收计算出来的 秒钟 的
      //回拨的信息收集
      name: "",
      phone: "",
      //录音的路径
      luyinUrl: "",
      //分配来电的信息
      dataTime: "",
      tel: "",
      mobilePhone: "",
      equipmentId: "",
      // id: "",
      mainUniqueId: null,
      userId: "",
      familyIds: null,
      callback: null,
      serviceId: "", //关联客服id
      //关联人员
      tableDataPerson: [{ name: "王小虎" }],
      //是否需要回电
      callback1: 0,
      callback2: 0,
      //改配
      quitedId: "",
      ids: "",
      cityName: "",
      countyName: "",
      customerIds: "",
      prohibit: "",
      HangUp: "",
      obj: [],
      quFinish: {}, //待我办理完成的参数
      muteCount: 0,
      endReasons: null,
      eventList: true,
      textPhone: null,
      gaipei: "", //改派
      currentRow: null,
      indexOfs: null,
      // flag:1,
      // beee: null, //被踢
      cnoStatus: "", //客服状态
      infoFlag: false,
      infoFlags: false,
      cityId: "",
      countyId: "",
      customerServiceId: "", //关联客服的id
      isDiv: false,
      valNmae: "",
      valNmaes: "",
      emergencyOnes: null,
      emergencyTwos: null,
      idName: null,
      idNames: null,
      Sphone: "",
      loginToken: "",
      strPhone: "",
      strUser: "",
      strequipmentId: null,
      strMessage: "",
      strendReason: "",
      endReasonOne: "",
      mscode: null,
      mainUniqueIds: "",
      strcode: null,
      strcodes: null,
      lockReconnect: false, //是否真正建立连接
      timeout: 1200, //58秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      setTimer: "",
      setOffTimer: "",
      pNumbers: "",
      jinjiPhone: "",
      flagjinji: false,
      pObj: "",
      listvalue: "",
      formDate: {
        name: "",
        sparePhone: "",
        equipmentNo: "",
        emergencyContact: "",
        emergencyContactPhone: "",
      },
      idList: null,
      familyIdss: "",
      memberIdYuan: "",
      famData: [],
      forDataList: {},
      memData: [],
      noTaking: false,
      jiUserD: "",
      dianji: "",
      equipmentIdStrTong: "",
      calltypes: "",
      mscodeS: true,
      qingkong: false,
      isZhou: false,
      numAdd: 0,
      isClick: false,
      isYesClick: false,
      isYesClick1: false,
      checkListData1One: [],
      checkListData2Two: [],
      checkListData3Three: [],
      checkListData4Four: [],
      allNew: null,
      isRun: false,
      tabName: "",
      huiPhone: "",
      // 新加的
      switch: false,
      zhuanjieimgs: false,
      zhuanjieimg: false,
      yiliaoPimgs: false,
      yiliaoPimg: false,
      jijiuPimgs: false,
      jijiuPimg: false,
      jinJiPhoneimgs: false,
      jinJiPhoneimg: false,
      closeButton: false,
    };
  },
  created() {
    //获取cno
    let cno = JSON.parse(localStorage.getItem("cno"));
    this.cno = cno;
    //判断是否为客服
    let cus = JSON.parse(localStorage.getItem("cusSer"));
    this.cusClass = cus;
    if (cus == "1") {
      //   this.initWebSocket();
      //客服状态
      if (this.passTiao == true) {
        let oo = JSON.parse(localStorage.getItem("serverStatus"));
        this.myStatus(oo);
        if (oo == "1") {
          this.valuezhuangtai = "忙碌";
        } else if (oo == "0") {
          this.valuezhuangtai = "空闲";
        }
      } else {
        this.myStatus(1);
      }
    } else {
      //不是客服
      this.isListion = false;
      this.isListioning = false;
      this.zhuanjieimgs = false;
      this.zhuanjieimg = false;
      this.yiliaoPimgs = false;
      this.yiliaoPimg = false;
      this.jijiuPimgs = false;
      this.jijiuPimg = false;
      this.jinJiPhoneimgs = false;
      this.jinJiPhoneimg = false;
      this.isDiv = true;
      clearInterval(this.timer);
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      // clearInterval(this.timer3);
      clearInterval(this.timer4);
      clearInterval(this.timers);
      // clearInterval(this.timer5);
      clearInterval(this.timer6);
      clearInterval(this.timer7);
    }
    //获取关联客服id
    let loginUserId = JSON.parse(localStorage.getItem("loginuser"));
    this.loginToken = loginUserId.token;
    this.serviceId = loginUserId.id;
    this.testTimer2();
    // //获取本地
    // let beiti = JSON.parse(localStorage.getItem("initcno"));
    // this.beee = beiti;
    // console.log("loginUserId:", loginUserId);
    if (loginUserId.post == "2") {
      this.isShowCommission = true;
    } else {
      this.isShowCommission = false;
    }
    // console.log('cno===:',this.cno);
    // console.log('关联客服id:',this.serviceId);
    //查询机构下客服类型所有客服接口
    api.getallTypeUser({ serviceType: 1 }).then((res) => {
      // console.log('查询机构下客服类型所有客服接口:',res.data);
      if (res.data.code == 200) {
        this.tableDataPerson = res.data.result;
      } else {
        this.$message.error(res.data.msg);
      }
    });

    this.offenKnow();
    //获取省份城市接口
    api.AreaCity({ parentId: 0 }).then((res) => {
      // this.historyOrderCity = res.data.result;
      this.historyProvince = res.data.result; //渲染省
    });
    //创建工单
    this.gongDan();

    //全部待办
    this.backlog();
    // ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
    //   console.log('走了1');
    //   ClinkAgent.registerListener(
    //     ClinkAgent.EventType.KICKOUT,
    //     function (token) {
    //       console.log("被踢下线=====================：", token);
    //       if (token.cno != null || token.cno != "") {
    //         //跳转登录界面
    //         this.$router.push("/");
    //         this.$message.error("不好意思，您被踢下线");
    //         //退出天润的登录
    //         ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
    //           // 传入参数对象
    //           console.log('走了2');
    //           var params = {};
    //           params.logoutMode = 1;
    //           params.removeBinding = 0;
    //           ClinkAgent.logout(params);
    //         });
    //       }
    //     }
    //   ); // 被踢下线
    // });
    this.tokenM = setInterval(this.tokenMethods, 30000);
  },
  mounted() {
    this.audioElement = document.getElementsByName("audioOne"); // 获取页面上所有的video对象
    // console.log(this.audioElement, "输出音频");
    //待我办理
    this.waitFinsh(this.serviceId);
    let domContainer = this.$refs.clink;
    new ClinkToolbar(this.options, domContainer, this.callback);
    //console.log(ClinkToolbar);
    // this.timer3 = setInterval(this.shout, 3000);
    // this.shout();
    // clearInterval(this.timer3);
    this.$once("hook:beforeDestroy", () => {
      // clearInterval(this.timer3);
    });
    // 添加socket通知监听
    window.addEventListener("onmessageWS", this.getSocketData);
    // this.dateTime();
    // console.log("走到mounted查询")
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    searchs(val) {
      this.$refs.tree.filter(val);
      console.log(val, "控件输出");
    },
    twoType2(bb) {
      if (bb == "1") {
        let qq = JSON.parse(localStorage.getItem("serPhone"));
        let ww = JSON.parse(localStorage.getItem("serEquId"));
        let ee = JSON.parse(localStorage.getItem("serUserId"));
        localStorage.setItem("userIds", JSON.stringify(ee));
        this.pNumbers = qq;
        this.strPhone = qq;
        this.tel = qq;
        this.strUser = ee;
        this.userId = ee;
        this.strequipmentId = ww;
        this.equipmentIdStr = ww;
        this.equipmentId = ww;
        this.shout();
        this.listion();
      }
    },
    fourLis(aa) {
      if (aa == true) {
        this.isFour = false;
        this.isServer = false;
        this.isServer1 = false;
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();
        this.isAnswer = false;
        clearInterval(this.timer);
        this.$refs.audioLing.pause();
      }
    },
    fourserStatusee(aa) {
      console.log("传到客服中心的客服状态：", aa);
      this.myStatus(aa);
      if (aa == "1") {
        this.valuezhuangtai = "忙碌";
      } else if (aa == "0") {
        this.valuezhuangtai = "空闲";
      }
    },
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    replayJi() {
      apiShout.getreplace({ phone: this.strPhone }).then((res) => {
        console.log("查询换机信息：", res.data);
        if (res.data.code == 200) {
          if (res.data.result != null || res.data.result != "") {
            this.replayId = res.data.result;
            this.diaReplacement = true;
          } else {
            this.diaReplacement = false;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    queitReplay() {
      if (this.equipmentId == null || this.equipmentId == "") {
        this.$message.error("设备id不能为空");
        return;
      }
      if (this.replayId == null || this.replayId == "") {
        this.$message.error("换机信息id不能为空");
        return;
      }
      if (this.mainUniqueIds == null || this.mainUniqueIds == "") {
        this.$message.error("通话记录不能为空");
        return;
      }
      let usaa = JSON.parse(localStorage.getItem("userId"));
      apiShout
        .getexReplay({
          equipmentId: this.equipmentId,
          id: this.replayId,
          mainUniqueId: this.mainUniqueIds,
          memberId: usaa,
        })
        .then((res) => {
          console.log("确定换机：", res.data);
          if (res.data.code == 200) {
            this.diaReplacement = false;
            this.$message({
              type: "success",
              message: "换机成功",
            });
            this.getOld();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    zhuang() {
      this.isFour = false;
      this.isServer = false;
      this.isServer1 = false;
      clearInterval(this.timer4);
      this.currentTime2 = 0;
      this.testTimer2();
      this.isAnswer = false;
      clearInterval(this.timer);
      this.$refs.audioLing.pause();
      let qq = JSON.parse(localStorage.getItem("serPhone"));
      let ww = JSON.parse(localStorage.getItem("serEquId"));
      let ee = JSON.parse(localStorage.getItem("serUserId"));
      localStorage.setItem("userIds", JSON.stringify(ee));
      this.pNumbers = qq;
      this.strPhone = qq;
      this.tel = qq;
      this.strUser = ee;
      this.userId = ee;
      this.strequipmentId = ww;
      this.equipmentIdStr = ww;
      this.equipmentId = ww;
      this.shout();
    },
    successCodes(data) {
      let ww = JSON.parse(localStorage.getItem("serEquId"));
      this.equipmentId = ww;
      this.isYesClick1 = true;
      this.isClick = false;
      this.valuebase = "";
      this.inputsize = "";
      this.gridData = [];
      if (data == 200) {
        this.activeName1 = "third";
        this.isTab = false;
        this.isTabs = true;
        this.$nextTick(() => {
          this.$refs.familyUsers.isXinZhen = true;
          this.$refs.equipmentUser.isXinZhen = false;
          this.$refs.RefMemberIds.isXinZhen = true;
          this.$refs.healthData.isXinZhen = true;
          this.$refs.RefgetList.isXinZhen = true;
        });
      } else {
        this.activeName1 = "fourth";
        this.isTab = true;
        this.isTabs = false;
        this.$nextTick(() => {
          this.$refs.familyUsersOne.isXinZhen = false;
          this.$refs.equipmentUserOne.isXinZhen = true;
          this.$refs.RefMemberIdsOne.isXinZhen = false;
          this.$refs.healthDataOne.isXinZhen = true;
          this.$refs.RefgetListOne.isXinZhen = false;
        });
      }
    },
    successCodeOnes(data) {
      let ww = JSON.parse(localStorage.getItem("serEquId"));
      this.equipmentId = ww;
      this.isZhou = false;
      // this.isYesClick = true;
      this.isClick = false;
      this.valuebase = "";
      this.inputsize = "";
      this.gridData = [];
      if (data == 200) {
        this.activeName1 = "third";
        this.isTab = false;
        this.isTabs = true;
        this.$nextTick(() => {
          this.$refs.familyUsers.isXinZhen = true;
          this.$refs.equipmentUser.isXinZhen = false;
          this.$refs.RefMemberIds.isXinZhen = true;
          this.$refs.healthData.isXinZhen = true;
          this.$refs.RefgetList.isXinZhen = true;
        });
      } else {
        this.isTab = true;
        this.isTabs = false;
        this.$nextTick(() => {
          this.$refs.familyUsersOne.isXinZhen = false;
          this.$refs.equipmentUserOne.isXinZhen = true;
          this.$refs.RefMemberIdsOne.isXinZhen = false;
          this.$refs.healthDataOne.isXinZhen = true;
          this.$refs.RefgetListOne.isXinZhen = false;
        });
      }
    },
    NewmemData(data) {
      console.log("设备使用者传过来的值:", data);
      this.msgList = data;
    },
    xinList(val) {
      if (val == 200 || val == "200") {
        this.isYou = false;
      } else {
        this.isYou = true;
      }
    },
    xinList1(kk) {
      if (kk == 200 || kk == "200") {
        this.isYou = false;
      } else {
        this.isYou = true;
      }
    },
    resetForm() {
      this.qingkong = true;
      this.isZhou = true;
      this.gridData = [];
      this.isClick = false;
      Object.keys(this.formMember).forEach((item) => {
        this.$delete(this.formMember, item);
      });
      Object.keys(this.formMemberInfo).forEach((item) => {
        this.$delete(this.formMemberInfo, item);
      });
      Object.keys(this.formMembers).forEach((item) => {
        this.$delete(this.formMembers, item);
      });
      this.historyList = undefined;
      this.formMember.sparePhone = "";
      this.formMember.city = "";
      this.formMember.county = "";
      this.valuebase = "";
      this.inputsize = "";

      if (this.isTab == true) {
        this.$refs.familyUsersOne.tableData = undefined;
        this.$refs.equipmentUserOne.tableData = undefined;
        this.$refs.RefMemberIdsOne.tableData = undefined;
        this.$refs.healthDataOne.tableData = undefined;
        this.$refs.RefgetListOne.tableData = undefined;
        this.$refs.familyUsersOne.isXinZhen = false;
        this.$refs.equipmentUserOne.isXinZhen = true;
        this.$refs.RefMemberIdsOne.isXinZhen = false;
        this.$refs.healthDataOne.isXinZhen = true;
        this.$refs.RefgetListOne.isXinZhen = false;
      } else {
        this.$refs.familyUsers.tableData = undefined;
        this.$refs.equipmentUser.tableData = undefined;
        this.$refs.RefMemberIds.tableData = undefined;
        this.$refs.healthData.tableData = undefined;
        this.$refs.RefgetList.tableData = undefined;
        this.$refs.familyUsers.isXinZhen = false;
        this.$refs.equipmentUser.isXinZhen = true;
        this.$refs.RefMemberIds.isXinZhen = false;
        this.$refs.healthData.isXinZhen = true;
        this.$refs.RefgetList.isXinZhen = false;
      }
      console.log(this.formMember, "formname");
    },
    xinMembenId(aa) {
      this.xinMembenIdaa = aa;
      this.customerIds = aa;
      this.getUserInfor();
      this.getLists();
      if (aa != null && this.isTab == true) {
        this.$nextTick(() => {
          this.$refs.RefMemberIdsOne.getMemberIds();
          this.$refs.RefgetListOne.getList();
          this.$refs.RadioBroadcastsOne.getOneTime();
        });
      } else {
        this.$nextTick(() => {
          this.$refs.RefMemberIds.getMemberIds();
          this.$refs.RefgetList.getList();
          this.$refs.RadioBroadcasts.getOneTime();
        });
      }
      // this.$refs.RefMemberIds.getMemberIds();
      // this.$refs.RefgetList.getList();
    },
    xinfamData(bb) {
      this.xinfamDataList = bb;
      console.log(this.xinfamDataList, "父级的新设备");
    },
    xinFamilyId(cc) {
      this.xinFamilyIdaa = cc;
    },
    gongDan() {
      apiShout.getworkType().then((res) => {
        // console.log("创建工单的接口：", res.data);
        if (res.data.code == 200) {
          this.historyOrder = res.data.result;
          this.checkList = res.data.result[0].children[0].children;
          this.checkList1 = res.data.result[1].children[0].children;
          this.checkList2 = res.data.result[1].children[1].children;
          this.checkList3 = res.data.result[2].children[0].children;
          this.checkList4 = res.data.result[2].children[1].children;
          this.checkList5 = res.data.result[3].children[0].children;
          this.checkList6 = res.data.result[3].children[1].children;
          this.checkList7 = res.data.result[4].children[0].children;
          this.checkList8 = res.data.result[4].children[1].children;
          // console.log("工单的第一个：", this.checkList1, this.checkList2);
          this.danName = res.data.result[0].children[0].name;
          this.danName1 = res.data.result[1].children[0].name;
          this.danName2 = res.data.result[1].children[1].name;
          this.danName3 = res.data.result[2].children[0].name;
          this.danName4 = res.data.result[2].children[1].name;
          this.danName5 = res.data.result[3].children[0].name;
          this.danName6 = res.data.result[3].children[1].name;
          this.danName7 = res.data.result[4].children[0].name;
          this.danName8 = res.data.result[4].children[1].name;
          // console.log("--", this.danName1, this.danName2);
        }
      });
    },
    tokenMethods() {
      //获取关联客服id
      let loginUserId = JSON.parse(localStorage.getItem("token"));
      this.loginToken = loginUserId;
      api.checkTokens({ token: this.loginToken }).then((res) => {
        if (res.data.code == 200) {
          console.log(res);
        } else {
          this.$message.error(res.data.msg);
          this.$router.push("/");
        }
      });
    },
    // close() {
    //   this.websock.close(); //关闭websocket
    //   this.websock.onclose = function (e) {
    //     console.log(e); //监听关闭事件
    //     console.log("关闭");
    //     // this.initWebSocket();
    //   };
    // },
    // initWebSocket() {
    //   console.log("初始进入websocket方法");
    //   // localStorage.setItem(
    //   //   "UniqueIdn",
    //   //   JSON.stringify("medias_1-1644976335.14895")
    //   // );
    //   // this.userId = "";
    //   // console.log("999:", this.userId);
    //   // this.equipmentIdStr = "19";
    //   // var ws = "wss://erp.careld.cn/socket";
    //   var ws = "wss://dev.careld.cn/socket";
    //   this.websock = new WebSocket(ws);
    //   // window.onbeforeunload = function (event) {
    //   //   console.log(event, "111");
    //   //   console.log("您正在刷新网页");
    //   //   return "Are you sure?";
    //   // };
    //   // 判断是否websocket是否已经打开，如果已经打开则直接发送数据  readyState
    //   if (this.websock.readyState !== WebSocket.OPEN) {
    //     this.websock.onopen = this.websocketonopen;
    //   } else {
    //     var objn = JSON.stringify({
    //       cno: this.cno,
    //       msgcode: "201",
    //       token: this.loginToken,
    //     });
    //     this.websocketsend(objn);
    //   }
    //   // this.websock.onopen = this.websocketonopen;
    //   this.websock.onerror = this.websocketonerror;
    //   this.websock.onmessage = this.websocketonmessage;
    //   this.websock.onclose = this.websocketclose;
    // },
    // reconnect() {
    //   //重新连接
    //   var that = this;
    //   if (that.lockReconnect) {
    //     return;
    //   }
    //   that.lockReconnect = true;
    //   //没连接上会一直重连，设置延迟避免请求过多
    //   that.timeoutnum && clearTimeout(that.timeoutnum);
    //   that.timeoutnum = setTimeout(function () {
    //     //新连接
    //     that.initWebSocket();
    //     that.lockReconnect = false;
    //     console.log("重新连接111");
    //   }, 10000);
    // },
    // reset() {
    //   //重置心跳
    //   var that = this;
    //   //清除时间
    //   clearTimeout(that.timeoutObj);
    //   clearTimeout(that.serverTimeoutObj);
    //   //重启心跳
    //   that.start();
    // },
    // start() {
    //   //开启心跳
    //   console.log("开启心跳");
    //   var self = this;
    //   self.timeoutObj && clearTimeout(self.timeoutObj);
    //   self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
    //   self.timeoutObj = setTimeout(() => {
    //     //这里发送一个心跳，后端收到后，返回一个心跳消息，
    //     //定时发送任务写到这里
    //     if (this.websock.readyState == 1) {
    //       // //如果连接正常
    //       var timeout = JSON.stringify({
    //         cno: this.cno,
    //         msgcode: "201",
    //         token: this.loginToken,
    //       });
    //       this.websocketsend(timeout);
    //     } else {
    //       this.initWebSocket();
    //     }
    //
    //     self.serverTimeoutObj = setTimeout(function () {
    //       //超时关闭
    //       this.websock.close();
    //     }, self.timeout);
    //   }, self.timeout);
    // },
    //  连接建立之后执行send方法发送数据
    // websocketonopen() {
    //   console.log("连接成功");
    //   var objn = JSON.stringify({
    //     cno: this.cno,
    //     msgcode: "201",
    //     token: this.loginToken,
    //   });
    //   this.websocketsend(objn);
    // },

    //  数据接收
    // websocketonmessage(e) {
    getSocketData(e) {
      // var endTime = new Date();
      // this.timers = setInterval(() => {
      //   var newTime = new Date();
      //   var newTimes = newTime - endTime;
      //   var newTimeOne = parseInt((newTimes % 3600000) / 60000);
      //   if (newTimeOne > 20) {
      //     var timeout = JSON.stringify({
      //       cno: this.cno,
      //       msgcode: "201",
      //       token: this.loginToken,
      //     });
      //     this.websocketsend(timeout);
      //     clearInterval(this.timers);
      //     endTime = new Date();
      //     this.timers;
      //   }
      //   // clearTimeout(this.timers)
      // }, 1000);
      console.log("服务器返回:" + e.detail.data);
      var str = e.detail.data;
      var strname = JSON.parse(str);
      // let that = this;
      // if (e.detail.data && strname.msgcode != 100) {
      //   var messagelist = JSON.stringify({
      //     cno: this.cno,
      //     msgcode: 200,
      //     source: strname.msgno,
      //     sourcecode: strname.msgcode,
      //     token: this.loginToken,
      //   });
      //   this.websocketsend(messagelist);
      // }
      if (strname.code == 500) {
        // alert("消息参数错误!");
        this.$message.error(strname.message);
        let loginUserId = JSON.parse(localStorage.getItem("loginuser"));
        let that = this;
        that
          .$http({
            url: "/too/center/weblog/save",
            params: {
              callback: "",
              errorCode: strname.code,
              errorExplain: strname.message,
              interfaceUrl: this.global.ws.url,
              menuName: "客服系统与400中心-webSocket链接",
              menuUrl: that.$route.path,
              params: strname,
              source: "客服系统",
              userId: loginUserId.id,
              userName: loginUserId.name,
            },
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
            method: "get",
          })
          .then((res) => {
            if (res.data.code === 200) {
              console.log();
            }
          });
        return false;
      } else if (strname.code == 10000) {
        this.$router.push("/");
        this.$message.error(strname.message);
        let loginUserId = JSON.parse(localStorage.getItem("loginuser"));
        let that = this;
        that
          .$http({
            url: "/too/center/weblog/save",
            params: {
              callback: "",
              errorCode: strname.code,
              errorExplain: strname.message,
              interfaceUrl: this.global.ws.url,
              menuName: "客服系统与400中心-webSocket链接",
              menuUrl: that.$route.path,
              params: strname,
              source: "客服系统",
              userId: loginUserId.id,
              userName: loginUserId.name,
            },
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
            method: "get",
          })
          .then((res) => {
            if (res.data.code === 200) {
              console.log();
            }
          });
        clearInterval(this.timer);
        clearInterval(this.timer1);
        clearInterval(this.timer2);
        clearInterval(this.timer4);
        clearInterval(this.timer6);
        clearInterval(this.timers);
      }
      if (strname.msgcode == 110) {
        this.strcodes = strname.msgcode;
        localStorage.setItem("strcodes", JSON.stringify(strname.msgcode));
        localStorage.setItem("listionPhone", JSON.stringify(strname.phone));
        localStorage.setItem("serEquId", JSON.stringify(strname.equipmentId));
        this.huiPhone = strname.phone;
        this.huiPhone =
          this.huiPhone.substr(0, 3) + "****" + this.huiPhone.substr(7);
        this.seType = strname.serviceType;
        this.pNumbers = strname.phone;
        this.flagdian = false;
        this.isSave = false;
        this.isComePhone = true;
        this.isZhou = false;
        this.strPhone = strname.phone;
        this.strUser = strname.userid;
        // this.strUser = null;
        this.userId = strname.userid;
        this.strequipmentId = strname.equipmentId;
        this.equipmentIdStr = strname.equipmentId;
        this.equipmentId = strname.equipmentId;
        this.strMessage = strname.message;
        this.mscode = strname.msgcode;
        this.shout();
        // this.getFamily();
        // this.getsheList();
        // this.getOld();
        localStorage.setItem("userIds", JSON.stringify(this.strUser));
        localStorage.setItem("userId", JSON.stringify(strname.userid));
        localStorage.setItem(
          "equMemberId",
          JSON.stringify(this.equipmentIdStr)
        );
        this.imgIcon = false;
        this.imgIcons = true;
        this.StandbyIcon = false;
        this.StandbyIcons = true;
        this.imgJinJi = true;
        console.log(this.imgJinJi, "true");
        this.imgIconBtnOne = false;
        this.imgIconBtns = false;
        if (strname.serviceType == 3) {
          //400
          this.isFour = true;
          this.isServer = false;
          this.isServer1 = false;
        } else if (strname.serviceType == 1) {
          //客服
          this.isFour = false;
          this.isServer = false;
          this.isServer1 = true;
        }
        if (strname.equipmentStatus == 2) {
          // this.diaReplacement = true;
          this.replayJi();
        }
        // if (this.strUser == null || this.strUser == "") {
        //   console.log('=1=1==1=1:',this.$refs.RefMemberIdsOne);
        //   this.$refs.RefMemberIdsOne.getMemberIds();
        //   this.$refs.RefgetListOne.getList();
        // } else {
        //   this.$refs.RefMemberIds.getMemberIds();
        //   this.$refs.RefgetList.getList();
        // }
        // this.$refs.RefMemberIds.getMemberIds();
        // this.$refs.RefgetList.getList();
      } else if (strname.msgcode == 112) {
        this.strcodes = strname.msgcode;
        localStorage.setItem("strcodes", JSON.stringify(strname.msgcode));
        this.pNumbers = strname.phone;
        localStorage.setItem("listionPhone", JSON.stringify(strname.phone));
        this.huiPhone = strname.phone;
        this.huiPhone =
          this.huiPhone.substr(0, 3) + "****" + this.huiPhone.substr(7);
        this.seType = strname.serviceType;
        this.flagdian = false;
        this.isSave = false;
        this.isComePhone = true;
        this.isZhou = false;
        this.strPhone = strname.phone;
        this.strUser = strname.userid;
        this.userId = strname.userid;
        localStorage.setItem("userIds", JSON.stringify(strname.userid));
        localStorage.setItem("userId", JSON.stringify(strname.userid));
        this.strequipmentId = strname.equipmentId;
        this.equipmentIdStr = strname.equipmentId;
        this.equipmentId = strname.equipmentId;
        localStorage.setItem(
          "strequipmentIds",
          JSON.stringify(strname.equipmentId)
        );
        localStorage.setItem("serEquId", JSON.stringify(strname.equipmentId));
        this.strMessage = strname.message;
        this.mscode = strname.msgcode;
        this.shout();
        localStorage.setItem("userIds", JSON.stringify(this.strUser));
        localStorage.setItem(
          "equMemberId",
          JSON.stringify(this.equipmentIdStr)
        );
        this.imgIcon = false;
        this.imgIcons = true;
        this.StandbyIcon = false;
        this.StandbyIcons = true;
        this.imgJinJi = true;
        console.log(this.imgJinJi, "true");
        this.imgIconBtnOne = false;
        this.imgIconBtns = false;
        if (strname.serviceType == 3) {
          //400
          this.isFour = true;
          this.isServer = false;
          this.isServer1 = false;
        } else if (strname.serviceType == 1) {
          //客服
          this.isFour = false;
          this.isServer = true;
          this.isServer1 = false;
        }
        if (strname.equipmentStatus == 2) {
          // this.diaReplacement = true;
          this.replayJi();
        }
      } else if (strname.msgcode == 120) {
        console.log("输出是否转接：", this.switch);
        let ll = JSON.parse(localStorage.getItem("strcodes"));
        if (ll === 110) {
          ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
            ClinkAgent.previewOutcallCancel();
          });
          this.isListion = true;
          this.isListioning = false;
          this.zhuanjieimgs = false;
          this.zhuanjieimg = false;
          this.yiliaoPimgs = false;
          this.yiliaoPimg = false;
          this.jijiuPimgs = false;
          this.jijiuPimg = false;
          this.jinJiPhoneimgs = false;
          this.jinJiPhoneimg = false;
          this.offImgIcon = false;
          this.offImgIcons = false;
          this.isAnswer = false;
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          console.log("走进来了");
        }
        // 新加的
        if (strname.endReason == 0) {
          this.$message("呼叫器已挂机！");
        }
        if (this.switch == true) {
          this.isListion = false;
          this.isListioning = true;
          this.zhuanjieimgs = true;
          this.zhuanjieimg = false;
          this.yiliaoPimgs = true;
          this.yiliaoPimg = false;
          this.jijiuPimgs = true;
          this.jijiuPimg = false;
          this.jinJiPhoneimgs = true;
          this.jinJiPhoneimg = false;
          this.isQues = false;
          this.isQues1 = false;
          localStorage.removeItem("phoneNumber");
          console.log("走进来了1");
        } else {
          console.log("走进来了2");
          this.isRun = true;
          this.hereLis = false;
          this.mscodeS = false;
          this.imgIcon = false;
          this.imgIcons = true;
          this.StandbyIcon = false;
          this.StandbyIcons = true;
          this.imgJinJi = true;
          console.log(this.imgJinJi, "true2");
          this.imgIconBtnOne = false;
          this.isArrangement = false;
          this.isArrangement1 = true;
          this.$message("通话记录正在加载，请耐心等待一分钟后查看！");
          this.endReasonOne = strname.endReason;
          this.noTaking = true;
          this.isClick = false;
          // this.$refs.RefgetList.disableds = true;
          // this.$refs.RefMemberIds.disableds = true;
          this.endReason();
          localStorage.removeItem("phoneNumber");
          this.isQues = false;
          this.isQues1 = false;
          this.isComePhone = false;
          this.isYou = false;
          this.gridData = [];
          this.valuebase = "";
          this.inputsize = "";
          this.isAnswer = false;
          this.isListion = true;
          this.isListioning = false;
          this.zhuanjieimgs = false;
          this.zhuanjieimg = false;
          this.yiliaoPimgs = false;
          this.yiliaoPimg = false;
          this.jijiuPimgs = false;
          this.jijiuPimg = false;
          this.jinJiPhoneimgs = false;
          this.jinJiPhoneimg = false;
          this.offImgIcon = false;
          this.offImgIcons = false;
          this.PersonstatusIng = false;
          this.isFour = false;
          this.isServer = false;
          this.isServer1 = false;
          this.dateTime();
          // console.log("走到120查询")
          if (this.isTab == true) {
            this.$nextTick(() => {
              this.$refs.RefgetListOne.disableds = false;
              this.$refs.RefMemberIdsOne.disableds = false;
            });
          } else {
            this.$nextTick(() => {
              this.$refs.RefgetList.disableds = false;
              this.$refs.RefMemberIds.disableds = false;
            });
          }
        }
      } else if (strname.msgcode == 130) {
        this.mscodeS = true;
        this.noTaking = false;
        this.mainUniqueIds = strname.mainUniqueId;
        localStorage.setItem("UniqueIdn", JSON.stringify(this.mainUniqueIds));
        this.phoneID();
      } else if (strname.msgcode == 101) {
        this.isFour = false;
        this.isServer = false;
        this.isServer1 = false;
        this.hereLis = false;
        // clearInterval(this.timer3);

        // this.isListion = true;
        // this.isListioning = false;
        // this.dateTime();
        // console.log("走到101查询")
        if (strname.status == "1" || strname.status == 1) {
          this.valuezhuangtai = "忙碌";
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          this.cnoStatus = 1;
          this.imgIcon = true;
          this.imgIcons = false;
          this.StandbyIcon = true;
          this.StandbyIcons = false;
          this.imgJinJi = false;
          console.log(this.imgJinJi, "false");
          this.imgIconBtnOne = true;
          this.isArrangement = true;
          this.isArrangement1 = false;
          this.isListion = true;
          this.isListioning = false;
          this.zhuanjieimgs = false;
          this.zhuanjieimg = false;
          this.yiliaoPimgs = false;
          this.yiliaoPimg = false;
          this.jijiuPimgs = false;
          this.jijiuPimg = false;
          this.jinJiPhoneimgs = false;
          this.jinJiPhoneimg = false;
          this.isQues = false;
          this.isQues1 = false;
          localStorage.removeItem("phoneNumber");
        } else {
          if (this.isRun == true) {
            this.valuezhuangtai = "空闲";
            this.cnoStatus = strname.status;
            localStorage.setItem(
              "serverStatus",
              JSON.stringify(this.cnoStatus)
            );
            // this.myStatus(this.cnoStatus);
            clearInterval(this.timer4);
            this.currentTime2 = 0;
            this.testTimer2();
            this.isArrangement = true;
            this.isArrangement1 = false;
            this.isAnswer = false;
            this.isListion = true;
            this.isListioning = false;
            this.zhuanjieimgs = false;
            this.zhuanjieimg = false;
            this.yiliaoPimgs = false;
            this.yiliaoPimg = false;
            this.jijiuPimgs = false;
            this.jijiuPimg = false;
            this.jinJiPhoneimgs = false;
            this.jinJiPhoneimg = false;
            this.offImgIcon = false;
            this.offImgIcons = false;
            this.PersonstatusIng = false;
            //铃不动
            clearInterval(this.timer);
            this.$refs.audioLing.pause();
            this.isQues = false;
            this.isQues1 = false;
            localStorage.removeItem("phoneNumber");
            this.isRun = false;
          } else {
            this.hereLis = false;
            this.mscodeS = false;
            this.imgIcon = false;
            this.imgIcons = true;
            this.StandbyIcon = false;
            this.StandbyIcons = true;
            this.imgJinJi = true;
            console.log(this.imgJinJi, "true2");
            this.imgIconBtnOne = false;
            this.isArrangement = false;
            this.isArrangement1 = true;
            this.$message("通话记录正在加载，请耐心等待一分钟后查看！");
            this.endReasonOne = strname.endReason;
            this.noTaking = true;
            this.isClick = false;
            // this.$refs.RefgetList.disableds = true;
            // this.$refs.RefMemberIds.disableds = true;
            this.endReason();
            this.isQues = false;
            this.isQues1 = false;
            this.isComePhone = false;
            this.isYou = false;
            this.gridData = [];
            this.valuebase = "";
            this.inputsize = "";
            this.isAnswer = false;
            this.isListion = true;
            this.isListioning = false;
            this.zhuanjieimgs = false;
            this.zhuanjieimg = false;
            this.yiliaoPimgs = false;
            this.yiliaoPimg = false;
            this.jijiuPimgs = false;
            this.jijiuPimg = false;
            this.jinJiPhoneimgs = false;
            this.jinJiPhoneimg = false;
            this.offImgIcon = false;
            this.offImgIcons = false;
            this.PersonstatusIng = false;
            this.isFour = false;
            this.isServer = false;
            this.isServer1 = false;
            this.dateTime();
            // console.log("走到120查询")
            if (this.isTab == true) {
              this.$nextTick(() => {
                this.$refs.RefgetListOne.disableds = false;
                this.$refs.RefMemberIdsOne.disableds = false;
              });
            } else {
              this.$nextTick(() => {
                this.$refs.RefgetList.disableds = false;
                this.$refs.RefMemberIds.disableds = false;
              });
            }
            this.valuezhuangtai = "空闲";
            this.cnoStatus = strname.status;
            localStorage.setItem(
              "serverStatus",
              JSON.stringify(this.cnoStatus)
            );
            // this.myStatus(this.cnoStatus);
            clearInterval(this.timer4);
            this.currentTime2 = 0;
            this.testTimer2();
            this.isArrangement = true;
            this.isArrangement1 = false;
            this.isAnswer = false;
            this.isListion = true;
            this.isListioning = false;
            this.zhuanjieimgs = false;
            this.zhuanjieimg = false;
            this.yiliaoPimgs = false;
            this.yiliaoPimg = false;
            this.jijiuPimgs = false;
            this.jijiuPimg = false;
            this.jinJiPhoneimgs = false;
            this.jinJiPhoneimg = false;
            this.offImgIcon = false;
            this.offImgIcons = false;
            this.PersonstatusIng = false;
            //铃不动
            clearInterval(this.timer);
            this.$refs.audioLing.pause();
            this.isQues = false;
            this.isQues1 = false;
            localStorage.removeItem("phoneNumber");
            this.isRun = false;
          }
        }
      }
      if (strname.msgcode == 111 || strname.msgcode == "111") {
        this.isFour = false;
        this.isServer = false;
        this.isServer1 = false;
        this.hereLis = false;
        this.shout();
        //铃响
        clearInterval(this.timer);
        this.$refs.audioLing.pause();
        this.isListion = true;
        this.isListioning = false;
        this.zhuanjieimgs = false;
        this.zhuanjieimg = false;
        this.yiliaoPimgs = false;
        this.yiliaoPimg = false;
        this.jijiuPimgs = false;
        this.jijiuPimg = false;
        this.jinJiPhoneimgs = false;
        this.jinJiPhoneimg = false;
        this.offImgIcon = false;
        this.offImgIcons = false;
        if (this.isAnswer == true) {
          this.isAnswer = false;
        }
        localStorage.removeItem("phoneNumber");
        this.cnoStatus = 1;
        console.log("走到111这一步");
      }
      this.strPhone = strname.phone;
      this.strUser = strname.userid;
      this.strequipmentId = strname.equipmentId;
      this.strMessage = strname.message;
      this.mscode = strname.msgcode;
      // this.reset();
      console.log(this.mainUniqueIds, "xinjiayi123");
      this.strcode = strname.code;
      console.log("服务器返回:" + strname);
    },
    timeName() {},
    //  数据发送
    websocketsend(Data) {
      //   this.websock.send(Data);
      this.global.ws.send(Data);
      console.log(`数据发送` + Data);
      // this.websock.close();
    },
    websocketonerror() {
      console.log("连接失败重新连接");
      // this.initWebSocket();
      // this.reconnect();
    },
    // //  关闭
    // websocketclose(e) {
    //   console.log("断开连接", e);
    //   this.reconnect();
    //   // this.initWebSocket();
    // },
    valuebases(val) {
      console.log(val, "sousuo");
      this.listvalue = val;
    },
    //查询用户信息
    searchList() {
      // if (this.valuebase == "1") {
      //   this.formDate.name = this.inputsize;
      // } else {
      //   this.formDate.name = "";
      // }
      // if (this.valuebase == "2") {
      //   this.formDate.sparePhone = this.inputsize;
      // } else {
      //   this.formDate.sparePhone = "";
      // }
      // if (this.valuebase == "3") {
      //   this.formDate.equipmentNo = this.inputsize;
      // } else {
      //   this.formDate.equipmentNo = "";
      // }
      // if (this.valuebase == "4") {
      //   this.formDate.emergencyContact = this.inputsize;
      // } else {
      //   this.formDate.emergencyContact = "";
      // }
      // if (this.valuebase == "5") {
      //   this.formDate.emergencyContactPhone = this.inputsize;
      // } else {
      //   this.formDate.emergencyContactPhone = "";
      // }
      if (this.inputsize != "" && this.valuebase != "") {
        apiShout
          .getmemByFamily({
            type: this.listvalue,
            value: this.inputsize,
            // name: this.formDate.name,
            // sparePhone: this.formDate.sparePhone,
            // equipmentNo: this.formDate.equipmentNo,
            // emergencyContact: this.formDate.emergencyContact,
            // emergencyContactPhone: this.formDate.emergencyContactPhone,
          })
          .then((res) => {
            if (res.data.code === 200) {
              console.log(res.data, "远程协助用户信息");
              this.gridData = res.data.result;
              localStorage.setItem("yuanInfor", res.data.result.length);
              this.allNew = res.data.result.length;
              this.gridData.forEach((item) => {
                //对于电话的处理
                if (
                  item.linkOnePhone != null ||
                  item.linkOnePhone != "" ||
                  item.linkOnePhone != "null"
                ) {
                  item.linkOnePhone =
                    item.linkOnePhone.substr(0, 3) +
                    "****" +
                    item.linkOnePhone.substr(7);
                }
                if (item.linkTwoPhone != null) {
                  item.linkTwoPhone =
                    item.linkTwoPhone.substr(0, 3) +
                    "****" +
                    item.linkTwoPhone.substr(7);
                }
                // //对于电话的处理
                // item.sparePhone =
                //   item.sparePhone.substr(0, 3) +
                //   "****" +
                //   item.sparePhone.substr(7);
              });
              console.log(this.gridData, "下拉搜索信息展示");
            } else {
              this.$message.error(res.data.msg);
            }
          });
      } else {
        return false;
      }
    },
    //点击表格的每一行事件
    rowClicks(row, column, event) {
      this.$refs.taPopover.doClose();
      this.isTab = true;
      this.isTabs = false;
      this.qingkong = false;
      this.isZhou = true;
      this.isClick = true;
      this.isActiOne = true;
      console.log(row, "row123");
      console.log(column, "column123");
      console.log(event, "event123");
      this.idList = row.id;
      this.flagdian = true;
      this.userId = row.id;
      this.customerIds = row.id;
      this.equipmentId = row.equipmentId;
      localStorage.setItem("userIds", JSON.stringify(row.id));
      this.wuFamilyId = row.familyId;
      this.getFamily();
      this.getsheList();
      this.getOld();
      this.getLists();
      if (this.isTabs == true) {
        this.$nextTick(() => {
          this.$refs.RefMemberIds.getMemberIds();
          this.$refs.RefgetList.getList();
          this.$refs.RadioBroadcasts.getOneTime();
          this.$refs.familyUsers.isXinZhen = true;
          this.$refs.equipmentUser.isXinZhen = false;
          this.$refs.RefMemberIds.isXinZhen = true;
          this.$refs.healthData.isXinZhen = true;
          this.$refs.RefgetList.isXinZhen = true;
        });
      } else {
        this.$nextTick(() => {
          this.$refs.RefMemberIdsOne.getMemberIds();
          this.$refs.RefgetListOne.getList();
          this.$refs.RadioBroadcastsOne.getOneTime();
          this.$refs.familyUsersOne.isXinZhen = false;
          this.$refs.equipmentUserOne.isXinZhen = false;
          this.$refs.RefMemberIdsOne.isXinZhen = false;
          this.$refs.healthDataOne.isXinZhen = true;
          this.$refs.RefgetListOne.isXinZhen = false;
        });
      }
      // this.$refs.RefMemberIdsOne.getMemberIds();
      // this.$refs.RefgetListOne.getList();
      console.log("2222:", this.userId);
      localStorage.setItem("memberId", JSON.stringify(row.id));
      // this.familyIdss = row.familyId;
      apiShout.getMemberInfoByIds({ id: row.id }).then((res) => {
        if (res.data.code === 200) {
          //对于生日的处理
          let a = res.data.result.member.birthday;
          let dd = new Date(a);
          let y = dd.getFullYear();
          let m = dd.getMonth() + 1;
          m = m < 10 ? "0" + m : m;
          let d = dd.getDate();
          d = d < 10 ? "0" + d : d;
          res.data.result.member.birthday = y + "-" + m + "-" + d;
          console.log(res.data, "查询用户信息");
          this.formMember = res.data.result.member;
          this.formMembers = res.data.result.member;
          this.xinLog = this.formMember.lon;
          this.xinLat = this.formMember.lat;
          this.xinLoc = this.formMember.location;
          this.familyIdss = res.data.result.member.familyId;
          this.emergencyOnes = res.data.result.member.emergencyOne;
          this.emergencyTwos = res.data.result.member.emergencyTwo;
          this.formMember.birthday = res.data.result.member.birthday;
          this.formMembers.createDate = res.data.result.memberInfo.createDate;
          this.formMembers.updateDate = res.data.result.memberInfo.updateDate;
          this.ursents = [];
          this.ursentsTwo = [];
          this.region = [];
          apiShout.getFamilyUsers({ fId: this.familyIdss }).then((res) => {
            if (res.data.code === 200) {
              console.log(res, "紧急联系人");
              // this.ursents = res.data.result
              res.data.result.forEach((item) => {
                this.ursents.push(item);
                this.ursentsTwo.push(item);
                this.region.push(item);
                // console.log(this.region, "紧急联系人111111");
              });

              for (var i = 0; i < this.ursents.length; i++) {
                this.userPhone = this.ursents[0];
                this.userPhones = this.ursents[1];
                // if (this.userPhone.id === this.emergencyOnes) {
                // console.log(
                //   this.userPhone.phone,
                //   this.userPhone.userName,
                //   "this.userPhone.phone"
                // );
                this.formMember.emergencyContactOnePhone = this.userPhone.phone;
                this.formMember.emergencyContactOne = this.userPhone.userName;
                let str = this.formMember.emergencyContactOnePhone;
                this.formMember.emergencyContactOnePhone = str.replace(
                  /(\d{3})\d{4}(\d{1})/,
                  "$1****$2"
                );
                // let arrss = [];
                let arrText = {};
                arrText.userName = this.userPhone.userName;
                arrText.phone = this.userPhone.phone;
                this.regionTong.push(arrText);
                this.formMember.emergencyContactTwoPhone =
                  this.userPhones.phone;
                this.formMember.emergencyContactTwo = this.userPhones.userName;
                let str1 = this.formMember.emergencyContactTwoPhone;
                this.formMember.emergencyContactTwoPhone = str1.replace(
                  /(\d{3})\d{4}(\d{1})/,
                  "$1****$2"
                );
                // let arrss = [];
                // let arrText = {};
                // arrText.userName = this.userPhones.userName;
                // arrText.phone = this.userPhones.phone;
                // this.regionTong.push(arrText);
                // this.region.push(arrText);
                // }
              }
              // for (let i = 0; i < this.ursentsTwo.length; i++) {
              //   this.userPhones = this.ursentsTwo[i];
              //   if (this.userPhones.id === this.emergencyTwos) {
              //     console.log(
              //       this.userPhones.phone,
              //       this.userPhones.userName,
              //       "this.userPhones.userName"
              //     );
              //     this.formMember.emergencyContactTwoPhone =
              //       this.userPhones.phone;
              //     this.formMember.emergencyContactTwo =
              //       this.userPhones.userName;
              //     let str = this.formMember.emergencyContactTwoPhone;
              //     this.formMember.emergencyContactTwoPhone = str.replace(
              //       /(\d{3})\d{4}(\d{1})/,
              //       "$1****$2"
              //     );
              //     // let arrss = [];
              //     let arrText = {};
              //     arrText.userName = this.userPhones.userName;
              //     arrText.phone = this.userPhones.phone;
              //     this.regionTong.push(arrText);
              //     // this.region.push(arrText);
              //   }
              // }
            } else {
              this.$message.error(res.data.msg);
            }
          });
          this.Sphone = res.data.result.member.sparePhone;
          let str = this.formMember.sparePhone;
          if (str !== null || str !== "" || str !== "null") {
            this.formMember.NewsparePhone = str.replace(
              /(\d{3})\d{4}(\d{1})/,
              "$1****$2"
            );
          }
          // this.formMember = row.memberInfo;
          this.formMemberInfo = res.data.result.memberInfo;
          if (res.data.result.memberInfo.illness == null) {
            this.isZhan1 = false;
            this.isZhan = false;
            this.inputman = "";
          } else {
            this.isZhan1 = true;
          }
          this.formMemberInfo.live = res.data.result.memberInfo.live;
          this.formMemberInfo.intellect = res.data.result.memberInfo.intellect;
          this.formMemberInfo.character = res.data.result.memberInfo.character;
          this.formMemberInfo.language = res.data.result.memberInfo.language;
          this.formMemberInfo.hearing = res.data.result.memberInfo.hearing;
          this.formMemberInfo.vision = res.data.result.memberInfo.vision;
          this.formMemberInfo.action = res.data.result.memberInfo.action;
          this.formMemberInfo.illness = res.data.result.memberInfo.illness;

          this.forBody = res.data.result.memberInfo;
          this.cityName = res.data.result.member.city;
          this.countyName = res.data.result.member.county; //TextToCode
          this.countyId = res.data.result.member.county;
          this.cityId = res.data.result.member.city;
          this.formMember.city = CodeToText[this.cityName];
          this.formMember.county = CodeToText[this.countyName];
          console.log(this.familyIdss, "家庭id");
        } else {
          this.$message.error(res.data.msg);
        }
      });

      // if (this.textPhone != null) {
      //   event.isTrusted = false;
      // }
      // else{
      //   event.isTrusted = true;
      // }
      apiShout
        .geteqMem({ equipmentId: row.equipmentId, memberId: row.id })
        .then((ss) => {
          console.log("查询服务项：", ss.data.result);
          if (ss.data.code == 200) {
            if (ss.data.result != null) {
              this.isSer = true;
              this.serverType = ss.data.result;
              if (this.serverType.basicStatus == 1) {
                this.serverType.basicEnd = "未开通";
              } else if (this.serverType.basicStatus == 3) {
                this.serverType.basicEnd = "已过期";
              }
              if (this.serverType.careStatus == 1) {
                this.serverType.careEnd = "未开通";
              } else if (this.serverType.careStatus == 3) {
                this.serverType.careEnd = "已过期";
              }
              if (this.serverType.ymStatus == 1) {
                this.serverType.ymEnd = "未开通";
              } else if (this.serverType.ymStatus == 3) {
                this.serverType.ymEnd = "已过期";
              }
              if (this.serverType.contactStatus == 1) {
                this.serverType.contactEnd = "未开通";
              } else if (this.serverType.contactStatus == 3) {
                this.serverType.contactEnd = "已过期";
              } else if (this.serverType.contactStatus == 2) {
                this.serverType.contactEnd = "长期有效";
              }
              if (this.serverType.healthStatus == 1) {
                this.serverType.healthEnd = "未开通";
              } else if (this.serverType.healthStatus == 3) {
                this.serverType.healthEnd = "已过期";
              }
              if (this.serverType.lawStatus == 1) {
                this.serverType.lawEnd = "未开通";
              } else if (this.serverType.lawStatus == 3) {
                this.serverType.lawEnd = "已过期";
              }
              if (this.serverType.cnoStatus == 1) {
                this.serverType.cnoEnd = "未开通";
              } else if (this.serverType.cnoStatus == 3) {
                this.serverType.cnoEnd = "已过期";
              }
            } else {
              this.isSer = false;
            }
          }
        });
    },
    jinJiPhone() {
      this.dialogVisiblejinji = true;
    },
    phQuitjinji() {
      if (this.strcode == 500) {
        return false;
      } else {
        var objs = JSON.stringify({
          cno: this.cno,
          msgcode: 203,
          token: this.loginToken,
          mainuniqueid: this.mainUniqueIds,
          clientnumber: this.jinjiPhone,
          trantype: "4",
        });
        this.websocketsend(objs);
        this.dialogVisiblejinji = false;
        this.isQues = true;
        this.isQues1 = true;
        let pObjJi = this.jinjiPhone;
        let cc = this.cno;
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.objectType = 0;
          params.consultObject = pObjJi;
          params.cno = cc;
          ClinkAgent.consult(params);
        });
        // 新加的
        this.switch = true;
      }
    },
    jijiuP() {
      this.dialogVisiblejijiu = true;
      apiShout.getparTitle({ title: "远盟急救" }).then((res) => {
        // console.log('转接：',res.data);
        if (res.data.code == 200) {
          this.inputPhs = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    phQuitjijiu() {
      if (this.strcode == 500) {
        return false;
      } else {
        var objs = JSON.stringify({
          cno: this.cno,
          msgcode: 203,
          token: this.loginToken,
          mainuniqueid: this.mainUniqueIds,
          clientnumber: this.inputPhs,
          trantype: "3",
        });
        this.websocketsend(objs);
        this.dialogVisiblejijiu = false;
        this.isQues = true;
        this.isQues1 = true;
        let aa = this.inputPhs;
        let cc = this.cno;
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.objectType = 0;
          params.consultObject = aa;
          params.cno = cc;
          ClinkAgent.consult(params);
        });
        // 新加的
        this.switch = true;
      }
    },
    yiliaoP() {
      this.dialogVisibleyiliao = true;
      apiShout.getparTitle({ title: "远盟急救" }).then((res) => {
        // console.log('转接：',res.data);
        if (res.data.code == 200) {
          this.inputPhyiliao = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    phQuityiliao() {
      if (this.strcode == 500) {
        return false;
      } else {
        var objs = JSON.stringify({
          cno: this.cno,
          msgcode: 203,
          token: this.loginToken,
          mainuniqueid: this.mainUniqueIds,
          clientnumber: this.inputPhyiliao,
          trantype: "2",
        });
        this.websocketsend(objs);
        this.dialogVisibleyiliao = false;
        this.isQues = true;
        this.isQues1 = true;
        let aa = this.inputPhyiliao;
        let cc = this.cno;
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.objectType = 0;
          params.consultObject = aa;
          params.cno = cc;
          ClinkAgent.consult(params);
        });
        // 新加的
        this.switch = true;
      }
    },
    zhuanjie() {
      this.dialogVisiblePh = true;
    },
    regionOnes(val) {
      this.flagjinji = true;
      this.jinjiPhone = val;
      console.log(this.jinjiPhone, "this.jinjiphone");
      // if (this.cnoStatus != 0 || this.cnoStatus != "0") {
      //   this.$message.error("当前忙碌，请稍等再试！");
      //   return false;
      // } else {
      //   var objmegThree = JSON.stringify({
      //     cno: this.cno,
      //     msgcode: 210,
      //     token: this.loginToken,
      //     phone: val,
      //     userid: this.elderlyId,
      //     equipmentid:null,
      //   });
      //   this.websocketsend(objmegThree);
      //   // this.phones = val;
      //   this.UniqueIds = "";
      //   if (val != null) {
      //     this.size = "呼叫中";
      //     //记时通话时间
      //     clearInterval(this.timer2);
      //     this.currentTime1 = 0;
      //     this.testTimer1();
      //     //客服状态置忙
      //     this.valuezhuangtai = "忙碌";
      //     this.cnoStatus = 1;
      //     //改变客服状态下的计时器
      //     clearInterval(this.timer4);
      //     this.currentTime2 = 0;
      //     this.testTimer2();
      //     this.imgIconBtnOne = true;
      //     this.imgIconBtns = false;
      //     let hh = val;
      //     this.allPhone = val;
      //     //回拨前调用的接口
      //     let backObj = {
      //       con: this.cno,
      //       phone: hh,
      //       userId: this.backUserId,
      //     };
      //     apiShout.getbackInfor(backObj).then((res) => {
      //       console.log("回拨前调用的接口：", res.data);
      //     });
      //     if (this.allPhone != null) {
      //       const audioElement = this.audioElement;
      //       if (audioElement && audioElement.length > 0) {
      //         for (let i = 0; i < audioElement.length; i++) {
      //           this.audioElement[i].pause();
      //         }
      //       }
      //     }
      //     //存储本地
      //     localStorage.setItem("phone", JSON.stringify(val));
      //     ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
      //       // 传入参数对象
      //       var params = {};
      //       params.tel = hh;
      //       ClinkAgent.previewOutcall(params);
      //       ClinkAgent.registerListener(
      //         ClinkAgent.EventType.PREVIEW_OUTCALL,
      //         function (token) {
      //           console.log("外呼结果：", token);
      //         }
      //       ); // 预览外呼
      //     });
      //     this.isListioning = true;
      //     this.isListion = false;
      //     // //请求电话通话记录ID
      //     // apiShout.getcallHistory({ phone: val }).then((res) => {
      //     //   console.log("电话通话记录", res.data);
      //     //   this.mainUniqueId=res.data.result;
      //     //   console.log('main:',this.mainUniqueId);
      //     // });
      //     //请求挂机接口
      //     // this.timer5 = setInterval(this.endReason, 1000);
      //   } else {
      //     this.imgIconBtns = true;
      //     this.imgIconBtnOne = true;
      //   }
      //   console.log(val, "123");
      // }
    },
    //咨询
    phQuit() {
      if (this.strcode == 500) {
        return false;
      } else {
        // if (this.flagjinji == true) {
        //   this.pObj = this.jinjiPhone;
        // } else {
        //   this.pObj = this.inputPhsZuan;
        // }
        var objs = JSON.stringify({
          cno: this.cno,
          msgcode: 203,
          token: this.loginToken,
          mainuniqueid: this.mainUniqueIds,
          clientnumber: this.inputPhsZuan,
          trantype: "1",
        });
        this.websocketsend(objs);
        this.dialogVisiblePh = false;
        this.isQues = true;

        let pObjs = this.inputPhsZuan;
        let cc = this.cno;
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.objectType = 0;
          params.consultObject = pObjs;
          params.cno = cc;
          ClinkAgent.consult(params);
        });
        this.setTimer = setTimeout(() => {
          this.isQues1 = true;
        }, 7000);
        this.inputPhsZuan = "";
        this.regions = "";
        this.flagjinji = false;
        // 新加的
        this.switch = true;
      }
    },
    //咨询转移
    questZhuan() {
      let aa = this.cno;
      this.isQues = false;
      this.isQues1 = false;
      // 新加的
      this.switch = false;
      console.log("cno:", aa);
      ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
        // 传入参数对象
        var params = {};
        params.cno = aa;
        ClinkAgent.consultTransfer();
      });
    },
    //咨询接回
    questBack() {
      ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
        // 传入参数对象
        ClinkAgent.unconsult();
      });
      this.isQues = false;
      this.isQues1 = false;
      // 新加的
      this.switch = false;
      clearTimeout(this.setTimer);
    },
    handleClickTan(tab, event) {
      console.log(tab, event);
    },

    filterNode(value, data) {
      // console.log("节点：",value,data);
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
      // return data.text.indexOf(value) !== -1
    },
    //紧急联系人
    emergencyContact(val) {
      console.log(this.ursents, "输出对");
      for (var i = 0; i < this.ursents.length; i++) {
        this.userPhone = this.ursents[i];
        if (val === this.userPhone.userName) {
          this.formMember.emergencyContactOnePhone = this.userPhone.phone;
          let str = this.formMember.emergencyContactOnePhone;
          this.formMember.emergencyContactOnePhone = str.replace(
            /(\d{3})\d{4}(\d{1})/,
            "$1****$2"
          );
          this.idName = this.userPhone.id;
          //对于电话的处理
        }
      }
    },
    //紧急联系人2
    emergencyContacts(val) {
      console.log(this.ursentsTwo, "输出对");
      for (var i = 0; i < this.ursentsTwo.length; i++) {
        this.userPhones = this.ursentsTwo[i];
        if (val === this.userPhones.userName) {
          this.formMember.emergencyContactTwoPhone = this.userPhones.phone;
          let str = this.formMember.emergencyContactTwoPhone;
          this.formMember.emergencyContactTwoPhone = str.replace(
            /(\d{3})\d{4}(\d{1})/,
            "$1****$2"
          );
          this.idNames = this.userPhones.id;
          //对于电话的处理
        }
      }
    },
    dateTime() {
      //查询通话记录
      let date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      this.timeDateShow = year + seperator1 + month + seperator1 + strDate;
      this.tableData1 = [];
      // console.log(this.timeDateShow, "输出当前时间");
      apiShout.getConverse({ time: this.timeDateShow }).then((res) => {
        // console.log("定时传送音频走了dateTime");
        console.log("通话记录：", res.data);
        if (res.data.code === 200) {
          // 对通话时长的处理
          this.tableData1 = res.data.result.data;
          console.log(this.tableData1, "查询渲染语音条的数据");
          for (let i = 0; i < res.data.result.data.length; i++) {
            this.luyinUrl = res.data.result.data[i].qiniuUrl;
            if (res.data.result.data[i].bridgeDuration == null) {
              res.data.result.data[i].bridgeDuration = "";
            } else {
              res.data.result.data[i].bridgeDuration = this.format(
                res.data.result.data[i].bridgeDuration
              );
              // console.log(res.data.result.data[i], "查询所有数据");
            }
            if (res.data.result.data[i].callMode == "未接通") {
              res.data.result.data[i].qiniuUrl = null;
            }
          }
          this.totalTime1 = this.format(res.data.result.totalTime);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // //计时器
    // timeOut(){
    //   this.timer=setInterval(()=>{
    //     if(this.flag==1){

    //     }
    //   },1000)
    // },
    // //接听按钮后的页面的紧急按钮
    // urgent(){
    //   let hh = this.tel;
    //   ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
    //     // 传入参数对象
    //     var params = {};
    //     params.tel = hh;
    //     ClinkAgent.previewOutcall(params);
    //   });
    // },
    //新增慢性病
    addInput() {
      this.numAdd++;
      if (this.numAdd % 2 === 0) {
        this.isZhan = false;
        this.isZhan1 = true;
      } else {
        this.isZhan = true;
      }
      if (this.formMemberInfo.illness != null) {
        this.inputman = this.formMemberInfo.illness;
      }
      console.log(this.formMemberInfo, "shuchuyixia111");
    },
    chrIllinse() {
      this.isZhan = true;
      this.isZhan1 = false;
      let aa = this.formMemberInfo.illness.lastIndexOf(",");
      let bb = this.formMemberInfo.illness.length - 1;
      console.log(
        "ygfyhfu:",
        aa,
        this.formMemberInfo.illness.substring(aa, 1),
        bb
      );
      if (this.formMemberInfo.illness != null && aa < bb) {
        this.inputman = this.formMemberInfo.illness + ",";
      } else {
        this.inputman = this.formMemberInfo.illness;
      }
    },
    // manFocus(){
    //   this.isZhan=false;
    //   this.isZhan1=true;
    //   this.formMemberInfo.illness=this.inputman;
    // },
    zhanChange(val) {
      if (
        this.formMemberInfo.illness == "" ||
        this.formMemberInfo.illness == null ||
        this.formMemberInfo.illness == undefined
      ) {
        this.isZhan1 = true;
        this.isZhan = false;
        this.numAdd = 0;
        this.formMemberInfo.illness = val + ",";
        this.formMemberInfo.illness = this.formMemberInfo.illness.substring(
          0,
          this.formMemberInfo.illnes.length - 1
        );
      } else {
        this.formMemberInfo.illness += val + ",";
        this.formMemberInfo.illness = this.formMemberInfo.illness.substring(
          0,
          this.formMemberInfo.illness.length - 1
        );
      }
      if (this.formMemberInfo.illness != this.inputman) {
        this.formMemberInfo.illness = this.inputman;
      }
      console.log("====?", val);
      this.isZhan = false;
      this.isZhan1 = true;
      this.inputman = "";
    },
    timeDate(val) {
      var seperator1 = "-";
      var year = val.getFullYear();
      var month = val.getMonth() + 1;
      var strDate = val.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      val = year + seperator1 + month + seperator1 + strDate;
      console.log(val, "输出日期格式1");
      this.tableData1 = [];
      apiShout.getConverse({ time: val }).then((res) => {
        // console.log("定时传送音频走了timeDate");
        //console.log("通话记录：", res.data);
        if (res.data.code === 200) {
          // 对通话时长的处理
          for (let i = 0; i < res.data.result.data.length; i++) {
            if (res.data.result.data[i].bridgeDuration == null) {
              res.data.result.data[i].bridgeDuration = "";
            } else {
              res.data.result.data[i].bridgeDuration = this.format(
                res.data.result.data[i].bridgeDuration
              );
            }
          }
          this.tableData1 = res.data.result.data;
          this.totalTime1 = this.format(res.data.result.totalTime);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //静音
    jinyin() {
      this.muteCount++;
      if (this.muteCount % 2 === 0) {
        this.isYIN = true;
        this.isJIN = false;
        //取消静音
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.direction = "in";
          ClinkAgent.unmute(params);
        });
      } else {
        this.isJIN = true;
        this.isYIN = false;
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.direction = "in";
          ClinkAgent.mute(params);
        });
      }
    },
    //保存用户信息
    preservation() {
      console.log(this.countyId, "输出城市");
      console.log(this.cityId, "输出城市2");
      this.infoFlag = true;
      this.$confirm("是否保存此信息?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //formMember.emergencyContactOne
          this.formMember.createDate = [];
          this.formMember.updateDate = [];
          this.formMember.joinDate = [];
          this.formMember.leaveDate = [];
          this.formMemberInfo.createDate = [];
          this.formMemberInfo.updateDate = [];
          this.formMemberInfo.bloodPressureDate = [];
          this.formMemberInfo.bloodSugarDate = [];
          this.formMemberInfo.heartRateDate = [];
          this.formMemberInfo.temperatureDate = [];
          this.formMemberInfo.sleepDate = [];
          this.formMemberInfo.walkDate = [];
          console.log("备用：", this.formMemberInfo.illness);
          // if (this.Sphone == "" || this.Sphone == null) {
          //   this.$message.error("保存失败，备用号码不能为空");
          //   return false;
          // }

          // let date = this.formMember.birthday;
          // var seperator1 = "-";
          // var year = date.getFullYear();
          // var month = date.getMonth() + 1;
          // var strDate = date.getDate();
          // if (month >= 1 && month <= 9) {
          //   month = "0" + month;
          // }
          // if (strDate >= 0 && strDate <= 9) {
          //   strDate = "0" + strDate;
          // }
          // this.formMember.birthday =
          //   year + seperator1 + month + seperator1 + strDate;
          apiShout
            .updateMembers({
              id: this.formMemberInfo.id,
              delFlag: this.formMemberInfo.delFlag,
              createId: this.formMemberInfo.createId,
              updateId: this.formMemberInfo.updateId,
              memberId: this.formMemberInfo.memberId,
              live: this.formMemberInfo.live,
              intellect: this.formMemberInfo.intellect,
              character: this.formMemberInfo.character,
              language: this.formMemberInfo.language,
              hearing: this.formMemberInfo.hearing,
              vision: this.formMemberInfo.vision,
              action: this.formMemberInfo.action,
              illness: this.formMemberInfo.illness,
              createDate: this.formMemberInfo.createDate,
              updateDate: this.formMemberInfo.updateDate,
              bloodPressureDate: this.formMemberInfo.bloodPressureDate,
              bloodSugarDate: this.formMemberInfo.bloodSugarDate,
              heartRateDate: this.formMemberInfo.heartRateDate,
              temperatureDate: this.formMemberInfo.temperatureDate,
              sleepDate: this.formMemberInfo.sleepDate,
              walkDate: this.formMemberInfo.walkDate,
            })
            .then((res) => {
              if (res.data.code === 200) {
                this.$message({
                  type: "success",
                  message: "保存成功!",
                });
                this.formMember.emergencyContactOne = "";
                this.formMember.emergencyContactTwo = "";
                this.inputman = "";
                // this.formMemberInfo = {};
                this.infoFlag = false;
                console.log(res, "res保存生活状况信息");
                //查询用户信息
                apiShout
                  .getMemberInfoByIds({ id: this.customerIds })
                  .then((dat) => {
                    console.log("用户信息：", dat.data);
                    if (dat.data.code === 200) {
                      //对于生日的处理
                      let a = dat.data.result.member.birthday;
                      let dd = new Date(a);
                      let y = dd.getFullYear();
                      let m = dd.getMonth() + 1;
                      m = m < 10 ? "0" + m : m;
                      let d = dd.getDate();
                      d = d < 10 ? "0" + d : d;
                      dat.data.result.member.birthday = y + "-" + m + "-" + d;
                      //对于激活日期的处理
                      let b = dat.data.result.member.createDate;
                      let dd1 = new Date(b);
                      let y1 = dd1.getFullYear();
                      let m1 = dd1.getMonth() + 1;
                      m1 = m1 < 10 ? "0" + m1 : m1;
                      let d1 = dd1.getDate();
                      d1 = d1 < 10 ? "0" + d1 : d1;
                      dat.data.result.member.createDate =
                        y1 + "-" + m1 + "-" + d1;
                      //对于到期日期的处理
                      let c = dat.data.result.member.updateDate;
                      let dd2 = new Date(c);
                      let y2 = dd2.getFullYear();
                      let m2 = dd2.getMonth() + 1;
                      m2 = m2 < 10 ? "0" + m2 : m2;
                      let d2 = dd2.getDate();
                      d2 = d2 < 10 ? "0" + d2 : d2;
                      dat.data.result.member.updateDate =
                        y2 + "-" + m2 + "-" + d2;
                      this.formMember = dat.data.result.member;
                      this.xinLog = this.formMember.lon;
                      this.xinLat = this.formMember.lat;
                      this.xinLoc = this.formMember.location;
                      this.formMembers = dat.data.result.member;
                      this.familyIds = dat.data.result.member.familyId;
                      console.log(this.familyIds, "123123123");
                      this.Sphone = dat.data.result.member.sparePhone;
                      console.log(this.Sphone, "备用手机号查看");
                      if (this.Sphone != null) {
                        this.StandbyIcon = true;
                        this.StandbyIcons = false;
                      } else {
                        this.StandbyIcon = false;
                        this.StandbyIcons = true;
                      }
                      let str = this.formMember.sparePhone;
                      if (str !== null) {
                        this.formMember.NewsparePhone = str.replace(
                          /(\d{3})\d{4}(\d{1})/,
                          "$1****$2"
                        );
                      }
                      // this.formMember.sparePhone =
                      //   this.formMember.sparePhone.substr(0, 3) +
                      //   "****" +
                      //   this.formMember.sparePhone.substr(7);
                      this.formMemberInfo = dat.data.result.memberInfo;
                      if (dat.data.result.memberInfo.illness == null) {
                        this.isZhan1 = false;
                        this.isZhan = false;
                        this.inputman = "";
                      } else {
                        this.isZhan1 = true;
                      }
                      this.cityName = dat.data.result.member.city;
                      this.countyName = dat.data.result.member.county;
                      this.cityId = dat.data.result.member.city;
                      this.countyId = dat.data.result.member.county;
                      this.emergencyOnes = dat.data.result.member.emergencyOne;
                      this.emergencyTwos = dat.data.result.member.emergencyTwo;
                      // console.log(CodeToText[this.countyName], "CodeToText123");
                      // console.log(CodeToText[this.cityName], "CodeToText11111");
                      this.formMember.city = CodeToText[this.cityName];
                      this.formMember.county = CodeToText[this.countyName];
                      if (this.infoFlag == true) {
                        this.formMember.city = dat.data.result.member.city;
                        this.formMember.county = dat.data.result.member.county;
                      }
                      this.formMember.birthday =
                        dat.data.result.member.birthday;
                      this.elderlyId = dat.data.result.member.id;
                      // this.formMemberInfo.live = dat.data.result.memberInfo.live;
                      this.formMemberInfo.intellect =
                        dat.data.result.memberInfo.intellect;
                      this.formMemberInfo.character =
                        dat.data.result.memberInfo.character;
                      this.formMemberInfo.language =
                        dat.data.result.memberInfo.language;
                      this.formMemberInfo.hearing =
                        dat.data.result.memberInfo.hearing;
                      this.formMemberInfo.vision =
                        dat.data.result.memberInfo.vision;
                      this.formMemberInfo.action =
                        dat.data.result.memberInfo.action;
                      this.formMemberInfo.illness =
                        dat.data.result.memberInfo.illness;
                      // //健康数据
                      // this.forBody = dat.data.result.memberInfo;
                      // for (let i = 0; i < dat.data.result.remind.length; i++) {
                      //   this.morningbefor = dat.data.result.remind[0];
                      //   this.morningafter = dat.data.result.remind[1];
                      //   this.lunchbefor = dat.data.result.remind[2];
                      //   this.lunchafter = dat.data.result.remind[3];
                      //   this.eveningbefor = dat.data.result.remind[4];
                      // }
                    } else {
                      this.$message.error(dat.data.msg);
                    }
                    this.imgIconBtns = false;
                    this.imgIconBtnOne = true;
                    console.log(this.familyIds, "家庭id");
                    // this.ursents = [];
                    // this.ursentsTwo = [];
                    // this.region = [];
                    // this.regionTong = [];
                    // this.regions = "";
                    // apiShout
                    //   .getFamilyUsers({ fId: this.familyIds })
                    //   .then((res) => {
                    //     if (res.data.code === 200) {
                    //       console.log(res, "紧急联系人");
                    //       // this.ursents = res.data.result
                    //       res.data.result.forEach((item) => {
                    //         this.ursents.push(item);
                    //         this.ursentsTwo.push(item);
                    //         // this.region.push(item);
                    //         // console.log(this.region, "紧急联系人111111");
                    //       });

                    //       for (var i = 0; i < this.ursents.length; i++) {
                    //         this.userPhone = this.ursents[i];

                    //         if (this.userPhone.id === this.emergencyOnes) {
                    //           this.formMember.emergencyContactOnePhone =
                    //             this.userPhone.phone;
                    //           this.formMember.emergencyContactOne =
                    //             this.userPhone.userName;
                    //           let str =
                    //             this.formMember.emergencyContactOnePhone;
                    //           this.formMember.emergencyContactOnePhone =
                    //             str.replace(/(\d{3})\d{4}(\d{1})/, "$1****$2");
                    //           let arrText = {};
                    //           arrText.userName = this.userPhone.userName;
                    //           arrText.phone = this.userPhone.phone;
                    //           this.regionTong.push(arrText);
                    //           this.region.push(arrText);
                    //         }
                    //       }
                    //       for (let i = 0; i < this.ursentsTwo.length; i++) {
                    //         this.userPhones = this.ursentsTwo[i];
                    //         if (this.userPhones.id === this.emergencyTwos) {
                    //           this.formMember.emergencyContactTwoPhone =
                    //             this.userPhones.phone;
                    //           this.formMember.emergencyContactTwo =
                    //             this.userPhones.userName;
                    //           let str =
                    //             this.formMember.emergencyContactTwoPhone;
                    //           this.formMember.emergencyContactTwoPhone =
                    //             str.replace(/(\d{3})\d{4}(\d{1})/, "$1****$2");
                    //           let arrText = {};
                    //           arrText.userName = this.userPhones.userName;
                    //           arrText.phone = this.userPhones.phone;
                    //           this.regionTong.push(arrText);
                    //           this.region.push(arrText);
                    //         }
                    //       }
                    //     } else {
                    //       this.$message.error(res.data.msg);
                    //     }
                    //   });
                  });
                let allEq = JSON.parse(localStorage.getItem("strequipmentIds"));
                apiShout
                  .geteqMem({ equipmentId: allEq, memberId: this.customerIds })
                  .then((ss) => {
                    console.log("查询服务项：", ss.data.result);
                    if (ss.data.code == 200) {
                      if (ss.data.result != null) {
                        this.isSer = true;
                        this.serverType = ss.data.result;
                        if (this.serverType.basicStatus == 1) {
                          this.serverType.basicEnd = "未开通";
                        } else if (this.serverType.basicStatus == 3) {
                          this.serverType.basicEnd = "已过期";
                        }
                        if (this.serverType.careStatus == 1) {
                          this.serverType.careEnd = "未开通";
                        } else if (this.serverType.careStatus == 3) {
                          this.serverType.careEnd = "已过期";
                        }
                        if (this.serverType.ymStatus == 1) {
                          this.serverType.ymEnd = "未开通";
                        } else if (this.serverType.ymStatus == 3) {
                          this.serverType.ymEnd = "已过期";
                        }
                        if (this.serverType.contactStatus == 1) {
                          this.serverType.contactEnd = "未开通";
                        } else if (this.serverType.contactStatus == 3) {
                          this.serverType.contactEnd = "已过期";
                        } else if (this.serverType.contactStatus == 2) {
                          this.serverType.contactEnd = "长期有效";
                        }
                        if (this.serverType.healthStatus == 1) {
                          this.serverType.healthEnd = "未开通";
                        } else if (this.serverType.healthStatus == 3) {
                          this.serverType.healthEnd = "已过期";
                        }
                        if (this.serverType.lawStatus == 1) {
                          this.serverType.lawEnd = "未开通";
                        } else if (this.serverType.lawStatus == 3) {
                          this.serverType.lawEnd = "已过期";
                        }
                        if (this.serverType.cnoStatus == 1) {
                          this.serverType.cnoEnd = "未开通";
                        } else if (this.serverType.cnoStatus == 3) {
                          this.serverType.cnoEnd = "已过期";
                        }
                      } else {
                        this.isSer = false;
                      }
                    }
                  });
              } else {
                this.$message.error(res.data.msg);
              }
            });
          // apiShout
          //   .updateMembers(
          //     this.formMemberInfo,
          //     this.formMemberInfo.createDate,
          //     this.formMemberInfo.updateDate,
          //     this.formMemberInfo.bloodPressureDate,
          //     this.formMemberInfo.bloodSugarDate,
          //     this.formMemberInfo.heartRateDate,
          //     this.formMemberInfo.temperatureDate,
          //     this.formMemberInfo.sleepDate,
          //     this.formMemberInfo.walkDate
          //   )
          //   .then((res) => {
          //     if (res.data.code === 200) {
          //       this.$message({
          //         type: "success",
          //         message: "保存成功!",
          //       });
          //       this.formMemberInfo={};
          //       console.log(res, "res保存生活状况信息");
          //     } else {
          //       this.$message({
          //         type: "success",
          //         message: "保存失败!",
          //       });
          //     }
          //   });
          this.infoFlags = true;
          if (this.infoFlags == true) {
            this.formMember.city = this.cityId;
            this.formMember.county = this.countyId;
            this.formMember.sparePhone = this.Sphone;
          }
          this.formMember.emergencyOne = this.idName;
          this.formMember.emergencyTwo = this.idNames;

          apiShout
            .updates(
              this.formMember,
              this.formMember.createDate,
              this.formMember.updateDate,
              this.formMember.joinDate,
              this.formMember.leaveDate,
              this.formMember.birthday,
              this.formMember.sparePhone
            )
            .then((res) => {
              if (res.data.code === 200) {
                console.log(res, "res保存用户信息");
                // this.formMember = {};
                this.infoFlag = false;
                //查询用户信息
                apiShout
                  .getMemberInfoByIds({ id: this.customerIds })
                  .then((dat) => {
                    console.log("用户信息：", dat.data);
                    if (dat.data.code === 200) {
                      //对于生日的处理
                      let a = dat.data.result.member.birthday;
                      let dd = new Date(a);
                      let y = dd.getFullYear();
                      let m = dd.getMonth() + 1;
                      m = m < 10 ? "0" + m : m;
                      let d = dd.getDate();
                      d = d < 10 ? "0" + d : d;
                      dat.data.result.member.birthday = y + "-" + m + "-" + d;
                      //对于激活日期的处理
                      let b = dat.data.result.member.createDate;
                      let dd1 = new Date(b);
                      let y1 = dd1.getFullYear();
                      let m1 = dd1.getMonth() + 1;
                      m1 = m1 < 10 ? "0" + m1 : m1;
                      let d1 = dd1.getDate();
                      d1 = d1 < 10 ? "0" + d1 : d1;
                      dat.data.result.member.createDate =
                        y1 + "-" + m1 + "-" + d1;
                      //对于到期日期的处理
                      let c = dat.data.result.member.updateDate;
                      let dd2 = new Date(c);
                      let y2 = dd2.getFullYear();
                      let m2 = dd2.getMonth() + 1;
                      m2 = m2 < 10 ? "0" + m2 : m2;
                      let d2 = dd2.getDate();
                      d2 = d2 < 10 ? "0" + d2 : d2;
                      dat.data.result.member.updateDate =
                        y2 + "-" + m2 + "-" + d2;
                      this.formMember = dat.data.result.member;
                      this.xinLog = this.formMember.lon;
                      this.xinLat = this.formMember.lat;
                      this.xinLoc = this.formMember.location;
                      this.formMembers = dat.data.result.member;
                      this.familyIds = dat.data.result.member.familyId;
                      console.log(this.familyIds, "123123123");
                      this.Sphone = dat.data.result.member.sparePhone;
                      console.log(this.Sphone, "备用手机号查看");
                      if (this.Sphone != null) {
                        this.StandbyIcon = true;
                        this.StandbyIcons = false;
                      } else {
                        this.StandbyIcon = false;
                        this.StandbyIcons = true;
                      }
                      let str = this.formMember.sparePhone;
                      if (str !== null) {
                        this.formMember.NewsparePhone = str.replace(
                          /(\d{3})\d{4}(\d{1})/,
                          "$1****$2"
                        );
                      }
                      // this.formMember.sparePhone =
                      //   this.formMember.sparePhone.substr(0, 3) +
                      //   "****" +
                      //   this.formMember.sparePhone.substr(7);
                      this.formMemberInfo = dat.data.result.memberInfo;
                      if (dat.data.result.memberInfo.illness == null) {
                        this.isZhan1 = false;
                        this.isZhan = false;
                        this.inputman = "";
                      } else {
                        this.isZhan1 = true;
                      }
                      this.cityName = dat.data.result.member.city;
                      this.countyName = dat.data.result.member.county;
                      this.cityId = dat.data.result.member.city;
                      this.countyId = dat.data.result.member.county;
                      this.emergencyOnes = dat.data.result.member.emergencyOne;
                      this.emergencyTwos = dat.data.result.member.emergencyTwo;
                      // console.log(CodeToText[this.countyName], "CodeToText123");
                      // console.log(CodeToText[this.cityName], "CodeToText11111");
                      this.formMember.city = CodeToText[this.cityName];
                      this.formMember.county = CodeToText[this.countyName];
                      if (this.infoFlag == true) {
                        this.formMember.city = dat.data.result.member.city;
                        this.formMember.county = dat.data.result.member.county;
                      }
                      this.formMember.birthday =
                        dat.data.result.member.birthday;
                      this.elderlyId = dat.data.result.member.id;
                      // this.formMemberInfo.live = dat.data.result.memberInfo.live;
                      this.formMemberInfo.intellect =
                        dat.data.result.memberInfo.intellect;
                      this.formMemberInfo.character =
                        dat.data.result.memberInfo.character;
                      this.formMemberInfo.language =
                        dat.data.result.memberInfo.language;
                      this.formMemberInfo.hearing =
                        dat.data.result.memberInfo.hearing;
                      this.formMemberInfo.vision =
                        dat.data.result.memberInfo.vision;
                      this.formMemberInfo.action =
                        dat.data.result.memberInfo.action;
                      this.formMemberInfo.illness =
                        dat.data.result.memberInfo.illness;
                      // //健康数据
                      // this.forBody = dat.data.result.memberInfo;
                      // for (let i = 0; i < dat.data.result.remind.length; i++) {
                      //   this.morningbefor = dat.data.result.remind[0];
                      //   this.morningafter = dat.data.result.remind[1];
                      //   this.lunchbefor = dat.data.result.remind[2];
                      //   this.lunchafter = dat.data.result.remind[3];
                      //   this.eveningbefor = dat.data.result.remind[4];
                      // }
                    } else {
                      this.$message.error(dat.data.msg);
                    }
                    this.imgIconBtns = false;
                    this.imgIconBtnOne = true;
                    console.log(this.familyIds, "家庭id");
                    this.ursents = [];
                    this.ursentsTwo = [];
                    this.region = [];
                    this.regionTong = [];
                    this.regions = "";
                    apiShout
                      .getFamilyUsers({ fId: this.familyIds })
                      .then((res) => {
                        if (res.data.code === 200) {
                          console.log(res, "紧急联系人");
                          // this.ursents = res.data.result
                          res.data.result.forEach((item) => {
                            this.ursents.push(item);
                            this.ursentsTwo.push(item);
                            this.region.push(item);
                            // console.log(this.region, "紧急联系人111111");
                          });

                          for (var i = 0; i < this.ursents.length; i++) {
                            this.userPhone = this.ursents[0];
                            this.userPhones = this.ursents[1];
                            // if (this.userPhone.id === this.emergencyOnes) {
                            // console.log(
                            //   this.userPhone.phone,
                            //   this.userPhone.userName,
                            //   "this.userPhone.phone"
                            // );
                            this.formMember.emergencyContactOnePhone =
                              this.userPhone.phone;
                            this.formMember.emergencyContactOne =
                              this.userPhone.userName;
                            let str = this.formMember.emergencyContactOnePhone;
                            this.formMember.emergencyContactOnePhone =
                              str.replace(/(\d{3})\d{4}(\d{1})/, "$1****$2");
                            // let arrss = [];
                            let arrText = {};
                            arrText.userName = this.userPhone.userName;
                            arrText.phone = this.userPhone.phone;
                            this.regionTong.push(arrText);
                            this.formMember.emergencyContactTwoPhone =
                              this.userPhones.phone;
                            this.formMember.emergencyContactTwo =
                              this.userPhones.userName;
                            let str1 = this.formMember.emergencyContactTwoPhone;
                            this.formMember.emergencyContactTwoPhone =
                              str1.replace(/(\d{3})\d{4}(\d{1})/, "$1****$2");
                            // let arrss = [];
                            // let arrText = {};
                            // arrText.userName = this.userPhones.userName;
                            // arrText.phone = this.userPhones.phone;
                            // this.regionTong.push(arrText);
                            // this.region.push(arrText);
                            // }
                          }
                          // for (let i = 0; i < this.ursentsTwo.length; i++) {
                          //   this.userPhones = this.ursentsTwo[i];
                          //   if (this.userPhones.id === this.emergencyTwos) {
                          //     console.log(
                          //       this.userPhones.phone,
                          //       this.userPhones.userName,
                          //       "this.userPhones.userName"
                          //     );
                          //     this.formMember.emergencyContactTwoPhone =
                          //       this.userPhones.phone;
                          //     this.formMember.emergencyContactTwo =
                          //       this.userPhones.userName;
                          //     let str = this.formMember.emergencyContactTwoPhone;
                          //     this.formMember.emergencyContactTwoPhone = str.replace(
                          //       /(\d{3})\d{4}(\d{1})/,
                          //       "$1****$2"
                          //     );
                          //     // let arrss = [];
                          //     let arrText = {};
                          //     arrText.userName = this.userPhones.userName;
                          //     arrText.phone = this.userPhones.phone;
                          //     this.regionTong.push(arrText);
                          //     // this.region.push(arrText);
                          //   }
                          // }
                        } else {
                          this.$message.error(res.data.msg);
                        }
                      });
                  });
                let allEq = JSON.parse(localStorage.getItem("strequipmentIds"));
                apiShout
                  .geteqMem({ equipmentId: allEq, memberId: this.customerIds })
                  .then((ss) => {
                    console.log("查询服务项：", ss.data.result);
                    if (ss.data.code == 200) {
                      if (ss.data.result != null) {
                        this.isSer = true;
                        this.serverType = ss.data.result;
                        if (this.serverType.basicStatus == 1) {
                          this.serverType.basicEnd = "未开通";
                        } else if (this.serverType.basicStatus == 3) {
                          this.serverType.basicEnd = "已过期";
                        }
                        if (this.serverType.careStatus == 1) {
                          this.serverType.careEnd = "未开通";
                        } else if (this.serverType.careStatus == 3) {
                          this.serverType.careEnd = "已过期";
                        }
                        if (this.serverType.ymStatus == 1) {
                          this.serverType.ymEnd = "未开通";
                        } else if (this.serverType.ymStatus == 3) {
                          this.serverType.ymEnd = "已过期";
                        }
                        if (this.serverType.contactStatus == 1) {
                          this.serverType.contactEnd = "未开通";
                        } else if (this.serverType.contactStatus == 3) {
                          this.serverType.contactEnd = "已过期";
                        } else if (this.serverType.contactStatus == 2) {
                          this.serverType.contactEnd = "长期有效";
                        }
                        if (this.serverType.healthStatus == 1) {
                          this.serverType.healthEnd = "未开通";
                        } else if (this.serverType.healthStatus == 3) {
                          this.serverType.healthEnd = "已过期";
                        }
                        if (this.serverType.lawStatus == 1) {
                          this.serverType.lawEnd = "未开通";
                        } else if (this.serverType.lawStatus == 3) {
                          this.serverType.lawEnd = "已过期";
                        }
                        if (this.serverType.cnoStatus == 1) {
                          this.serverType.cnoEnd = "未开通";
                        } else if (this.serverType.cnoStatus == 3) {
                          this.serverType.cnoEnd = "已过期";
                        }
                      } else {
                        this.isSer = false;
                      }
                    }
                  });
              } else {
                this.$message.error(res.data.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消保存！",
          });
        });
    },
    tellQuit(tell) {
      if (tell == "") {
        this.$message.error("号码不能为空!");
      } else {
        this.$confirm("手机转接, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(() => {
          console.log(tell, "查看输入的号码");
          apiShout.setTranPhone({ phone: tell }).then((res) => {
            if (res.data.code == 200) {
              console.log(res.data, "查看转接是否成功");
              this.$message({
                type: "success",
                message: "转接成功!",
              });
              this.tellStatus = "手机接听中...";
              this.closeButton = true;
            } else {
              this.$message.error(res.data.msg);
            }
          });
        });
      }
    },
    closeTell() {
      this.dialogVisibleTell = false;
      this.tellStatus = "手机转接";
      this.inputPhsTell = "";
      this.closeButton = false;
    },
    cancelTell(tell) {
      if (tell == "") {
        this.$message.error("号码不能为空!");
      } else {
        this.$confirm("取消转接, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(() => {
          console.log(tell, "查看输入的号码");
          apiShout.cancelTranPhone({ phone: tell }).then((res) => {
            if (res.data.code == 200) {
              console.log(res.data, "查看转接是否成功");
              this.$message({
                type: "success",
                message: "取消转接!",
              });
              this.dialogVisibleTell = false;
              this.tellStatus = "手机转接";
              this.inputPhsTell = "";
              this.closeButton = false;
            } else {
              this.$message.error(res.data.msg);
            }
          });
        });
      }
    },
    myStatusValue(value) {
      console.log(value, "查看value");
      if (value == 2) {
        this.dialogVisibleTell = true;
        console.log("进入这个里面");
      }
    },
    //客服状态的修改
    myStatus(value) {
      // console.log('=====',value);
      // console.log("=====", value);
      console.log(value, "ceshi测试111111111111111111");
      // this.$emit("serStatus", value);
      localStorage.setItem("serverStatus", JSON.stringify(value));
      apiShout.getUpStatus({ cno: this.cno, status: value }).then((res) => {
        console.log("修改状态：", res.data);
        if (value == "0" && res.data.code == 200) {
          this.cnoStatus = value;
          //调用一次挂断接口
          // this.endReason();
          //空闲
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          // this.timer3 = setInterval(this.shout, 3000);
          // this.shout();
        } else if (value == "1" && res.data.code == 200) {
          this.cnoStatus = value;
          //忙碌
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          // clearInterval(this.timer3);
        } else if (value == "2" && res.data.code == 200) {
          this.cnoStatus = value;
          //暂离
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          // clearInterval(this.timer3);
        } else {
          this.$message.error(res.data.msg);
          this
            .$http({
              url: "/too/center/weblog/save",
              params: {
                callback: "",
                errorCode: res.data.msg,
                errorExplain: "我的状态",
                interfaceUrl: '',
                menuName: "客服系统与400中心-状态切换",
                menuUrl: this.$route.path,
                params: res.config.params,
                source: "客服系统",
                userId: this.userinfo.id,
                userName: this.userinfo.name,
              },
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
              method: "get",
            })
            .then((res) => {
              if (res.data.code === 200) {
                console.log();
              }
            });
        }
      });
    },
    handleCloseTwo() {},
    //市
    provinces(val) {
      this.ids = val;
      api.AreaCity({ parentId: this.ids }).then((res) => {
        console.log(res);
        // this.historyOrderCity = res.data.result;
        this.historyOrderCity = res.data.result; //渲染省
      });
    },
    //区
    provincesOne(val) {
      this.ids = val;
      this.cityId = val;
      api.AreaCity({ parentId: this.ids }).then((res) => {
        // this.historyOrderCity = res.data.result;
        this.historycounty = res.data.result; //渲染省
      });
    },
    Fcounty(val) {
      this.countyId = val;
    },
    //模糊搜索
    handlChange(val) {
      if (this.searchs !== "") {
        this.dataLists = "";
        //获取常用知识
        apiShout.getKnowledge({ text: val }).then((res) => {
          //console.log("常用知识：", res.data);
          if (res.data.code === 200) {
            // this.dataList = res.data.result;
            this.dataLists = res.data.result[0].childs[0].text;
          } else {
            this.$message.error(res.data.msg);
          }
        });
        // console.log(apiShout.getKnowledge(), "清空搜索框展示数据");
      } else {
        this.offenKnow();
      }
    },
    handlePlay(index) {
      this.indexOfs = index;

      if (this.textPhone == null) {
        const audioElement = this.audioElement;
        if (audioElement && audioElement.length > 0) {
          for (let i = 0; i < audioElement.length; i++) {
            if (i === index) {
              this.audioElement[i].play();
            } else {
              this.audioElement[i].pause();
            }
          }
        }
      } else if (this.textPhone != null && this.isListion == true) {
        const audioElement = this.audioElement;
        if (audioElement && audioElement.length > 0) {
          for (let i = 0; i < audioElement.length; i++) {
            if (i === index) {
              this.audioElement[i].play();
            } else {
              this.audioElement[i].pause();
            }
          }
        }
      } else if (this.textPhone != null) {
        const audioElement = this.audioElement;
        if (audioElement && audioElement.length > 0) {
          for (let i = 0; i < audioElement.length; i++) {
            this.audioElement[i].pause();
          }
        }
      }
      // const audioElement = this.audioElement;
      // if (audioElement && audioElement.length > 0) {
      //   for (let i = 0; i < audioElement.length; i++) {
      //     if (i === index) {
      //       this.audioElement[i].play();
      //     } else {
      //       this.audioElement[i].pause();
      //     }
      //   }
      // }
    },
    //关联人员
    Associatedpersonnel() {
      this.dialogVisible = true;
    },
    secondList() {
      const audioElement = this.audioElement;
      if (audioElement && audioElement.length > 0) {
        for (let i = 0; i < audioElement.length; i++) {
          this.audioElement[i].pause();
        }
      }
      this.waitFinsh(this.serviceId);
    },
    sphonesOne(tel) {
      this.Sphone = tel;
      // console.log('号码：',this.Sphone);
    },
    //点击表格的每一行事件
    rowClick(row, column, event) {
      this.activeName1 = "first";
      console.log("点击表格事件：", row, event, this.textPhone);
      localStorage.setItem("strequipmentIds", JSON.stringify(row.equipmentId));
      this.isSave = false;
      // this.jiUserD=row.customerId;
      // this.dianji=true;
      // this.equipmentIdStrTong=row.equipmentId;
      // this.tel = row.localPhone;
      this.mobilePhone = row.callPhone;
      console.log(this.UniqueIds, "mainUniqueId123123123");
      if (this.isArrangement1 == true) {
        this.imgIcon = false;
        this.imgIcons = true;
        event.isTrusted = false;
      }
      if (this.textPhone == null && this.isListioning == false) {
        if (row.customerId != null || row.customerId != "") {
          this.isTabs = true;
          this.isTab = false;
        } else {
          this.isTabs = false;
          this.isTab = true;
        }
        this.userId = row.customerId;
        // this.equipmentIdStr = row.equipmentId;
        this.textarea = "";
        this.type = "";
        this.checkListData = [];
        this.checkListData1 = [];
        this.checkListData2 = [];
        this.checkListData3 = [];
        this.checkListData4 = [];
        this.zz7 = [];
        this.zz8 = [];
        this.zz5 = [];
        this.zz6 = [];
        this.zz3 = [];
        this.zz4 = [];
        this.zz = [];
        this.zz1 = [];
        this.zz2 = [];
        this.handleTime = "";
        this.valueOrdertime = "";
        this.valueOrdertimes = "";
        this.valueOrderputong = "";
        this.valueOrdertime1 = "";
        this.valueOrderputong1 = "";
        this.valueOrder = "";
        this.callback1 = 0;
        this.valueType = false;
        this.customerServiceId = "";
        this.isZhou = false;
        this.callback2 = 0;
        this.valueTypes = false;

        this.customerIds = row.customerId;
        this.equipmentId = row.equipmentId;
        this.UniqueIds = row.mainUniqueId;
        this.tel = row.localPhone;
        localStorage.setItem("UniqueIdn", JSON.stringify(row.mainUniqueId));
        localStorage.setItem("userIds", JSON.stringify(row.customerId));

        this.noTaking = true;
        this.getFamily();
        this.getsheList();
        this.getOld();
        if (this.isTabs == true) {
          this.$nextTick(() => {
            this.$refs.RefMemberIds.getMemberIds();
            this.$refs.RefgetList.getList();
            this.$refs.RadioBroadcasts.getOneTime();
            this.isYou = false;
            this.$refs.familyUsers.isXinZhen = true;
            this.$refs.equipmentUser.isXinZhen = false;
            this.$refs.RefMemberIds.isXinZhen = true;
            this.$refs.healthData.isXinZhen = true;
            this.$refs.RefgetList.isXinZhen = true;
          });
        } else {
          this.$nextTick(() => {
            this.$refs.RefMemberIdsOne.getMemberIds();
            this.$refs.RefgetListOne.getList();
            this.$refs.RadioBroadcastsOne.getOneTime();
            this.$refs.familyUsersOne.isXinZhen = false;
            this.$refs.equipmentUserOne.isXinZhen = true;
            this.$refs.RefMemberIdsOne.isXinZhen = false;
            this.$refs.healthDataOne.isXinZhen = true;
            this.$refs.RefgetListOne.isXinZhen = false;
          });
        }
        // this.$refs.RefMemberIds.getMemberIds();
        // this.$refs.RefgetList.getList();
        this.prohibit = event.cancelable;
        if (this.mobilePhone != null || this.mobilePhone != "") {
          this.imgIcon = true;
          this.imgIcons = false;
        } else {
          this.imgIcon = false;
          this.imgIcons = true;
        }
        if (
          (this.isArrangement1 == true && this.mobilePhone != null) ||
          (this.isArrangement1 == true && this.mobilePhone != "")
        ) {
          this.imgIcon = false;
          this.imgIcons = true;
          event.isTrusted = false;
        }
        if (row.equipmentId == null || row.customerId == null) {
          this.elderlyId = null;
          this.noTaking = true;
          this.formMember = {};
          this.formMembers = {};
          this.forBody = {};
          this.formMember.birthday = "";
          this.formMemberInfo.live = "";
          this.formMemberInfo.intellect = "";
          this.formMemberInfo.character = "";
          this.formMemberInfo.language = "";
          this.formMemberInfo.hearing = "";
          this.formMemberInfo.vision = "";
          this.formMemberInfo.action = "";
          this.formMemberInfo.illness = "";
          this.formMember.county = "";
          this.formMember.city = "";
          this.historyList = [];
          this.imgIconBtns = false;
          this.imgIconBtnOne = true;
          this.imgJinJi = false;
          console.log(this.imgJinJi, "false1");
        } else {
          //查询用户信息
          apiShout.getMemberInfoByIds({ id: row.customerId }).then((dat) => {
            console.log("用户信息：", dat.data);
            if (dat.data.code === 200) {
              //对于生日的处理
              let a = dat.data.result.member.birthday;
              let dd = new Date(a);
              let y = dd.getFullYear();
              let m = dd.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = dd.getDate();
              d = d < 10 ? "0" + d : d;
              dat.data.result.member.birthday = y + "-" + m + "-" + d;
              //对于激活日期的处理
              let b = dat.data.result.member.createDate;
              let dd1 = new Date(b);
              let y1 = dd1.getFullYear();
              let m1 = dd1.getMonth() + 1;
              m1 = m1 < 10 ? "0" + m1 : m1;
              let d1 = dd1.getDate();
              d1 = d1 < 10 ? "0" + d1 : d1;
              dat.data.result.member.createDate = y1 + "-" + m1 + "-" + d1;
              //对于到期日期的处理
              let c = dat.data.result.member.updateDate;
              let dd2 = new Date(c);
              let y2 = dd2.getFullYear();
              let m2 = dd2.getMonth() + 1;
              m2 = m2 < 10 ? "0" + m2 : m2;
              let d2 = dd2.getDate();
              d2 = d2 < 10 ? "0" + d2 : d2;
              dat.data.result.member.updateDate = y2 + "-" + m2 + "-" + d2;
              this.formMember = dat.data.result.member;
              // this.formMember.localPhone = row.localPhone;
              console.log("1=1=1=1=1=1=1=1:", this.formMember.location);
              this.xinLog = this.formMember.lon;
              this.xinLat = this.formMember.lat;
              this.xinLoc = this.formMember.location;
              this.formMembers = dat.data.result.member;
              this.familyIds = dat.data.result.member.familyId;
              console.log(this.familyIds, "123123123");
              this.Sphone = dat.data.result.member.sparePhone;
              console.log(this.Sphone, "备用手机号查看");
              if (this.Sphone != null) {
                this.StandbyIcon = true;
                this.StandbyIcons = false;
              } else {
                this.StandbyIcon = false;
                this.StandbyIcons = true;
              }
              let str = this.formMember.sparePhone;
              if (str !== null) {
                this.formMember.NewsparePhone = str.replace(
                  /(\d{3})\d{4}(\d{1})/,
                  "$1****$2"
                );
              }
              // this.formMember.sparePhone =
              //   this.formMember.sparePhone.substr(0, 3) +
              //   "****" +
              //   this.formMember.sparePhone.substr(7);
              this.formMemberInfo = dat.data.result.memberInfo;
              if (dat.data.result.memberInfo.illness == null) {
                this.isZhan1 = false;
                this.isZhan = false;
                this.inputman = "";
              } else {
                this.isZhan1 = true;
              }
              this.cityName = dat.data.result.member.city;
              this.countyName = dat.data.result.member.county;
              this.cityId = dat.data.result.member.city;
              this.countyId = dat.data.result.member.county;
              this.emergencyOnes = dat.data.result.member.emergencyOne;
              this.emergencyTwos = dat.data.result.member.emergencyTwo;
              console.log(
                this.emergencyOnes,
                this.emergencyTwos,
                "查询紧急联系人的匹配值"
              );
              // console.log(CodeToText[this.countyName], "CodeToText123");
              // console.log(CodeToText[this.cityName], "CodeToText11111");
              this.formMember.city = CodeToText[this.cityName];
              this.formMember.county = CodeToText[this.countyName];
              if (this.infoFlag == true) {
                this.formMember.city = dat.data.result.member.city;
                this.formMember.county = dat.data.result.member.county;
              }
              this.formMember.birthday = dat.data.result.member.birthday;
              this.elderlyId = dat.data.result.member.id;
              // this.formMemberInfo.live = dat.data.result.memberInfo.live;
              this.formMemberInfo.intellect =
                dat.data.result.memberInfo.intellect;
              this.formMemberInfo.character =
                dat.data.result.memberInfo.character;
              this.formMemberInfo.language =
                dat.data.result.memberInfo.language;
              this.formMemberInfo.hearing = dat.data.result.memberInfo.hearing;
              this.formMemberInfo.vision = dat.data.result.memberInfo.vision;
              this.formMemberInfo.action = dat.data.result.memberInfo.action;
              this.formMemberInfo.illness = dat.data.result.memberInfo.illness;
              // //健康数据
              // this.forBody = dat.data.result.memberInfo;
              // for (let i = 0; i < dat.data.result.remind.length; i++) {
              //   this.morningbefor = dat.data.result.remind[0];
              //   this.morningafter = dat.data.result.remind[1];
              //   this.lunchbefor = dat.data.result.remind[2];
              //   this.lunchafter = dat.data.result.remind[3];
              //   this.eveningbefor = dat.data.result.remind[4];
              // }
            } else {
              this.$message.error(dat.data.msg);
            }
            this.imgIconBtns = false;
            this.imgIconBtnOne = true;
            this.imgJinJi = false;
            console.log(this.imgJinJi, "false2");
            console.log(this.familyIds, "家庭id");
            this.ursents = [];
            this.ursentsTwo = [];
            this.region = [];
            this.regionTong = [];
            this.regions = "";
            apiShout.getFamilyUsers({ fId: this.familyIds }).then((res) => {
              if (res.data.code === 200) {
                console.log(res, "紧急联系人");
                // this.ursents = res.data.result
                res.data.result.forEach((item) => {
                  this.ursents.push(item);
                  this.ursentsTwo.push(item);
                  this.region.push(item);
                  // console.log(this.region, "紧急联系人111111");
                });

                for (var i = 0; i < this.ursents.length; i++) {
                  this.userPhone = this.ursents[0];
                  this.userPhones = this.ursents[1];
                  // if (this.userPhone.id === this.emergencyOnes) {
                  // console.log(
                  //   this.userPhone.phone,
                  //   this.userPhone.userName,
                  //   "this.userPhone.phone"
                  // );
                  this.formMember.emergencyContactOnePhone =
                    this.userPhone.phone;
                  this.formMember.emergencyContactOne = this.userPhone.userName;
                  let str = this.formMember.emergencyContactOnePhone;
                  this.formMember.emergencyContactOnePhone = str.replace(
                    /(\d{3})\d{4}(\d{1})/,
                    "$1****$2"
                  );
                  // let arrss = [];
                  let arrText = {};
                  arrText.userName = this.userPhone.userName;
                  arrText.phone = this.userPhone.phone;
                  this.regionTong.push(arrText);
                  this.formMember.emergencyContactTwoPhone =
                    this.userPhones.phone;
                  this.formMember.emergencyContactTwo =
                    this.userPhones.userName;
                  let str1 = this.formMember.emergencyContactTwoPhone;
                  this.formMember.emergencyContactTwoPhone = str1.replace(
                    /(\d{3})\d{4}(\d{1})/,
                    "$1****$2"
                  );
                  // let arrss = [];
                  // let arrText = {};
                  // arrText.userName = this.userPhones.userName;
                  // arrText.phone = this.userPhones.phone;
                  // this.regionTong.push(arrText);
                  // this.region.push(arrText);
                  // }
                }
                // for (let i = 0; i < this.ursentsTwo.length; i++) {
                //   this.userPhones = this.ursentsTwo[i];
                //   if (this.userPhones.id === this.emergencyTwos) {
                //     console.log(
                //       this.userPhones.phone,
                //       this.userPhones.userName,
                //       "this.userPhones.userName"
                //     );
                //     this.formMember.emergencyContactTwoPhone =
                //       this.userPhones.phone;
                //     this.formMember.emergencyContactTwo =
                //       this.userPhones.userName;
                //     let str = this.formMember.emergencyContactTwoPhone;
                //     this.formMember.emergencyContactTwoPhone = str.replace(
                //       /(\d{3})\d{4}(\d{1})/,
                //       "$1****$2"
                //     );
                //     // let arrss = [];
                //     let arrText = {};
                //     arrText.userName = this.userPhones.userName;
                //     arrText.phone = this.userPhones.phone;
                //     this.regionTong.push(arrText);
                //     // this.region.push(arrText);
                //   }
                // }
              } else {
                this.$message.error(res.data.msg);
              }
            });
          });
          this.getLists();
          apiShout
            .geteqMem({
              equipmentId: row.equipmentId,
              memberId: row.customerId,
            })
            .then((ss) => {
              console.log("查询服务项：", ss.data.result);
              if (ss.data.code == 200) {
                if (ss.data.result != null) {
                  this.isSer = true;
                  this.serverType = ss.data.result;
                  if (this.serverType.basicStatus == 1) {
                    this.serverType.basicEnd = "未开通";
                  } else if (this.serverType.basicStatus == 3) {
                    this.serverType.basicEnd = "已过期";
                  }
                  if (this.serverType.careStatus == 1) {
                    this.serverType.careEnd = "未开通";
                  } else if (this.serverType.careStatus == 3) {
                    this.serverType.careEnd = "已过期";
                  }
                  if (this.serverType.ymStatus == 1) {
                    this.serverType.ymEnd = "未开通";
                  } else if (this.serverType.ymStatus == 3) {
                    this.serverType.ymEnd = "已过期";
                  }
                  if (this.serverType.contactStatus == 1) {
                    this.serverType.contactEnd = "未开通";
                  } else if (this.serverType.contactStatus == 3) {
                    this.serverType.contactEnd = "已过期";
                  } else if (this.serverType.contactStatus == 2) {
                    this.serverType.contactEnd = "长期有效";
                  }
                  if (this.serverType.healthStatus == 1) {
                    this.serverType.healthEnd = "未开通";
                  } else if (this.serverType.healthStatus == 3) {
                    this.serverType.healthEnd = "已过期";
                  }
                  if (this.serverType.lawStatus == 1) {
                    this.serverType.lawEnd = "未开通";
                  } else if (this.serverType.lawStatus == 3) {
                    this.serverType.lawEnd = "已过期";
                  }
                  if (this.serverType.cnoStatus == 1) {
                    this.serverType.cnoEnd = "未开通";
                  } else if (this.serverType.cnoStatus == 3) {
                    this.serverType.cnoEnd = "已过期";
                  }
                } else {
                  this.isSer = false;
                }
              }
            });
        }
      } else if (
        this.textPhone != null &&
        this.isListioning == false &&
        this.isListion == true
      ) {
        if (row.customerId != null || row.customerId != "") {
          this.isTabs = true;
          this.isTab = false;
        } else {
          this.isTabs = false;
          this.isTab = true;
        }
        this.userId = row.customerId;
        // this.equipmentIdStr = row.equipmentId;
        this.textarea = "";
        this.type = "";
        this.checkListData = [];
        this.checkListData1 = [];
        this.checkListData2 = [];
        this.checkListData3 = [];
        this.checkListData4 = [];
        this.zz7 = [];
        this.zz8 = [];
        this.zz5 = [];
        this.zz6 = [];
        this.zz3 = [];
        this.zz4 = [];
        this.zz = [];
        this.zz1 = [];
        this.zz2 = [];
        this.handleTime = "";
        this.valueOrdertime = "";
        this.valueOrdertimes = "";
        this.valueOrderputong = "";
        this.valueOrdertime1 = "";
        this.valueOrderputong1 = "";
        this.valueOrder = "";
        this.callback1 = 0;
        this.valueType = false;
        this.customerServiceId = "";
        this.isZhou = false;
        this.callback2 = 0;
        this.valueTypes = false;
        this.customerIds = row.customerId;
        this.equipmentId = row.equipmentId;
        this.UniqueIds = row.mainUniqueId;
        this.tel = row.localPhone;
        localStorage.setItem("UniqueIdn", JSON.stringify(row.mainUniqueId));
        localStorage.setItem("userIds", JSON.stringify(row.customerId));
        this.noTaking = true;
        this.getFamily();
        this.getsheList();
        this.getOld();

        if (this.isTabs == true) {
          this.$nextTick(() => {
            this.$refs.RefMemberIds.getMemberIds();
            this.$refs.RefgetList.getList();
            this.$refs.RadioBroadcasts.getOneTime();
            this.isYou = false;
            this.$refs.familyUsers.isXinZhen = true;
            this.$refs.equipmentUser.isXinZhen = false;
            this.$refs.RefMemberIds.isXinZhen = true;
            this.$refs.healthData.isXinZhen = true;
            this.$refs.RefgetList.isXinZhen = true;
          });
        } else {
          this.$nextTick(() => {
            this.$refs.RefMemberIdsOne.getMemberIds();
            this.$refs.RefgetListOne.getList();
            this.$refs.RadioBroadcastsOne.getOneTime();
            this.$refs.familyUsersOne.isXinZhen = false;
            this.$refs.equipmentUserOne.isXinZhen = true;
            this.$refs.RefMemberIdsOne.isXinZhen = false;
            this.$refs.healthDataOne.isXinZhen = true;
            this.$refs.RefgetListOne.isXinZhen = false;
          });
        }
        // this.$refs.RefMemberIds.getMemberIds();
        // this.$refs.RefgetList.getList();
        this.prohibit = event.cancelable;
        if (this.mobilePhone != null || this.mobilePhone != "") {
          this.imgIcon = true;
          this.imgIcons = false;
        } else {
          this.imgIcon = false;
          this.imgIcons = true;
        }
        if (row.equipmentId == null || row.customerId == null) {
          this.elderlyId = null;
          this.noTaking = true;
          this.formMember = {};
          this.formMembers = {};
          this.forBody = {};
          this.formMember.birthday = "";
          this.formMemberInfo.live = "";
          this.formMemberInfo.intellect = "";
          this.formMemberInfo.character = "";
          this.formMemberInfo.language = "";
          this.formMemberInfo.hearing = "";
          this.formMemberInfo.vision = "";
          this.formMemberInfo.action = "";
          this.formMemberInfo.illness = "";
          this.formMember.county = "";
          this.formMember.city = "";
          this.historyList = [];
          this.imgIconBtns = false;
          this.imgIconBtnOne = true;
          this.imgJinJi = false;
          console.log(this.imgJinJi, "false3");
        } else {
          //查询用户信息
          apiShout.getMemberInfoByIds({ id: row.customerId }).then((dat) => {
            console.log("用户信息：", dat.data);
            if (dat.data.code === 200) {
              //对于生日的处理
              let a = dat.data.result.member.birthday;
              let dd = new Date(a);
              let y = dd.getFullYear();
              let m = dd.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = dd.getDate();
              d = d < 10 ? "0" + d : d;
              dat.data.result.member.birthday = y + "-" + m + "-" + d;
              //对于激活日期的处理
              let b = dat.data.result.member.createDate;
              let dd1 = new Date(b);
              let y1 = dd1.getFullYear();
              let m1 = dd1.getMonth() + 1;
              m1 = m1 < 10 ? "0" + m1 : m1;
              let d1 = dd1.getDate();
              d1 = d1 < 10 ? "0" + d1 : d1;
              dat.data.result.member.createDate = y1 + "-" + m1 + "-" + d1;
              //对于到期日期的处理
              let c = dat.data.result.member.updateDate;
              let dd2 = new Date(c);
              let y2 = dd2.getFullYear();
              let m2 = dd2.getMonth() + 1;
              m2 = m2 < 10 ? "0" + m2 : m2;
              let d2 = dd2.getDate();
              d2 = d2 < 10 ? "0" + d2 : d2;
              dat.data.result.member.updateDate = y2 + "-" + m2 + "-" + d2;
              this.formMember = dat.data.result.member;
              this.xinLog = this.formMember.lon;
              this.xinLat = this.formMember.lat;
              this.xinLoc = this.formMember.location;
              this.formMembers = dat.data.result.member;
              this.familyIds = dat.data.result.member.familyId;
              console.log(this.familyIds, "123123123");
              this.Sphone = dat.data.result.member.sparePhone;
              console.log(this.Sphone, "备用手机号查看");
              if (this.Sphone != null) {
                this.StandbyIcon = true;
                this.StandbyIcons = false;
              } else {
                this.StandbyIcon = false;
                this.StandbyIcons = true;
              }
              let str = this.formMember.sparePhone;
              if (str !== null) {
                this.formMember.NewsparePhone = str.replace(
                  /(\d{3})\d{4}(\d{1})/,
                  "$1****$2"
                );
              }
              // this.formMember.sparePhone =
              //   this.formMember.sparePhone.substr(0, 3) +
              //   "****" +
              //   this.formMember.sparePhone.substr(7);
              this.formMemberInfo = dat.data.result.memberInfo;
              if (dat.data.result.memberInfo.illness == null) {
                this.isZhan1 = false;
                this.isZhan = false;
                this.inputman = "";
              } else {
                this.isZhan1 = true;
              }
              this.cityName = dat.data.result.member.city;
              this.countyName = dat.data.result.member.county;
              this.cityId = dat.data.result.member.city;
              this.countyId = dat.data.result.member.county;
              this.emergencyOnes = dat.data.result.member.emergencyOne;
              this.emergencyTwos = dat.data.result.member.emergencyTwo;
              console.log(
                this.emergencyOnes,
                this.emergencyTwos,
                "查询紧急联系人的匹配值"
              );
              // console.log(CodeToText[this.countyName], "CodeToText123");
              // console.log(CodeToText[this.cityName], "CodeToText11111");
              this.formMember.city = CodeToText[this.cityName];
              this.formMember.county = CodeToText[this.countyName];
              if (this.infoFlag == true) {
                this.formMember.city = dat.data.result.member.city;
                this.formMember.county = dat.data.result.member.county;
              }
              this.formMember.birthday = dat.data.result.member.birthday;
              this.elderlyId = dat.data.result.member.id;
              // this.formMemberInfo.live = dat.data.result.memberInfo.live;
              this.formMemberInfo.intellect =
                dat.data.result.memberInfo.intellect;
              this.formMemberInfo.character =
                dat.data.result.memberInfo.character;
              this.formMemberInfo.language =
                dat.data.result.memberInfo.language;
              this.formMemberInfo.hearing = dat.data.result.memberInfo.hearing;
              this.formMemberInfo.vision = dat.data.result.memberInfo.vision;
              this.formMemberInfo.action = dat.data.result.memberInfo.action;
              this.formMemberInfo.illness = dat.data.result.memberInfo.illness;
              // //健康数据
              // this.forBody = dat.data.result.memberInfo;
              // for (let i = 0; i < dat.data.result.remind.length; i++) {
              //   this.morningbefor = dat.data.result.remind[0];
              //   this.morningafter = dat.data.result.remind[1];
              //   this.lunchbefor = dat.data.result.remind[2];
              //   this.lunchafter = dat.data.result.remind[3];
              //   this.eveningbefor = dat.data.result.remind[4];
              // }
            } else {
              this.$message.error(dat.data.msg);
            }
            this.imgIconBtns = false;
            this.imgIconBtnOne = true;
            this.imgJinJi = false;
            console.log(this.imgJinJi, "false4");
            console.log(this.familyIds, "家庭id");
            this.ursents = [];
            this.ursentsTwo = [];
            this.region = [];
            this.regionTong = [];
            this.regions = "";
            apiShout.getFamilyUsers({ fId: this.familyIds }).then((res) => {
              if (res.data.code === 200) {
                console.log(res, "紧急联系人");
                // this.ursents = res.data.result
                res.data.result.forEach((item) => {
                  this.ursents.push(item);
                  this.ursentsTwo.push(item);
                  this.region.push(item);
                  // console.log(this.region, "紧急联系人111111");
                });

                for (var i = 0; i < this.ursents.length; i++) {
                  this.userPhone = this.ursents[0];
                  this.userPhones = this.ursents[1];
                  // if (this.userPhone.id === this.emergencyOnes) {
                  // console.log(
                  //   this.userPhone.phone,
                  //   this.userPhone.userName,
                  //   "this.userPhone.phone"
                  // );
                  this.formMember.emergencyContactOnePhone =
                    this.userPhone.phone;
                  this.formMember.emergencyContactOne = this.userPhone.userName;
                  let str = this.formMember.emergencyContactOnePhone;
                  this.formMember.emergencyContactOnePhone = str.replace(
                    /(\d{3})\d{4}(\d{1})/,
                    "$1****$2"
                  );
                  // let arrss = [];
                  let arrText = {};
                  arrText.userName = this.userPhone.userName;
                  arrText.phone = this.userPhone.phone;
                  this.regionTong.push(arrText);
                  this.formMember.emergencyContactTwoPhone =
                    this.userPhones.phone;
                  this.formMember.emergencyContactTwo =
                    this.userPhones.userName;
                  let str1 = this.formMember.emergencyContactTwoPhone;
                  this.formMember.emergencyContactTwoPhone = str1.replace(
                    /(\d{3})\d{4}(\d{1})/,
                    "$1****$2"
                  );
                  // let arrss = [];
                  // let arrText = {};
                  // arrText.userName = this.userPhones.userName;
                  // arrText.phone = this.userPhones.phone;
                  // this.regionTong.push(arrText);
                  // this.region.push(arrText);
                  // }
                }
                // for (let i = 0; i < this.ursentsTwo.length; i++) {
                //   this.userPhones = this.ursentsTwo[i];
                //   if (this.userPhones.id === this.emergencyTwos) {
                //     console.log(
                //       this.userPhones.phone,
                //       this.userPhones.userName,
                //       "this.userPhones.userName"
                //     );
                //     this.formMember.emergencyContactTwoPhone =
                //       this.userPhones.phone;
                //     this.formMember.emergencyContactTwo =
                //       this.userPhones.userName;
                //     let str = this.formMember.emergencyContactTwoPhone;
                //     this.formMember.emergencyContactTwoPhone = str.replace(
                //       /(\d{3})\d{4}(\d{1})/,
                //       "$1****$2"
                //     );
                //     // let arrss = [];
                //     let arrText = {};
                //     arrText.userName = this.userPhones.userName;
                //     arrText.phone = this.userPhones.phone;
                //     this.regionTong.push(arrText);
                //     // this.region.push(arrText);
                //   }
                // }
              } else {
                this.$message.error(res.data.msg);
              }
            });
          });
          this.getLists();
          apiShout
            .geteqMem({
              equipmentId: row.equipmentId,
              memberId: row.customerId,
            })
            .then((ss) => {
              console.log("查询服务项：", ss.data.result);
              if (ss.data.code == 200) {
                if (ss.data.result != null) {
                  this.isSer = true;
                  this.serverType = ss.data.result;
                  if (this.serverType.basicStatus == 1) {
                    this.serverType.basicEnd = "未开通";
                  } else if (this.serverType.basicStatus == 3) {
                    this.serverType.basicEnd = "已过期";
                  }
                  if (this.serverType.careStatus == 1) {
                    this.serverType.careEnd = "未开通";
                  } else if (this.serverType.careStatus == 3) {
                    this.serverType.careEnd = "已过期";
                  }
                  if (this.serverType.ymStatus == 1) {
                    this.serverType.ymEnd = "未开通";
                  } else if (this.serverType.ymStatus == 3) {
                    this.serverType.ymEnd = "已过期";
                  }
                  if (this.serverType.contactStatus == 1) {
                    this.serverType.contactEnd = "未开通";
                  } else if (this.serverType.contactStatus == 3) {
                    this.serverType.contactEnd = "已过期";
                  } else if (this.serverType.contactStatus == 2) {
                    this.serverType.contactEnd = "长期有效";
                  }
                  if (this.serverType.healthStatus == 1) {
                    this.serverType.healthEnd = "未开通";
                  } else if (this.serverType.healthStatus == 3) {
                    this.serverType.healthEnd = "已过期";
                  }
                  if (this.serverType.lawStatus == 1) {
                    this.serverType.lawEnd = "未开通";
                  } else if (this.serverType.lawStatus == 3) {
                    this.serverType.lawEnd = "已过期";
                  }
                  if (this.serverType.cnoStatus == 1) {
                    this.serverType.cnoEnd = "未开通";
                  } else if (this.serverType.cnoStatus == 3) {
                    this.serverType.cnoEnd = "已过期";
                  }
                } else {
                  this.isSer = false;
                }
              }
            });
        }
      } else if (this.textPhone != null && this.isListioning == true) {
        event.isTrusted = false;
        this.noTaking = false;
      }
      // event.isTrusted = this.eventList
      // console.log(event.isTrusted,"true123")
      // console.log("=====", row, column, event);
      // this.name = row.name;
      // this.phone = row.phone;
    },
    rowBtn(row, column, event) {
      this.activeName1 = "first";
      const audioElement = this.audioElement;
      if (audioElement && audioElement.length > 0) {
        for (let i = 0; i < audioElement.length; i++) {
          this.audioElement[i].pause();
        }
      }
      console.log(row, "rowrowrow");
      console.log(column, "columncolumn");
      console.log(event, "eventevent");
      this.isSave = false;
      localStorage.setItem("strequipmentIds", JSON.stringify(row.equipmentId));
      if (this.textPhone == null && this.isListioning == false) {
        if (row.customerId != null || row.customerId != "") {
          this.isTabs = true;
          this.isTab = false;
        } else {
          this.isTabs = false;
          this.isTab = true;
        }
        this.textarea = "";
        this.type = "";
        this.checkListData = [];
        this.checkListData1 = [];
        this.checkListData2 = [];
        this.checkListData3 = [];
        this.checkListData4 = [];
        this.zz7 = [];
        this.zz8 = [];
        this.zz5 = [];
        this.zz6 = [];
        this.zz3 = [];
        this.zz4 = [];
        this.zz = [];
        this.zz1 = [];
        this.zz2 = [];
        this.handleTime = "";
        this.valueOrdertime = "";
        this.valueOrdertimes = "";
        this.valueOrderputong = "";
        this.valueOrdertime1 = "";
        this.valueOrderputong1 = "";
        this.valueOrder = "";
        this.callback1 = 0;
        this.valueType = false;
        this.customerServiceId = "";
        this.isZhou = false;
        this.callback2 = 0;
        this.valueTypes = false;
        this.customerIds = row.customerId;
        this.mobilePhone = row.customerNumber;
        console.log(this.customerIds, "rowBtn点击出现的id");
        this.noTaking = true;
        this.userId = row.customerId;
        this.equipmentId = row.equipmentId;
        this.tel = row.customerNumber;
        localStorage.setItem("UniqueIdn", JSON.stringify(row.mainUniqueId));
        localStorage.setItem("userIds", JSON.stringify(row.customerId));
        this.getFamily();
        this.getsheList();
        this.getOld();
        if (this.isTabs == true) {
          this.$nextTick(() => {
            this.$refs.RefMemberIds.getMemberIds();
            this.$refs.RefgetList.getList();
            this.$refs.RadioBroadcasts.getOneTime();
            this.isYou = false;
            this.$refs.familyUsers.isXinZhen = true;
            this.$refs.equipmentUser.isXinZhen = false;
            this.$refs.RefMemberIds.isXinZhen = true;
            this.$refs.healthData.isXinZhen = true;
            this.$refs.RefgetList.isXinZhen = true;
          });
        } else {
          this.$nextTick(() => {
            this.$refs.RefMemberIdsOne.getMemberIds();
            this.$refs.RefgetListOne.getList();
            this.$refs.RadioBroadcastsOne.getOneTime();
            this.$refs.familyUsersOne.isXinZhen = false;
            this.$refs.equipmentUserOne.isXinZhen = true;
            this.$refs.RefMemberIdsOne.isXinZhen = false;
            this.$refs.healthDataOne.isXinZhen = true;
            this.$refs.RefgetListOne.isXinZhen = false;
          });
        }
        if (this.mobilePhone != null || this.mobilePhone != "") {
          this.imgIcon = true;
          this.imgIcons = false;
        } else {
          this.imgIcon = false;
          this.imgIcons = true;
        }
        this.prohibit = event.cancelable;
        if (row.customerNumber == null || row.customerId == null) {
          this.elderlyId = null;
          this.noTaking = true;
          this.userId = row.customerId;
          this.equipmentId = row.equipmentId;
          this.formMember = {};
          this.formMembers = {};
          this.formMemberInfo = {};
          this.forBody = {};
          this.formMember.birthday = "";
          this.formMemberInfo.live = "";
          this.formMemberInfo.intellect = "";
          this.formMemberInfo.character = "";
          this.formMemberInfo.language = "";
          this.formMemberInfo.hearing = "";
          this.formMemberInfo.vision = "";
          this.formMemberInfo.action = "";
          this.formMemberInfo.illness = "";
          this.formMember.county = "";
          this.formMember.city = "";
          this.historyList = [];
          this.imgIconBtns = false;
          this.imgIconBtnOne = true;
          this.imgJinJi = false;
          this.getsheList();
          this.isSer = false;
          this.serverType = {};
          console.log(this.imgJinJi, "false5");
        } else {
          apiShout.getMemberInfoByIds({ id: row.customerId }).then((res) => {
            if (res.data.code === 200) {
              console.log(res, "手机号查询用户信息");
              //对于生日的处理
              let a = res.data.result.member.birthday;
              let dd = new Date(a);
              let y = dd.getFullYear();
              let m = dd.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = dd.getDate();
              d = d < 10 ? "0" + d : d;
              res.data.result.member.birthday = y + "-" + m + "-" + d;
              this.formMember = res.data.result.member;
              this.xinLog = this.formMember.lon;
              this.xinLat = this.formMember.lat;
              this.xinLoc = this.formMember.location;
              this.Sphone = res.data.result.member.sparePhone;
              if (this.Sphone != null) {
                this.StandbyIcon = true;
                this.StandbyIcons = false;
              } else {
                this.StandbyIcon = false;
                this.StandbyIcons = true;
              }
              let str = this.formMember.sparePhone;
              if (str !== null) {
                this.formMember.NewsparePhone = str.replace(
                  /(\d{3})\d{4}(\d{1})/,
                  "$1****$2"
                );
              }

              // this.formMember.sparePhone =
              //   this.formMember.sparePhone.substr(0, 3) +
              //   "****" +
              //   this.formMember.sparePhone.substr(7);
              // this.formMember = row.memberInfo;
              this.familyIds = res.data.result.member.familyId;
              this.elderlyId = res.data.result.member.id;
              console.log(this.familyIds, "123123123");
              this.emergencyOnes = res.data.result.member.emergencyOne;
              this.emergencyTwos = res.data.result.member.emergencyTwo;
              // this.formMembers = res.data.result.memberInfo;
              this.formMembers = res.data.result.member;
              this.formMemberInfo = res.data.result.memberInfo;
              if (res.data.result.memberInfo.illness == null) {
                this.isZhan1 = false;
                this.isZhan = false;
                this.inputman = "";
              } else {
                this.isZhan1 = true;
              }
              this.formMember.birthday = res.data.result.member.birthday;
              this.forBody = res.data.result.memberInfo;
              this.cityId = res.data.result.member.city;
              this.countyId = res.data.result.member.county;
              this.cityName = res.data.result.member.city;
              this.countyName = res.data.result.member.county; //TextToCode
              this.formMember.city = CodeToText[this.cityName];
              this.formMember.county = CodeToText[this.countyName];
              this.imgIconBtns = false;
              this.imgIconBtnOne = true;
              this.imgJinJi = false;
              console.log(this.imgJinJi, "false6");
              console.log(this.familyIds, "家庭id");
              this.ursents = [];
              this.ursentsTwo = [];
              this.region = [];
              this.regionTong = [];
              this.regions = "";
              apiShout.getFamilyUsers({ fId: this.familyIds }).then((res) => {
                if (res.data.code === 200) {
                  console.log(res, "紧急联系人");
                  // this.ursents = res.data.result
                  res.data.result.forEach((item) => {
                    this.ursents.push(item);
                    this.ursentsTwo.push(item);
                    this.region.push(item);
                    // console.log(this.region, "紧急联系人111111");
                  });

                  for (var i = 0; i < this.ursents.length; i++) {
                    this.userPhone = this.ursents[0];
                    this.userPhones = this.ursents[1];
                    // if (this.userPhone.id === this.emergencyOnes) {
                    // console.log(
                    //   this.userPhone.phone,
                    //   this.userPhone.userName,
                    //   "this.userPhone.phone"
                    // );
                    this.formMember.emergencyContactOnePhone =
                      this.userPhone.phone;
                    this.formMember.emergencyContactOne =
                      this.userPhone.userName;
                    let str = this.formMember.emergencyContactOnePhone;
                    this.formMember.emergencyContactOnePhone = str.replace(
                      /(\d{3})\d{4}(\d{1})/,
                      "$1****$2"
                    );
                    // let arrss = [];
                    let arrText = {};
                    arrText.userName = this.userPhone.userName;
                    arrText.phone = this.userPhone.phone;
                    this.regionTong.push(arrText);
                    this.formMember.emergencyContactTwoPhone =
                      this.userPhones.phone;
                    this.formMember.emergencyContactTwo =
                      this.userPhones.userName;
                    let str1 = this.formMember.emergencyContactTwoPhone;
                    this.formMember.emergencyContactTwoPhone = str1.replace(
                      /(\d{3})\d{4}(\d{1})/,
                      "$1****$2"
                    );
                    // let arrss = [];
                    // let arrText = {};
                    // arrText.userName = this.userPhones.userName;
                    // arrText.phone = this.userPhones.phone;
                    // this.regionTong.push(arrText);
                    // this.region.push(arrText);
                    // }
                  }
                  // for (let i = 0; i < this.ursentsTwo.length; i++) {
                  //   this.userPhones = this.ursentsTwo[i];
                  //   if (this.userPhones.id === this.emergencyTwos) {
                  //     console.log(
                  //       this.userPhones.phone,
                  //       this.userPhones.userName,
                  //       "this.userPhones.userName"
                  //     );
                  //     this.formMember.emergencyContactTwoPhone =
                  //       this.userPhones.phone;
                  //     this.formMember.emergencyContactTwo =
                  //       this.userPhones.userName;
                  //     let str = this.formMember.emergencyContactTwoPhone;
                  //     this.formMember.emergencyContactTwoPhone = str.replace(
                  //       /(\d{3})\d{4}(\d{1})/,
                  //       "$1****$2"
                  //     );
                  //     // let arrss = [];
                  //     let arrText = {};
                  //     arrText.userName = this.userPhones.userName;
                  //     arrText.phone = this.userPhones.phone;
                  //     this.regionTong.push(arrText);
                  //     // this.region.push(arrText);
                  //   }
                  // }
                } else {
                  this.$message.error(res.data.msg);
                }
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
          this.getLists();
          apiShout
            .geteqMem({
              equipmentId: row.equipmentId,
              memberId: row.customerId,
            })
            .then((ss) => {
              console.log("查询服务项：", ss.data.result);
              if (ss.data.code == 200) {
                if (ss.data.result != null) {
                  this.isSer = true;
                  this.serverType = ss.data.result;
                  if (this.serverType.basicStatus == 1) {
                    this.serverType.basicEnd = "未开通";
                  } else if (this.serverType.basicStatus == 3) {
                    this.serverType.basicEnd = "已过期";
                  }
                  if (this.serverType.careStatus == 1) {
                    this.serverType.careEnd = "未开通";
                  } else if (this.serverType.careStatus == 3) {
                    this.serverType.careEnd = "已过期";
                  }
                  if (this.serverType.ymStatus == 1) {
                    this.serverType.ymEnd = "未开通";
                  } else if (this.serverType.ymStatus == 3) {
                    this.serverType.ymEnd = "已过期";
                  }
                  if (this.serverType.contactStatus == 1) {
                    this.serverType.contactEnd = "未开通";
                  } else if (this.serverType.contactStatus == 3) {
                    this.serverType.contactEnd = "已过期";
                  } else if (this.serverType.contactStatus == 2) {
                    this.serverType.contactEnd = "长期有效";
                  }
                  if (this.serverType.healthStatus == 1) {
                    this.serverType.healthEnd = "未开通";
                  } else if (this.serverType.healthStatus == 3) {
                    this.serverType.healthEnd = "已过期";
                  }
                  if (this.serverType.lawStatus == 1) {
                    this.serverType.lawEnd = "未开通";
                  } else if (this.serverType.lawStatus == 3) {
                    this.serverType.lawEnd = "已过期";
                  }
                  if (this.serverType.cnoStatus == 1) {
                    this.serverType.cnoEnd = "未开通";
                  } else if (this.serverType.cnoStatus == 3) {
                    this.serverType.cnoEnd = "已过期";
                  }
                } else {
                  this.isSer = false;
                }
              }
            });
        }
      } else if (
        this.textPhone != null &&
        this.isListioning == false &&
        this.isListion == true
      ) {
        if (row.customerId != null || row.customerId != "") {
          this.isTabs = true;
          this.isTab = false;
        } else {
          this.isTabs = false;
          this.isTab = true;
        }
        this.customerIds = row.customerId;
        this.mobilePhone = row.customerNumber;
        console.log(this.customerIds, "rowBtn点击出现的id");
        this.userId = row.customerId;
        this.textarea = "";
        this.type = "";
        this.checkListData = [];
        this.checkListData1 = [];
        this.checkListData2 = [];
        this.checkListData3 = [];
        this.checkListData4 = [];
        this.zz7 = [];
        this.zz8 = [];
        this.zz5 = [];
        this.zz6 = [];
        this.zz3 = [];
        this.zz4 = [];
        this.zz = [];
        this.zz1 = [];
        this.zz2 = [];
        this.handleTime = "";
        this.valueOrdertime = "";
        this.valueOrdertimes = "";
        this.valueOrderputong = "";
        this.valueOrdertime1 = "";
        this.valueOrderputong1 = "";
        this.valueOrder = "";
        this.callback1 = 0;
        this.valueType = false;
        this.customerServiceId = "";
        this.isZhou = false;
        this.callback2 = 0;
        this.valueTypes = false;
        this.equipmentId = row.equipmentId;
        this.tel = row.customerNumber;
        localStorage.setItem("UniqueIdn", JSON.stringify(row.mainUniqueId));
        localStorage.setItem("userIds", JSON.stringify(row.customerId));
        this.noTaking = true;
        if (this.isTabs == true) {
          this.$nextTick(() => {
            this.$refs.RefMemberIds.getMemberIds();
            this.$refs.RefgetList.getList();
            this.$refs.RadioBroadcasts.getOneTime();
            this.isYou = false;
            this.$refs.familyUsers.isXinZhen = true;
            this.$refs.equipmentUser.isXinZhen = false;
            this.$refs.RefMemberIds.isXinZhen = true;
            this.$refs.healthData.isXinZhen = true;
            this.$refs.RefgetList.isXinZhen = true;
          });
        } else {
          this.$nextTick(() => {
            this.$refs.RefMemberIdsOne.getMemberIds();
            this.$refs.RefgetListOne.getList();
            this.$refs.RadioBroadcastsOne.getOneTime();
            this.$refs.familyUsersOne.isXinZhen = false;
            this.$refs.equipmentUserOne.isXinZhen = true;
            this.$refs.RefMemberIdsOne.isXinZhen = false;
            this.$refs.healthDataOne.isXinZhen = true;
            this.$refs.RefgetListOne.isXinZhen = false;
          });
        }
        // if (this.isYou == true) {
        //   this.$refs.RefMemberIdsOne.getMemberIds();
        //   this.$refs.RefgetListOne.getList();
        // } else {
        //   this.$refs.RefMemberIds.getMemberIds();
        //   this.$refs.RefgetList.getList();
        // }
        // this.$refs.RefMemberIds.getMemberIds();
        // this.$refs.RefgetList.getList();
        this.getFamily();
        this.getsheList();
        this.getOld();
        this.tongQian = true;
        if (this.mobilePhone != null || this.mobilePhone != "") {
          this.imgIcon = true;
          this.imgIcons = false;
        } else {
          this.imgIcon = false;
          this.imgIcons = true;
        }
        this.prohibit = event.cancelable;

        if (row.customerNumber == null || row.customerId == null) {
          this.elderlyId = null;
          this.formMember = {};
          this.formMembers = {};
          this.formMemberInfo = {};
          this.forBody = {};
          this.formMember.birthday = "";
          this.formMemberInfo.live = "";
          this.formMemberInfo.intellect = "";
          this.formMemberInfo.character = "";
          this.formMemberInfo.language = "";
          this.formMemberInfo.hearing = "";
          this.formMemberInfo.vision = "";
          this.formMemberInfo.action = "";
          this.formMemberInfo.illness = "";
          this.formMember.county = "";
          this.formMember.city = "";
          this.historyList = [];
          this.imgIconBtns = false;
          this.imgIconBtnOne = true;
          this.imgJinJi = false;
          this.getsheList();
          this.isSer = false;
          this.serverType = {};
          console.log(this.imgJinJi, "false7");
        } else {
          apiShout.getMemberInfoByIds({ id: row.customerId }).then((res) => {
            if (res.data.code === 200) {
              console.log(res, "手机号查询用户信息");
              //对于生日的处理
              let a = res.data.result.member.birthday;
              let dd = new Date(a);
              let y = dd.getFullYear();
              let m = dd.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = dd.getDate();
              d = d < 10 ? "0" + d : d;
              res.data.result.member.birthday = y + "-" + m + "-" + d;
              this.formMember = res.data.result.member;
              this.xinLog = this.formMember.lon;
              this.xinLat = this.formMember.lat;
              this.xinLoc = this.formMember.location;
              // this.formMember = row.memberInfo;
              // this.formMembers = res.data.result.memberInfo;
              this.formMembers = res.data.result.member;
              this.formMemberInfo = res.data.result.memberInfo;
              if (res.data.result.memberInfo.illness == null) {
                this.isZhan1 = false;
                this.isZhan = false;
                this.inputman = "";
              } else {
                this.isZhan1 = true;
              }
              this.Sphone = res.data.result.member.sparePhone;
              if (this.Sphone != null) {
                this.StandbyIcon = true;
                this.StandbyIcons = false;
              } else {
                this.StandbyIcon = false;
                this.StandbyIcons = true;
              }
              let str = this.formMember.sparePhone;
              this.formMember.NewsparePhone = str.replace(
                /(\d{3})\d{4}(\d{1})/,
                "$1****$2"
              );
              // this.formMember.sparePhone =
              //   this.formMember.sparePhone.substr(0, 3) +
              //   "****" +
              //   this.formMember.sparePhone.substr(7);
              this.familyIds = res.data.result.member.familyId;
              console.log(this.familyIds, "123123123");
              this.emergencyOnes = res.data.result.member.emergencyOne;
              this.emergencyTwos = res.data.result.member.emergencyTwo;
              this.formMember.birthday = res.data.result.member.birthday;
              this.elderlyId = res.data.result.member.id;
              this.cityId = res.data.result.member.city;
              this.countyId = res.data.result.member.county;
              this.forBody = res.data.result.memberInfo;
              this.cityName = res.data.result.member.city;
              this.countyName = res.data.result.member.county; //TextToCode
              this.formMember.city = CodeToText[this.cityName];
              this.formMember.county = CodeToText[this.countyName];
              this.imgIconBtns = false;
              this.imgIconBtnOne = true;
              this.imgJinJi = false;
              console.log(this.imgJinJi, "false8");
              console.log(this.familyIds, "家庭id");
              this.ursents = [];
              this.ursentsTwo = [];
              this.region = [];
              this.regionTong = [];
              this.regions = "";
              apiShout.getFamilyUsers({ fId: this.familyIds }).then((res) => {
                if (res.data.code === 200) {
                  console.log(res, "紧急联系人");
                  // this.ursents = res.data.result
                  res.data.result.forEach((item) => {
                    this.ursents.push(item);
                    this.ursentsTwo.push(item);
                    this.region.push(item);
                    // console.log(this.region, "紧急联系人111111");
                  });

                  for (var i = 0; i < this.ursents.length; i++) {
                    this.userPhone = this.ursents[0];
                    this.userPhones = this.ursents[1];
                    // if (this.userPhone.id === this.emergencyOnes) {
                    // console.log(
                    //   this.userPhone.phone,
                    //   this.userPhone.userName,
                    //   "this.userPhone.phone"
                    // );
                    this.formMember.emergencyContactOnePhone =
                      this.userPhone.phone;
                    this.formMember.emergencyContactOne =
                      this.userPhone.userName;
                    let str = this.formMember.emergencyContactOnePhone;
                    this.formMember.emergencyContactOnePhone = str.replace(
                      /(\d{3})\d{4}(\d{1})/,
                      "$1****$2"
                    );
                    // let arrss = [];
                    let arrText = {};
                    arrText.userName = this.userPhone.userName;
                    arrText.phone = this.userPhone.phone;
                    this.regionTong.push(arrText);
                    this.formMember.emergencyContactTwoPhone =
                      this.userPhones.phone;
                    this.formMember.emergencyContactTwo =
                      this.userPhones.userName;
                    let str1 = this.formMember.emergencyContactTwoPhone;
                    this.formMember.emergencyContactTwoPhone = str1.replace(
                      /(\d{3})\d{4}(\d{1})/,
                      "$1****$2"
                    );
                    // let arrss = [];
                    // let arrText = {};
                    // arrText.userName = this.userPhones.userName;
                    // arrText.phone = this.userPhones.phone;
                    // this.regionTong.push(arrText);
                    // this.region.push(arrText);
                    // }
                  }
                  // for (let i = 0; i < this.ursentsTwo.length; i++) {
                  //   this.userPhones = this.ursentsTwo[i];
                  //   if (this.userPhones.id === this.emergencyTwos) {
                  //     console.log(
                  //       this.userPhones.phone,
                  //       this.userPhones.userName,
                  //       "this.userPhones.userName"
                  //     );
                  //     this.formMember.emergencyContactTwoPhone =
                  //       this.userPhones.phone;
                  //     this.formMember.emergencyContactTwo =
                  //       this.userPhones.userName;
                  //     let str = this.formMember.emergencyContactTwoPhone;
                  //     this.formMember.emergencyContactTwoPhone = str.replace(
                  //       /(\d{3})\d{4}(\d{1})/,
                  //       "$1****$2"
                  //     );
                  //     // let arrss = [];
                  //     let arrText = {};
                  //     arrText.userName = this.userPhones.userName;
                  //     arrText.phone = this.userPhones.phone;
                  //     this.regionTong.push(arrText);
                  //     // this.region.push(arrText);
                  //   }
                  // }
                } else {
                  this.$message.error(res.data.msg);
                }
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
          this.getLists();
          apiShout
            .geteqMem({
              equipmentId: row.equipmentId,
              memberId: row.customerId,
            })
            .then((ss) => {
              console.log("查询服务项：", ss.data.result);
              if (ss.data.code == 200) {
                if (ss.data.result != null) {
                  this.isSer = true;
                  this.serverType = ss.data.result;
                  if (this.serverType.basicStatus == 1) {
                    this.serverType.basicEnd = "未开通";
                  } else if (this.serverType.basicStatus == 3) {
                    this.serverType.basicEnd = "已过期";
                  }
                  if (this.serverType.careStatus == 1) {
                    this.serverType.careEnd = "未开通";
                  } else if (this.serverType.careStatus == 3) {
                    this.serverType.careEnd = "已过期";
                  }
                  if (this.serverType.ymStatus == 1) {
                    this.serverType.ymEnd = "未开通";
                  } else if (this.serverType.ymStatus == 3) {
                    this.serverType.ymEnd = "已过期";
                  }
                  if (this.serverType.contactStatus == 1) {
                    this.serverType.contactEnd = "未开通";
                  } else if (this.serverType.contactStatus == 3) {
                    this.serverType.contactEnd = "已过期";
                  } else if (this.serverType.contactStatus == 2) {
                    this.serverType.contactEnd = "长期有效";
                  }
                  if (this.serverType.healthStatus == 1) {
                    this.serverType.healthEnd = "未开通";
                  } else if (this.serverType.healthStatus == 3) {
                    this.serverType.healthEnd = "已过期";
                  }
                  if (this.serverType.lawStatus == 1) {
                    this.serverType.lawEnd = "未开通";
                  } else if (this.serverType.lawStatus == 3) {
                    this.serverType.lawEnd = "已过期";
                  }
                  if (this.serverType.cnoStatus == 1) {
                    this.serverType.cnoEnd = "未开通";
                  } else if (this.serverType.cnoStatus == 3) {
                    this.serverType.cnoEnd = "已过期";
                  }
                } else {
                  this.isSer = false;
                }
              }
            });
        }
      } else if (this.textPhone != null && this.isListioning == true) {
        event.isTrusted = false;
        this.noTaking = false;
      }
    },
    //回拨事件
    callBackMessage() {
      console.log("回拨上边的：", this.customerIds);
      console.log(this.cnoStatus, "查看状态");
      if (this.cnoStatus == "0" || this.cnoStatus == 0) {
        this.$message.error("请置忙后点击！");
        return false;
      } else {
        var objmeg = JSON.stringify({
          cno: this.cno,
          msgcode: 210,
          token: this.loginToken,
          phone: this.mobilePhone || "",
          userid: this.elderlyId || null,
          equipmentid: this.equipmentId,
          calltype: "2",
        });
        this.websocketsend(objmeg);
        this.calltypes = "2";
        this.endStatus = "0";
        //页面跳转
        this.isListion = false;
        this.isListioning = true;
        this.zhuanjieimgs = false;
        this.zhuanjieimg = true;
        this.yiliaoPimgs = false;
        this.yiliaoPimg = true;
        this.jijiuPimgs = false;
        this.jijiuPimg = true;
        this.jinJiPhoneimgs = false;
        this.jinJiPhoneimg = true;
        this.offImgIcons = true;
        this.setOffTimer = setTimeout(() => {
          this.offImgIcon = true;
          this.offImgIcons = false;
        }, 3000);
        this.muteCount = 0;
        this.isYIN = true;
        this.isJIN = false;
        //清空计时器
        this.currentTime = 0;
        //铃不动
        clearInterval(this.timer);
        //记时结束
        clearInterval(this.timer1);
        //记时通话时间
        this.testTimer1();
        //客服状态置忙
        this.valuezhuangtai = "忙碌";
        this.cnoStatus = 1;
        //改变客服状态下的计时器
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();
        let hh = this.mobilePhone;
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.tel = hh;
          params.obClid = "01086484968";
          ClinkAgent.previewOutcall(params);
        });
        this.huiPhone = this.mobilePhone;
        this.huiPhone =
          this.huiPhone.substr(0, 3) + "****" + this.huiPhone.substr(7);
      }
      console.log("回拨完出现的:", this.customerIds);
    },
    //紧急联系人
    imgIconBtn() {
      this.regions = "";
      if (this.customerIds == null || this.customerIds == "") {
        this.imgIconBtnOne = true;
        this.imgIconBtns = false;
      } else {
        this.imgIconBtnOne = true;
        this.imgIconBtns = true;
      }
    },
    //紧急事件
    regionsOne(val) {
      console.log(this.cnoStatus, "查看状态");
      if (this.cnoStatus == "0" || this.cnoStatus == 0) {
        this.$message.error("请置忙后点击！");
        this.imgIconBtnOne = true;
        this.imgIconBtns = false;
        return false;
      } else {
        var objmegTwo = JSON.stringify({
          cno: this.cno,
          msgcode: 210,
          token: this.loginToken,
          phone: val || "",
          userid: this.elderlyId || null,
          equipmentid: null,
          calltype: "4",
        });
        this.websocketsend(objmegTwo);
        this.calltypes = "4";
        this.endStatus = "0";
        if (val != null) {
          //页面跳转
          this.isListion = false;
          this.isListioning = true;
          this.zhuanjieimgs = false;
          this.zhuanjieimg = true;
          this.yiliaoPimgs = false;
          this.yiliaoPimg = true;
          this.jijiuPimgs = false;
          this.jijiuPimg = true;
          this.jinJiPhoneimgs = false;
          this.jinJiPhoneimg = true;
          this.offImgIcons = true;
          this.setOffTimer = setTimeout(() => {
            this.offImgIcon = true;
            this.offImgIcons = false;
          }, 3000);
          //清空计时器
          this.currentTime = 0;
          //铃不动
          clearInterval(this.timer);
          //记时结束
          clearInterval(this.timer1);
          //记时通话时间
          this.testTimer1();
          //客服状态置忙
          this.valuezhuangtai = "忙碌";
          this.cnoStatus = 1;
          //改变客服状态下的计时器
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          this.imgIconBtnOne = true;
          this.imgIconBtns = false;
          let hh = val;
          apiShout.getparTitle({ title: "外呼外显" }).then((res) => {
            // console.log('转接：',res.data);
            if (res.data.code == 200) {
              // this.inputPhs = res.data.result;
              ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
                // 传入参数对象
                var params = {};
                params.tel = hh;
                params.obClid = res.data.result;
                // params.obClid = "02138277595";
                // params.obClid = "01086484968";
                ClinkAgent.previewOutcall(params);
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
          // ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          //   // 传入参数对象
          //   var params = {};
          //   params.tel = hh;
          //   params.obClid = "02138277595";
          //   // params.obClid = "01086484968";
          //   ClinkAgent.previewOutcall(params);
          // });
          this.huiPhone = val;
          this.huiPhone =
            this.huiPhone.substr(0, 3) + "****" + this.huiPhone.substr(7);
        } else {
          this.imgIconBtns = true;
          this.imgIconBtnOne = true;
        }
        console.log(val, "123");
      }
    },
    //备用事件
    callBackStandby() {
      console.log(this.cnoStatus, "查看状态");
      if (this.cnoStatus == "0" || this.cnoStatus == 0) {
        this.$message.error("请置忙后点击！");
        return false;
      } else {
        var objmegTwo = JSON.stringify({
          cno: this.cno,
          msgcode: 210,
          token: this.loginToken,
          phone: this.Sphone || "",
          userid: this.elderlyId || null,
          equipmentid: null,
          calltype: "3",
        });
        this.websocketsend(objmegTwo);
        this.calltypes = "3";
        this.endStatus = "0";
        //页面跳转
        this.isListion = false;
        this.isListioning = true;
        this.zhuanjieimgs = false;
        this.zhuanjieimg = true;
        this.yiliaoPimgs = false;
        this.yiliaoPimg = true;
        this.jijiuPimgs = false;
        this.jijiuPimg = true;
        this.jinJiPhoneimgs = false;
        this.jinJiPhoneimg = true;
        this.offImgIcons = true;
        this.setOffTimer = setTimeout(() => {
          this.offImgIcon = true;
          this.offImgIcons = false;
        }, 3000);
        //清空计时器
        this.currentTime = 0;
        //铃不动
        clearInterval(this.timer);
        //记时结束
        clearInterval(this.timer1);
        //记时通话时间
        this.testTimer1();
        //客服状态置忙
        this.valuezhuangtai = "忙碌";
        this.cnoStatus = 1;
        //改变客服状态下的计时器
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();
        let hh = this.Sphone;
        apiShout.getparTitle({ title: "外呼外显" }).then((res) => {
          // console.log('转接：',res.data);
          if (res.data.code == 200) {
            // this.inputPhs = res.data.result;
            ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
              // 传入参数对象
              var params = {};
              params.tel = hh;
              params.obClid = res.data.result;
              // params.obClid = "02138277595";
              // params.obClid = "01086484968";
              ClinkAgent.previewOutcall(params);
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
        // ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
        //       // 传入参数对象
        //       var params = {};
        //       params.tel = hh;
        //       params.obClid = "02138277595";
        //       // params.obClid = "01086484968";
        //       ClinkAgent.previewOutcall(params);
        //     });
        this.huiPhone = this.Sphone;
        this.huiPhone =
          this.huiPhone.substr(0, 3) + "****" + this.huiPhone.substr(7);
      }
    },
    //是否需要回电
    callChange(val) {
      this.historyOrdertime.forEach((item) => {
        if (item.value === "6") {
          return (this.valueOrdertime = item.value);
        }
      });
      this.historyOrderputong.forEach((item) => {
        if (item.value === "3") {
          return (this.valueOrderputong = item.value);
        }
      });
      this.callback1 = val;
      if (this.callback1 == "0") {
        this.valueType = false;
      } else if (this.callback1 == "1") {
        this.valueType = true;
      }
    },
    tableRowClassName({ row }) {
      console.log(row, "row,row");
      if (row.id === this.serviceId) {
        return "warning-row";
      }
    },
    tableRowClassName1({ row }) {
      if (row.id === this.gaipei) {
        return "warning-row";
      }
    },
    // callChange(val) {
    //   console.log("是否需要回电:", val);
    //   this.callback1 = val;
    // },
    //关联人员弹框
    cellClick(row) {
      console.log("===========", row);
      this.serviceId = row.id;
      if (row.id === this.serviceId) {
        this.usersName = row.name;
        this.dialogVisible = false;
        return "warning-row";
      }
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    //改派人员弹框
    cellClick1(row) {
      //console.log('===========',row);
      this.gaipei = row.id;
      if (row.id === this.gaipei) {
        return "warning-row";
      }
    },
    //取消
    cancel() {
      this.currentRow = null;
      this.dialogVisible1 = false;
    },
    // handleCurrentChange(val) {
    //   this.currentRow = val;
    // },
    //创建工单的提交
    finish() {
      console.log("再次出现：", this.customerIds, this.equipmentId);
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      this.UniqueIds = UniqueId;
      let obj = {
        userId: this.customerServiceId || this.serviceId,
        describe: this.textarea,
        type: this.type,
        typeDetail: this.checkListData,
        handleTime: this.valueOrdertime,
        priority: this.valueOrderputong,
        mainUniqueId: this.UniqueIds,
        customerId: this.customerIds,
        centerType: 1,
        callback: this.callback1,
        status: "0",
        equipmentId: this.equipmentId,
        callPhone: this.tel,
        calltype: this.calltypes,
        serviceCalltype: this.calltypes,
      };
      console.log(obj, "输出equipmentId查询");
      if (obj.equipmentId == "" || obj.equipmentId == null) {
        this.$message.error("用户设备id不能为空");
        return;
      }
      if (obj.customerId == "" || obj.customerId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (this.valueOrder == "" || this.valueOrder == null) {
        this.$message.error("请选择创建工单。");
        return;
      }
      if (obj.describe == "" || obj.describe == null) {
        this.$message.error("工单描述内容不能为空！");
        return;
      }
      if (this.isZhou == true) {
        this.$message.error("请绑定后再创建工单！");
        return;
      }
      //创建工单
      apiShout.getCreateOrder(obj).then((aa) => {
        console.log("创建工单：", aa.data);
        if (aa.data.code === 200) {
          //页面刷新 全部待办  待我办理 历史工单
          this.backlog();
          this.waitFinsh(this.serviceId);
          // this.historyOrders();
          this.getLists();
          // let namePhone = JSON.parse(localStorage.getItem("userId"));
          // this.customerIds = namePhone;
          //清空选中的选项
          this.textarea = "";
          this.type = "";
          this.checkListData = [];
          this.zz = [];
          this.handleTime = "";
          this.valueOrdertime = "";
          this.valueOrderputong = "";
          this.valueOrder = "";
          this.callback1 = 0;
          this.valueType = false;
          this.customerServiceId = "";
          this.isZhou = false;
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          if (this.isTabs == true) {
            this.$nextTick(() => {
              this.$refs.familyUsers.isXinZhen = true;
              this.$refs.equipmentUser.isXinZhen = false;
              this.$refs.RefMemberIds.isXinZhen = true;
              this.$refs.healthData.isXinZhen = true;
              this.$refs.RefgetList.isXinZhen = true;
            });
          } else {
            this.$nextTick(() => {
              this.$refs.familyUsersOne.isXinZhen = false;
              this.$refs.equipmentUserOne.isXinZhen = false;
              this.$refs.RefMemberIdsOne.isXinZhen = false;
              this.$refs.healthDataOne.isXinZhen = true;
              this.$refs.RefgetListOne.isXinZhen = false;

              // this.$refs.familyUsers.isXinZhen = false;
              // this.$refs.equipmentUser.isXinZhen = true;
              // this.$refs.RefMemberIds.isXinZhen = false;
              // this.$refs.healthData.isXinZhen = true;
              // this.$refs.RefgetList.isXinZhen = false;
            });
          }
        } else {
          this.$message.error(aa.data.msg);
        }
      });
    },
    finish1() {
      let typeList = [];
      typeList = this.checkListData1.concat(this.checkListData1One);
      console.log("pingjie:", typeList);
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      this.UniqueIds = UniqueId;
      let obj = {
        userId: this.serviceId,
        describe: this.textarea,
        type: this.type,
        typeDetail: typeList,
        handleTime: this.handleTime,
        mainUniqueId: this.UniqueIds,
        customerId: this.customerIds,
        centerType: 1,
        status: "0",
        equipmentId: this.equipmentId,
        callPhone: this.tel,
        calltype: this.calltypes,
        serviceCalltype: this.calltypes,
      };
      if (obj.equipmentId == "" || obj.equipmentId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (obj.customerId == "" || obj.customerId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (this.valueOrder == "" || this.valueOrder == null) {
        this.$message.error("请选择创建工单。");
        return;
      }
      if (obj.describe == "" || obj.describe == null) {
        this.$message.error("工单描述内容不能为空！");
        return;
      }
      if (this.isZhou == true) {
        this.$message.error("请绑定后再创建！");
        return;
      }
      // console.log(this.mainUniqueId, "创建工单2");
      if (obj == "") {
        this.$message({
          type: "success",
          message: "提交失败!请完善工单信息",
        });
      } else {
        //创建工单
        apiShout.getCreateOrder(obj).then((aa) => {
          console.log("创建工单1：", aa.data);
          if (aa.data.code === 200) {
            this.$message({
              type: "success",
              message: "提交成功!",
            });
            //页面刷新 全部待办  待我办理
            this.backlog();
            this.waitFinsh(this.serviceId);
            // this.historyOrders();
            this.getLists();
            // let namePhone = JSON.parse(localStorage.getItem("userId"));
            // this.customerIds = namePhone;
            // //存储到vuex中
            // this.setMes(this.type);
            //清空选中的选项
            this.textarea = "";
            // console.log('-----------------------',this.textarea);
            this.type = "";
            this.handleTime = "";
            this.valueOrdertimes = "";
            this.valueOrder = "";
            this.zz1 = [];
            this.zz2 = [];
            this.checkListData1 = [];
            this.isZhou = false;
            // this.callback1 = "0";
            if (this.isTabs == true) {
              this.$nextTick(() => {
                this.$refs.familyUsers.isXinZhen = true;
                this.$refs.equipmentUser.isXinZhen = false;
                this.$refs.RefMemberIds.isXinZhen = true;
                this.$refs.healthData.isXinZhen = true;
                this.$refs.RefgetList.isXinZhen = true;
              });
            } else {
              this.$nextTick(() => {
                this.$refs.familyUsersOne.isXinZhen = false;
                this.$refs.equipmentUserOne.isXinZhen = false;
                this.$refs.RefMemberIdsOne.isXinZhen = false;
                this.$refs.healthDataOne.isXinZhen = true;
                this.$refs.RefgetListOne.isXinZhen = false;
                // this.$refs.familyUsers.isXinZhen = false;
                // this.$refs.equipmentUser.isXinZhen = true;
                // this.$refs.RefMemberIds.isXinZhen = false;
                // this.$refs.healthData.isXinZhen = true;
                // this.$refs.RefgetList.isXinZhen = false;
              });
            }
          } else {
            this.$message.error(aa.data.msg);
          }
        });
      }
    },
    finish2() {
      let typeList = [];
      typeList = this.checkListData2.concat(this.checkListData2Two);
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      this.UniqueIds = UniqueId;
      let obj = {
        userId: this.serviceId,
        describe: this.textarea,
        type: this.type,
        typeDetail: typeList,
        handleTime: this.handleTime,
        mainUniqueId: this.UniqueIds,
        customerId: this.customerIds,
        centerType: 1,
        status: "0",
        equipmentId: this.equipmentId,
        callPhone: this.tel,
        calltype: this.calltypes,
        serviceCalltype: this.calltypes,
      };
      if (obj.equipmentId == "" || obj.equipmentId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (obj.customerId == "" || obj.customerId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (this.valueOrder == "" || this.valueOrder == null) {
        this.$message.error("请选择创建工单。");
        return;
      }
      if (obj.describe == "" || obj.describe == null) {
        this.$message.error("工单描述内容不能为空！");
        return;
      }
      if (this.isZhou == true) {
        this.$message.error("请绑定后再创建！");
        return;
      }
      //创建工单
      apiShout.getCreateOrder(obj).then((aa) => {
        console.log("创建工单1：", aa.data);
        if (aa.data.code === 200) {
          // this.reload();
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          //页面刷新 全部待办  待我办理
          this.backlog();
          this.waitFinsh(this.serviceId);
          // this.historyOrders();
          this.getLists();
          // let namePhone = JSON.parse(localStorage.getItem("userId"));
          // this.customerIds = namePhone;
          //存储到本地中
          localStorage.setItem("xiaType", JSON.stringify(this.type));
          //清空选中的选项
          this.textarea = "";
          this.type = "";
          this.checkListData2 = [];
          this.zz3 = [];
          this.zz4 = [];
          this.handleTime = "";
          this.valueOrdertimes = "";
          this.valueOrder = "";
          this.isZhou = false;
          // this.callback1 = "0";
          if (this.isTabs == true) {
            this.$nextTick(() => {
              this.$refs.familyUsers.isXinZhen = true;
              this.$refs.equipmentUser.isXinZhen = false;
              this.$refs.RefMemberIds.isXinZhen = true;
              this.$refs.healthData.isXinZhen = true;
              this.$refs.RefgetList.isXinZhen = true;
            });
          } else {
            this.$nextTick(() => {
              this.$refs.familyUsersOne.isXinZhen = false;
              this.$refs.equipmentUserOne.isXinZhen = false;
              this.$refs.RefMemberIdsOne.isXinZhen = false;
              this.$refs.healthDataOne.isXinZhen = true;
              this.$refs.RefgetListOne.isXinZhen = false;
              // this.$refs.familyUsers.isXinZhen = false;
              // this.$refs.equipmentUser.isXinZhen = true;
              // this.$refs.RefMemberIds.isXinZhen = false;
              // this.$refs.healthData.isXinZhen = true;
              // this.$refs.RefgetList.isXinZhen = false;
            });
          }
        } else {
          this.$message.error(aa.data.msg);
        }
      });
    },
    finish3() {
      let typeList = [];
      typeList = this.checkListData3.concat(this.checkListData3Three);
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      this.UniqueIds = UniqueId;
      let obj = {
        userId: this.serviceId,
        describe: this.textarea,
        type: this.type,
        typeDetail: typeList,
        handleTime: this.handleTime,
        priority: this.priority,
        mainUniqueId: this.UniqueIds,
        customerId: this.customerIds,
        centerType: 1,
        status: "0",
        equipmentId: this.equipmentId,
        callPhone: this.tel,
        calltype: this.calltypes,
        serviceCalltype: this.calltypes,
      };
      if (obj.equipmentId == "" || obj.equipmentId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (obj.customerId == "" || obj.customerId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (this.valueOrder == "" || this.valueOrder == null) {
        this.$message.error("请选择创建工单。");
        return;
      }
      if (obj.describe == "" || obj.describe == null) {
        this.$message.error("工单描述内容不能为空！");
        return;
      }
      if (this.isZhou == true) {
        this.$message.error("请绑定后再创建！");
        return;
      }
      //创建工单
      apiShout.getCreateOrder(obj).then((aa) => {
        console.log("创建工单1：", aa.data);
        if (aa.data.code === 200) {
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          //页面刷新 全部待办  待我办理
          this.backlog();
          this.waitFinsh(this.serviceId);
          // this.historyOrders();
          this.getLists();
          // let namePhone = JSON.parse(localStorage.getItem("userId"));
          // this.customerIds = namePhone;
          //存储到本地中
          localStorage.setItem("xiaType", JSON.stringify(this.type));
          //清空选中的选项
          this.textarea = "";
          this.type = "";
          this.checkListData3 = [];
          this.zz5 = [];
          this.zz6 = [];
          this.handleTime = "";
          this.valueOrdertimes = "";
          this.valueOrder = "";
          this.isZhou = false;
          // this.callback1 = "0";
          if (this.isTabs == true) {
            this.$nextTick(() => {
              this.$refs.familyUsers.isXinZhen = true;
              this.$refs.equipmentUser.isXinZhen = false;
              this.$refs.RefMemberIds.isXinZhen = true;
              this.$refs.healthData.isXinZhen = true;
              this.$refs.RefgetList.isXinZhen = true;
            });
          } else {
            this.$nextTick(() => {
              this.$refs.familyUsersOne.isXinZhen = false;
              this.$refs.equipmentUserOne.isXinZhen = false;
              this.$refs.RefMemberIdsOne.isXinZhen = false;
              this.$refs.healthDataOne.isXinZhen = true;
              this.$refs.RefgetListOne.isXinZhen = false;

              // this.$refs.familyUsers.isXinZhen = false;
              // this.$refs.equipmentUser.isXinZhen = true;
              // this.$refs.RefMemberIds.isXinZhen = false;
              // this.$refs.healthData.isXinZhen = true;
              // this.$refs.RefgetList.isXinZhen = false;
            });
          }
        } else {
          this.$message.error(aa.data.msg);
        }
      });
    },
    finish4() {
      let typeList = [];
      typeList = this.checkListData4.concat(this.checkListData4Four);
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      this.UniqueIds = UniqueId;
      let obj = {
        userId: this.serviceId,
        describe: this.textarea,
        type: this.type,
        typeDetail: typeList,
        callback: this.callback2,
        handleTime: this.valueOrdertime1,
        priority: this.valueOrderputong1,
        mainUniqueId: this.UniqueIds,
        customerId: this.customerIds,
        centerType: 1,
        status: "0",
        equipmentId: this.equipmentId,
        callPhone: this.tel,
        calltype: this.calltypes,
        serviceCalltype: this.calltypes,
      };
      if (obj.equipmentId == "" || obj.equipmentId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      if (obj.customerId == "" || obj.customerId == null) {
        this.$message.error("用户信息不能为空");
        return;
      }
      console.log(this.mainUniqueId, "创建工单5");
      if (this.valueOrder == "" || this.valueOrder == null) {
        this.$message.error("请选择创建工单。");
        return;
      }
      if (obj.describe == "" || obj.describe == null) {
        this.$message.error("工单描述内容不能为空！");
        return;
      }
      if (this.isZhou == true) {
        this.$message.error("请绑定后再创建！");
        return;
      }
      //创建工单
      apiShout.getCreateOrder(obj).then((aa) => {
        console.log("创建工单1：", aa.data);
        if (aa.data.code === 200) {
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          //页面刷新 全部待办  待我办理
          this.backlog();
          this.waitFinsh(this.serviceId);
          // this.historyOrders();
          this.getLists();
          // let namePhone = JSON.parse(localStorage.getItem("userId"));
          // this.customerIds = namePhone;
          //清空选中的选项
          this.textarea = "";
          this.type = "";
          this.checkListData4 = [];
          this.zz7 = [];
          this.zz8 = [];
          this.handleTime = "";
          this.valueOrdertime1 = "";
          this.valueOrderputong1 = "";
          this.valueOrder = "";
          this.callback2 = 0;
          this.valueTypes = false;
          this.isZhou = false;
          if (this.isTabs == true) {
            this.$nextTick(() => {
              this.$refs.familyUsers.isXinZhen = true;
              this.$refs.equipmentUser.isXinZhen = false;
              this.$refs.RefMemberIds.isXinZhen = true;
              this.$refs.healthData.isXinZhen = true;
              this.$refs.RefgetList.isXinZhen = true;
            });
          } else {
            this.$nextTick(() => {
              this.$refs.familyUsersOne.isXinZhen = false;
              this.$refs.equipmentUserOne.isXinZhen = false;
              this.$refs.RefMemberIdsOne.isXinZhen = false;
              this.$refs.healthDataOne.isXinZhen = true;
              this.$refs.RefgetListOne.isXinZhen = false;

              // this.$refs.familyUsers.isXinZhen = false;
              // this.$refs.equipmentUser.isXinZhen = true;
              // this.$refs.RefMemberIds.isXinZhen = false;
              // this.$refs.healthData.isXinZhen = true;
              // this.$refs.RefgetList.isXinZhen = false;
            });
          }
        } else {
          this.$message.error(aa.data.msg);
        }
      });
    },
    //创建工单的多选
    checkboxChange(ib) {
      console.log(ib);
      if (ib != undefined) {
        this.checkListData = ib;
      }
      console.log("==", this.checkListData);
    },
    checkboxChange1(ib) {
      console.log("---==", ib);
      if (ib != undefined) {
        this.checkListData1 = ib;
        console.log("==", this.checkListData1);
      }
      // this.checkList1 = b;
    },
    checkboxChange2(ib) {
      console.log("---", ib);
      if (ib != undefined) {
        this.checkListData1One = ib;
        console.log("==", this.checkListData1);
      }
      // this.checkList2 = b;
    },
    checkboxChange3(ib) {
      //console.log('---',b);
      if (ib != undefined) {
        this.checkListData2 = ib;
        console.log("==", this.checkListData2);
      }
      // this.checkList3 = b;
    },
    checkboxChange4(ib) {
      //console.log('---',b);
      // this.checkList4 = b;
      if (ib != undefined) {
        this.checkListData2Two = ib;
        console.log("==", this.checkListData2);
      }
    },
    checkboxChange5(ib) {
      //console.log('---',b);
      // this.checkList5 = b;
      if (ib != undefined) {
        this.checkListData3 = ib;
        console.log("==", this.checkListData3);
      }
    },
    checkboxChange6(ib) {
      //console.log('---',b);
      // this.checkList6 = b;
      if (ib != undefined) {
        this.checkListData3Three = ib;
        console.log("==", this.checkListData3);
      }
    },
    checkboxChange7(ib) {
      //console.log('---',b);
      // this.checkList7 = b;
      if (ib != undefined) {
        this.checkListData4 = ib;
        console.log("==", this.checkListData4);
      }
    },
    checkboxChange8(ib) {
      //console.log('---',b);
      // this.checkList8 = b;
      if (ib != undefined) {
        this.checkListData4Four = ib;
        console.log("==", this.checkListData4);
      }
    },
    //处理时间
    handelChange(vId) {
      let obj = {};
      obj = this.historyOrdertime.find((item) => {
        return item.value === vId;
      });
      //  let a=obj.label.indexOf('小');
      //  console.log(obj.label.substring(a,-1));
      this.handleTime = obj.label.substring(obj.label.indexOf("小"), -1);
    },
    handelChange1(vId) {
      this.handleTime = vId;
      // let obj = {};
      // obj = this.historyOrdertimes.find((item) => {
      //   return item.value === vId;
      // });
      // //  let a=obj.label.indexOf('小');
      // //  console.log(obj.label.substring(a,-1));
      // this.handleTime = obj.label.substring(obj.label.indexOf("小"), -1);
    },
    handelChange2(vId) {
      this.handleTime = vId;
      // let obj = {};
      // obj = this.historyOrdertimes.find((item) => {
      //   return item.value === vId;
      // });
      // //  let a=obj.label.indexOf('小');
      // //  console.log(obj.label.substring(a,-1));
      // this.handleTime = obj.label.substring(obj.label.indexOf("小"), -1);
    },
    handelChange3(vId) {
      this.handleTime = vId;
      // let obj = {};
      // obj = this.historyOrdertimes.find((item) => {
      //   return item.value === vId;
      // });
      // //  let a=obj.label.indexOf('小');
      // //  console.log(obj.label.substring(a,-1));
      // this.handleTime = obj.label.substring(obj.label.indexOf("小"), -1);
    },
    handelChange4(vId) {
      let obj = {};
      obj = this.historyOrdertime1.find((item) => {
        return item.value === vId;
      });
      //  let a=obj.label.indexOf('小');
      //  console.log(obj.label.substring(a,-1));
      this.handleTime = obj.label.substring(obj.label.indexOf("小"), -1);
    },
    //回电的紧急程度
    handelChangeHistory(vId) {
      // let obj = {};
      // obj = this.historyOrderputong.find((item) => {
      //   return item.value === vId;
      // });
      // //  console.log(obj.label);
      this.priority = vId;
    },
    handelChangeHistory1(vId) {
      this.priority = vId;
    },
    //创建工单右侧的下拉
    changexiaLa(vId) {
      console.log("==", vId);
      this.type = vId;
      this.gongDan();
      //清空选中的选项
      // this.textarea = "";
      this.zz = [];
      this.zz1 = [];
      this.zz2 = [];
      this.zz3 = [];
      this.zz4 = [];
      this.zz5 = [];
      this.zz6 = [];
      this.zz7 = [];
      this.zz8 = [];
      this.checkList = [];
      this.checkList1 = [];
      this.checkList2 = [];
      this.checkList3 = [];
      this.checkList4 = [];
      this.checkList5 = [];
      this.checkList6 = [];
      this.checkList7 = [];
      this.checkList8 = [];
      this.handleTime = "";
      this.valueOrdertime = "";
      this.valueOrdertimes = "";
      this.valueOrderputong = "";
      this.valueOrdertime1 = "";
      this.valueOrderputong1 = "";
      this.callback2 = 0;
      this.valueTypes = false;
      this.callback1 = 0;
      this.valueType = false;
      if (vId == "1") {
        this.isXian = true;
        this.isXian1 = false;
        this.isXian2 = false;
        this.isXian3 = false;
        this.isXian4 = false;
        // //清空选中的选项
        // this.textarea = "";
        // this.type = "";
        // this.checkList = [];
        // this.handleTime = "";
        // this.valueOrdertime = "";
        // this.valueOrderputong = "";
        // // this.valueOrder = "";
        // this.callback1 = 0;
        // this.valueType = false;
      } else if (vId == "2") {
        this.isXian = false;
        this.isXian2 = false;
        this.isXian3 = false;
        this.isXian4 = false;
        this.isXian1 = true;
        // //清空选中的选项
        // this.textarea = "";
        // this.type = "";
        // this.checkList1 = [];
        // this.checkList2 = [];
        // this.handleTime = "";
        // this.valueOrdertimes = "";
        // // this.valueOrder = "";
      } else if (vId == "3") {
        this.isXian1 = false;
        this.isXian = false;
        this.isXian3 = false;
        this.isXian4 = false;
        this.isXian2 = true;
        // //清空选中的选项
        // this.textarea = "";
        // this.type = "";
        // this.checkList3 = [];
        // this.checkList4 = [];
        // this.handleTime = "";
        // this.valueOrdertimes = "";
        // // this.valueOrder = "";
      } else if (vId == "4") {
        this.isXian = false;
        this.isXian1 = false;
        this.isXian2 = false;
        this.isXian4 = false;
        this.isXian3 = true;
        // //清空选中的选项
        // this.textarea = "";
        // this.type = "";
        // this.checkList5 = [];
        // this.checkList6 = [];
        // this.handleTime = "";
        // this.valueOrdertimes = "";
        // // this.valueOrder = "";
      } else if (vId == "5") {
        this.isXian = false;
        this.isXian1 = false;
        this.isXian2 = false;
        this.isXian3 = false;
        this.isXian4 = true;
        // //清空选中的选项
        // this.textarea = "";
        // this.type = "";
        // this.checkList7 = [];
        // this.checkList8 = [];
        // this.handleTime = "";
        // this.valueOrdertime1 = "";
        // this.valueOrderputong1 = "";
        // // this.valueOrder = "";
        // this.callback2 = 0;
        // this.valueTypes = false;
      }
      // //获取本地存储的通话信息
      // let or = JSON.parse(localStorage.getItem("createOrder"));
      // if (or != null&&or.equipmentId!=null) {
      //   console.log("or-----:", or);
      //   this.userId = or.userId;
      //   this.equipmentId = or.equipmentId;
      // }
    },
    //是否分配有来电呼叫接口
    shout() {
      // this.endReason();
      //console.log('========',this.$refs.audioLing);
      // apiShout
      //   .getIsShout({ cno: this.cno, time: this.beee, status: this.endStatus })
      //   .then((res) => {
      // if (this.strMessage == 500) {
      //   //跳转到登录页面
      //   this.$router.push("/");
      //   //清除缓存
      //   window.localStorage.clear();
      //   window.sessionStorage.clear();
      //   //退出天润的登录
      //   ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
      //     // 传入参数对象
      //     var params = {};
      //     params.logoutMode = 1;
      //     params.removeBinding = 0;
      //     ClinkAgent.logout(params);
      //   });
      //   this.$message({
      //     showClose: true,
      //     message: "不好意思，您被踢下线，请重新登录",
      //     type: "error",
      //   });
      //   clearInterval(this.timer);
      //   clearInterval(this.timer1);
      //   clearInterval(this.timer2);
      //   // clearInterval(this.timer3);
      //   clearInterval(this.timer4);
      //   // clearInterval(this.timer5);
      //   clearInterval(this.timer6);
      // }
      // console.log(this.textPhone, "123textphone");
      if (this.isJIN == true) {
        this.muteCount = 0;
        this.isYIN = true;
        this.isJIN = false;
      }
      if (
        (this.strUser == null && this.isComePhone == true) ||
        (this.strUser == "" && this.isComePhone == true)
      ) {
        this.isTab = true;
        this.isTabs = false;
        this.isYou = true;
      } else {
        this.isTab = false;
        this.isTabs = true;
        this.isYou = false;
      }
      if (this.isYou == true) {
        this.isTab = true;
        this.isTabs = false;
      } else {
        this.isTab = false;
        this.isTabs = true;
      }

      if (this.mscode == 111 || this.mscode == "111") {
        //铃响
        clearInterval(this.timer);
        this.$refs.audioLing.pause();
        this.isListion = true;
        this.isListioning = false;
        this.zhuanjieimgs = false;
        this.zhuanjieimg = false;
        this.yiliaoPimgs = false;
        this.yiliaoPimg = false;
        this.jijiuPimgs = false;
        this.jijiuPimg = false;
        this.jinJiPhoneimgs = false;
        this.jinJiPhoneimg = false;
        this.offImgIcon = false;
        this.offImgIcons = false;
        this.cnoStatus = 1;
        if (this.isAnswer == true) {
          this.isAnswer = false;
        }
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();
        console.log("取消铃声，走到这一步");
        return false;
      } else {
        if (this.cnoStatus != "0" || this.cnoStatus != 0) {
          return false;
        } else {
          if (this.strPhone == null) {
            // clearInterval(this.timer5);
            clearInterval(this.timer);
            clearInterval(this.timer2);
            this.currentTime1 = 0;
            clearInterval(this.timer1);
            this.currentTime = 0;
            //隐藏接听按钮
            this.isAnswer = false;
          } else if (this.strPhone != null) {
            this.activeName1 = "first";
            this.noTaking = false;
            this.textPhone = this.strPhone;
            // //存储本地
            // localStorage.setItem(
            //   "phone",
            //   JSON.stringify(res.data.result.phone)
            // );
            // this.timer5 = setInterval(this.endReason, 5000);
            //有分配
            //停止请求分配的计时器
            // clearInterval(this.timer3);
            clearInterval(this.timer);
            clearInterval(this.timer1);
            this.currentTime = 0;
            this.currentTime1 = 0;
            //显示接听按钮
            this.isAnswer = true;
            //铃响
            this.startRotate();
            this.$refs.audioLing.play();
            //开始计时器
            this.testTimer();
            this.tel = this.strPhone;
            localStorage.setItem("phoneNumber", JSON.stringify(this.strPhone));
            localStorage.setItem(
              "strequipmentIds",
              JSON.stringify(this.strequipmentId)
            );
            this.userId = this.strUser;
            //将信息保存到本地中
            let obj = {
              tel: this.strPhone,
              equipmentId: this.strequipmentId,
              // dataTime: res.data.result.dataTime,
              userId: this.strUser,
            };
            localStorage.setItem("createOrder", JSON.stringify(obj));
            //保存到本地
            localStorage.setItem("userId", JSON.stringify(this.strUser));
            // //查询用户历史工单
            // apiShout.getUserHistory({ customerId: this.strUser }).then((dd) => {
            //   console.log("用户历史工单:", dd.data);
            //   if (dd.data.code === 200) {
            //     //对于日期的处理
            //     for (let i = 0; i < dd.data.result.length; i++) {
            //       if (dd.data.result[i].createDate == null) {
            //         dd.data.result[i].createDate = "";
            //       } else {
            //         let a = dd.data.result[i].createDate;
            //         let ds = new Date(a);
            //         let y = ds.getFullYear();
            //         let m = ds.getMonth() + 1;
            //         m = m < 10 ? "0" + m : m;
            //         let d = ds.getDate();
            //         d = d < 10 ? "0" + d : d;
            //         let h = ds.getHours();
            //         let minute = ds.getMinutes();
            //         minute = minute < 10 ? "0" + minute : minute;
            //         let second = ds.getSeconds();
            //         second = second < 10 ? "0" + second : second;
            //         dd.data.result[i].createDate =
            //           y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
            //       }
            //     }
            //     this.historyList = dd.data.result;
            //     this.eventList = false;
            //     console.log(this.eventList, "接听的false");
            //   }
            // });
            // //查询用户信息
            // apiShout.getuserInfor({ localPhone: this.strPhone }).then((dat) => {
            //   console.log("用户信息：", dat.data);
            //   this.userName = dat.data.result.member.name;
            //   // console.log('00000000000000000000:',this.userName);
            //   if (dat.data.code === 200) {
            //     //对于生日的处理
            //     let a = dat.data.result.member.birthday;
            //     let dd = new Date(a);
            //     let y = dd.getFullYear();
            //     let m = dd.getMonth() + 1;
            //     m = m < 10 ? "0" + m : m;
            //     let d = dd.getDate();
            //     d = d < 10 ? "0" + d : d;
            //     dat.data.result.member.birthday = y + "-" + m + "-" + d;
            //     //对于激活日期的处理
            //     let b = dat.data.result.member.createDate;
            //     let dd1 = new Date(b);
            //     let y1 = dd1.getFullYear();
            //     let m1 = dd1.getMonth() + 1;
            //     m1 = m1 < 10 ? "0" + m1 : m1;
            //     let d1 = dd1.getDate();
            //     d1 = d1 < 10 ? "0" + d1 : d1;
            //     dat.data.result.member.createDate = y1 + "-" + m1 + "-" + d1;
            //     //对于到期日期的处理
            //     let c = dat.data.result.member.updateDate;
            //     let dd2 = new Date(c);
            //     let y2 = dd2.getFullYear();
            //     let m2 = dd2.getMonth() + 1;
            //     m2 = m2 < 10 ? "0" + m2 : m2;
            //     let d2 = dd2.getDate();
            //     d2 = d2 < 10 ? "0" + d2 : d2;
            //     dat.data.result.member.updateDate = y2 + "-" + m2 + "-" + d2;
            //     this.formMember = dat.data.result.member;
            //     this.formMembers = dat.data.result.member;
            //     this.familyIds = dat.data.result.member.familyId;
            //     console.log(this.familyIds, "123123123");
            //     this.Sphone = dat.data.result.member.sparePhone;
            //     let str = this.formMember.sparePhone;
            //     this.formMember.NewsparePhone = str.replace(
            //       /(\d{3})\d{4}(\d{1})/,
            //       "$1****$2"
            //     );
            //     // this.formMember.sparePhone =
            //     //   this.formMember.sparePhone.substr(0, 3) +
            //     //   "****" +
            //     //   this.formMember.sparePhone.substr(7);
            //     this.formMemberInfo = dat.data.result.memberInfo;
            //     this.cityName = dat.data.result.member.city;
            //     this.countyName = dat.data.result.member.county;
            //     this.cityId = dat.data.result.member.city;
            //     this.countyId = dat.data.result.member.county;
            //     this.emergencyOnes = dat.data.result.member.emergencyOne;
            //     this.emergencyTwos = dat.data.result.member.emergencyTwo;
            //     // console.log(CodeToText[this.countyName], "CodeToText123");
            //     // console.log(CodeToText[this.cityName], "CodeToText11111");
            //     this.formMember.city = CodeToText[this.cityName];
            //     this.formMember.county = CodeToText[this.countyName];
            //     if (this.infoFlag == true) {
            //       this.formMember.city = dat.data.result.member.city;
            //       this.formMember.county = dat.data.result.member.county;
            //     }
            //     this.formMember.birthday = dat.data.result.member.birthday;
            //     // this.formMemberInfo.live = dat.data.result.memberInfo.live;
            //     this.formMemberInfo.intellect =
            //       dat.data.result.memberInfo.intellect;
            //     this.formMemberInfo.character =
            //       dat.data.result.memberInfo.character;
            //     this.formMemberInfo.language = dat.data.result.memberInfo.language;
            //     this.formMemberInfo.hearing = dat.data.result.memberInfo.hearing;
            //     this.formMemberInfo.vision = dat.data.result.memberInfo.vision;
            //     this.formMemberInfo.action = dat.data.result.memberInfo.action;
            //     this.formMemberInfo.illness = dat.data.result.memberInfo.illness;
            //     //健康数据
            //     this.forBody = dat.data.result.memberInfo;
            //     for (let i = 0; i < dat.data.result.remind.length; i++) {
            //       this.morningbefor = dat.data.result.remind[0];
            //       this.morningafter = dat.data.result.remind[1];
            //       this.lunchbefor = dat.data.result.remind[2];
            //       this.lunchafter = dat.data.result.remind[3];
            //       this.eveningbefor = dat.data.result.remind[4];
            //     }
            //   }
            //   // console.log(this.familyIds, "家庭id");
            //   this.ursents = [];
            //   this.ursentsTwo = [];
            //   apiShout.getFamilyUsers({ fId: this.familyIds }).then((res) => {
            //     if (res.data.code === 200) {
            //       console.log(res, "紧急联系人");
            //       // this.ursents = res.data.result
            //       res.data.result.forEach((item) => {
            //         this.ursents.push(item);
            //         this.ursentsTwo.push(item);
            //         console.log(this.ursents, "输出下拉框");
            //       });
            //       for (var i = 0; i < this.ursents.length; i++) {
            //         this.userPhone = this.ursents[i];
            //         if (this.userPhone.id === this.emergencyOnes) {
            //           this.formMember.emergencyContactOnePhone =
            //             this.userPhone.phone;
            //           this.formMember.emergencyContactOne = this.userPhone.userName;
            //           let str = this.formMember.emergencyContactOnePhone;
            //           this.formMember.emergencyContactOnePhone = str.replace(
            //             /(\d{3})\d{4}(\d{1})/,
            //             "$1****$2"
            //           );
            //         }
            //       }
            //       for (let i = 0; i < this.ursentsTwo.length; i++) {
            //         this.userPhones = this.ursentsTwo[i];
            //         if (this.userPhones.id === this.emergencyTwos) {
            //           this.formMember.emergencyContactTwoPhone =
            //             this.userPhones.phone;
            //           this.formMember.emergencyContactTwo =
            //             this.userPhones.userName;
            //           let str = this.formMember.emergencyContactTwoPhone;
            //           this.formMember.emergencyContactTwoPhone = str.replace(
            //             /(\d{3})\d{4}(\d{1})/,
            //             "$1****$2"
            //           );
            //         }
            //       }
            //     }
            //   });
            // });
            let namePhone = JSON.parse(localStorage.getItem("userId"));
            this.customerIds = namePhone;
            console.log(this.customerIds, "来电的时候出现的id");
            let strequipmentIdN = JSON.parse(
              localStorage.getItem("strequipmentIds")
            );
            this.equipmentId = strequipmentIdN;
            if (this.strPhone != null) {
              this.imgIcon = true;
              this.imgIcons = false;
            } else {
              this.imgIcons = true;
              this.imgIcon = false;
            }
            if (this.strPhone != null) {
              this.StandbyIcon = true;
              this.StandbyIcons = false;
            } else {
              this.StandbyIcon = false;
              this.StandbyIcons = true;
            }
          }

          //存本地
          // localStorage.setItem("calPhones",JSON.stringify(this.textPhone));
          if (this.textPhone != null) {
            const audioElement = this.audioElement;
            if (audioElement && audioElement.length > 0) {
              for (let i = 0; i < audioElement.length; i++) {
                this.audioElement[i].pause();
              }
            }
          }
          // });
        }
      }
    },
    //点击接听事件
    listion() {
      if (this.strcode == 500) {
        // this.$message.error("格式错误");
        return false;
      } else {
        let ll = JSON.parse(localStorage.getItem("strcodes"));
        // let ll = 110;
        if (this.seType == 3) {
          this.$emit("changeEvent", "2");
          this.isFour = false;
          this.isServer = false;
          this.isServer1 = false;
          clearInterval(this.timer4);
          this.currentTime2 = 0;
          this.testTimer2();
          this.isAnswer = false;
          return false;
        }
        this.hereLis = true;
        this.$emit("hereListion", this.hereLis);
        this.isFour = false;
        this.isServer = false;
        this.isServer1 = false;
        this.isSave = false;
        this.huiPhone = JSON.parse(localStorage.getItem("listionPhone"));
        this.huiPhone =
          this.huiPhone.substr(0, 3) + "****" + this.huiPhone.substr(7);
        console.log(this.strcodes, "查询this.strcodes");
        this.activeName1 = "first";
        // if (this.strcodes === 110) {
        if (ll === 110) {
          console.log(this.strcodes, "接听之后回拨回去");
          var objs = JSON.stringify({
            cno: this.cno,
            msgcode: 210,
            token: this.loginToken,
            phone: this.pNumbers || "",
            userid: this.strUser || null,
            equipmentid: null,
            calltype: "1",
          });
          this.websocketsend(objs);
          this.calltypes = "1";
          let pNumber = JSON.parse(localStorage.getItem("phoneNumber"));
          this.pNumbers = pNumber;
          let hh = this.pNumbers;
          apiShout.getparTitle({ title: "外呼外显" }).then((res) => {
            // console.log('转接：',res.data);
            if (res.data.code == 200) {
              // this.inputPhs = res.data.result;
              ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
                // 传入参数对象
                var params = {};
                params.tel = hh;
                params.obClid = res.data.result;
                // params.obClid = "02138277595";
                // params.obClid = "01086484968";
                ClinkAgent.previewOutcall(params);
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
          // ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          //       // 传入参数对象
          //       var params = {};
          //       params.tel = hh;
          //       params.obClid = "02138277595";
          //       // params.obClid = "01086484968";
          //       ClinkAgent.previewOutcall(params);
          //     });
        } else if (ll === 112) {
          console.log(this.strcodes, "走的直接接听");
          var objss = JSON.stringify({
            cno: this.cno,
            msgcode: 204,
            token: this.loginToken,
            phone: this.pNumbers || "",
          });
          this.websocketsend(objss);
          ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
            ClinkAgent.sipLink();
          });
        }
        //切换上边
        setTimeout(() => {
          this.isListioning = true;
          this.zhuanjieimgs = false;
          this.zhuanjieimg = true;
          this.yiliaoPimgs = false;
          this.yiliaoPimg = true;
          this.jijiuPimgs = false;
          this.jijiuPimg = true;
          this.jinJiPhoneimgs = false;
          this.jinJiPhoneimg = true;
        }, 1500);
        this.isListion = false;
        this.offImgIcons = true;
        this.setOffTimer = setTimeout(() => {
          this.offImgIcon = true;
          this.offImgIcons = false;
        }, 3000);
        let kk = JSON.parse(localStorage.getItem("userId"));
        if (kk == null || kk == "") {
          this.isTab = true;
          this.isTabs = false;
          this.isClick = false;
          this.isActiOne = false;
          this.userId = "";
          this.customerIds = "" || null;
          console.log("热线走这里1");
          localStorage.removeItem("userIds");
          this.isYou = true;
          Object.keys(this.formMember).forEach((item) => {
            this.$delete(this.formMember, item);
          });
          Object.keys(this.formMemberInfo).forEach((item) => {
            this.$delete(this.formMemberInfo, item);
          });
          Object.keys(this.formMembers).forEach((item) => {
            this.$delete(this.formMembers, item);
          });
          console.log("热线走这里11", this.strPhone);
          this.historyList = undefined;
          this.formMember.sparePhone = "";
          this.formMember.city = "";
          this.formMember.county = "";
          this.formMember.localPhone = this.strPhone;
          this.valuebase = "";
          this.inputsize = "";
          this.isSer = false;
          this.$nextTick(() => {
            this.$refs.familyUsersOne.tableData = undefined;
            this.$refs.equipmentUserOne.tableData = undefined;
            this.$refs.RefMemberIdsOne.tableData = undefined;
            this.$refs.healthDataOne.tableData = undefined;
            this.$refs.RefgetListOne.tableData = undefined;
            this.$refs.familyUsersOne.isXinZhen = false;
            this.$refs.equipmentUserOne.isXinZhen = true;
            this.$refs.RefMemberIdsOne.isXinZhen = false;
            this.$refs.healthDataOne.isXinZhen = true;
            this.$refs.RefgetListOne.isXinZhen = false;
            // console.log("00000000:", this.$refs.familyUsersOne.tableData);
            this.$refs.RefMemberIdsOne.getMemberIds();
            this.$refs.RefgetListOne.getList();
            this.$refs.RadioBroadcastsOne.getOneTime();
          });
        } else {
          console.log("热线走这里2");
          this.userId = kk;
          this.customerIds = kk;
          localStorage.setItem("userIds", JSON.stringify(kk));
          this.getFamily();
          this.getsheList();
          this.getOld();
          this.$nextTick(() => {
            this.$refs.RefMemberIds.getMemberIds();
            this.$refs.RefgetList.getList();
            this.$refs.RadioBroadcasts.getOneTime();
            this.$refs.familyUsers.isXinZhen = true;
            this.$refs.equipmentUser.isXinZhen = false;
            this.$refs.RefMemberIds.isXinZhen = true;
            this.$refs.healthData.isXinZhen = true;
            this.$refs.RefgetList.isXinZhen = true;
          });
          // //查询用户历史工单
          apiShout.getUserHistory({ customerId: kk }).then((dd) => {
            console.log("热线走这里22");
            console.log("用户历史工单:", dd.data);
            if (dd.data.code === 200) {
              //对于日期的处理
              for (let i = 0; i < dd.data.result.length; i++) {
                if (dd.data.result[i].createDate == null) {
                  dd.data.result[i].createDate = "";
                } else {
                  let a = dd.data.result[i].createDate;
                  // let mm = dd.data.result[i].typeDetail.slice(1, -1);
                  let ds = new Date(a);
                  let y = ds.getFullYear();
                  let m = ds.getMonth() + 1;
                  m = m < 10 ? "0" + m : m;
                  let d = ds.getDate();
                  d = d < 10 ? "0" + d : d;
                  let h = ds.getHours();
                  let minute = ds.getMinutes();
                  minute = minute < 10 ? "0" + minute : minute;
                  let second = ds.getSeconds();
                  second = second < 10 ? "0" + second : second;
                  dd.data.result[i].createDate =
                    y +
                    "-" +
                    m +
                    "-" +
                    d +
                    " " +
                    h +
                    ":" +
                    minute +
                    ":" +
                    second;
                  // dd.data.result[i].typeDetail = mm;
                }
              }
              this.historyList = dd.data.result;
              this.eventList = false;
              console.log(this.eventList, "接听的false");
            } else {
              this.$message.error(dd.data.msg);
            }
          });
          //查询用户信息
          apiShout.getMemberInfoByIds({ id: kk }).then((dat) => {
            console.log("用户信息：", dat.data);
            if (dat.data.code === 200) {
              this.regionTong = [];
              this.region = [];
              //对于生日的处理
              let a = dat.data.result.member.birthday;
              let dd = new Date(a);
              let y = dd.getFullYear();
              let m = dd.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = dd.getDate();
              d = d < 10 ? "0" + d : d;
              dat.data.result.member.birthday = y + "-" + m + "-" + d;
              //对于激活日期的处理
              let b = dat.data.result.member.createDate;
              let dd1 = new Date(b);
              let y1 = dd1.getFullYear();
              let m1 = dd1.getMonth() + 1;
              m1 = m1 < 10 ? "0" + m1 : m1;
              let d1 = dd1.getDate();
              d1 = d1 < 10 ? "0" + d1 : d1;
              dat.data.result.member.createDate = y1 + "-" + m1 + "-" + d1;
              //对于到期日期的处理
              let c = dat.data.result.member.updateDate;
              let dd2 = new Date(c);
              let y2 = dd2.getFullYear();
              let m2 = dd2.getMonth() + 1;
              m2 = m2 < 10 ? "0" + m2 : m2;
              let d2 = dd2.getDate();
              d2 = d2 < 10 ? "0" + d2 : d2;
              dat.data.result.member.updateDate = y2 + "-" + m2 + "-" + d2;
              this.formMember = dat.data.result.member;
              this.xinLog = this.formMember.lon;
              this.xinLat = this.formMember.lat;
              this.xinLoc = this.formMember.location;
              this.formMembers = dat.data.result.member;
              this.familyIds = dat.data.result.member.familyId;
              console.log(this.familyIds, "123123123");
              this.emergencyOnes = dat.data.result.member.emergencyOne;
              this.emergencyTwos = dat.data.result.member.emergencyTwo;
              this.ursents = [];
              this.ursentsTwo = [];
              this.region = [];
              apiShout.getFamilyUsers({ fId: this.familyIds }).then((res) => {
                if (res.data.code === 200) {
                  console.log(res, "紧急联系人");
                  // this.ursents = res.data.result
                  res.data.result.forEach((item) => {
                    this.ursents.push(item);
                    this.ursentsTwo.push(item);
                    this.region.push(item);
                    // console.log(this.region, "紧急联系人111111");
                  });

                  for (var i = 0; i < this.ursents.length; i++) {
                    this.userPhone = this.ursents[0];
                    this.userPhones = this.ursents[1];
                    // if (this.userPhone.id === this.emergencyOnes) {
                    // console.log(
                    //   this.userPhone.phone,
                    //   this.userPhone.userName,
                    //   "this.userPhone.phone"
                    // );
                    this.formMember.emergencyContactOnePhone =
                      this.userPhone.phone;
                    this.formMember.emergencyContactOne =
                      this.userPhone.userName;
                    let str = this.formMember.emergencyContactOnePhone;
                    this.formMember.emergencyContactOnePhone = str.replace(
                      /(\d{3})\d{4}(\d{1})/,
                      "$1****$2"
                    );
                    // let arrss = [];
                    let arrText = {};
                    arrText.userName = this.userPhone.userName;
                    arrText.phone = this.userPhone.phone;
                    this.regionTong.push(arrText);
                    this.formMember.emergencyContactTwoPhone =
                      this.userPhones.phone;
                    this.formMember.emergencyContactTwo =
                      this.userPhones.userName;
                    let str1 = this.formMember.emergencyContactTwoPhone;
                    this.formMember.emergencyContactTwoPhone = str1.replace(
                      /(\d{3})\d{4}(\d{1})/,
                      "$1****$2"
                    );
                    // let arrss = [];
                    // let arrText = {};
                    // arrText.userName = this.userPhones.userName;
                    // arrText.phone = this.userPhones.phone;
                    // this.regionTong.push(arrText);
                    // this.region.push(arrText);
                    // }
                  }
                  // for (let i = 0; i < this.ursentsTwo.length; i++) {
                  //   this.userPhones = this.ursentsTwo[i];
                  //   if (this.userPhones.id === this.emergencyTwos) {
                  //     console.log(
                  //       this.userPhones.phone,
                  //       this.userPhones.userName,
                  //       "this.userPhones.userName"
                  //     );
                  //     this.formMember.emergencyContactTwoPhone =
                  //       this.userPhones.phone;
                  //     this.formMember.emergencyContactTwo =
                  //       this.userPhones.userName;
                  //     let str = this.formMember.emergencyContactTwoPhone;
                  //     this.formMember.emergencyContactTwoPhone = str.replace(
                  //       /(\d{3})\d{4}(\d{1})/,
                  //       "$1****$2"
                  //     );
                  //     // let arrss = [];
                  //     let arrText = {};
                  //     arrText.userName = this.userPhones.userName;
                  //     arrText.phone = this.userPhones.phone;
                  //     this.regionTong.push(arrText);
                  //     // this.region.push(arrText);
                  //   }
                  // }
                } else {
                  this.$message.error(res.data.msg);
                }
              });
              this.Sphone = dat.data.result.member.sparePhone;
              console.log(this.Sphone, "备用手机号查看");
              if (this.Sphone != null) {
                this.StandbyIcon = true;
                this.StandbyIcons = false;
              } else {
                this.StandbyIcon = false;
                this.StandbyIcons = true;
              }
              let str = this.formMember.sparePhone;
              if (str !== null) {
                this.formMember.NewsparePhone = str.replace(
                  /(\d{3})\d{4}(\d{1})/,
                  "$1****$2"
                );
              }
              this.formMemberInfo = dat.data.result.memberInfo;
              if (dat.data.result.memberInfo.illness == null) {
                this.isZhan1 = false;
                this.isZhan = false;
                this.inputman = "";
              } else {
                this.isZhan1 = true;
              }
              this.cityName = dat.data.result.member.city;
              this.countyName = dat.data.result.member.county;
              this.cityId = dat.data.result.member.city;
              this.countyId = dat.data.result.member.county;
              this.emergencyOnes = dat.data.result.member.emergencyOne;
              this.emergencyTwos = dat.data.result.member.emergencyTwo;
              console.log(
                this.emergencyOnes,
                this.emergencyTwos,
                "查询紧急联系人的匹配值"
              );
              this.formMember.city = CodeToText[this.cityName];
              this.formMember.county = CodeToText[this.countyName];
              if (this.infoFlag == true) {
                this.formMember.city = dat.data.result.member.city;
                this.formMember.county = dat.data.result.member.county;
              }
              this.formMember.birthday = dat.data.result.member.birthday;
              this.elderlyId = dat.data.result.member.id;
              this.formMemberInfo.intellect =
                dat.data.result.memberInfo.intellect;
              this.formMemberInfo.character =
                dat.data.result.memberInfo.character;
              this.formMemberInfo.language =
                dat.data.result.memberInfo.language;
              this.formMemberInfo.hearing = dat.data.result.memberInfo.hearing;
              this.formMemberInfo.vision = dat.data.result.memberInfo.vision;
              this.formMemberInfo.action = dat.data.result.memberInfo.action;
              this.formMemberInfo.illness = dat.data.result.memberInfo.illness;
            } else {
              this.$message.error(dat.data.msg);
            }
            this.imgIconBtns = false;
            this.imgIconBtnOne = true;
            console.log(this.familyIds, "家庭id");
          });
          let allEq = JSON.parse(localStorage.getItem("strequipmentIds"));
          apiShout.geteqMem({ equipmentId: allEq, memberId: kk }).then((ss) => {
            console.log("查询服务项：", ss.data.result);
            if (ss.data.code == 200) {
              if (ss.data.result != null) {
                this.isSer = true;
                this.serverType = ss.data.result;
                if (this.serverType.basicStatus == 1) {
                  this.serverType.basicEnd = "未开通";
                } else if (this.serverType.basicStatus == 3) {
                  this.serverType.basicEnd = "已过期";
                }
                if (this.serverType.careStatus == 1) {
                  this.serverType.careEnd = "未开通";
                } else if (this.serverType.careStatus == 3) {
                  this.serverType.careEnd = "已过期";
                }
                if (this.serverType.ymStatus == 1) {
                  this.serverType.ymEnd = "未开通";
                } else if (this.serverType.ymStatus == 3) {
                  this.serverType.ymEnd = "已过期";
                }
                if (this.serverType.contactStatus == 1) {
                  this.serverType.contactEnd = "未开通";
                } else if (this.serverType.contactStatus == 3) {
                  this.serverType.contactEnd = "已过期";
                } else if (this.serverType.contactStatus == 2) {
                  this.serverType.contactEnd = "长期有效";
                }
                if (this.serverType.healthStatus == 1) {
                  this.serverType.healthEnd = "未开通";
                } else if (this.serverType.healthStatus == 3) {
                  this.serverType.healthEnd = "已过期";
                }
                if (this.serverType.lawStatus == 1) {
                  this.serverType.lawEnd = "未开通";
                } else if (this.serverType.lawStatus == 3) {
                  this.serverType.lawEnd = "已过期";
                }
                if (this.serverType.cnoStatus == 1) {
                  this.serverType.cnoEnd = "未开通";
                } else if (this.serverType.cnoStatus == 3) {
                  this.serverType.cnoEnd = "已过期";
                }
              } else {
                this.isSer = false;
              }
            }
          });
        }

        if (this.isTab == true) {
          this.$nextTick(() => {
            this.$refs.RefgetListOne.disableds = false;
            this.$refs.RefMemberIdsOne.disableds = false;
          });
        } else {
          this.$refs.RefgetList.disableds = false;
          this.$refs.RefMemberIds.disableds = false;
        }
        //清空通话记录ID
        this.UniqueIds = "";
        // //获取电话通话记录ID
        // this.timer6 = setInterval(this.phoneID, 1000);
        //请求挂机接口
        // this.timer5 = setInterval(this.endReason, 1000);
        //铃不动不响
        clearInterval(this.timer);
        this.$refs.audioLing.pause();
        //记时结束
        clearInterval(this.timer1);
        this.currentTime = 0;
        this.Personstatus = "呼叫中";
        this.PersonstatusIng = true;
        // //不再请求后台是否分配来电
        // clearInterval(this.timer3);

        //客服状态置忙
        this.valuezhuangtai = "忙碌";
        this.cnoStatus = 1;
        localStorage.setItem("serverStatus", JSON.stringify(this.cnoStatus));
        //客服状态置忙下的计时器
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();
        clearInterval(this.timers);

        //记时通话时间
        this.testTimer1();
        // //将客服状态置忙
        // apiShout.getBusy({ cno: this.cno }).then((aa) => {
        //   console.log("客服置忙：", aa.data);
        // });
        this.eventList = false;
        console.log(this.eventList, "接听的false");
        if (this.userId == null) {
          this.formMember = {};
          this.formMemberInfo = {};
          this.historyList = [];
          this.formMembers.createDate = "";
          this.formMembers.updateDate = "";
        }
      }
    },
    //挂断电话
    offPhone() {
      if (this.strcode == 500) {
        // this.$message.error("格式错误，重新登陆");
        return false;
      } else {
        var objs = JSON.stringify({
          cno: this.cno,
          msgcode: 220,
          token: this.loginToken,
          phone: this.pNumbers || "",
        });
        this.websocketsend(objs);
        this.hereLis = false;
        this.noTaking = true;
        this.isQues = false;
        this.isQues1 = false;
        this.isClick = false;
        this.isYou = false;
        this.gridData = [];
        this.valuebase = "";
        this.inputsize = "";
        //挂断电话
        this.endStatus = "1";
        this.endReason();
        // this.shout();
        this.endStatus = "0";
        //隐藏接听按钮
        this.isAnswer = false;
        //暂停挂机接口
        // clearInterval(this.timer5);
        //暂停接通时的计时器 清空定时器
        clearInterval(this.timer2);
        this.currentTime1 = 0;
        //铃不动
        clearInterval(this.timer);
        this.PersonstatusIng = false;
        localStorage.removeItem("userId");

        let obj = {
          userId: this.strUser,
          describe: this.textarea,
          type: this.type,
          typeDetail: this.checkList,
          handleTime: this.handleTime,
          priority: this.priority,
        };
        //存储本地
        localStorage.setItem("order", JSON.stringify(obj));
        // console.log("===", obj);
        //改变客服状态
        // this.valuezhuangtai = "空闲";
        //改变客服状态下的计时器
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();
        //切换上边
        this.isListion = true;
        this.isListioning = false;
        this.zhuanjieimgs = false;
        this.zhuanjieimg = false;
        this.yiliaoPimgs = false;
        this.yiliaoPimg = false;
        this.jijiuPimgs = false;
        this.jijiuPimg = false;
        this.jinJiPhoneimgs = false;
        this.jinJiPhoneimg = false;
        this.offImgIcon = false;
        this.offImgIcons = false;
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.side = 1;
          console.log("走了挂断的这一步-------");
          ClinkAgent.unlink(params);
        });
        if (this.isListion == true) {
          // this.$message("通话记录正在加载，请耐心等待一分钟后查看！");
        }
        if (
          (this.isListion == true && this.cnoStatus == "1") ||
          (this.isListion == true && this.cnoStatus == 1)
        ) {
          this.imgIcon = false;
          this.imgIcons = true;
          this.StandbyIcon = false;
          this.StandbyIcons = true;
          this.imgJinJi = true;
          console.log(this.imgJinJi, "true5");
          this.imgIconBtnOne = false;
          this.isArrangement = false;
          this.isArrangement1 = true;
        }
        //继续请求后台是否分配来电
        // this.timer3 = setInterval(this.shout, 3000)
        // this.shout();
        //清除本地
        // localStorage.removeItem("phoneNumber");
        // localStorage.removeItem("calPhones");
        this.eventList = true;
        // console.log(this.eventList, "挂断的true");
      }
    },
    callChanges(val) {
      this.callback2 = val;
      this.historyOrdertime1.forEach((item) => {
        if (item.value === "6") {
          return (this.valueOrdertime1 = item.value);
        }
      });
      this.historyOrderputong1.forEach((item) => {
        if (item.value === "3") {
          return (this.valueOrderputong1 = item.value);
        }
      });
      if (this.callback2 == "0") {
        this.valueTypes = false;
      } else if (this.callback2 == "1") {
        this.valueTypes = true;
      }
    },
    //有来电时铃抖动
    startRotate() {
      // clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.shrinkPacket = !this.shrinkPacket;
      }, 150);
    },
    //点击录音播放事件
    broadcast() {
      this.isBroadcast = false;
      this.isPlays = true;
      console.log("点击了");
    },
    //来电时的记时
    testTimer() {
      this.timer1 = setInterval(() => {
        this.timeFunction();
      }, 1000);
    },
    timeFunction() {
      // 开始执行倒计时
      this.timeObj = {
        // 时间对象
        seconds: Math.floor(this.currentTime % 60),
        minutes: Math.floor(this.currentTime / 60) % 60,
        hours: Math.floor(this.currentTime / 60 / 60) % 24,
      };
      // 计算出时分秒
      this.myHours =
        this.timeObj.hours < 10 ? "0" + this.timeObj.hours : this.timeObj.hours;
      this.myMinutes =
        this.timeObj.minutes < 10
          ? "0" + this.timeObj.minutes
          : this.timeObj.minutes;
      this.mySeconds =
        this.timeObj.seconds < 10
          ? "0" + this.timeObj.seconds
          : this.timeObj.seconds;
      this.currentTime++;
    },
    //通话时的记时
    testTimer1() {
      this.timer2 = setInterval(() => {
        this.timeFunction1();
      }, 1000);
    },
    timeFunction1() {
      // 开始执行倒计时
      this.timeObj1 = {
        // 时间对象
        seconds: Math.floor(this.currentTime1 % 60),
        minutes: Math.floor(this.currentTime1 / 60) % 60,
        hours: Math.floor(this.currentTime1 / 60 / 60) % 24,
      };
      // 计算出时分秒
      this.myHours1 =
        this.timeObj1.hours < 10
          ? "0" + this.timeObj1.hours
          : this.timeObj1.hours;
      this.myMinutes1 =
        this.timeObj1.minutes < 10
          ? "0" + this.timeObj1.minutes
          : this.timeObj1.minutes;
      this.mySeconds1 =
        this.timeObj1.seconds < 10
          ? "0" + this.timeObj1.seconds
          : this.timeObj1.seconds;
      this.currentTime1++;
    },
    //空闲或忙碌的记时
    testTimer2() {
      this.timer4 = setInterval(() => {
        this.timeFunction2();
      }, 1000);
    },
    timeFunction2() {
      // 开始执行倒计时
      this.timeObj2 = {
        // 时间对象
        seconds: Math.floor(this.currentTime2 % 60),
        minutes: Math.floor(this.currentTime2 / 60) % 60,
        hours: Math.floor(this.currentTime2 / 60 / 60) % 24,
      };
      // 计算出时分秒
      this.myHours2 =
        this.timeObj2.hours < 10
          ? "0" + this.timeObj2.hours
          : this.timeObj2.hours;
      this.myMinutes2 =
        this.timeObj2.minutes < 10
          ? "0" + this.timeObj2.minutes
          : this.timeObj2.minutes;
      this.mySeconds2 =
        this.timeObj2.seconds < 10
          ? "0" + this.timeObj2.seconds
          : this.timeObj2.seconds;
      this.currentTime2++;
      var s =
        Number(this.myHours2 * 3600) +
        Number(this.myMinutes2 * 60) +
        Number(this.mySeconds2);
      // console.log('0101:',s);
      if (s % 5 === 0 && this.isShowCommission == true) {
        this.backlog();
        this.waitFinsh(this.serviceId);
      } else if (s % 5 === 0 && this.isShowCommission == false) {
        this.waitFinsh(this.serviceId);
      }
    },
    // timeOuts() {
    //   var timeout = JSON.stringify({
    //     cno: this.cno,
    //     msgcode: "200",
    //     source: "",
    //     token: this.loginToken,
    //   });
    //   this.websocketsend(timeout);
    // },
    //点击树事件
    handleNodeClick(obj) {
      this.dataTitle = obj.title;
      this.dataLists = obj.text;
      //console.log("---", obj);
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.tabName = tab.label;
      //再次请求待我办理
      if (tab.label == "待我办理") {
        const audioElement = this.audioElement;
        if (audioElement && audioElement.length > 0) {
          for (let i = 0; i < audioElement.length; i++) {
            this.audioElement[i].pause();
          }
        }
        this.waitFinsh(this.serviceId);
      } else if (tab.label == "常用知识") {
        this.offenKnow();
        const audioElement = this.audioElement;
        if (audioElement && audioElement.length > 0) {
          for (let i = 0; i < audioElement.length; i++) {
            this.audioElement[i].pause();
          }
        }
      } else if (tab.label == "全部待办") {
        const audioElement = this.audioElement;
        if (audioElement && audioElement.length > 0) {
          for (let i = 0; i < audioElement.length; i++) {
            this.audioElement[i].pause();
          }
        }
        //请求全部待办
        this.backlog();
      } else if (tab.label == "通话记录") {
        this.dateTime();
        console.log("点击通话记录走到待我办理查询");
      }
    },
    //待我办理的完成
    handleEditTwo(index, row) {
      console.log(index, row, "19191919");
      if (this.textPhone == null && this.isListioning == false) {
        this.dialogVisibleFinish = true;
        this.customerIds = row.customerId;
        this.userId = row.customerId;
        let obj = {
          centerType: row.centerType,
          createId: row.createId,
          customerId: row.customerId,
          describe: row.describe,
          type: row.type,
          userId: row.userId,
          workNumber: row.workNumber,
          priority: row.priority,
          status: "1",
          id: row.id,
        };
        this.quFinish = obj;
      } else if (
        this.textPhone != null &&
        this.isListioning == false &&
        this.isListion == true
      ) {
        this.dialogVisibleFinish = true;
        this.customerIds = row.customerId;
        this.userId = row.customerId;
        let obj = {
          centerType: row.centerType,
          createId: row.createId,
          customerId: row.customerId,
          describe: row.describe,
          type: row.type,
          userId: row.userId,
          workNumber: row.workNumber,
          priority: row.priority,
          status: "1",
          id: row.id,
        };
        this.quFinish = obj;
      }
    },
    quitFinish() {
      this.dialogVisibleFinish = false;
      apiShout.getUpdateWorkOrder(this.quFinish).then((res) => {
        console.log("更新工单：", res.data);
        if (res.data.code == 200) {
          console.log("userid---------:", this.userId);
          //刷新历史工单
          this.getLists();
          //重新请求页面
          this.backlog();
          this.waitFinsh(this.serviceId);
          this.$message({
            message: "恭喜你，更新工单成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //全部待办的完成
    handleEdit(index, row) {
      console.log(index, row);
      this.$confirm("是否完成此工单?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.textPhone == null && this.isListioning == false) {
            this.userId = row.customerId;
            let obj = {
              centerType: row.centerType,
              createId: row.createId,
              customerId: row.customerId,
              describe: row.describe,
              type: row.type,
              userId: row.userId,
              workNumber: row.workNumber,
              priority: row.priority,
              status: "1",
              id: row.id,
            };
            apiShout.getUpdateWorkOrder(obj).then((res) => {
              console.log("更新工单：", res.data);
              if (res.data.code == 200) {
                //刷新历史工单
                this.historyOrders();
                //重新请求页面
                this.backlog();
                this.waitFinsh(this.serviceId);
                this.$message({
                  message: "恭喜你，更新工单成功",
                  type: "success",
                });
              }
            });
          } else if (
            this.textPhone != null &&
            this.isListioning == false &&
            this.isListion == true
          ) {
            this.userId = row.customerId;
            let obj = {
              centerType: row.centerType,
              createId: row.createId,
              customerId: row.customerId,
              describe: row.describe,
              type: row.type,
              userId: row.userId,
              workNumber: row.workNumber,
              priority: row.priority,
              status: "1",
              id: row.id,
            };
            apiShout.getUpdateWorkOrder(obj).then((res) => {
              console.log("更新工单：", res.data);
              if (res.data.code == 200) {
                //刷新历史工单
                this.historyOrders();
                //重新请求页面
                this.backlog();
                this.waitFinsh(this.serviceId);
                this.$message({
                  message: "恭喜你，更新工单成功",
                  type: "success",
                });
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消保存！",
          });
        });

      // //删除该行
      // this.tableData2.splice(index,1);
    },
    //全部待办的改派
    govern(index, row) {
      this.dialogVisible1 = true;
      this.quitedId = row.id;
      // this.reload();
    },
    quietChange() {
      this.dialogVisible1 = false;
      let obj = {
        userId: this.gaipei,
        status: "0",
        id: this.quitedId,
      };
      apiShout.getUpdateWorkOrder(obj).then((res) => {
        console.log("改派工单：", res.data);
        if (res.data.code == 200) {
          //重新请求页面
          this.backlog();
          this.$message({
            message: "恭喜你，改派工单成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
      // this.waitFinsh(this.serviceId);
    },
    handleClickRight(tab, event) {
      console.log(tab, event);
      if (tab.label == "远程协助") {
        // let aa = JSON.parse(localStorage.getItem("memberId"));
        // this.memberIdYuan = aa;
      }
    },
    //家庭成员列表
    getFamily() {
      // if (this.textPhone != null && this.flagdian != true) {
      //   let UniqueId = JSON.parse(localStorage.getItem("userId"));
      //   this.userId = UniqueId;
      // }
      // if(this.dianji==true){
      //   this.userId=this.jiUserD;
      //   this.dianji=false;
      // }
      apiShout.getfamUserMid({ memberId: this.userId }).then((res) => {
        console.log("家庭成员列表：", res.data);
        if (res.data.code == 200) {
          this.famData = res.data.result;
          for (let i = 0; i < this.famData.length; i++) {
            if (this.isTabs == true) {
              this.famData[i].phones = this.famData[i].phone;
            } else {
              this.famData[i].phones = this.famData[i].phone;
            }
            this.famData[i].phone =
              this.famData[i].phone.substr(0, 3) +
              "****" +
              this.famData[i].phone.substr(7);
          }
        } else {
          this.$message.error(res.data.msg);
          this.famData = [];
        }
      });
    },
    getsheList() {
      // if (this.textPhone != null && this.flagdian != true) {
      //   let UniqueId = JSON.parse(localStorage.getItem("userId"));
      //   this.userId = UniqueId;
      // }
      if (this.userId == null) {
        this.forDataList = [];
        return false;
      } else {
        apiShout.getbindEquipMem({ memberId: this.userId }).then((res) => {
          this.codeMsg = res.data.code;
          if (res.data.code === 200) {
            console.log("设备使用者信息：", res.data);
            if (res.data.result.bloodSugar != null) {
              let aa = res.data.result.bloodSugar.indexOf(",");
              // console.log('字符：',res.data.result.bloodSugar.indexOf(','));
              res.data.result.bloodSugar = res.data.result.bloodSugar.substr(
                aa + 1
              );
            }
            this.forDataList = res.data.result;
            this.eqfamliyId = res.data.result.familyId;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    getOld() {
      //设备维护
      // if (this.textPhone != null && this.flagdian != true) {
      //   let UniqueId = JSON.parse(localStorage.getItem("userId"));
      //   this.userId = UniqueId;
      // }
      // if(this.dianji==true){
      //   this.userId=this.jiUserD;
      //   this.dianji=false;
      // }
      apiShout.getmemEquip({ memberId: this.userId }).then((res) => {
        console.log("设备维护：", res.data);
        if (res.data.code == 200) {
          this.memData = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
        // this.falflagdian=false;
      });
    },

    changeIndex(val) {
      this.num = val;
    },
    // 将秒转为00:00:00格式
    format(seconds) {
      let hour =
        Math.floor(seconds / 3600) >= 10
          ? Math.floor(seconds / 3600)
          : "0" + Math.floor(seconds / 3600);
      seconds -= 3600 * hour;
      let min =
        Math.floor(seconds / 60) >= 10
          ? Math.floor(seconds / 60)
          : "0" + Math.floor(seconds / 60);
      seconds -= 60 * min;
      let sec = seconds >= 10 ? seconds : "0" + seconds;
      return hour + ":" + min + ":" + sec;
    },
    //查询待办工单
    backlog() {
      //查询待办工单
      apiShout.getCommission().then((res) => {
        // console.log("全部待办：", res.data);
        if (res.data.code === 200) {
          //对于日期的处理
          for (let i = 0; i < res.data.result.data.length; i++) {
            if (res.data.result.data[i].createDate == null) {
              res.data.result.data[i].createDate = "";
            } else {
              let a = res.data.result.data[i].createDate;
              let dd = new Date(a);
              let y = dd.getFullYear();
              let m = dd.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = dd.getDate();
              d = d < 10 ? "0" + d : d;
              let h = dd.getHours();
              let minute = dd.getMinutes();
              minute = minute < 10 ? "0" + minute : minute;
              let second = dd.getSeconds();
              second = second < 10 ? "0" + second : second;
              res.data.result.data[i].createDate =
                y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
            }
          }
          //对于剩余时间的处理
          this.remaining = res.data.result.data;
          this.tableData2 = res.data.result.data;
          this.tableData2.forEach((itemee) => {
            // console.log("====:", itemee.remainingTime);
            if (itemee.remainingTime == "0" || itemee.remainingTime == "") {
              itemee.remainingTime = "已到期";
            } else if (itemee.remainingTime != null) {
              itemee.remainingTime =
                Math.floor(itemee.remainingTime / 60) +
                "小时" +
                (itemee.remainingTime % 60) +
                "分";
            }
          });
          this.Allcount = res.data.result.data.length;
        } else if (res.data.code === 40001) {
          this.$message.error(res.data.msg);
          this.$router.push("/");
          //清除缓存
          window.localStorage.clear();
          window.sessionStorage.clear();
          //退出天润的登录
          ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
            // 传入参数对象
            var params = {};
            params.logoutMode = 1;
            params.removeBinding = 0;
            ClinkAgent.logout(params);
          });
          clearInterval(this.timer);
          clearInterval(this.timer1);
          clearInterval(this.timer2);
          // clearInterval(this.timer3);
          clearInterval(this.timer4);
          clearInterval(this.timer5);
          clearInterval(this.timer6);
        } else if (res.data.code === 40002) {
          this.$message.error(res.data.msg);
          this.$router.push("/");
          //清除缓存
          window.localStorage.clear();
          window.sessionStorage.clear();
          //退出天润的登录
          ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
            // 传入参数对象
            var params = {};
            params.logoutMode = 1;
            params.removeBinding = 0;
            ClinkAgent.logout(params);
          });
          clearInterval(this.timer);
          clearInterval(this.timer1);
          clearInterval(this.timer2);
          // clearInterval(this.timer3);
          clearInterval(this.timer4);
          clearInterval(this.timer5);
          clearInterval(this.timer6);
        } else if (res.data.code === 40003) {
          this.$message.error(res.data.msg);
          this.$router.push("/");
          //清除缓存
          window.localStorage.clear();
          window.sessionStorage.clear();
          //退出天润的登录
          ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
            // 传入参数对象
            var params = {};
            params.logoutMode = 1;
            params.removeBinding = 0;
            ClinkAgent.logout(params);
          });
          clearInterval(this.timer);
          clearInterval(this.timer1);
          clearInterval(this.timer2);
          // clearInterval(this.timer3);
          clearInterval(this.timer4);
          clearInterval(this.timer5);
          clearInterval(this.timer6);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //待我办理
    waitFinsh() {
      // serviceId
      // { userId: serviceId }
      //待我办理
      apiShout.getCommission().then((res) => {
        // console.log("待我办理：", res.data);
        if (res.data.code === 200) {
          //对于剩余时间的处理
          this.remaining = res.data.result.data;
          this.tableData = res.data.result.data;
          this.tableData.forEach((itemee) => {
            // console.log("====:", itemee.remainingTime);
            if (itemee.remainingTime == "0" || itemee.remainingTime == "") {
              itemee.remainingTime = "已到期";
            } else if (itemee.remainingTime != null) {
              itemee.remainingTime =
                Math.floor(itemee.remainingTime / 60) +
                "小时" +
                (itemee.remainingTime % 60) +
                "分";
            }
          });
          // if (this.textPhone == null && this.isListioning == false) {
          //   for (var i = 0; i < this.tableData.length; i++) {
          //     this.customerIds = this.tableData[i].customerId;
          //   }
          // } else if (
          //   this.textPhone != null &&
          //   this.isListioning == false &&
          //   this.isListion == true
          // ) {
          //   for (var j = 0; j < this.tableData.length; j++) {
          //     this.customerIds = this.tableData[j].customerId;
          //   }
          // }
          this.count = res.data.result.data.length;
        } else if (res.data.code === 40001) {
          this.$message.error(res.data.msg);
          this.$router.push("/");
          //清除缓存
          window.localStorage.clear();
          window.sessionStorage.clear();
          //退出天润的登录
          ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
            // 传入参数对象
            var params = {};
            params.logoutMode = 1;
            params.removeBinding = 0;
            ClinkAgent.logout(params);
          });
          clearInterval(this.timer);
          clearInterval(this.timer1);
          clearInterval(this.timer2);
          // clearInterval(this.timer3);
          clearInterval(this.timer4);
          clearInterval(this.timer5);
          clearInterval(this.timer6);
        } else if (res.data.code === 40002) {
          this.$message.error(res.data.msg);
          this.$router.push("/");
          //清除缓存
          window.localStorage.clear();
          window.sessionStorage.clear();
          //退出天润的登录
          ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
            // 传入参数对象
            var params = {};
            params.logoutMode = 1;
            params.removeBinding = 0;
            ClinkAgent.logout(params);
          });
          clearInterval(this.timer);
          clearInterval(this.timer1);
          clearInterval(this.timer2);
          // clearInterval(this.timer3);
          clearInterval(this.timer4);
          clearInterval(this.timer5);
          clearInterval(this.timer6);
        } else if (res.data.code === 40003) {
          this.$message.error(res.data.msg);
          this.$router.push("/");
          //清除缓存
          window.localStorage.clear();
          window.sessionStorage.clear();
          //退出天润的登录
          ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
            // 传入参数对象
            var params = {};
            params.logoutMode = 1;
            params.removeBinding = 0;
            ClinkAgent.logout(params);
          });
          clearInterval(this.timer);
          clearInterval(this.timer1);
          clearInterval(this.timer2);
          // clearInterval(this.timer3);
          clearInterval(this.timer4);
          clearInterval(this.timer5);
          clearInterval(this.timer6);
        } else {
          this.$message.error(res.data.msg);
        }
        // this.getLists();
      });
      // },
    },
    //查询用户历史工单
    getLists() {
      apiShout.getUserHistory({ customerId: this.customerIds }).then((dd) => {
        console.log("用户历史工单:", dd.data);
        if (dd.data.code === 200) {
          //对于日期的处理
          for (let i = 0; i < dd.data.result.length; i++) {
            // if(dd.data.result[i].typeDetail!=null||dd.data.result[i].typeDetail!=""){
            //   let mm = dd.data.result[i].typeDetail.slice(1,-1);
            //   console.log('1-1-1-1-1-1:',mm);
            //   dd.data.result[i].typeDetail = mm;
            // }
            if (dd.data.result[i].createDate == null) {
              dd.data.result[i].createDate = "";
            } else {
              let a = dd.data.result[i].createDate;
              let ds = new Date(a);
              let y = ds.getFullYear();
              let m = ds.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = ds.getDate();
              d = d < 10 ? "0" + d : d;
              let h = ds.getHours();
              let minute = ds.getMinutes();
              minute = minute < 10 ? "0" + minute : minute;
              let second = ds.getSeconds();
              second = second < 10 ? "0" + second : second;
              dd.data.result[i].createDate =
                y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
            }
          }
          this.historyList = dd.data.result;
        } else {
          this.$message.error(dd.data.msg);
        }
      });
    },
    //请求挂断的状态的接口
    endReason() {
      if (this.endReasonOne == "0" || this.endReasonOne == "1") {
        //隐藏接听按钮
        this.isAnswer = false;
        this.isListion = true;
        this.isListioning = false;
        this.zhuanjieimgs = false;
        this.zhuanjieimg = false;
        this.yiliaoPimgs = false;
        this.yiliaoPimg = false;
        this.jijiuPimgs = false;
        this.jijiuPimg = false;
        this.jinJiPhoneimgs = false;
        this.jinJiPhoneimg = false;
        this.offImgIcon = false;
        this.offImgIcons = false;
        this.textPhone = null;
        if (this.isListion == true) {
          // this.$message("通话记录正在加载，请耐心等待一分钟后查看！");
        }
        this.PersonstatusIng = false;
        clearInterval(this.timer5);
        //客服状态的计时
        clearInterval(this.timer4);
        this.currentTime2 = 0;
        this.testTimer2();
        //清除本地
        localStorage.removeItem("phone");
        // localStorage.removeItem("phoneNumber");
      }
    },
    //查询用户历史工单
    historyOrders() {
      apiShout.getUserHistory({ customerId: this.customerIds }).then((dd) => {
        console.log("用户历史工单:", dd.data);
        if (dd.data.code === 200) {
          //对于日期的处理
          for (let i = 0; i < dd.data.result.length; i++) {
            // if(dd.data.result[i].typeDetail!=''){
            //   let mm = dd.data.result[i].typeDetail.slice(1,-1);
            //   console.log('1-1-1-1-1-1:',mm);
            //   dd.data.result[i].typeDetail = mm;
            // }
            if (dd.data.result[i].createDate == null) {
              dd.data.result[i].createDate = "";
            } else {
              let a = dd.data.result[i].createDate;
              let ds = new Date(a);
              let y = ds.getFullYear();
              let m = ds.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              let d = ds.getDate();
              d = d < 10 ? "0" + d : d;
              let h = ds.getHours();
              let minute = ds.getMinutes();
              minute = minute < 10 ? "0" + minute : minute;
              let second = ds.getSeconds();
              second = second < 10 ? "0" + second : second;
              dd.data.result[i].createDate =
                y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
            }
          }
          this.historyList = dd.data.result;
        } else {
          this.$message.error(dd.data.msg);
        }
      });
    },
    //请求电话通话记录ID
    phoneID() {
      console.log(this.mainUniqueIds, "uniqueidssssss");
      if (this.mainUniqueIds == null || this.mainUniqueIds == "") {
        this.$message.error("获取通话信息中，请稍后提交");
        return;
      }
      // if (this.type == "1") {
      //   this.finish();
      // } else if (this.type == "2") {
      //   this.finish1();
      // } else if (this.type == "3") {
      //   this.finish2();
      // } else if (this.type == "4") {
      //   this.finish3();
      // } else if (this.type == "5") {
      //   this.finish4();
      // }
    },
    handleCloseFinish() {
      this.dialogVisibleFinish = false;
    },
    getUserInfor() {
      //查询用户信息
      apiShout.getMemberInfoByIds({ id: this.customerIds }).then((dat) => {
        console.log("用户信息：", dat.data);
        if (dat.data.code === 200) {
          this.regionTong = [];
          //对于生日的处理
          let a = dat.data.result.member.birthday;
          let dd = new Date(a);
          let y = dd.getFullYear();
          let m = dd.getMonth() + 1;
          m = m < 10 ? "0" + m : m;
          let d = dd.getDate();
          d = d < 10 ? "0" + d : d;
          dat.data.result.member.birthday = y + "-" + m + "-" + d;
          //对于激活日期的处理
          let b = dat.data.result.member.createDate;
          let dd1 = new Date(b);
          let y1 = dd1.getFullYear();
          let m1 = dd1.getMonth() + 1;
          m1 = m1 < 10 ? "0" + m1 : m1;
          let d1 = dd1.getDate();
          d1 = d1 < 10 ? "0" + d1 : d1;
          dat.data.result.member.createDate = y1 + "-" + m1 + "-" + d1;
          //对于到期日期的处理
          let c = dat.data.result.member.updateDate;
          let dd2 = new Date(c);
          let y2 = dd2.getFullYear();
          let m2 = dd2.getMonth() + 1;
          m2 = m2 < 10 ? "0" + m2 : m2;
          let d2 = dd2.getDate();
          d2 = d2 < 10 ? "0" + d2 : d2;
          dat.data.result.member.updateDate = y2 + "-" + m2 + "-" + d2;
          this.formMember = dat.data.result.member;
          this.xinLog = this.formMember.lon;
          this.xinLat = this.formMember.lat;
          this.xinLoc = this.formMember.location;
          this.formMembers = dat.data.result.member;
          this.familyIds = dat.data.result.member.familyId;
          console.log(this.familyIds, "123123123");
          this.emergencyOnes = dat.data.result.member.emergencyOne;
          this.emergencyTwos = dat.data.result.member.emergencyTwo;
          this.ursents = [];
          this.ursentsTwo = [];
          apiShout.getFamilyUsers({ fId: this.familyIds }).then((res) => {
            if (res.data.code === 200) {
              console.log(res, "紧急联系人");
              // this.ursents = res.data.result
              res.data.result.forEach((item) => {
                this.ursents.push(item);
                this.ursentsTwo.push(item);
                this.region.push(item);
                // console.log(this.region, "紧急联系人111111");
              });

              for (var i = 0; i < this.ursents.length; i++) {
                this.userPhone = this.ursents[0];
                this.userPhones = this.ursents[1];
                // if (this.userPhone.id === this.emergencyOnes) {
                // console.log(
                //   this.userPhone.phone,
                //   this.userPhone.userName,
                //   "this.userPhone.phone"
                // );
                this.formMember.emergencyContactOnePhone = this.userPhone.phone;
                this.formMember.emergencyContactOne = this.userPhone.userName;
                let str = this.formMember.emergencyContactOnePhone;
                this.formMember.emergencyContactOnePhone = str.replace(
                  /(\d{3})\d{4}(\d{1})/,
                  "$1****$2"
                );
                // let arrss = [];
                let arrText = {};
                arrText.userName = this.userPhone.userName;
                arrText.phone = this.userPhone.phone;
                this.regionTong.push(arrText);
                this.formMember.emergencyContactTwoPhone =
                  this.userPhones.phone;
                this.formMember.emergencyContactTwo = this.userPhones.userName;
                let str1 = this.formMember.emergencyContactTwoPhone;
                this.formMember.emergencyContactTwoPhone = str1.replace(
                  /(\d{3})\d{4}(\d{1})/,
                  "$1****$2"
                );
                // let arrss = [];
                // let arrText = {};
                // arrText.userName = this.userPhones.userName;
                // arrText.phone = this.userPhones.phone;
                // this.regionTong.push(arrText);
                // this.region.push(arrText);
                // }
              }
              // for (let i = 0; i < this.ursentsTwo.length; i++) {
              //   this.userPhones = this.ursentsTwo[i];
              //   if (this.userPhones.id === this.emergencyTwos) {
              //     console.log(
              //       this.userPhones.phone,
              //       this.userPhones.userName,
              //       "this.userPhones.userName"
              //     );
              //     this.formMember.emergencyContactTwoPhone =
              //       this.userPhones.phone;
              //     this.formMember.emergencyContactTwo =
              //       this.userPhones.userName;
              //     let str = this.formMember.emergencyContactTwoPhone;
              //     this.formMember.emergencyContactTwoPhone = str.replace(
              //       /(\d{3})\d{4}(\d{1})/,
              //       "$1****$2"
              //     );
              //     // let arrss = [];
              //     let arrText = {};
              //     arrText.userName = this.userPhones.userName;
              //     arrText.phone = this.userPhones.phone;
              //     this.regionTong.push(arrText);
              //     // this.region.push(arrText);
              //   }
              // }
            } else {
              this.$message.error(res.data.msg);
            }
          });
          this.Sphone = dat.data.result.member.sparePhone;
          console.log(this.Sphone, "备用手机号查看");
          if (this.Sphone != null) {
            this.StandbyIcon = true;
            this.StandbyIcons = false;
          } else {
            this.StandbyIcon = false;
            this.StandbyIcons = true;
          }
          let str = this.formMember.sparePhone;
          if (str !== null) {
            this.formMember.NewsparePhone = str.replace(
              /(\d{3})\d{4}(\d{1})/,
              "$1****$2"
            );
          }
          // this.formMember.sparePhone =
          //   this.formMember.sparePhone.substr(0, 3) +
          //   "****" +
          //   this.formMember.sparePhone.substr(7);
          this.formMemberInfo = dat.data.result.memberInfo;
          if (dat.data.result.memberInfo.illness == null) {
            this.isZhan1 = false;
            this.isZhan = false;
            this.inputman = "";
          } else {
            this.isZhan1 = true;
          }
          this.cityName = dat.data.result.member.city;
          this.countyName = dat.data.result.member.county;
          this.cityId = dat.data.result.member.city;
          this.countyId = dat.data.result.member.county;
          this.emergencyOnes = dat.data.result.member.emergencyOne;
          this.emergencyTwos = dat.data.result.member.emergencyTwo;
          console.log(
            this.emergencyOnes,
            this.emergencyTwos,
            "查询紧急联系人的匹配值"
          );
          // console.log(CodeToText[this.countyName], "CodeToText123");
          // console.log(CodeToText[this.cityName], "CodeToText11111");
          this.formMember.city = CodeToText[this.cityName];
          this.formMember.county = CodeToText[this.countyName];
          if (this.infoFlag == true) {
            this.formMember.city = dat.data.result.member.city;
            this.formMember.county = dat.data.result.member.county;
          }
          this.formMember.birthday = dat.data.result.member.birthday;
          this.elderlyId = dat.data.result.member.id;
          // this.formMemberInfo.live = dat.data.result.memberInfo.live;
          this.formMemberInfo.intellect = dat.data.result.memberInfo.intellect;
          this.formMemberInfo.character = dat.data.result.memberInfo.character;
          this.formMemberInfo.language = dat.data.result.memberInfo.language;
          this.formMemberInfo.hearing = dat.data.result.memberInfo.hearing;
          this.formMemberInfo.vision = dat.data.result.memberInfo.vision;
          this.formMemberInfo.action = dat.data.result.memberInfo.action;
          this.formMemberInfo.illness = dat.data.result.memberInfo.illness;
          // //健康数据
          // this.forBody = dat.data.result.memberInfo;
          // for (let i = 0; i < dat.data.result.remind.length; i++) {
          //   this.morningbefor = dat.data.result.remind[0];
          //   this.morningafter = dat.data.result.remind[1];
          //   this.lunchbefor = dat.data.result.remind[2];
          //   this.lunchafter = dat.data.result.remind[3];
          //   this.eveningbefor = dat.data.result.remind[4];
          // }
        } else {
          this.$message.error(dat.data.msg);
        }
        this.imgIconBtns = false;
        this.imgIconBtnOne = false;
        this.imgJinJi = true;
        console.log(this.familyIds, "家庭id");
        // this.ursents = [];
        // this.ursentsTwo = [];
        // this.region = [];
        // this.regionTong = [];
        // this.regions = "";
        // apiShout.getFamilyUsers({ fId: this.familyIds }).then((res) => {
        //   if (res.data.code === 200) {
        //     console.log(res, "紧急联系人");
        //     // this.ursents = res.data.result
        //     res.data.result.forEach((item) => {
        //       this.ursents.push(item);
        //       this.ursentsTwo.push(item);
        //       // this.region.push(item);
        //       // console.log(this.region, "紧急联系人111111");
        //     });

        //     for (var i = 0; i < this.ursents.length; i++) {
        //       this.userPhone = this.ursents[i];

        //       if (this.userPhone.id === this.emergencyOnes) {
        //         console.log(
        //           this.userPhone.phone,
        //           this.userPhone.userName,
        //           "this.userPhone.phone"
        //         );
        //         this.formMember.emergencyContactOnePhone =
        //           this.userPhone.phone;
        //         this.formMember.emergencyContactOne = this.userPhone.userName;
        //         let str = this.formMember.emergencyContactOnePhone;
        //         this.formMember.emergencyContactOnePhone = str.replace(
        //           /(\d{3})\d{4}(\d{1})/,
        //           "$1****$2"
        //         );
        //         // let arrss = [];
        //         let arrText = {};
        //         arrText.userName = this.userPhone.userName;
        //         arrText.phone = this.userPhone.phone;
        //         this.regionTong.push(arrText);
        //         this.region.push(arrText);
        //       }
        //     }
        //     for (let i = 0; i < this.ursentsTwo.length; i++) {
        //       this.userPhones = this.ursentsTwo[i];
        //       if (this.userPhones.id === this.emergencyTwos) {
        //         console.log(
        //           this.userPhones.phone,
        //           this.userPhones.userName,
        //           "this.userPhones.userName"
        //         );
        //         this.formMember.emergencyContactTwoPhone =
        //           this.userPhones.phone;
        //         this.formMember.emergencyContactTwo =
        //           this.userPhones.userName;
        //         let str = this.formMember.emergencyContactTwoPhone;
        //         this.formMember.emergencyContactTwoPhone = str.replace(
        //           /(\d{3})\d{4}(\d{1})/,
        //           "$1****$2"
        //         );
        //         // let arrss = [];
        //         let arrText = {};
        //         arrText.userName = this.userPhones.userName;
        //         arrText.phone = this.userPhones.phone;
        //         this.regionTong.push(arrText);
        //         this.region.push(arrText);
        //       }
        //     }
        //   } else {
        //     this.$message.error(res.data.msg);
        //   }
        // });
      });
      let allEq = JSON.parse(localStorage.getItem("strequipmentIds"));
      apiShout
        .geteqMem({ equipmentId: allEq, memberId: this.customerIds })
        .then((ss) => {
          console.log("查询服务项：", ss.data.result);
          if (ss.data.code == 200) {
            if (ss.data.result != null) {
              this.isSer = true;
              this.serverType = ss.data.result;
              if (this.serverType.basicStatus == 1) {
                this.serverType.basicEnd = "未开通";
              } else if (this.serverType.basicStatus == 3) {
                this.serverType.basicEnd = "已过期";
              }
              if (this.serverType.careStatus == 1) {
                this.serverType.careEnd = "未开通";
              } else if (this.serverType.careStatus == 3) {
                this.serverType.careEnd = "已过期";
              }
              if (this.serverType.ymStatus == 1) {
                this.serverType.ymEnd = "未开通";
              } else if (this.serverType.ymStatus == 3) {
                this.serverType.ymEnd = "已过期";
              }
              if (this.serverType.contactStatus == 1) {
                this.serverType.contactEnd = "未开通";
              } else if (this.serverType.contactStatus == 3) {
                this.serverType.contactEnd = "已过期";
              } else if (this.serverType.contactStatus == 2) {
                this.serverType.contactEnd = "长期有效";
              }
              if (this.serverType.healthStatus == 1) {
                this.serverType.healthEnd = "未开通";
              } else if (this.serverType.healthStatus == 3) {
                this.serverType.healthEnd = "已过期";
              }
              if (this.serverType.lawStatus == 1) {
                this.serverType.lawEnd = "未开通";
              } else if (this.serverType.lawStatus == 3) {
                this.serverType.lawEnd = "已过期";
              }
              if (this.serverType.cnoStatus == 1) {
                this.serverType.cnoEnd = "未开通";
              } else if (this.serverType.cnoStatus == 3) {
                this.serverType.cnoEnd = "已过期";
              }
            } else {
              this.isSer = false;
            }
          }
        });
    },
    refresh() {
      this.getUserInfor();
    },
    //常用知识
    offenKnow() {
      //获取常用知识
      apiShout.getKnowledge().then((res) => {
        console.log("常用知识：", res.data);
        if (res.data.code === 200) {
          this.dataList = res.data.result;
          this.dataLists = res.data.result;
          if (this.dataLists.length > 0) {
            for (var i = 0; i < this.dataLists.length; i++) {
              for (var j = 0; j < this.dataLists[i].childs.length; j++) {
                this.dataLists = this.dataLists[i].childs[0].text;
                this.dataTitle = this.dataLists[i].childs[0].title;
              }
            }
          } else {
            this.dataLists = "";
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  beforeDestroy() {
    // this.close();
    // localStorage.clear("cno");
    // localStorage.clear("userId");
    clearTimeout(this.setTimer);
    clearTimeout(this.setOffTimer);
    clearInterval(this.timer);
    clearInterval(this.timer1);
    clearInterval(this.timer2);
    clearInterval(this.timer4);
    clearInterval(this.timers);
    clearInterval(this.timer6);
    clearInterval(this.tokenM);
  },
};
</script>

<style lang='less' scoped>
//搜索弹出框高度限制
.el-table {
  font-size: 14px;
  color: #606266;
  max-height: 730px;
  overflow: auto;
}
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 800px;
}
.amap-icon img {
  width: 30px;
  height: 30px;
}

.amap-marker-label {
  border: 0;
  background-color: transparent;
}
.shenti {
  position: absolute;
  left: 260px;
  top: 35px;
}
.base-niceng {
  float: left;
}
.base-imgs {
  float: left;
}
.base-images {
  float: left;
  width: 50px;
  height: 28px;
  margin-left: 30px;
}
.tang {
  color: #c4b8b5;
  font-size: 18px;
}
/deep/.el-table .warning-row {
  background: #f09c83 !important;
}
//通话记录里的日期的样式
/deep/ .tonghua .el-input__inner {
  width: 65%;
  color: rgb(185, 182, 182);
}
/deep/ .tonghua .el-icon-date:before {
  content: none;
}
/deep/ .tonghua .el-date-editor {
  text-align: right;
}
/deep/ .tonghua .icon-triangle-right:before {
  position: absolute;
  left: 360px;
  top: 2px;
}
/deep/ .tonghua .icon-triangle-left:before {
  position: absolute;
  left: 225px;
  top: 2px;
}
//标记
.badge {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 25px;
  position: absolute;
  font-size: 18px;
  left: 195px;
  top: -19px;
  // top: 73px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
  padding: 3px 3px;
}
.badges {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 25px;
  position: absolute;
  font-size: 20px;
  left: 195px;
  top: -19px;
  // top: 0px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
.badge1 {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 423px;
  top: -19px;
  // top: 73px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
.badge1s {
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 423px;
  top: -19px;
  // top: 0px;
  border-radius: 50%;
  background: #d78673;
  z-index: 999;
}
// 最上边
.center-top {
  position: relative;
  margin: 0;
  height: 80px;
  line-height: 50px;
}
.top-name {
  font-size: 30px;
  color: #92aca7;
  margin-left: 20px;
  float: left;
}
.lisitions {
  width: 60px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background: #76b935;
  color: #fff;
  border-radius: 20px;
  float: left;
  margin: 15px 10px 10px 3px;
  font-size: 14px;
}
.top-tome {
  float: left;
}
.top-tu {
  display: inline-block;
  margin-left: -18px;
  margin-top: -15px;
}
.top-tu-one {
  float: left;
  margin-top: 15px;
}
.top-tu-one:nth-child(2) {
  margin-left: 15px;
}
.top-tu-one:last-child {
  margin-left: 13px;
}
.top-tu:first-child {
  margin-left: 50px;
}
.top-rig {
  float: right;
}
.top-right {
  float: left;
  .r1 {
    transform: rotate(-5deg);
  }
  .r2 {
    transform: rotate(3deg);
  }
}
.top-time {
  float: left;
  font-size: 17px;
  color: rgb(197, 193, 193);
}
.top-jie {
  float: left;
}
.top-zhuangtai {
  float: left;
  font-size: 17px;
  color: rgb(197, 193, 193);
}
.top-zhuangtai1 {
  float: right;
  font-size: 17px;
  color: rgb(197, 193, 193);
}
.my-zhuangtai {
  margin-right: 20px;
}
.top-tonghua {
  position: absolute;
  top: 30px;
  right: 30px;
}
//下左边
.bottom {
  font-size: 19px;
  color: #d78673;
  margin: 10px 10px 10px 0px;
  display: flex;
  overflow-x: auto;
  padding: 20px 0px 0px 0px;
}
// .bottom::-webkit-scrollbar {
//     width: 0 !important;
//   }
.bottom-left {
  flex: 1;
  // width: 60%;
  margin-right: 10px;
  // height: 900px;
  position: relative;
}
.bottom-right {
  flex: 1;
  height: 900px;
}
.home .el-aside {
  background: #fff;
}
/deep/ .el-tabs__item {
  color: #d78673;
  font-size: 18px;
}
/deep/ .el-tabs__item.is-active {
  color: #fff;
  background-color: #d78673;
}
/deep/ .el-tree-node__label.aside-tree {
  color: #fff;
  background-color: #d78673;
}
/deep/ .el-input__inner {
  height: 30px;
  margin-bottom: 10px;
  // margin-left: 10px;
  //width: 230px;
}
/deep/ .el-tree-node__label {
  color: #92aca7;
  font-size: 18px;
}
.nav > div[data-v-232c23e3] {
  padding: 15px;
  border: 1px solid #eee;
}
.active[data-v-01042540] {
  color: #af6e5f;
  background: #d78673;
}
// tabs下边的边框
/deep/ .el-tabs__content {
  border: 1px solid #eee;
  height: 730px;
}
.search {
  display: flex;
  width: 300px;
  font-size: 16px;
  .imgs {
    width: 53px;
    height: 31px;
    margin-left: 10px;
  }
}
.left {
  display: flex;
  margin: 10px 0;
  font-size: 16px;
  color: #a8a8a8;
  .el-tabs--left .el-tabs__item.is-left {
    text-align: center;
  }
}
/deep/ .el-tree-node__label.aside-tree {
  color: #fff;
  background-color: #d78673;
}
.tonghua {
  text-align: center;
  color: #c0bdbd;
  height: 35px;
  line-height: 30px;
}
.right {
  padding: 15px;
  line-height: 23px;
  white-space: pre-wrap;
}
/deep/ .el-table th.el-table__cell > .cell {
  background-color: #92aca7 !important;
  color: #fff !important;
  font-size: 17px;
  padding: 10px;
}
/deep/ .el-table tr:nth-child(odd) {
  background-color: #e5f4f1;
}
/deep/ .el-table tr:nth-child(even) {
  background-color: #fff;
}
/deep/ .el-table th {
  background-color: #92aca7;
  color: #fff;
}
.luyin {
  color: #d78673;
}
// 下右边
//历史工单
.history {
  width: 95%;
  border: 1px solid #f0eeee;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  margin: 10px;
  padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.historydan {
  color: #92aca7;
  font-size: 19px;
  font-weight: bolder;
  margin-bottom: 10px;
}
.history-ul {
  height: 350px;
  //background-color: #d78673;
  overflow: auto;
}
.suggest {
  width: 80px;
  height: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #92aca7;
  color: #fff;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  margin: 10px;
}
.suggest1 {
  // width: 80px;
  height: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  // background-color: #e5f4f1;
  color: #a8a8a8;
  font-size: 15px;
  text-align: left;
  line-height: 20px;
  margin: 10px;
}
.person {
  color: #92aca7;
  font-size: 16px;
  margin: 10px 5px 10px 20px;
}
.person-id {
  color: #92aca7;
  font-size: 16px;
  margin: 10px 5px 10px 20px;
}
.times {
  color: rgb(192, 188, 188);
  font-size: 14px;
}
.content {
  color: rgb(192, 188, 188);
  font-size: 14px;
  margin: 0px 0px 10px 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d6d4d4;
}
//历史工单的 创建工单
.historydan1 {
  color: #92aca7;
  font-size: 19px;
  font-weight: bolder;
  display: inline-block;
}
.create-order {
  width: 95%;
  border: 1px solid #f0eeee;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  margin: 10px;
  padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.infor-content {
  display: flex;
}
.inforwrite {
  width: 325px;
  height: 200px;
  border: 1px solid #d6d4d4;
}
.history-phone {
  margin-left: 20px;
  color: rgb(221, 216, 216);
  font-size: 16px;
}
.fenglei {
  color: #92aca7;
  font-size: 15px;
  margin-left: 20px;
}
.radio-dan {
  margin: 10px 10px 10px 20px;
}
.his-right {
  margin: 0px 10px 10px 10px;
}
.infor-bottom {
  margin-top: 10px;
}
.benji-phone {
  margin-left: 10px;
  color: rgb(199, 196, 196);
  font-size: 16px;
}
.buttons {
  width: 60px;
  height: 30px;
  // line-height: 30px;
  text-align: center;
  padding: 5px;
  border-radius: 25px;
  background-color: #d78673;
  color: #ffffff;
  font-size: 14px;
  display: inline-block;
  margin: 10px;
}
//信息查询
.right-big {
  margin: 5px;
  border: 1px solid #f0eeee;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  padding: 10px 10px 79px 10px;
  // width: 600px;
  float: left;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.infor-top {
  height: 30px;
}
.baseinfor {
  font-size: 14px;
  color: #92aca7;
  margin-top: 5px;
  margin-right: 50px;
  float: left;
  // display: flex;
  // flex-wrap: nowrap;
  // align-items: center;
  // justify-content: space-between;
}
.base-niceng {
  float: left;
}
.base-imgs {
  float: left;
}
.base-images {
  float: left;
  width: 50px;
  height: 28px;
  margin-left: 30px;
}
.base-content {
  height: 390px;
}
//调小距离
[class*="el-col-"] {
  height: 40px;
}
.faimal {
  margin-right: 5px;
}
.inforbirth {
  position: absolute;
  left: 22px;
  top: 182px;
}
.inforbirths {
  position: absolute;
  left: 42%;
  top: 182px;
}
.xiao {
  position: absolute;
  left: 22px;
  top: 230px;
}
.dengji {
  position: absolute;
  left: 22px;
  top: 280px;
}
.inforInput {
  position: absolute;
  left: 100px;
  top: 320px;
  width: 269px;
  border-color: #eee;
}
.shiji {
  position: absolute;
  left: 20px;
  top: 370px;
}
.shijibox {
  //border: 1px solid #ebeaea;
  font-size: 14px;
  color: #b9b3b3;
  width: 300px;
  margin-top: 10px;
  line-height: 20px;
}
.jihuo {
  position: absolute;
  left: 400px;
  top: 318px;
}
.daoqi {
  position: absolute;
  left: 595px;
  top: 318px;
}
/deep/ .el-form--inline .el-form-item__label {
  font-size: 14px;
  font-weight: bolder;
  color: #a8a8a8;
}
//生活状况
.life-status [class*="el-col-"] {
  float: left;
}
.life-zhuangtai {
  color: #92aca7;
  font-size: 14px;
}
.big-center {
  display: flex;
}
.left,
.life-status,
.jiangkang {
  background: #ffffff;
  border-radius: 15px;
  position: relative;
  // margin: 0 10px;
  flex: 1;
  table {
    width: 100%;
    margin-top: 20px;
    tr {
      // margin-bottom: 17px;
      display: flex;
      td:first-child {
        color: #a8a8a8;
        font-weight: bolder;
        font-size: 14px;
        width: 80px;
      }
      td:nth-child(2) {
        font-size: 14px;
        color: #a8a8a8;
        padding-right: 15px;
        flex: 1;
      }
      td {
        .tdspan {
          line-height: 35px;
        }
        .el-select {
          width: 100%;
          .el-input__inner {
            border: 1px solid #ffffff;
            color: #c8c8c8;
            font-size: 16px;
          }
        }
      }
      .infor-img {
        margin-left: 110px;
      }
    }
  }
}
// 健康数据
.jiangkang {
  float: left;
  margin-left: 20px;
}
//保存
.baocun {
  position: absolute;
  left: 550px;
  bottom: 5px;
}
//tabs右浮
/deep/ .bottom-right .el-tabs__nav-scroll {
  float: right;
}
//表格里的内容居中对齐
/deep/ .el-table td.el-table__cell div {
  text-align: center;
}
/deep/ .el-table .cell {
  text-align: center;
}
//创建工单的 相关联的人员
.guan {
  img {
    width: 20px;
  }
  span {
    font-size: 14px;
    margin-left: 10px;
  }
}
.callBackPhone {
  font-size: 14px;
}
//接听时的计时器样式
.timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 0em;
  color: #c2bebe;
}
.time-card {
  margin: 0 5px;
  text-align: center;
}
.time-card-count {
  font-size: 14px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
}
.time-card-label {
  font-size: 0.625em;
  text-transform: uppercase;
  opacity: 0.7;
}
audio {
  width: 100%;
  height: 40px;
  outline: none;
  filter: none;
}

audio::-webkit-media-controls-enclosure {
  background: none;
  border-radius: 4px;
}
.media-controls-container,
.media-controls-container * {
  background: none;
  border-radius: 4px;
}
audio::-webkit-media-controls-play-button {
  height: 22px;
  width: 22px;
  min-width: 22px;
  border-radius: 50%;
  flex-basis: 22px;
}
audio::-webkit-media-controls-volume-control-container {
  display: none;
}

audio::-webkit-media-controls-current-time-display {
  order: 1;
  color: #000;
  text-shadow: unset;
}

audio::-webkit-media-controls-time-remaining-display {
  order: 2;
  color: rgba(0, 0, 0, 0.6);
  text-shadow: unset;
}
/deep/ .el-table .sort-caret.ascending {
  top: -13px;
}
/deep/ .el-table .sort-caret.descending {
  bottom: -10px;
}
/deep/ .el-table .caret-wrapper {
  height: auto;
}
.fourCall {
  font-size: 20px;
  margin-right: 10px;
  color: #d78673;
}
//本页面的tab样式
/deep/ .el-tabs__item {
  border: 1px solid #e4e7ed !important;
  border-left: 1px solid #e4e7ed !important;
  outline: none;
}
/deep/.el-tabs__item.is-active {
  color: #fff !important;
  border: none;
  font-size: 18px !important;
}
/deep/.el-tabs__item:hover {
  color: #d78673 !important;
  font-size: 18px !important;
}
/deep/.el-dialog__body {
  padding: 0 0 14px 0;
  margin: 10px;
}
/deep/.el-dialog__wrapper {
  position: absolute;
  top: 12%;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
}
.tellLink /deep/.el-dialog {
  margin-top: 0 !important;
}
.tellLink /deep/.el-dialog__body {
  margin: 17px 23px 0 23px;
}
@media all and (min-width: 640px) {
}
@media all and (min-width: 768px) {
}
@media all and (min-width: 800px) {
}
@media all and (min-width: 1050px) {
}
@media all and (min-width: 1100px) {
}
@media all and (min-width: 1280px) {
}
@media all and (min-width: 1281px) and (min-width: 1365px) {
}
@media all and (min-width: 1366px) and (min-width: 1440px) {
}
@media all and (min-width: 1441px) and (min-width: 1680px) {
}
@media all and (min-width: 1681px) and (min-width: 1920px) {
}
@media all and (min-width: 1921px) {
}
</style>