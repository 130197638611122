<template>
  <div>
    <div class="big">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="客服中心" name="1" v-if="isKeFu">
          <allService
            ref="serverRef"
            @changeEvent="changeEvent"
            @serStatus="serStatus"
            :fourserStatusee="fourserStatusee"
            :twoType2="twoType2"
            @hereListion="hereListion"
            :fourLis="fourLis"
            :passTiao="passTiao"
          ></allService>
        </el-tab-pane>
        <el-tab-pane label="400中心" name="2" v-if="isFour">
          <four
            ref="fourRef"
            :twoType="twoType"
            :serStatusee="serStatusee"
            @fourserStatus="fourserStatus"
            @changeEvent2="changeEvent2"
            :seHere="seHere"
            @fourHere="fourHere"
          ></four>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import allService from "./allService.vue";
import four from "./four.vue";
export default {
  components: { allService, four },
  data() {
    return {
      activeName: "1",
      loginTokens: "",
      cnoOne: "",
      twoType: "",
      twoType2: "",
      serStatusee: "",
      isKeFu: true,
      isFour: false,
      fourserStatusee: "",
      seHere: false,
      fourLis: false,
      passTiao: false,
      num: 0,
      num1: 0,
    };
  },
  created() {
    let cus = JSON.parse(localStorage.getItem("cusSer"));
    if (cus == "1") {
      this.localSocket();
    } else {
      clearInterval(this.timer);
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      // clearInterval(this.timer3);
      clearInterval(this.timer4);
      clearInterval(this.timers);
      // clearInterval(this.timer5);
      clearInterval(this.timer6);
      clearInterval(this.timer7);
    }
  },
  methods: {
    changeEvent(val) {
      this.num1++;
      this.isKeFu = false;
      this.isFour = true;
      this.$nextTick(() => {
        this.activeName = val;
        if (this.num1 > 1) {
          this.$refs.fourRef.zhuang();
          this.$refs.fourRef.listion();
        } else {
          this.twoType = val;
        }
      });
    },
    changeEvent2(value) {
      this.num++;
      this.isKeFu = true;
      this.isFour = false;
      this.passTiao = true;
      this.$nextTick(() => {
        this.activeName = value;
        if (this.num > 1) {
          this.$refs.serverRef.zhuang();
          this.$refs.serverRef.listion();
        } else {
          this.twoType2 = value;
        }
      });
    },
    fourHere(aa) {
      // this.activeName = "1";
      this.fourLis = aa;
    },
    hereListion(va) {
      // this.activeName = "2";
      this.seHere = va;
    },
    serStatus(aa) {
      this.serStatusee = aa;
    },
    fourserStatus(bb) {
      this.fourserStatusee = bb;
    },
    handleClick(tab, event) {
      console.log("tab,event", tab.name, event);
      let ph = JSON.parse(localStorage.getItem("phoneNumber"));
      console.log("ph===", ph);
      if (ph == null) {
        if (tab.name == "1") {
          this.activeName = "2";
          this.isKeFu = false;
          this.isFour = true;
          clearInterval(this.timer4);
        } else {
          this.activeName = "1";
          this.isKeFu = true;
          this.isFour = false;
          this.passTiao = true;
        }
      } else {
        this.$message({
          message: "不好意思，客服正忙，不能跳转页面",
          type: "success",
        });
      }
    },
    //连接websocket
    localSocket() {
      let that = this;
      let loginUserId = JSON.parse(localStorage.getItem("loginuser"));
      that.loginTokens = loginUserId.token;
      //获取cno
      let cno = JSON.parse(localStorage.getItem("cno"));
      that.cnoOne = cno;
      console.log(that.cnoOne, "that.cnoOne");
      if ("WebSocket" in window) {
        // console.log("您的浏览器支持 WebSocket!");
        // location.host
        that.ws = new WebSocket("wss://dev.careld.cn/socket");
        // that.ws = new WebSocket("wss://erp.careld.cn/socket");
        that.global.setWs(that.ws);
        // window.onbeforeunload = function (event) {
        //   console.log(event, "111");
        //   console.log("您正在刷新网页1211");
        //   this.global.ws.onclose();
        //   return "Are you sure?";
        // };
        that.ws.onopen = function () {
          console.log(that.ws, "查询");
          console.log("websocket连接成功");
          var objn = JSON.stringify({
            cno: that.cnoOne,
            msgcode: 201,
            token: that.loginTokens,
          });
          console.log(that.global, "this.global");
          // console.log(that.ws, "this.global.ws");
          that.global.ws.send(objn);
        };
        this.global.ws.onmessage = this.websocketonmessage;
        this.global.ws.onclose = this.websocketclose;
        that.ws.onclose = function () {
          // 关闭 websocket
          console.log("连接已关闭...");
          that
            .$http({
              url: "/too/center/weblog/save",
              params: {
                callback: "",
                errorCode: "",
                errorExplain: "连接已关闭，尝试重新连接",
                interfaceUrl: that.ws.url,
                menuName: "客服系统-webSocket链接",
                menuUrl: that.$route.path,
                params: "",
                source: "客服系统",
                userId: loginUserId.id,
                userName: loginUserId.name,
              },
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
              method: "get",
            })
            .then((res) => {
              if (res.data.code === 200) {
                console.log();
              }
            });
          //断线重新连接
          setTimeout(() => {
            that.localSocket();
          }, 2000);
        };
      } else {
        // 浏览器不支持 WebSocket
        console.log("您的浏览器不支持 WebSocket!");
        this.openNotificationWithIcon(
          "error",
          "浏览器",
          "您的浏览器不支持显示消息请更换",
          1,
          1
        );
      }
    },
    async websocketonmessage(e) {
      // console.log("服务器返回:" + e);
      var str = e.data;
      var strname = JSON.parse(str);
      // 自定义全局监听事件
      window.dispatchEvent(
        new CustomEvent("onmessageWS", {
          detail: {
            data: e.data,
          },
        })
      );
      if (e.data && strname.msgcode != 100) {
        var messagelist = JSON.stringify({
          cno: this.cnoOne,
          msgcode: 200,
          source: strname.msgno,
          sourcecode: strname.msgcode,
          token: this.loginTokens,
        });
        this.global.ws.send(messagelist);
      }
      // if (strname.code == 500) {
      //   // alert("消息参数错误!");
      //   this.$message.error(strname.message);
      //   return false;
      // } else if (strname.code == 10000) {
      //   this.$router.push("/");
      //   clearInterval(this.tokenM);
      //   //退出天润的登录
      //   ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
      //     // 传入参数对象
      //     var params = {};
      //     params.logoutMode = 1;
      //     params.removeBinding = 0;
      //     ClinkAgent.logout(params);
      //   });
      //   localStorage.removeItem("tokenGuo");
      //   localStorage.removeItem("cno");
      //   localStorage.removeItem("result");
      //   localStorage.removeItem("isReload");
      // }
      // if (strname.msgcode == 102) {
      //   var objn = JSON.stringify({
      //     cno: this.cnoOne,
      //     msgcode: 200,
      //     source: 200116212001230,
      //     sourcecode: 120,
      //     token: this.loginTokens,
      //   });
      //   this.global.ws.send(objn);
      //   console.log("102走这里:" + strname.msgcode);
      // }
    },
    reconnect() {
      //重新连接
      console.log("重新连接000");
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.localSocket();
        that.lockReconnect = false;
        console.log("重新连接111");
      }, 10000);
    },
    //  关闭
    websocketclose(e) {
      let that = this;
      var str = e.data;
      var strname = JSON.parse(str);
      let loginUserId = JSON.parse(localStorage.getItem("loginuser"));
      that
        .$http({
          url: "/too/center/weblog/save",
          params: {
            callback: "",
            errorCode: strname.msgcode,
            errorExplain: "断开连接",
            interfaceUrl: that.ws.url,
            menuName: "客服系统-webSocket链接",
            menuUrl: that.$route.path,
            params: strname,
            source: "客服系统",
            userId: loginUserId.id,
            userName: loginUserId.name,
          },
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
          method: "get",
        })
        .then((res) => {
          if (res.data.code === 200) {
            console.log();
          }
        });
      console.log("断开连接", e);
      this.reconnect();
    },
    beforeDestroy() {
      clearTimeout(this.setTimer);
      clearInterval(this.timer);
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      clearInterval(this.timer4);
      clearInterval(this.timers);
      clearInterval(this.timer6);
      clearInterval(this.tokenM);
    },
  },
};
</script>

<style lang='less' scoped>
.big {
  margin-left: 50px;
  font-size: 20px;
  color: #92aca7;
  /deep/.el-tabs--card > .el-tabs__header,
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none;
  }
  /deep/.el-tabs__item.is-active {
    font-size: 20px;
    color: #92aca7;
    border: none;
  }
  /deep/.el-tabs__item:hover {
    font-size: 20px;
    color: #92aca7;
  }
  /deep/ .el-tabs__item {
    outline: none;
    border: none;
  }
  /deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none;
  }
}
</style>