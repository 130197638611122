<template>
  <div class="big">
    <div>
      <span class="family">家庭成员</span
      ><el-button
        type="primary"
        class="zeng"
        @click="addUser"
        :disabled="isNow"
        v-if="isXinZhen"
        >邀请</el-button
      >
    </div>
    <div>
      <el-table :data="tableData" style="width: 100%" @row-click="tableList">
        <el-table-column prop="userName" label="昵称"></el-table-column>
        <el-table-column prop="phone" label="手机号码" width="130">
        </el-table-column>
        <el-table-column label="成员身份">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              class="fclass"
              :disabled="isNow"
              @click="fen(scope.$index, scope.row)"
              >{{ scope.row.roleName }}
              <div
                style="
                  width: 55px;
                  height: 1.5px;
                  background: rgb(184, 182, 180);
                  margin-top: 5px;
                "
              ></div
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="jiao" label="呼叫">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              class="fclass"
              :disabled="isNow"
              @click="jiao(scope.$index, scope.row)"
              >拨打电话
              <div
                style="
                  width: 55px;
                  height: 1.5px;
                  background: rgb(184, 182, 180);
                  margin-top: 5px;
                "
              ></div
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="liu" label="留言">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              class="fclass"
              :disabled="isNow"
              @click="liu(scope.$index, scope.row)"
              >留言
              <div
                style="
                  width: 30px;
                  height: 1.5px;
                  background: rgb(184, 182, 180);
                  margin-top: 5px;
                "
              ></div
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              :disabled="isNow"
              @click="handleDelete(scope.$index, scope.row)"
              >移出</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 增加成员的弹框 -->
    <div>
      <el-dialog title="增加成员" :visible.sync="dialogVisible" width="30%">
        <el-input
          v-model="input"
          @change="addChange"
          placeholder="app端手机号码"
          style="width: 400px"
        ></el-input>
        <el-input
          v-model="inputName"
          @change="addUChange"
          placeholder="成员昵称"
          style="width: 180px; margin-top: 10px"
        ></el-input>
        <!-- <el-select
          v-model="value"
          placeholder="请选择"
          @change="sexChange"
          style="width: 100px; margin-left: 20px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="quitAdd">保存</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 成员身份的弹框 -->
    <div>
      <el-dialog title="选择身份" :visible.sync="dialogVisibleFen" width="30%">
        <el-radio-group
          v-model="radio"
          @change="fenChange"
          style="margin-left: 20px"
        >
          <el-radio :label="1">管理员</el-radio>
          <el-radio :label="2">紧急联系人</el-radio>
          <!-- <el-radio :label="3">普通成员</el-radio> -->
        </el-radio-group>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="dialogVisibleFen = false">取 消</el-button> -->
          <el-button type="primary" @click="chengFen">确定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 留言的弹框 -->
    <div>
      <el-dialog title="家庭留言" :visible.sync="dialogVisibleLiu" width="30%">
        <el-input
          type="textarea"
          autosize
          placeholder="请输入留言内容"
          v-model="textarea1"
          style="padding: 10px; width: 400px"
        >
        </el-input>
        <el-select
          v-model="valueliu"
          placeholder="请选择"
          @change="LiuChange"
          style="width: 180px; margin-left: 20px"
        >
          <el-option
            v-for="item in optionsLiu"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleLiu = false">取 消</el-button>
          <el-button type="primary" @click="quitLiu">保存</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 移出的弹框 -->
    <div>
      <el-dialog title="移出" :visible.sync="dialogVisibleSelect" width="30%">
        <span>确定将该成员移出家庭吗？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleSelect = false">取 消</el-button>
          <el-button type="primary" @click="quitDelete">确定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 呼叫的弹框 -->
    <!-- <div>
      <el-dialog title="呼叫" :visible.sync="dialogVisibleShout" width="30%">
        电话号码：<el-input
        v-model="inputPhs"
        placeholder="请输入电话"
        style="width: 300px"
        :disabled="true"
      ></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleShout = false">取 消</el-button>
          <el-button type="primary" @click="quitShout"
            >确定</el-button
          >
        </span>
      </el-dialog>
    </div> -->
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
export default {
  props: [
    "famData",
    "eqfamliyId",
    "userId",
    "xinfamDataList",
    "xinMembenIdaa",
    "xinFamilyIdaa",
    "noTaking",
    "isClick",
    // "isYesClick",
    // "isYesClick1",
  ],
  watch: {
    // msgList(dd){
    //   console.log('设备使用者传过来的：',dd);
    //   this.tableData=dd;
    // },
    userId(cc) {
      if (cc == "" || cc == null) {
        this.isXinZhen = false;
      } else {
        this.isXinZhen = true;
      }
    },
    // isYesClick(pp){
    //   if(pp==true){
    //     this.isNow=false;
    //   }else{
    //     this.isNow=true;
    //   }
    // },
    // isYesClick1(ll){
    //   if(ll==true){
    //     this.isNow=false;
    //   }else{
    //     this.isNow=true;
    //   }
    // },
    // noTaking(aa) {
    //   console.log(this.$parent.$parent.$parent.mainUniqueIds, "一直显示的id");
    //   this.noTakingaa = aa;
    //   if (aa == true) {
    //     this.isNow = true;
    //   } else {
    //     this.isNow = false;
    //   }
    // },
    // isClick(qq){
    //   if(qq==true){
    //     this.isNow=true;
    //   }else{
    //     this.isNow=false;
    //   }
    // },
    famData(val) {
      console.log("传过来的数据：", val);
      this.tableData = val;
      console.log(this.tableData, "chuanguolaideshujv...");
    },
    xinFamilyIdaa(bb) {
      this.famId = bb;
    },
    eqfamliyId(cc) {
      this.famId = cc;
      console.log("传到家庭成员的家庭id：", cc);
      // if (this.noTaking == true && this.isClick ==true) {
      //   this.isNow = true;
      // }else if(this.noTaking == true && this.isClick ==false){
      //   this.isNow = true;
      // }else if(this.noTaking == false && this.isClick ==true){
      //   this.isNow = true;
      // }else {
      //   this.isNow = false;
      // }
    },
    xinfamDataList(dd) {
      this.tableData = [];
      console.log("新设备传过来的家庭成员：", dd);
      this.tableData = dd;
    },
  },
  data() {
    return {
      familyPhone: "",
      isXinZhen: true,
      noTakingaa: true,
      isNow: false,
      input: "",
      inputName: "",
      // inputPhs:'',
      dialogVisible: false,
      dialogVisibleFen: false,
      dialogVisibleLiu: false,
      dialogVisibleSelect: false,
      dialogVisibleShout: false,
      value: "",
      radio: "",
      textarea1: "",
      valueliu: "",
      tableData: [],
      options: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "0",
          label: "女",
        },
      ],
      optionsLiu: [
        {
          value: "2",
          label: "短信留言",
        },
        {
          value: "1",
          label: "App留言",
        },
      ],
      memberId: "",
      cFen: "",
      userID: "",
      UniqueIds: "",
      selectLiu: "",
      famId: "", //家庭id
      Rolename: "",
      deletePhone: "",
      deleteId: "", //移出待加入成员的id
      addPhone: "",
      addName: "",
      addSex: "",
      userIDLiu: "",
      panName: "",
      mscodeId: "",
      cnoaa: "",
    };
  },
  created() {
    // console.log('---',this.famData);
    //获取cno
  },
  methods: {
    //增加家庭成员
    quitAdd() {
      this.dialogVisible = false;
      if (!/^1[0123456789]\d{9}$/.test(this.addPhone)) {
        this.$message.error("手机号码不正确，请重新添加");
      }
      // let aa=JSON.parse(localStorage.getItem('memberId'));
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      apiShout
        .getinsertUFam({
          familyId: this.famId,
          mainUniqueId: UniqueId,
          memberId: this.userId || this.xinMembenIdaa,
          phone: this.addPhone,
          userName: this.addName,
        })
        .then((res) => {
          console.log("增加家庭成员：", res.data);
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "成功增加家庭成员",
            });
            this.input = "";
            this.inputName = "";
            this.value = "";
            this.getUsList();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //移出
    quitDelete() {
      this.dialogVisibleSelect = false;
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      debugger;
      console.log(this.familyPhone, "familyPhonefamilyPhone");
      if (this.Rolename == "待加入") {
        apiShout
          .getdeleteInvite({
            familyId: this.famId,
            mainUniqueId: UniqueId,
            memberId: this.userId || this.xinMembenIdaa,
            // phone: this.deletePhone,
            phone: this.familyPhone,
          })
          .then((res) => {
            console.log("删除待加入成员：", res.data);
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "移出待加入成员成功",
              });
              this.getUsList();
            } else {
              this.$message.error(res.data.msg);
            }
          });
      } else {
        apiShout
          .getdeleteFamCno({
            fId: this.famId || this.xinFamilyIdaa,
            mainUniqueId: UniqueId,
            memberId: this.userId || this.xinMembenIdaa,
            userId: this.deleteId,
          })
          .then((res) => {
            console.log("移出成员：", res.data);
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "移出成员成功",
              });
              this.getUsList();
            } else {
              this.$message.error(res.data.msg);
            }
          });
      }
    },
    //留言的确定
    quitLiu() {
      // let cc=JSON.parse(localStorage.getItem('memberId'));
      this.$confirm("是否继续留言?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
          apiShout
            .getleaveMess({
              familyId: this.famId,
              mainUniqueId: UniqueId,
              memberId: this.userId || this.xinMembenIdaa,
              message: this.textarea1,
              type: this.selectLiu,
              userId: this.userIDLiu,
            })
            .then((res) => {
              console.log("留言的确定：", res.data);
              if (res.data.code == 200) {
                this.dialogVisibleLiu = false;
                this.$message({
                  type: "success",
                  message: "留言成功",
                });
                this.getLiu();
                this.getUsList();
                this.textarea1 = "";
                this.valueliu = "";
              } else {
                this.$message.error(res.data.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //成员身份的确定
    chengFen() {
      this.dialogVisibleFen = false;
      // let cc=JSON.parse(localStorage.getItem('memberId'));
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      // if (this.panName == "管理员" || this.panName == "紧急联系人") {
      //   this.$message.error("已是紧急联系人,无需修改");
      //   this.radio = "";
      //   return false;
      // }
      if (this.cFen == "1") {
        apiShout
          .getupFamAdminCno({
            memberId: this.userId || this.xinMembenIdaa,
            userId: this.userID,
            fId: this.famId || this.xinFamilyIdaa,
            mainUniqueId: UniqueId,
          })
          .then((res) => {
            console.log("成员身份管理员：", res.data);
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "成功修改成员身份为管理员",
              });
              this.radio = "";
              this.getUsList();
            } else {
              this.$message.error(res.data.msg);
            }
          });
      } else if (this.cFen == "2") {
        apiShout
          .getupFamUrgCno({
            fId: this.famId || this.xinFamilyIdaa,
            mainUniqueId: UniqueId,
            memberId: this.userId || this.xinMembenIdaa,
            userId: this.userID,
          })
          .then((res) => {
            console.log("成员身份紧急联系人：", res.data);
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "成功修改成员身份为紧急联系人",
              });
              this.radio = "";
              this.getUsList();
            } else {
              this.$message.error(res.data.msg);
            }
          });
      }
    },
    // quitShout(){
    //   this.dialogVisibleShout=false;
    //    this.$confirm('确认呼叫该号码？')
    //       .then(()=> {
    //         console.log('确定');
    //       })
    //       .catch(()=> {});

    // },
    sexChange(val) {
      console.log("性别：", val);
      this.addSex = val;
    },
    LiuChange(val) {
      console.log("留言选择：", val);
      this.selectLiu = val;
    },
    addUser() {
      this.dialogVisible = true;
    },
    addChange(val) {
      console.log("增加的输入框：", val);
      this.addPhone = val;
    },
    addUChange(val) {
      console.log("成员昵称：", val);
      this.addName = val;
    },
    fen(index, row) {
      console.log("身份：", index, row);
      this.userID = row.id;
      this.panName = row.roleName;
      this.dialogVisibleFen = true;
    },
    fenChange(val) {
      console.log("身份选择：", val);
      this.cFen = val;
    },
    jiao(index, row) {
      console.log(row, "查看row是什么显示！");
      // console.log(this.$parent.$parent.$parent.websocketsend(),"this.$parent.$parent.websocketsend1")
      console.log("呼叫：", index, row);
      let cno = JSON.parse(localStorage.getItem("cno"));
      this.cnoaa = cno;
      if (this.$parent.$parent.$parent.mscodeS === true) {
        this.$confirm("是否继续拨打此号码?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            if (this.$parent.$parent.$parent.strcode == 500) {
              return false;
            } else {
              var objs = JSON.stringify({
                cno: this.$parent.$parent.$parent.cno,
                msgcode: 203,
                token: this.$parent.$parent.$parent.loginToken,
                mainuniqueid: this.$parent.$parent.$parent.mainUniqueIds,
                clientnumber: row.phones,
                trantype: "5",
              });
              this.$parent.$parent.$parent.websocketsend(objs);
              let cnoNum = this.cnoaa;
              ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
                // 传入参数对象
                var params = {};
                params.objectType = "0";
                params.consultObject = row.phones;
                params.cno = cnoNum;
                ClinkAgent.consult(params);
              });
              this.$parent.$parent.$parent.switch = true;
              if (row.phone != "") {
                this.$parent.$parent.$parent.isQues = true;
                this.$parent.$parent.$parent.isQues1 = true;
              } else {
                this.$parent.$parent.$parent.isQues = false;
                this.$parent.$parent.$parent.isQues1 = false;
              }
            }
            // console.log(this.$parent.$parent.$parent.strcode,'8383838383838383831');
            // console.log(this.$parent.$parent.$parent.cno,'8383838383838383832');
            // console.log(this.$parent.$parent.$parent.loginToken,'8383838383838383833');
            // console.log(this.$parent.$parent.$parent.mainUniqueIds,'8383838383838383834');

            // this.$message({
            //   type: "success",
            //   message: "请稍等，等待设备接听!",
            // });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else {
        this.$message.error("等待通话建立成功后拨打！");
      }
      // this.dialogVisibleShout=true;
    },
    liu(index, row) {
      console.log("留言：", index, row);
      this.userIDLiu = row.id;
      this.dialogVisibleLiu = true;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.dialogVisibleSelect = true;
      this.Rolename = row.roleName;
      this.deletePhone = row.phone;
      this.familyPhone = row.phones;
      this.deleteId = row.id;
    },
    //列表
    tableList(row, column, event) {
      // this.dialogVisible = true;
      // if (this.noTaking == true) {
      //   event.isTrusted = false;
      // }
      console.log(row, event, "查看点击出来的内容");
    },
    //家庭成员列表
    getUsList() {
      // let cc=JSON.parse(localStorage.getItem('memberId'));
      apiShout
        .getfamUserMid({ memberId: this.userId || this.xinMembenIdaa })
        .then((res) => {
          console.log("家庭成员列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result;
            for (let i = 0; i < this.tableData.length; i++) {
              if (this.$parent.$parent.$parent.isTabs == true) {
                this.tableData[i].phones = this.tableData[i].phone;
              } else {
                this.tableData[i].phones = this.tableData[i].phone;
              }
              this.tableData[i].phone =
                this.tableData[i].phone.substr(0, 3) +
                "****" +
                this.tableData[i].phone.substr(7);
            }
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //留言提醒
    getLiu() {
      let UniqueId = JSON.parse(localStorage.getItem("userIds"));
      // console.log(UniqueId, "查看//////0000------------------------");
      if (UniqueId == null) {
        this.tableData = [];
        return false;
      }
      let UseridData = UniqueId;
      // console.log(UseridData, "查看//////");
      // console.log(this.userIds, "查看userid是否显示正确查看");
      apiShout.getLeaveMessages({ memberId: UseridData }).then((res) => {
        console.log(res, "查询数据");
        if (res.data.code === 200) {
          this.tableData = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
        // else{
        //   this.$message.error(res.data.msg);
        // }
      });
    },
  },
};
</script>

<style lang='less' scoped>
.big {
  padding: 10px;
}
.zeng {
  background: #f09c83;
  padding: 8px 10px;
  margin-left: 20px;
}
.family {
  font-size: 20px;
  font-weight: 600;
}
.fclass {
  background: none;
  color: rgb(90, 88, 88);
  border: 0px;
}
/deep/.el-dialog__headerbtn {
  font-size: 20px;
}
/deep/.el-dialog__wrapper {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
}
</style>