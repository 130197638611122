<template>
  <div class="big">
    <div>
      <span class="family">设备使用者</span
      ><el-button
        type="primary"
        class="zeng"
        @click="addEquipment"
        v-if="isXinZhen"
        :disabled="isNow"
        >新增</el-button
      >
    </div>
    <div>
      <el-table :data="tableData" style="width: 100%" @row-click="tableList">
        <el-table-column prop="name" label="昵称">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              class="fclass"
              :disabled="isNow"
              @click="nameUser(scope.$index, scope.row)"
              >{{ scope.row.name }}
              <div
                style="
                  width: 35px;
                  height: 1.5px;
                  background: rgb(184, 182, 180);
                  margin-top: 5px;
                "
              ></div
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="bloodPressure" label="血压">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              class="fclass"
              :disabled="isNow"
              @click="bloodPressure(scope.$index, scope.row)"
              >{{ scope.row.bloodPressure }}
              <div
                style="
                  width: 35px;
                  height: 1.5px;
                  background: rgb(184, 182, 180);
                  margin-top: 5px;
                "
              ></div
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="bloodSugar" label="血糖">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              class="fclass"
              :disabled="isNow"
              @click="bloodSugar(scope.$index, scope.row)"
              >{{ scope.row.bloodSugar }}
              <div
                style="
                  width: 30px;
                  height: 1.5px;
                  background: rgb(184, 182, 180);
                  margin-top: 5px;
                "
              ></div
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="oxygen" label="血氧">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              class="fclass"
              :disabled="isNow"
              @click="bloodOxygen(scope.$index, scope.row)"
              >{{ scope.row.oxygen }}
              <div
                style="
                  width: 30px;
                  height: 1.5px;
                  background: rgb(184, 182, 180);
                  margin-top: 5px;
                "
              ></div
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="heartRate" label="心率">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              class="fclass"
              :disabled="isNow"
              @click="heartRate(scope.$index, scope.row)"
              >{{ scope.row.heartRate }}
              <div
                style="
                  width: 30px;
                  height: 1.5px;
                  background: rgb(184, 182, 180);
                  margin-top: 5px;
                "
              ></div
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="temperature" label="体温">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              class="fclass"
              :disabled="isNow"
              @click="temperature(scope.$index, scope.row)"
              >{{ scope.row.temperature }}
              <div
                style="
                  width: 30px;
                  height: 1.5px;
                  background: rgb(184, 182, 180);
                  margin-top: 5px;
                "
              ></div
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="walk" label="步数">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              class="fclass"
              :disabled="isNow"
              @click="steps(scope.$index, scope.row)"
              >{{ scope.row.walk }}
              <div
                style="
                  width: 30px;
                  height: 1.5px;
                  background: rgb(184, 182, 180);
                  margin-top: 5px;
                "
              ></div
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column label="救援服务" width="130px">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              class="fclass"
              :disabled="isNow"
              @click="rescue(scope.$index, scope.row)"
              >{{ scope.row.openRescueCn }}
              <div
                style="
                  width: 35px;
                  height: 1.5px;
                  background: rgb(184, 182, 180);
                  margin-top: 5px;
                "
              ></div
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增的弹框 -->
    <div>
      <el-dialog
        title="增加使用者"
        :visible.sync="dialogVisibleUse"
        width="30%"
      >
        <el-input
          v-model="inputUse"
          @change="addUse"
          placeholder="使用者昵称"
          style="width: 300px"
        ></el-input>
        <el-select
          v-model="valueSex"
          placeholder="请选择"
          @change="sexChange"
          style="width: 100px; margin-left: 20px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="inputApp"
          @change="AppChange"
          placeholder="app端手机号码"
          style="width: 400px"
          v-if="isKuang"
        ></el-input>
        <el-input
          v-model="inputNameApp"
          @change="AppUChange"
          placeholder="成员昵称"
          style="width: 180px; margin-top: 10px"
          v-if="isKuang"
        ></el-input>
        <el-select
          v-model="valueApp"
          placeholder="请选择"
          @change="AppsexChange"
          style="width: 100px; margin-left: 20px"
          v-if="isKuang"
        >
          <el-option
            v-for="item in Appoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div v-if="isImei">
          <div style="font-size: 16px; margin-bottom: 10px">
            当前通话号码：<span>{{ nowPhone }}</span>
          </div>
          <el-input
            v-model="inputImei"
            placeholder="IMEI"
            style="width: 250px"
          ></el-input>
          <el-button
            type="primary"
            plain
            style="padding: 8px; margin-left: 10px"
            @click="search"
            >确定</el-button
          >
          <div>
            <span
              style="
                margin-right: 20px;
                color: #d78673;
                font-weight: bold;
                font-size: 16px;
              "
              >{{ productName }}</span
            >
            <span style="color: #d78673; margin-right: 16px; font-size: 16px">{{
              statusCn
            }}</span>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="dialogVisibleUse = false">取 消</el-button> -->
          <el-button type="primary" @click="quitAdd">保存</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 血压的弹框 -->
    <div>
      <el-dialog title="血压数据" :visible.sync="dialogVisible" width="30%">
        <el-input v-model="input" maxlength="6" style="width: 100px"></el-input
        ><span style="margin: 20px 0px 20px 20px">(高压)/</span>
        <el-input
          v-model="input1"
          maxlength="6"
          style="width: 100px; margin-top: 10px"
        ></el-input
        ><span style="margin: 20px">(低压)mmHg</span>
        <div class="small">
          <el-table
            :data="gridDatabloodPressure"
            :show-header="false"
            :row-style="{ background: '#fff' }"
          >
            <el-table-column
              width="200"
              property="createDate"
            ></el-table-column>
            <el-table-column width="100" property="value"></el-table-column>
          </el-table>
          <!-- <el-pagination
            @size-change="handleSizeChanges"
            @current-change="handleCurrentChanges"
            :page-size="pageSizes"
            :current-page="pageCounts"
            layout="total, prev, pager, next"
            :total="totalCount"
          >
          </el-pagination> -->
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addBloodPressure">保存</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 血糖的弹框 -->
    <div>
      <el-dialog title="血糖数据" :visible.sync="dialogVisibleone" width="30%">
        <el-input
          v-model="inputTang"
          @input="handeloneTang"
          style="width: 100px"
          maxlength="5"
        ></el-input
        ><span style="margin: 20px 0px 20px 20px">mmol/L</span>
        <el-radio-group v-model="radio" @change="xuetang">
          <el-radio :label="1" style="margin-left: 20px">空腹</el-radio>
          <el-radio :label="2">餐二</el-radio>
          <el-radio :label="0">随机</el-radio>
        </el-radio-group>

        <div class="small">
          <el-table
            :data="gridDatabloodSugar"
            :show-header="false"
            :row-style="{ background: '#fff' }"
          >
            <el-table-column
              width="200"
              property="createDate"
            ></el-table-column>
            <el-table-column width="100" property="value"></el-table-column>
            <el-table-column width="100" property="valueType">
              <template slot-scope="scope">
                {{ scope.row.valueType == null ? "随机" : scope.row.valueType }}
              </template>
            </el-table-column>
          </el-table>
          <!-- <el-pagination
            @size-change="handleSizeChanges"
            @current-change="handleCurrentChanges"
            :page-size="pageSizes"
            :current-page="pageCounts"
            layout="total, prev, pager, next"
            :total="totalCount"
          >
          </el-pagination> -->
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleone = false">取 消</el-button>
          <el-button type="primary" @click="addBloodSugar">保存</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 血氧的弹框 -->
    <div>
      <el-dialog title="血氧数据" :visible.sync="dialogVisibletwo" width="30%">
        <el-input
          v-model="inputoxygen"
          @input="handeloneoxygen"
          :pattern="intPattern"
          :maxlength="3"
          style="width: 100px"
        ></el-input
        ><span style="margin: 20px 0px 20px 20px">%</span>
        <div class="small">
          <el-table
            :data="gridDataoxygen"
            :show-header="false"
            :row-style="{ background: '#fff' }"
          >
            <el-table-column
              width="200"
              property="createDate"
            ></el-table-column>
            <el-table-column width="100" property="value"></el-table-column>
          </el-table>
          <!-- <el-pagination
            @size-change="handleSizeChanges"
            @current-change="handleCurrentChanges"
            :page-size="pageSizes"
            :current-page="pageCounts"
            layout="total, prev, pager, next"
            :total="totalCount"
          >
          </el-pagination> -->
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibletwo = false">取 消</el-button>
          <el-button type="primary" @click="addOxygen">保存</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 心率的弹框 -->
    <div>
      <el-dialog
        title="心率数据"
        :visible.sync="dialogVisiblethree"
        width="30%"
      >
        <el-input
          v-model="inputheartRate"
          @input="handeloneheartRate"
          style="width: 100px"
          maxlength="5"
        ></el-input
        ><span style="margin: 20px 0px 20px 20px">次/分</span>
        <div class="small">
          <el-table
            :data="gridDataheartRate"
            :show-header="false"
            :row-style="{ background: '#fff' }"
          >
            <el-table-column
              width="200"
              property="createDate"
            ></el-table-column>
            <el-table-column width="100" property="value"></el-table-column>
          </el-table>
          <!-- <el-pagination
            @size-change="handleSizeChanges"
            @current-change="handleCurrentChanges"
            :page-size="pageSizes"
            :current-page="pageCounts"
            layout="total, prev, pager, next"
            :total="totalCount"
          >
          </el-pagination> -->
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisiblethree = false">取 消</el-button>
          <el-button type="primary" @click="addHeartRate">保存</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 体温的弹框 -->
    <div>
      <el-dialog title="体温数据" :visible.sync="dialogVisiblefour" width="30%">
        <el-input
          v-model="inputtemperature"
          @input="handelonetemperature"
          style="width: 100px"
          maxlength="5"
        ></el-input
        ><span style="margin: 20px 0px 20px 20px">℃</span>
        <div class="small">
          <el-table
            :data="gridDatatemperature"
            :show-header="false"
            :row-style="{ background: '#fff' }"
          >
            <el-table-column
              width="200"
              property="createDate"
            ></el-table-column>
            <el-table-column width="100" property="value"></el-table-column>
          </el-table>
          <!-- <el-pagination
            @size-change="handleSizeChanges"
            @current-change="handleCurrentChanges"
            :page-size="pageSizes"
            :current-page="pageCounts"
            layout="total, prev, pager, next"
            :total="totalCount"
          >
          </el-pagination> -->
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisiblefour = false">取 消</el-button>
          <el-button type="primary" @click="addTemperature">保存</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 步数的弹框 -->
    <div>
      <el-dialog title="步数数据" :visible.sync="dialogVisiblefive" width="30%">
        <div class="small">
          <el-table
            :data="gridDatawalk"
            :show-header="false"
            :row-style="{ background: '#fff' }"
          >
            <el-table-column
              width="200"
              property="createDate"
            ></el-table-column>
            <el-table-column width="100" property="value"></el-table-column>
          </el-table>
          <!-- <el-pagination
            @size-change="handleSizeChanges"
            @current-change="handleCurrentChanges"
            :page-size="pageSizes"
            :current-page="pageCounts"
            layout="total, prev, pager, next"
            :total="totalCount"
          >
          </el-pagination> -->
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisiblefive = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisiblefive = false"
            >保存</el-button
          >
        </span>
      </el-dialog>
    </div>
    <!-- 救援的弹框 -->
    <div>
      <el-dialog
        title="开通救援服务"
        :visible.sync="dialogVisiblesix"
        width="30%"
      >
        <!-- <span v-if="isJiu">救援服务已开通</span> -->
        <el-form :model="formInline" class="demo-form-inline">
          <el-form-item>
            <el-checkbox
              label="开通救援服务"
              @change="callChange"
              v-model="callback"
              true-label="1"
              false-label="0"
              :disabled="disabled1"
            ></el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="inputname"
              @input="handelname"
              style="width: 300px"
              placeholder="请输入真实姓名"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="inputNum"
              @input="handelNum"
              style="width: 300px"
              placeholder="请输入身份证号码"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="inputPhone"
              @input="handelPhone"
              style="width: 300px"
              placeholder="请输入联系电话"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisiblesix = false">取 消</el-button>
          <el-button type="primary" @click="jiu">保存</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 修改设备使用人 -->
    <div>
      <el-dialog
        title="编辑使用者"
        :visible.sync="dialogVisiblenameUser"
        width="30%"
      >
        <el-input
          v-model="inputName"
          @input="addUChange"
          placeholder="使用者昵称"
          style="width: 180px; margin-top: 10px"
        ></el-input>
        <el-select
          v-model="valueCh"
          placeholder="请选择"
          @change="sexChs"
          style="width: 100px; margin-left: 20px"
        >
          <el-option
            v-for="item in optionsCh"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisiblenameUser = false">取 消</el-button>
          <el-button type="primary" @click="changeUser">保存</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
export default {
  props: [
    "famData",
    "forDataList",
    "eqfamliyId",
    "equipmentIdStr",
    "wuFamilyId",
    "noTaking",
    "userId",
    "qingkong",
    "isClick",
    // "isYesClick",
    "isActiOne",
    "codeMsg",
    "allNew",
  ],
  watch: {
    // equipmentIdStr(mm){
    //   console.log('传过来的equipmentId',mm);
    //   this.equipmentId=mm;
    // },
    qingkong(ww) {
      if (ww == true) {
        this.isShow = true;
        // this.isNow=false;
        // this.isKuang = true;
      } else {
        this.isShow = false;
        // this.isNow=true;
        // this.isKuang = false;
      }
    },
    // userId(cc) {
    //   // this.userIdAA=cc;
    //   if (cc == "" || cc == null) {
    //     this.isXinZhen = true;
    //   } else {
    //     this.isXinZhen = false;
    //   }
    // },

    userId(cc) {
      // this.userIdAA=cc;
      // console.log('输出获取：',strEq,strInfor);
      if (
        (cc == "" && this.isActiOne == false) ||
        (cc == null && this.isActiOne == false)
      ) {
        this.isXinZhen = true;
      } else if (cc != "" && this.isActiOne == true) {
        this.isXinZhen = false;
      } else {
        this.isXinZhen = true;
      }
    },
    allNew(ff) {
      let strEq = JSON.parse(localStorage.getItem("strequipmentIds"));
      let strInfor = JSON.parse(localStorage.getItem("yuanInfor"));
      if (ff != null && strEq == null && strInfor == 0) {
        this.isImei = true;
      }
    },

    // isClick(qq){
    //   if(qq==true){
    //     this.isNow=true;
    //   }else{
    //     this.isNow=false;
    //   }
    // },
    // isYesClick(pp){
    //   if(pp==true){
    //     this.isNow=false;
    //   }else{
    //     this.isNow=true;
    //   }
    // },
    // noTaking(aa) {
    //   if (aa == true) {
    //     this.isNow = true;
    //   } else {
    //     this.isNow = false;
    //   }
    // },
    // famData(val) {
    //   console.log("传过来的家庭成员：", val);
    //   if (val.length == 0) {
    //     this.isShow = true;
    //     this.isKuang = true;
    //     this.flag = true;
    //   } else {
    //     this.isShow = false;
    //     this.isKuang = false;
    //     this.flag = false;
    //   }
    // },

    eqfamliyId(cc) {
      this.famId = cc;
      console.log("传到设备使用者的家庭id：", cc);
    },
    codeMsg(aa) {
      if (aa === 500) {
        this.tableData = [];
      }
    },
    forDataList(aa) {
      console.log("设备使用者：", aa);
      this.tableData = [];
      this.haveFamilyId = aa.familyId;
      this.tableData.push(aa);
      this.input = "";
      this.input1 = "";
      this.inputTang = "";
      this.inputoxygen = "";
      this.inputheartRate = "";
      this.inputtemperature = "";
      // // console.log('11:',this.tableData);
      // if (this.flag == true && aa == undefined) {
      //   //无家庭无设备使用者
      //   // this.haveFamilyId = "";
      //   this.isShow = true;
      //   this.isKuang = true;
      //   this.tableData = [];
      // } else if (this.flag == false && aa == undefined) {
      //   //有家庭无设备使用者
      //   this.haveFamilyId = this.wuFamilyId;
      //   this.isShow = true;
      //   this.isKuang = false;
      //   this.tableData = [];
      // } else {
      //   this.tableData = [];
      //   this.isShow = false;
      //   this.isKuang = false;
      //   this.tableData.push(aa);
      // }
    },
  },
  data() {
    return {
      isImei: false,
      inputImei: "",
      bloodsugarS: null,
      radio: 0,
      successCode: null,
      userIdAA: null,
      isXinZhen: true,
      isNow: false,
      xinfamData: [],
      xinMembenId: "",
      xinFamilyId: "",
      isJiu: false,
      famId: "",
      haveFamilyId: null,
      flag: false,
      isShow: false,
      isKuang: true,
      inputTang: "",
      inputoxygen: "",
      inputheartRate: "",
      inputtemperature: "",
      inputApp: "",
      inputNameApp: "",
      valueApp: "",
      Appoptions: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "0",
          label: "女",
        },
      ],
      allNum: "",
      gridDatabloodPressure: [],
      gridDataheartRate: [],
      gridDatabloodSugar: [],
      gridDataoxygen: [],
      gridDatatemperature: [],
      gridDatawalk: [],
      dialogVisibleUse: false,
      dialogVisible: false,
      dialogVisibleone: false,
      dialogVisibletwo: false,
      dialogVisiblethree: false,
      dialogVisiblefour: false,
      dialogVisiblefive: false,
      dialogVisiblesix: false,
      dialogVisiblenameUser: false,
      input: "",
      input1: "",
      inputname: "",
      inputNum: "",
      inputPhone: "",
      callback: "",
      inputUse: "",
      valueSex: "",
      valueCh: "",
      inputName: "",
      disabled: true,
      disabled1: false,
      formInline: {},
      tableData: [],
      gridData: [
        {
          phone: "12345678562",
          name: "王小虎",
          fen: "管理员",
          jiao: "拨打电话",
          liu: "留言",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      options: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "0",
          label: "女",
        },
      ],
      optionsCh: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "0",
          label: "女",
        },
      ],
      pageSizes: 10,
      pageCounts: 1,
      totalCount: 0,
      equipmentId: "",
      changeId: "",
      UniqueIds: "",
      changeName: "",
      changeSex: "",
      addName: "",
      addSex: "",
      Appname: "",
      AppPhone: "",
      AppSex: "",
      jiuID: "",
      jiuCard: "",
      jiuName: "",
      jiuPhone: "",
      memberId: "",
      typeNum: null,
      bloodHigh: "",
      bloodLow: "",
      newUserList: [],
      NewmemData: [],
      flagZeng: false,
      xinList: null,
      productName: "",
      statusCn: "",
      nowPhone: "",
      equId: null,
      intPattern: /^(100|[1-9]\d|\d)$/,
    };
  },
  created() {
    // let aa = JSON.parse(localStorage.getItem("strequipmentIds"));
    // this.equipmentId = aa;
    console.log("11100000:", this.$parent);

    // this.userList();
  },
  methods: {
    search() {
      apiShout.getequIMEI({ imei: this.inputImei }).then((res) => {
        console.log("搜索：", res.data);
        if (res.data.code == 200) {
          this.productName = res.data.result.productName;
          this.equId = res.data.result.id;
          this.statusCn = res.data.result.statusCn;
        }
      });
    },
    xuetang(val) {
      console.log(val, "查看");
      this.bloodsugarS = val;
    },
    handeloneTang(aa) {
      console.log("输入的血糖：", aa);
      this.allNum = aa;
    },
    handeloneoxygen(ff) {
      console.log("输入的血氧：", ff);
      this.allNum = ff;
    },
    handeloneheartRate(gg) {
      console.log("输入的心率：", gg);
      this.allNum = gg;
    },
    handelonetemperature(hh) {
      console.log("输入的体温：", hh);
      this.allNum = hh;
    },
    //血压的添加
    addBloodPressure() {
      this.dialogVisible = false;
      // if(this.flagZeng==true){
      //   this.memberId=this.xinMembenId;
      // }
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      let ss = JSON.parse(localStorage.getItem("equMemberId"));
      if (Number(this.input) < Number(this.input1)) {
        this.$message.error("请检查血压值是否正确");
        return false;
      }
      if (!/^\+?[1-9][0-9]*$/.test(this.input)) {
        this.$message.error("请输入正整数");
        return false;
      }
      if (!/^\+?[1-9][0-9]*$/.test(this.input1)) {
        this.$message.error("请输入正整数");
        return false;
      }
      // if(this.bloodLow>this.bloodHigh||this.bloodHigh>140&&this.bloodHigh<90||this.bloodLow>90&&this.bloodLow<50){
      //   this.$message.error('请检查血压值是否正确');
      //   return false;
      // }
      // else if(this.bloodHigh>140&&this.bloodHigh<90||this.bloodLow>90&&this.bloodLow<50){
      //   this.$message.error('请检查血压值是否正确');
      //   return false;
      // }
      // else if(){
      //   this.$message.error('请检查血压值是否正确');
      //   return false;
      // }
      if (this.input == "" || this.input1 == "") {
        this.$message.error("请填写血压");
        return false;
      }
      apiShout
        .getinMemHealth({
          equipmentId: ss,
          mainUniqueId: UniqueId,
          memberId: this.memberId,
          type: this.typeNum,
          value: this.input + "/" + this.input1,
        })
        .then((res) => {
          console.log("数据增加：", res.data);
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.input = "";
            this.input1 = "";
            this.bloodHigh = "";
            this.bloodLow = "";
            this.getList();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //血糖的添加
    addBloodSugar() {
      this.dialogVisibleone = false;
      this.addListNums1();
    },
    //血氧
    addOxygen() {
      this.dialogVisibletwo = false;
      this.addListNums2();
    },
    //心率
    addHeartRate() {
      this.dialogVisiblethree = false;
      this.addListNums();
    },
    //体温
    addTemperature() {
      this.dialogVisiblefour = false;
      this.addListNums();
    },
    //数据增加的接口
    addListNums() {
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      let ss = JSON.parse(localStorage.getItem("equMemberId"));
      // if(this.flagZeng==true){
      //   this.memberId=this.xinMembenId;
      // }
      // if(this.typeNum==2){
      //   if(this.allNum>7.8||this.allNum<3.9){
      //      this.$message.error('请检查血糖值是否正确');
      //      return false;
      //   }
      // }else if(this.typeNum==7){
      //   if(this.allNum>100||this.allNum<95){
      //     this.$message.error('请检查血氧值是否正确');
      //      return false;
      //   }
      // }else if(this.typeNum==4){
      //   if(this.allNum>100||this.allNum<60){
      //     this.$message.error('请检查心率值是否正确');
      //      return false;
      //   }
      // }else if(this.typeNum==3){
      //   if(this.allNum>37.4||this.allNum<36){
      //      this.$message.error('请检查体温值是否正确');
      //      return false;
      //   }
      // }
      if (!/^\+?[1-9][0-9]*$/.test(this.allNum)) {
        this.$message.error("请输入整数");
        return false;
      }
      if (this.allNum == "") {
        this.$message.error("请输入心率");
        return false;
      }
      apiShout
        .getinMemHealth({
          equipmentId: ss,
          mainUniqueId: UniqueId,
          memberId: this.memberId,
          type: this.typeNum,
          value: this.allNum,
        })
        .then((res) => {
          console.log("数据增加：", res.data);
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.inputTang = "";
            this.inputoxygen = "";
            this.inputheartRate = "";
            this.inputtemperature = "";
            this.getList();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //血糖的添加
    addListNums1() {
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      let ss = JSON.parse(localStorage.getItem("equMemberId"));
      // if(this.flagZeng==true){
      //   this.memberId=this.xinMembenId;
      // }
      // if(this.typeNum==2){
      //   if(this.allNum>7.8||this.allNum<3.9){
      //      this.$message.error('请检查血糖值是否正确');
      //      return false;
      //   }
      // }else if(this.typeNum==7){
      //   if(this.allNum>100||this.allNum<95){
      //     this.$message.error('请检查血氧值是否正确');
      //      return false;
      //   }
      // }else if(this.typeNum==4){
      //   if(this.allNum>100||this.allNum<60){
      //     this.$message.error('请检查心率值是否正确');
      //      return false;
      //   }
      // }else if(this.typeNum==3){
      //   if(this.allNum>37.4||this.allNum<36){
      //      this.$message.error('请检查体温值是否正确');
      //      return false;
      //   }
      // }
      this.bloodsugarS = this.radio;
      console.log(this.bloodsugarS, this.radio, "值展示");
      if (this.bloodsugarS == null) {
        this.$message.error("请选择时间");
        return false;
      }
      if (this.allNum == "") {
        this.$message.error("请选择血糖");
        return false;
      }
      let aa = this.bloodsugarS + "," + this.allNum;
      let ww = aa.indexOf(",");
      let ww1 = aa.lastIndexOf(",");
      // console.log('0909090:',ss,ss1);
      if (ww !== ww1) {
        this.$message.error("请输入正确的血糖值");
        return false;
      }
      apiShout
        .getinMemHealth({
          equipmentId: ss,
          mainUniqueId: UniqueId,
          memberId: this.memberId,
          type: this.typeNum,
          value: aa,
        })
        .then((res) => {
          console.log("数据增加：", res.data);
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.inputTang = "";
            this.inputoxygen = "";
            this.inputheartRate = "";
            this.allNum = "";
            this.getList();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //血氧增加的接口
    addListNums2() {
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      let ss = JSON.parse(localStorage.getItem("equMemberId"));
      // if(this.flagZeng==true){
      //   this.memberId=this.xinMembenId;
      // }
      // if(this.typeNum==2){
      //   if(this.allNum>7.8||this.allNum<3.9){
      //      this.$message.error('请检查血糖值是否正确');
      //      return false;
      //   }
      // }else if(this.typeNum==7){
      //   if(this.allNum>100||this.allNum<95){
      //     this.$message.error('请检查血氧值是否正确');
      //      return false;
      //   }
      // }else if(this.typeNum==4){
      //   if(this.allNum>100||this.allNum<60){
      //     this.$message.error('请检查心率值是否正确');
      //      return false;
      //   }
      // }else if(this.typeNum==3){
      //   if(this.allNum>37.4||this.allNum<36){
      //      this.$message.error('请检查体温值是否正确');
      //      return false;
      //   }
      // }
      if (!/^\+?[1-9][0-9]*$/.test(this.allNum)) {
        this.$message.error("请输入整数");
        return false;
      }
      if (this.allNum == "") {
        this.$message.error("请输入血氧");
        return false;
      }
      apiShout
        .getinMemHealth({
          equipmentId: ss,
          mainUniqueId: UniqueId,
          memberId: this.memberId,
          type: this.typeNum,
          value: this.allNum,
        })
        .then((res) => {
          console.log("数据增加：", res.data);
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.inputTang = "";
            this.inputoxygen = "";
            this.inputheartRate = "";
            this.allNum = "";
            this.getList();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //开通救援
    jiu() {
      this.dialogVisiblesix = false;
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      let equipmentIdStr = JSON.parse(localStorage.getItem("strequipmentIds"));
      if (!/^\d{17}[\dX]$/.test(this.jiuCard) || this.jiuCard == "") {
        this.$message.error("请输入正确的身份证号或不能为空");
        return false;
      }
      if (!/^1[0123456789]\d{9}$/.test(this.jiuPhone) || this.jiuPhone == "") {
        this.$message.error("手机号码不正确，请重新添加或不能为空");
        return false;
      }
      apiShout
        .getupMemYMBCno({
          equipmentId: equipmentIdStr,
          id: this.jiuID,
          idcard: this.jiuCard,
          mainUniqueId: UniqueId,
          realName: this.jiuName,
          sparePhone: this.jiuPhone,
        })
        .then((res) => {
          console.log("救援服务："), res.data;
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "成功开通救援服务",
            });
            this.getList();
            this.inputname = "";
            this.inputNum = "";
            this.inputPhone = "";
            this.callback = "";
            this.disabled = true;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //增加的弹框
    quitAdd() {
      this.$confirm("是否保存此信息?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.isImei == false) {
            this.haveFamilyId = null;
            this.dialogVisibleUse = false;
            this.flagZeng = true;
            let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
            let equipmentIdStr = JSON.parse(
              localStorage.getItem("strequipmentIds")
            );
            console.log("this.haveFamilyId", this.haveFamilyId);
            if (this.haveFamilyId == null) {
              apiShout
                .getinsertMem({
                  equipmentId: equipmentIdStr,
                  mainUniqueId: UniqueId,
                  memberName: this.addName,
                  memberSex: this.addSex,
                  userName: this.Appname,
                  userPhone: this.AppPhone,
                  userSex: this.AppSex,
                })
                .then((res) => {
                  console.log("增加：", res.data);
                  this.successCode = res.data.code;
                  this.$emit("successCodes", this.successCode);
                  if (res.data.code == 200) {
                    this.$message({
                      type: "success",
                      message: "成功增加设备使用者",
                    });
                    this.xinList = res.data.code;
                    this.$emit("xinList", res.data.code);
                    this.userIdAA = res.data.result;
                    this.isXinZhen = false;
                    this.getList();
                    this.flagZeng = false;
                    this.valueApp = "";
                    this.inputNameApp = "";
                    this.inputApp = "";
                    this.valueSex = "";
                    this.inputUse = "";
                    this.inputImei = "";
                    this.productName = "";
                    this.statusCn = "";
                    // this.getOld();
                    // this.userList();
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
            } else {
              apiShout
                .getinsertMem({
                  equipmentId: equipmentIdStr,
                  familyId: this.haveFamilyId,
                  mainUniqueId: UniqueId,
                  memberName: this.addName,
                  memberSex: this.addSex,
                  userName: this.Appname,
                  userPhone: this.AppPhone,
                  userSex: this.AppSex,
                })
                .then((res) => {
                  console.log("增加：", res.data);
                  this.successCode = res.data.code;
                  this.$emit("successCodes", this.successCode);
                  if (res.data.code == 200) {
                    this.$message({
                      type: "success",
                      message: "成功增加设备使用者",
                    });
                    this.xinList = res.data.code;
                    this.$emit("xinList", res.data.code);
                    this.userIdAA = res.data.result;
                    this.isXinZhen = false;
                    this.getList();
                    this.flagZeng = false;
                    this.valueApp = "";
                    this.inputNameApp = "";
                    this.inputApp = "";
                    this.valueSex = "";
                    this.inputUse = "";
                    // this.getOld();
                    // this.userList();
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
            }
          } else {
            //全新设备
            let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
            apiShout
              .getaddFaUa({
                callPhone: this.nowPhone,
                equipmentId: this.equId,
                mainUniqueId: UniqueId,
                memberName: this.addName,
                memberSex: this.addSex,
                userName: this.Appname,
                userPhone: this.AppPhone,
                userSex: this.AppSex,
              })
              .then((res) => {
                console.log("全新设备绑定：", res.data);
                localStorage.setItem("serEquId", this.equId);
                this.successCode = res.data.code;
                this.$emit("successCodes", this.successCode);
                if (res.data.code == 200) {
                  this.dialogVisibleUse = false;
                  this.$message({
                    type: "success",
                    message: "成功增加设备使用者",
                  });
                  // localStorage.setItem("serUserId",this.equId);
                  this.xinList = res.data.code;
                  this.$emit("xinList", res.data.code);
                  this.userIdAA = res.data.result;
                  this.isXinZhen = false;
                  this.getList();
                  this.flagZeng = false;
                  this.valueApp = "";
                  this.inputNameApp = "";
                  this.inputApp = "";
                  this.valueSex = "";
                  this.inputUse = "";
                  this.inputImei = "";
                  this.productName = "";
                  this.statusCn = "";
                } else {
                  this.$message.error(res.data.msg);
                }
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
    // //家庭成员列表
    // userList(){
    //   debugger
    //   let aa = JSON.parse(localStorage.getItem("memberId"));
    //    apiShout.getfamUserMid({ memberId: aa }).then((res) => {
    //     console.log("家庭成员列表：", res.data);
    //     this.newUserList = res.data.result;
    //     this.$emit('equMent',this.newUserList);
    //   });
    // },
    //修改设备使用者
    changeUser() {
      this.dialogVisiblenameUser = false;
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      apiShout
        .getupMemByCno({
          id: this.changeId,
          mainUniqueId: UniqueId,
          name: this.changeName,
          sex: this.changeSex,
        })
        .then((res) => {
          console.log("修改使用者：", res.data);
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "成功修改设备使用者信息",
            });
            this.inputName = "";
            this.valueCh = "";
            this.getList();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    addEquipment() {
      this.dialogVisibleUse = true;
      let aa = JSON.parse(localStorage.getItem("listionPhone"));
      this.nowPhone = aa;
    },
    AppChange(cc) {
      console.log("App电话：", cc);
      this.AppPhone = cc;
    },
    AppUChange(dd) {
      console.log("App名称：", dd);
      this.Appname = dd;
    },
    AppsexChange(ee) {
      console.log("App性别 :", ee);
      this.AppSex = ee;
    },
    addUse(val) {
      console.log("增加使用者：", val);
      this.addName = val;
    },
    sexChange(aa) {
      console.log("性别：", aa);
      this.addSex = aa;
    },
    sexChs(bb) {
      console.log("修改的性别：", bb);
      this.changeSex = bb;
    },
    addUChange(val) {
      console.log("使用者昵称：", val);
      this.changeName = val;
    },
    nameUser(index, row) {
      console.log("修改设备使用人信息：", index, row);
      this.dialogVisiblenameUser = true;
      this.changeId = row.id;
    },
    bloodPressure(index, row) {
      // 血压正常值 50-90  90-140
      console.log("血压：", index, row);
      this.memberId = row.id;
      this.userIdAA = row.id;
      this.typeNum = 1;
      this.getOldList();
      this.dialogVisible = true;
    },
    bloodSugar(index, row) {
      // 3.9-7.8
      console.log("血糖：", index, row);
      this.memberId = row.id;
      this.userIdAA = row.id;
      this.typeNum = 2;
      this.getOldList();
      this.dialogVisibleone = true;
    },
    bloodOxygen(index, row) {
      // 95-100
      console.log("血氧：", index, row);
      this.memberId = row.id;
      this.userIdAA = row.id;
      this.typeNum = 7;
      this.getOldList();
      this.dialogVisibletwo = true;
    },
    heartRate(index, row) {
      // 60-100
      console.log("心率：", index, row);
      this.memberId = row.id;
      this.userIdAA = row.id;
      this.typeNum = 4;
      this.getOldList();
      this.dialogVisiblethree = true;
    },
    temperature(index, row) {
      console.log("体温：", index, row);
      this.memberId = row.id;
      this.userIdAA = row.id;
      this.typeNum = 3;
      this.getOldList();
      this.dialogVisiblefour = true;
    },
    steps(index, row) {
      console.log("步数：", index, row);
      this.memberId = row.id;
      this.userIdAA = row.id;
      this.typeNum = 6;
      this.getOldList();
      this.dialogVisiblefive = true;
    },
    rescue(index, row) {
      console.log("救援：", index, row);
      this.jiuID = row.id;
      this.userIdAA = row.id;
      if (row.openRescueCn == "已开通") {
        this.disabled1 = true;
        this.disabled = false;
        this.inputname = row.realName;
        this.inputNum = row.idcard;
        this.inputPhone = row.sparePhone;
        // this.isJiu = true;
      } else {
        this.disabled1 = false;
        // this.isJiu = false;
      }
      this.dialogVisiblesix = true;
    },
    //查询老人健康数据
    getOldList() {
      apiShout
        .getmemberHeal({
          memberId: this.memberId,
          type: this.typeNum,
        })
        .then((res) => {
          console.log("查询老人健康数据：", res.data);
          if (this.typeNum == 1) {
            this.gridDatabloodPressure = res.data.result;
          } else if (this.typeNum == 2) {
            this.gridDatabloodSugar = res.data.result;
          } else if (this.typeNum == 7) {
            this.gridDataoxygen = res.data.result;
          } else if (this.typeNum == 4) {
            this.gridDataheartRate = res.data.result;
          } else if (this.typeNum == 3) {
            this.gridDatatemperature = res.data.result;
          } else if (this.typeNum == 6) {
            this.gridDatawalk = res.data.result;
          }
        });
    },
    handelname(val) {
      console.log("名字：", val);
      this.jiuName = val;
    },
    handelNum(val) {
      console.log("身份证：", val);
      this.jiuCard = val;
    },
    handelPhone(aa) {
      console.log("电话：", aa);
      this.jiuPhone = aa;
    },
    callChange(val) {
      this.callback = val;
      console.log("开通选择：", this.callback);
      if (this.callback == "1") {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    //列表
    tableList(row, column, event) {
      // this.dialogVisible = true;
      console.log(row, event, "查看点击出来的内容");
      // if (this.noTaking == true) {
      //   event.isTrusted = false;
      // }
    },
    // handelone(val) {
    //   console.log("血压1：", val);
    //   this.bloodHigh = val;
    // },
    // handeltwo(val) {
    //   console.log("血压2：", val);
    //   this.bloodLow = val;
    // },
    handleCurrentChanges(pageCounts) {
      this.pageCounts = pageCounts;
      this.getSelectList();
    },
    // 分页
    handleSizeChanges(size) {
      this.pageSizes = size;
      this.getSelectList();
    },
    //设备使用者列表
    getList() {
      console.log("0909090909090:", this.userIdAA);
      this.tableData = [];
      apiShout.getbindEquipMem({ memberId: this.userIdAA }).then((res) => {
        console.log("设备使用者信息：", res.data);
        if (res.data.code == 200) {
          if (res.data.result.bloodSugar != null) {
            let aa = res.data.result.bloodSugar.indexOf(",");
            // console.log('字符：',res.data.result.bloodSugar.indexOf(','));
            res.data.result.bloodSugar = res.data.result.bloodSugar.substr(
              aa + 1
            );
          }
          this.tableData.push(res.data.result);
          this.xinMembenId = res.data.result.id;
          localStorage.setItem("userIds", JSON.stringify(res.data.result.id));
          localStorage.setItem("userId", JSON.stringify(res.data.result.id));
          this.xinFamilyId = res.data.result.familyId;
          this.$emit("xinMembenId", res.data.result.id);
          this.$emit("xinFamilyId", res.data.result.familyId);
          apiShout
            .getmemEquip({ memberId: this.userIdAA || this.xinMembenId })
            .then((dd) => {
              console.log("设备维护：", dd.data);
              if (dd.data.code == 200) {
                this.NewmemData = dd.data.result;
                this.$emit("NewmemData", this.NewmemData);
              } else {
                this.$message.error(dd.data.msg);
              }
            });
          // if (this.flag == true) {
          apiShout
            .getfamUserMid({ memberId: this.userIdAA || this.xinMembenId })
            .then((res) => {
              console.log("家庭成员列表：", res.data);
              if (res.data.code == 200) {
                this.xinfamData = res.data.result;
                for (let i = 0; i < this.xinfamData.length; i++) {
                  if (this.$parent.$parent.$parent.isTabs == true) {
                    this.xinfamData[i].phones = this.xinfamData[i].phone;
                  } else {
                    this.xinfamData[i].phones = this.xinfamData[i].phone;
                  }
                  this.xinfamData[i].phone =
                    this.xinfamData[i].phone.substr(0, 3) +
                    "****" +
                    this.xinfamData[i].phone.substr(7);
                }
                this.$emit("xinfamData", res.data.result);
              } else {
                this.$message.error(res.data.msg);
              }
            });
          // }
        }
      });
    },
    // getOld() {
    //   //设备维护
    //   apiShout.getmemEquip({ memberId: this.xinMembenId }).then((res) => {
    //     console.log("设备维护：", res.data);
    //     this.NewmemData = res.data.result;
    //     this.$emit('NewmemData',this.NewmemData);
    //   });
    // },
  },
};
</script>

<style lang='less' scoped>
.big {
  padding: 10px;
}
.zeng {
  background: #f09c83;
  padding: 8px 10px;
  margin-left: 20px;
}
.family {
  font-size: 20px;
  font-weight: 600;
}
.fclass {
  background: none;
  color: rgb(90, 88, 88);
  border: 0px;
}
.small {
  padding: 10px;
}
.el-form-item {
  margin-bottom: 0px;
}
/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/ .el-dialog__body {
  padding: 10px 22px;
}
/deep/.el-dialog__headerbtn {
  font-size: 20px;
}
/deep/.el-dialog__wrapper {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
}
</style>