<template>
  <div class="contentBox">
    <div class="headers">
      <span class="headersTitle">设备维护</span
      ><el-button
        class="headersAdd"
        @click="addList"
        :disabled="isNow"
        v-if="isXinZhen"
        >新增</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%" @row-click="tableList">
      <el-table-column
        prop="productName"
        label="产品名"
        width="100"
      ></el-table-column>
      <el-table-column prop="imei" label="IMEI" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.imei }}</span>
          <img
            src="../../assets/耗材更换.png"
            alt
            style="width: 15%; margin-left: 10px"
            v-show="scope.row.isResend == true"
            @click="clickReplay(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="localPhone" label="内置号码" width="110">
        <template slot-scope="scope">
          <span
            v-show="scope.row.localPhone"
            style="cursor: pointer"
            @click="builtNumber(scope.row)"
            >{{ scope.row.localPhone }}</span
          >
          <span v-show="!scope.row.localPhone">——</span>
        </template>
      </el-table-column>
      <el-table-column prop="memberName" label="使用者"></el-table-column>
      <el-table-column prop="electric" label="电量"></el-table-column>
      <el-table-column prop="activateDate" label="激活时间" width="100">
      </el-table-column>
      <el-table-column prop="lastOnlineDate" label="最后在线" width="100">
      </el-table-column>
      <!-- <el-table-column prop="volume" label="音量">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            class="fclass"
            :disabled="isNow"
            @click="volumes(scope.row)"
            >{{ scope.row.volume }}
            <div
              style="
                width: 30px;
                height: 1.5px;
                background: rgb(184, 182, 180);
                margin-top: 5px;
              "
            ></div
          ></el-button>
        </template>
      </el-table-column> -->
      <el-table-column prop="Exception" label="提醒通知" width="100">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            class="fclass"
            :disabled="isNow"
            @click="Exceptions(scope.row)"
            >设置
            <div
              style="
                width: 35px;
                height: 1.5px;
                background: rgb(184, 182, 180);
                margin-top: 5px;
              "
            ></div
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="form">
        <el-form-item>
          <el-form-item
            label="音量设置"
            label-width="80px"
            style="margin-left: 10px"
          >
            <el-input
              v-model="form.name"
              style="width: 26%; margin-right: 10px; margin-left: 46px"
            ></el-input
            >%
          </el-form-item>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="preservation">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 提醒通知 -->
    <el-dialog :visible.sync="SpringFrame" width="40%">
      <div style="display: flex">
        <div style="border: 1px solid #eee; margin: 10px; padding: 10px">
          <span style="position: relative; top: -22px; background: white"
            >日常提醒</span
          >
          <el-form ref="formOne" :model="formTwo">
            <el-form-item label="天气预报:" style="display: flex" v-if="isTong">
              <el-select
                v-model="weather"
                placeholder="输入关键字"
                style="width: 150px"
              >
                <el-option
                  v-for="item in opWeather"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="日常广播:"
              style="display: flex"
              v-if="isTong1"
            >
              <el-select
                v-model="daily"
                placeholder="输入关键字"
                style="width: 150px"
              >
                <el-option
                  v-for="item in opDaily"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="低电提醒:"
              style="display: flex"
              v-if="isTong2"
            >
              <el-select
                v-model="lowPower"
                placeholder="输入关键字"
                style="width: 150px"
              >
                <el-option
                  v-for="item in opLowPower"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="健康数据:"
              style="display: flex"
              v-if="isTong3"
            >
              <el-select
                v-model="health"
                placeholder="输入关键字"
                style="width: 150px"
              >
                <el-option
                  v-for="item in opHealth"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div
          style="border: 1px solid #eee; margin: 10px; padding: 10px"
          v-if="isTong4"
        >
          <span style="position: relative; top: -22px; background: white"
            >异常通知</span
          >
          <el-form ref="form" :model="formOne">
            <el-form-item label="通知方式:" style="display: flex">
              <el-select
                v-model="notification"
                placeholder="输入关键字"
                style="width: 150px"
                @change="changNo"
              >
                <el-option
                  v-for="item in opNotification"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item>
            <el-radio-group v-model="radio" @change="tiRadio">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">电话</el-radio>
              <el-radio :label="2">短信</el-radio>
              <el-radio :label="3">电话+短信</el-radio>
            </el-radio-group>
          </el-form-item> -->
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="increase">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 内置号码 -->
    <el-dialog
      :visible.sync="dialogVisibleBuilt"
      title="修改内置号码"
      width="30%"
    >
      <el-form
        ref="Builtform"
        :model="Builtform"
        style="width: 75%; margin-left: 4%"
      >
        <el-form-item>
          <el-input
            :disabled="builtShow"
            v-model="Builtform.builtPhone"
            autocomplete="off"
            style="height: auto"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox-group
            v-model="Builtform.type"
            @change="currentPhone(Builtform.type)"
          >
            <el-checkbox label="当前通话号码" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button
          type="primary"
          @click="preservationBuilt"
          style="padding: 12px 51px"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 新增的弹框 -->
    <el-dialog
      title="新增设备"
      :visible.sync="newlyAdded"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="formTwo" label-width="20px">
        <el-form-item>
          <el-input
            v-model="value"
            style="width: 60%; margin-right: 15px"
            @change="valSou"
            :disabled="valSous"
          ></el-input>
          <i class="el-icon-search" style="font-size: 26px" @click="search"></i>
        </el-form-item>
        <el-form-item>
          <el-checkbox
            label="当前设备"
            v-model="equipmentIds"
            name="type"
            :disabled="isXian"
            @change="checkB"
            true-label="1"
            false-label="0"
          ></el-checkbox>
        </el-form-item>
        <el-form-item v-if="searchList">
          <el-checkbox
            v-model="checked"
            :disabled="isChe"
            v-if="checkboxT"
          ></el-checkbox>
          <span
            style="
              color: #d78673;
              font-weight: bold;
              margin-right: 16px;
              font-size: 15px;
            "
            >{{ productName }}</span
          >
          <span style="color: #d78673; margin-right: 16px; font-size: 14px">{{
            statusCn
          }}</span>
          <!-- <el-button
            style="padding: 6px 18px; border: 1px solid #333"
            @click="bangDing"
            >绑定</el-button
          > -->
        </el-form-item>
        <!-- <el-form-item v-if="isYou">
          <p
            style="
              line-height: 15px;
              font-size: 16px;
              font-weight: bold;
              margin-top: 7px;
              margin-bottom: 6px;
            "
          >
            选择使用者
          </p>
          <div v-for="(Item, Index) in radioList" :key="Index">
            <el-radio-group v-model="Item.name" @change="userRadio">
              <el-radio
                :label="Item.id"
                :key="Item.id"
                >{{ Item.name }}</el-radio
              >
            </el-radio-group>
          </div>

          <div
            v-for="(Item, Index) in radioList"
            :key="Index"
            style="display: inline; margin-left: 20px"
          >
            <input
              type="radio"
              :id="Item.id"
              :value="Item.name"
              v-model="picked2"
              @change="userRadio"
            />
            <label :for="Item.id">{{ Item.name }}</label>
          </div>

        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="increaseTwo">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 换机的弹框 -->
    <el-dialog
      :visible.sync="diaReplacement"
      width="40%"
      :close-on-click-modal="false"
      :modal="false"
    >
      <span>
        执行后，新老设备数据将立即交换，此操作不可逆。请务必在确定用户收到新
        设备后再执行此操作。 是否立即执行换机操作？
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="diaReplacement = false">取 消</el-button>
        <el-button type="primary" @click="queitReplay">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
export default {
  props: [
    "memData",
    "eqfamliyId",
    "userId",
    "forDataList",
    "equipmentIdStr",
    "msgList",
    "xinMembenIdaa",
    "xinFamilyIdaa",
    "noTaking",
  ],
  watch: {
    userId(cc) {
      if (cc == "" || cc == null) {
        this.isXinZhen = true;
      } else {
        this.isXinZhen = true;
      }
    },
    //  noTaking(aa){
    //    if(aa==true){
    //      this.isNow=true;
    //    }else{
    //      this.isNow=false;
    //    }
    //  },
    msgList(dd) {
      this.tableData = [];
      console.log("设备使用者传过来的：", dd);
      this.tableData = dd;
      this.tableData.forEach((ele, index) => {
        if (ele.productName == "智能呼叫器" && ele.replaceId != null) {
          this.isResend = true;
          let obj = {
            id: ele.id,
            value: ele.replaceId,
          };
          this.replayId.push(obj);
          this.runSelMap = []; //存放过滤后的数组
          let map = new Map();
          for (let item of this.replayId) {
            map.set(item.id, item);
            this.runSelMap = [...map.values()];
          }
        } else {
          this.isResend = false;
        }
        this.$set(this.tableData[index], "isResend", this.isResend);
      });
    },
    equipmentIdStr(equipmentIdOne) {
      this.equipmentIds = equipmentIdOne;
      console.log(this.equipmentIds, "equipmentIdsequipmentIds");
    },
    forDataList(dd) {
      if (dd != undefined) {
        this.isXian = false;
        this.isYou = true;
      } else {
        this.isXian = true;
        this.isYou = false;
        // this.bangUserId=dd.id;
      }
      console.log("该设备是否有使用者：", dd.id);
    },
    memData(val) {
      console.log("传过来的列表:", val);
      this.$nextTick(() => {
        this.tableData = val;
        this.tableData.forEach((ele, index) => {
          if (ele.productName == "智能呼叫器" && ele.replaceId != null) {
            this.isResend = true;
            let obj = {
              id: ele.id,
              value: ele.replaceId,
            };
            this.replayId.push(obj);
            this.runSelMap = []; //存放过滤后的数组
            let map = new Map();
            for (let item of this.replayId) {
              map.set(item.id, item);
              this.runSelMap = [...map.values()];
            }
          } else {
            this.isResend = false;
          }
          this.$set(this.tableData[index], "isResend", this.isResend);
        });
        // console.log("收集的数组：", this.runSelMap);
      });
    },
    xinFamilyIdaa(cc) {
      if (cc != undefined || cc != null || cc != "") {
        this.eqfamliyId = cc;
      }
    },
    // eqfamliyId(aa) {
    //   console.log("传到设备维护的家庭id:", aa);
    //   this.familyId = aa;
    // },
    // userId(cc) {
    //   console.log("传过来的memberId:", cc);
    //   this.memberId = cc;
    // },
  },
  data() {
    return {
      isTong: true,
      isTong1: true,
      isTong2: true,
      isTong3: true,
      isTong4: true,
      weather: "",
      opWeather: [
        {
          value: "0",
          label: "关闭",
        },
        {
          value: "1",
          label: "开启",
        },
      ],
      daily: "",
      opDaily: [
        {
          value: "0",
          label: "关闭",
        },
        {
          value: "1",
          label: "开启",
        },
      ],
      lowPower: "",
      opLowPower: [
        {
          value: "0",
          label: "关闭",
        },
        {
          value: "1",
          label: "开启",
        },
      ],
      health: "",
      opHealth: [
        {
          value: "0",
          label: "关闭",
        },
        {
          value: "1",
          label: "开启",
        },
      ],
      notification: "",
      opNotification: [
        {
          value: "0",
          label: "无",
        },
        {
          value: "1",
          label: "电话",
        },
        {
          value: "2",
          label: "短信",
        },
        {
          value: "3",
          label: "电话+短信",
        },
      ],
      reuserId: null,
      runSelMap: [],
      isResend: false,
      diaReplacement: false,
      isReplay: false,
      replayId: [],
      dialogVisibleBuilt: false,
      backPhone: "",
      backID: null,
      Builtform: {
        builtPhone: "",
        type: "",
      },
      builtShow: false,
      isChe: false,
      successCodeOne: null,
      xinList1: null,
      isXinZhen: true,
      equipmentIds: null,
      valSous: false,
      checked: false,
      isNow: false,
      picked2: "",
      isXian: true,
      isYou: true,
      memberId: "",
      familyId: "",
      dialogVisible: false,
      SpringFrame: false,
      newlyAdded: false,
      searchList: false,
      checkboxT: false,
      radio: "",
      tableData: [],
      radioList: [
        { id: "1", name: "李璐" },
        { id: "2", name: "lisi" },
      ],
      value: "",
      form: {
        name: "55",
        input: "1",
        region: "片",
        value1: new Date(2016, 9, 10, 18, 40),
      },
      formOne: {
        desc: "",
      },
      formTwo: {
        name: "",
      },
      listName: [
        {
          id: "0",
          name: "片",
        },
        {
          id: "1",
          name: "粒",
        },
        {
          id: "2",
          name: "瓶",
        },
        {
          id: "3",
          name: "剂",
        },
        {
          id: "4",
          name: "袋",
        },
        {
          id: "5",
          name: "ml",
        },
      ],
      imeiValue: "",
      productName: "",
      equId: "",
      statusCn: "",
      bangUserId: "",
      nowEquID: "",
      tiVal: "",
      tiEquID: "",
      xiuEq: null,
      xiuMem: null,
      xiuFam: "",
      xiuId: "",
      isNoti: false,
      isNoti1: false,
    };
  },
  created() {
    console.log("000:", this.userId, this.eqfamliyId, this.xinMembenIdaa);
  },
  methods: {
    changNo() {
      this.isNoti = true;
    },
    clickReplay(row) {
      console.log(row, "设备维护点击某一行");
      this.runSelMap.forEach((el) => {
        if (el.id == row.id && el.value != null) {
          this.diaReplacement = true;
          this.reuserId = el.value;
        }
      });
    },
    queitReplay() {
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      let eqId = JSON.parse(localStorage.getItem("strequipmentIds"));
      let UnId = JSON.parse(localStorage.getItem("userIds"));
      if (eqId == null || eqId == "") {
        this.$message.error("设备id不能为空");
        return;
      }
      if (this.reuserId == null || this.reuserId == "") {
        this.$message.error("换机信息id不能为空");
        return;
      }
      if (UniqueId == null || UniqueId == "") {
        this.$message.error("通话记录不能为空");
        return;
      }
      // let obj = {
      //   equipmentId: eqId || this.equId,
      //   id: this.reuserId,
      //   mainUniqueId: UniqueId,
      //   memberId: UnId || this.xinMembenIdaa,
      // };
      // console.log("传入的值是否全：",obj);
      apiShout
        .getexReplay({
          equipmentId: eqId,
          id: this.reuserId,
          mainUniqueId: UniqueId,
          memberId: UnId,
        })
        .then((res) => {
          console.log("确定换机：", res.data);
          if (res.data.code == 200) {
            this.diaReplacement = false;
            this.$message({
              type: "success",
              message: "换机成功",
            });
            this.getOld();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //内置号码
    builtNumber(row) {
      console.log(row.localPhone);
      this.backPhone = row.localPhone;
      this.backID = row.id;
      this.dialogVisibleBuilt = true;
      let ph = JSON.parse(localStorage.getItem("phoneNumber"));
      if (row.localPhone !== "" || row.localPhone !== null) {
        if (ph == null) {
          this.Builtform.builtPhone = row.localPhone;
          this.Builtform.type = true;
          this.builtShow = true;
        } else {
          this.Builtform.builtPhone = ph;
          this.Builtform.type = true;
          this.builtShow = true;
        }
        // this.Builtform.builtPhone = row.localPhone;
        // this.Builtform.type = true;
        // this.builtShow = true;
      }
    },
    //是否取消当前手机号
    currentPhone(item) {
      console.log(item, "查看类型");

      if (item === true) {
        this.builtShow = true;
        this.Builtform.builtPhone = this.backPhone;
      } else {
        this.builtShow = false;
        this.Builtform.builtPhone = "";
      }
    },
    //内置号码确定
    preservationBuilt() {
      let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
      let userMem = JSON.parse(localStorage.getItem("userId"));
      apiShout
        .equipmentPhone({ phone: this.Builtform.builtPhone })
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res, "检查号码");
            this.$confirm(res.data.result, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                if (userMem != null) {
                  apiShout
                    .updateLocalPhone({
                      id: this.backID,
                      phone: this.Builtform.builtPhone,
                      mainUniqueId: UniqueId,
                      memberId: userMem,
                    })
                    .then((res) => {
                      if (res.data.code === 200) {
                        this.$message({
                          type: "success",
                          message: "修改成功!",
                        });
                        console.log(res.data, "输出结果");
                        this.dialogVisibleBuilt = false;
                        this.getOld();
                      } else {
                        this.$message.error(res.data.msg);
                      }
                    });
                } else {
                  apiShout
                    .updateLocalPhone({
                      id: this.backID,
                      phone: this.Builtform.builtPhone,
                      mainUniqueId: UniqueId,
                    })
                    .then((res) => {
                      if (res.data.code === 200) {
                        this.$message({
                          type: "success",
                          message: "修改成功!",
                        });
                        console.log(res.data, "输出结果");
                        this.dialogVisibleBuilt = false;
                        this.getOld();
                      } else {
                        this.$message.error(res.data.msg);
                      }
                    });
                }
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // dataOne(dd){
    //   console.log("该设备是否有使用者3：", dd);
    //   console.log("该设备是否有使用者3：");
    //   if (dd==undefined) {
    //     this.isXian = false;
    //     this.isYou=true;
    //   } else {
    //     this.isXian = true;
    //     this.isYou=false;
    //     this.bangUserId=this.userId;
    //   }
    // },
    tiRadio(val) {
      console.log("提醒：", val);
      this.tiVal = val;
    },
    valSou(val) {
      console.log("==", val);
      this.imeiValue = val;
    },
    checkB(aa) {
      console.log("--", aa);
      if (aa === "1") {
        let aa = JSON.parse(localStorage.getItem("strequipmentIds"));
        //选中
        apiShout.getEquipmentByIds({ id: aa }).then((res) => {
          console.log("设备IMEI获取 ：", res.data);
          this.value = res.data.result.imei;
          apiShout.getequIMEI({ imei: res.data.result.imei }).then((res) => {
            console.log("搜索：", res.data);
            if (res.data.code == 200) {
              this.productName = res.data.result.productName;
              this.equId = res.data.result.id;
              this.statusCn = res.data.result.statusCn;
            }
          });
        });
        this.searchList = true;
        this.checkboxT = false;
        this.isChe = true;
        this.valSous = true;
        console.log("-----1", aa);
        // this.nowEquID = this.tableData[0].id;
        // this.searchList=true;
      } else {
        this.valSous = false;
        console.log("-----0", aa);
      }
      // else{
      //   this.searchList=false;
      // }
    },
    // userRadio(bb) {
    //   console.log("选中绑定人：", bb.srcElement.id);
    //   this.bangUserId = bb.srcElement.id;
    // },
    // bangDing() {},
    //列表
    tableList(row, column, event) {
      // this.dialogVisible = true;
      // if (this.noTaking == true) {
      //   event.isTrusted = false;
      // }
      console.log(row, event, "查看点击出来的内容");
    },
    //音量
    volumes(val) {
      console.log(val.volume, "列表单独点击的数据弹框");
      this.dialogVisible = true;
    },
    //异常通知
    Exceptions(val) {
      console.log(val, "弹框2出现数据");
      this.isNoti = false;
      this.tiEquID = val.id;
      apiShout.getnoConf({ id: val.id }).then((res) => {
        console.log("查询：", res.data);
        if (res.data.code == 200) {
          if (res.data.result.productType == 5) {
            this.isTong = true;
            this.isTong1 = true;
            this.isTong2 = true;
            this.isTong3 = true;
            this.isTong4 = true;
            this.SpringFrame = true;
          } else if (res.data.result.productType == 2) {
            this.isTong = false;
            this.isTong1 = false;
            this.isTong2 = true;
            this.isTong3 = false;
            this.isTong4 = false;
            this.SpringFrame = true;
          } else if (res.data.result.productType == 3) {
            this.isTong = false;
            this.isTong1 = false;
            this.isTong2 = true;
            this.isTong3 = false;
            this.isTong4 = false;
            this.SpringFrame = true;
          } else if (res.data.result.productType == 1) {
            this.isTong = false;
            this.isTong1 = false;
            this.isTong2 = true;
            this.isTong3 = true;
            this.isTong4 = true;
            this.SpringFrame = true;
          }
          this.lowPower = res.data.result.electric;
          this.health = res.data.result.health;
          this.weather = res.data.result.weather;
          this.notification = res.data.result.noticeType;
          if (res.data.result.noticeType == null) {
            this.notification = "未设置";
          }
          this.daily = res.data.result.daily;
          this.xiuEq = res.data.result.equipmentId;
          this.xiuMem = res.data.result.memberId;
          this.xiuFam = res.data.result.familyId;
          this.xiuId = res.data.result.id;
        }
      });
    },
    //异常通知确认框
    increase() {
      this.$confirm("是否保存此信息?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.SpringFrame = false;
          let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
          apiShout
            .getupNoConf({
              equipmentId: this.xiuEq,
              mainUniqueId: UniqueId,
              memberId: this.xiuMem,
              familyId: this.xiuFam,
              id: this.xiuId,
              electric: this.lowPower,
              health: this.health,
              weather: this.weather,
              daily: this.daily,
            })
            .then((aa) => {
              console.log("日常：", aa.data);
              if (aa.data.code == 200) {
                if (this.isNoti == false) {
                  this.$message({
                    type: "success",
                    message: "保存成功!",
                  });
                }
                this.isNoti = false;
                this.getOld();
              } else {
                this.$message.error(aa.data.msg);
              }
            });
          if (this.isNoti == true) {
            apiShout
              .getupNotice({
                equipmentId: this.equipmentIds || this.tiEquID,
                mainUniqueId: UniqueId,
                memberId: this.userId || this.xinMembenIdaa,
                noticeType: this.notification,
              })
              .then((res) => {
                console.log("异常提醒：", res.data);
                if (res.data.code == 200) {
                  this.$message({
                    type: "success",
                    message: "保存成功!",
                  });
                  this.radio = "";
                  this.getOld();
                } else {
                  this.$message.error(res.data.msg);
                }
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
    increaseTwo() {
      this.$confirm("是否保存此信息?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.newlyAdded = false;
          let UniqueId = JSON.parse(localStorage.getItem("UniqueIdn"));
          let UnId = JSON.parse(localStorage.getItem("userIds"));
          let eqId = JSON.parse(localStorage.getItem("strequipmentIds"));
          apiShout
            .getbindEquCno({
              bindEquipmentId: this.equId || eqId,
              bindFamilyId: this.eqfamliyId || this.xinFamilyIdaa,
              bindMemberId: UnId,
              equipmentId: eqId,
              mainUniqueId: UniqueId,
              memberId: UnId || this.xinMembenIdaa,
            })
            .then((res) => {
              console.log("绑定：", res.data);
              this.successCodeOne = res.data.code;
              this.$emit("successCodeOnes", this.successCodeOne);
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "绑定成功",
                });
                this.xinList1 = res.data.code;
                this.$emit("xinList1", res.data.code);
                this.getOld();
                this.radio = "";
                this.value = "";
              } else {
                this.$message.error(res.data.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
    handleClose() {
      this.newlyAdded = false;
      this.radio = "";
      this.value = "";
      this.radioList = [];
    },
    //新增
    addList() {
      this.equipmentIds = "";
      this.valSous = false;
      this.newlyAdded = true;
      this.searchList = false;
      this.isXian = false;
      apiShout.getmenFamId({ familyId: this.eqfamliyId }).then((res) => {
        console.log("家庭使用人：", res.data);
        if (res.data.code == 200) {
          this.radioList = res.data.result;
        }
      });
    },
    //保存
    preservation() {
      this.$confirm("是否保存此信息?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.dialogVisible = false;
          this.$message({
            type: "success",
            message: "保存成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
    search() {
      this.searchList = true;
      this.checkboxT = true;
      this.isChe = false;
      this.isXian = true;
      apiShout.getequIMEI({ imei: this.imeiValue }).then((res) => {
        console.log("搜索：", res.data);
        if (res.data.code == 200) {
          this.productName = res.data.result.productName;
          this.equId = res.data.result.id;
          this.statusCn = res.data.result.statusCn;
          if (this.statusCn == "正常") {
            this.checked = true;
          } else {
            this.checked = false;
          }
        }
      });
    },
    getOld() {
      //设备维护
      let UnId = JSON.parse(localStorage.getItem("userIds"));
      apiShout
        .getmemEquip({ memberId: UnId || this.xinMembenIdaa })
        .then((res) => {
          console.log("设备维护：", res.data);
          this.tableData = res.data.result;
          this.tableData.forEach((ele, index) => {
            if (ele.productName == "智能呼叫器" && ele.replaceId != null) {
              this.isResend = true;
              let obj = {
                id: ele.id,
                value: ele.replaceId,
              };
              this.replayId.push(obj);
              this.runSelMap = []; //存放过滤后的数组
              let map = new Map();
              for (let item of this.replayId) {
                map.set(item.id, item);
                this.runSelMap = [...map.values()];
              }
            } else {
              this.isResend = false;
            }
            this.$set(this.tableData[index], "isResend", this.isResend);
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.contentBox {
  height: auto;
  background: #fff;
  padding: 5px;
  margin: 12px 5px;
}
.headersTitle {
  font-size: 20px;
  font-weight: bold;
}
.headersAdd {
  padding: 8px 10px;
  border: 1px solid #333;
  margin-left: 15px;
  background: #f09c83;
  color: #fff;
}
.fclass {
  background: none;
  color: rgb(90, 88, 88);
  border: 0px;
}
/deep/ .el-form-item {
  margin-bottom: 0px;
}
/deep/ .el-input__inner {
  height: 40px !important;
  margin-left: 0px !important;
}
/deep/.el-dialog__headerbtn {
  font-size: 20px;
}
/deep/.el-dialog__wrapper {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
}
</style>