<template>
  <div class="contentBox">
    <div class="headers" v-if="tableHidden">
      <span class="headersTitle">留言提醒</span
      ><el-button
        class="headersAdd"
        @click="addList"
        :disabled="disableds"
        v-if="isXinZhen"
        >新增</el-button
      >
    </div>
    <el-table
      v-if="tableHidden"
      :data="tableData"
      style="width: 100%"
      @row-click="tableList"
    >
      <el-table-column prop="memberName" label="使用者"></el-table-column>
      <el-table-column prop="productName" label="产品名"></el-table-column>
      <el-table-column prop="date" label="日期(每周)">
        <template slot-scope="scope">
          <div>
            <span>{{
              scope.row.frequency == 1
                ? scope.row.week || "每天"
                : scope.row.date
            }}</span>
            <!-- <span>{{ scope.row.date == null ? "每天" : scope.row.date }}</span> -->
            <!-- <span v-else value="0">已关闭</span> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="time" label="时间"></el-table-column>
      <el-table-column prop="type" label="类型">
        <template slot-scope="scope">
          <div>
            <span>{{ scope.row.type == 1 ? "文字" : "语音" }}</span>
            <!-- <span v-else value="0">已关闭</span> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="message" label="内容" width="150">
        <template slot-scope="scope">
          <!-- <div v-if="scope.row.type == 2">
            <audio controls :src="scope.row.message"></audio>
          </div>
          <div v-else>
            <span>{{ scope.row.message }}</span>
          </div> -->
          <div>
            <span>{{ scope.row.message }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="生效">
        <template slot-scope="scope">
          <el-button
            style="background: none; border: none"
            @click="volumes(scope.row)"
            v-if="scope.row.isResend == true"
            :disabled="true"
            ><span>{{
              scope.row.status == 0 ? "已开启" : "已关闭"
            }}</span></el-button
          >
          <el-button
            style="background: none; border: none"
            @click="volumes(scope.row)"
            v-else
            ><span>{{
              scope.row.status == 0 ? "已开启" : "已关闭"
            }}</span></el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="resendVal" label="重发">
        <template slot-scope="scope">
          <el-button
            style="background: none; border: none"
            @click="resend(scope.row)"
            ><span>{{
              scope.row.resendVal == 0 ? "5分钟后发送" : "取消发送"
            }}</span></el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="newlyAdded"
      width="37%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="20px">
        <el-form-item>
          <el-select
            style="width: 38%"
            v-model="form.equipmentId"
            @change="equipments(form.equipmentId)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in formOne"
              :key="item.id"
              :label="item.productName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-date-picker
            v-model="form.date"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            style="width: 35%; margin-right: 10px"
            @input="$forceUpdate()"
            @change="dateText(form.date)"
            :disabled="isDate"
          >
          </el-date-picker>
          <el-checkbox
            label="每天"
            true-label="1"
            false-label="2"
            name="type"
            v-model="form.frequency"
            @change="everyData"
          ></el-checkbox>
          <el-time-picker
            v-model="form.time"
            placeholder="任意时间点"
            format="HH:mm:ss"
            value-format="HH:mm:ss"
            style="width: 28%; margin-right: 30px; float: right"
            @input="$forceUpdate()"
            @change="dateTime(form.time)"
          >
          </el-time-picker>
        </el-form-item> -->
        <el-row>
          <el-col :span="6">
            <el-form-item label="" class="senOne">
              <el-select
                v-model="form.sendType"
                placeholder="请选择发送方式"
                class="sendFirst"
                @change="sendMethod"
                @input="$forceUpdate()"
              >
                <el-option
                  v-for="item in opArea3"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" v-if="showOne">
            <el-form-item label="" class="senTwo">
              <el-date-picker
                v-model="form.date"
                type="date"
                placeholder="选择日期"
                class="sendFirstOne"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                @input="$forceUpdate()"
                @change="riqi"
                :clearable="false"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10" class="zhouOne" v-if="!showOne">
            <span class="zhou">周</span>
            <el-checkbox-group v-model="doc.doctime" @change="changeDoctime">
              <el-checkbox
                v-for="item in doctimeData"
                :label="item.value"
                true-label
                :key="item.value"
                >{{ item.title }}</el-checkbox
              >
            </el-checkbox-group>
          </el-col>
          <el-col :span="6">
            <el-form-item label="">
              <el-time-picker
                v-model="form.time"
                class="sendFirst"
                placeholder="选择时间"
                format="HH:mm:ss"
                value-format="HH:mm:ss"
                :clearable="false"
                :picker-options="{
                  selectableRange: startTimeRange2,
                }"
                @input="formChange2"
                @change="changeTime2"
              >
              </el-time-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-input
            type="textarea"
            v-model="form.message"
            style="width: 93%"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-checkbox
            label="开启"
            true-label="0"
            false-label="1"
            name="type"
            v-model="form.status"
          ></el-checkbox>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="increaseTwo">确 定</el-button>
      </span>
    </el-dialog>
    <el-form v-if="formList" ref="forms" :model="forms">
      <el-form-item>
        <div
          style="width: 55%; background: #f3f3f3; margin-top: 2%; padding: 9px"
        >
          <p style="font-size: 15px; font-weight: bold">
            张老三呼叫器的留言提醒
          </p>
          <el-card class="box-card">
            <div
              v-for="(o, index) in 6"
              :class="{ active: currentIndex === index }"
              :key="index"
              id="text"
              @click="openArticle(index)"
            >
              {{ "内容显示 " + o }}
            </div>
          </el-card>
          <!-- <el-input
            type="textarea"
            v-model="forms.desc"
            style="width: 98%"
          ></el-input> -->
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
// import Vue from "_vue@2.6.14@vue";
export default {
  props: ["noTaking", "userId"],
  data() {
    return {
      startTimeRange2: ["00:00:00 - 23:59:59"],
      weekList: "",
      doc: {
        doctime: ["1", "2", "3", "4", "5", "6", "7"],
        // doctime: [1, 2, 3, 4, 5, 6, 7],
      },
      doctimeData: [
        {
          value: "1",
          title: "一",
          id: 2,
        },
        {
          value: "2",
          title: "二",
          id: 3,
        },
        {
          value: "3",
          title: "三",
          id: 4,
        },
        {
          value: "4",
          title: "四",
          id: 5,
        },
        {
          value: "5",
          title: "五",
          id: 6,
        },
        {
          value: "6",
          title: "六",
          id: 7,
        },
        {
          value: "7",
          title: "日",
          id: 1,
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      opArea3: [
        {
          code: "1",
          name: "发送一次",
        },
        {
          code: "2",
          name: "循环发送",
        },
      ],
      showOne: true,
      sendMet: "1",
      isResend: false,
      isDate: false,
      wenSize: 1,
      isXinZhen: true,
      dialogVisible: false,
      SpringFrame: false,
      newlyAdded: false,
      tableHidden: true,
      formList: false,
      currentIndex: null,
      userIds: null,
      disableds: false,
      forms: {
        desc: "",
      },
      tableData: [],
      form: {
        frequency: "2",
        mainUniqueId: "",
        memberId: null,
        sendType: "1",
        date: "",
        time: "",
      },
      formOne: [],
      listName: [
        {
          id: "0",
          name: "片",
        },
        {
          id: "1",
          name: "粒",
        },
        {
          id: "2",
          name: "瓶",
        },
        {
          id: "3",
          name: "剂",
        },
        {
          id: "4",
          name: "袋",
        },
        {
          id: "5",
          name: "ml",
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  watch: {
    userId(cc) {
      if (cc == "" || cc == null) {
        this.isXinZhen = false;
      } else {
        this.isXinZhen = true;
      }
    },
    // noTaking(aa) {
    //   if (aa == true) {
    //     this.disableds = false;
    //   } else {
    //     this.disableds = false;
    //   }
    // },
  },
  methods: {
    formChange2() {
      this.$forceUpdate();
      var data = new Date();
      var aa = data.getHours();
      var bb = data.getMinutes();
      var cc = data.getSeconds();
      var dd = aa + ":" + bb + ":" + cc;
      // 获取当前时间
      var currentTime = new Date();
      let array = this.form.date.substring(0, 4);
      let array1 = this.form.date.substring(5, 7);
      let array2 = this.form.date.substring(8);
      console.log("shuchushijain:", array, array1, array2);
      // // 设置要比较的年、月、日
      // var yearToCompare = 2021; // 需要比较的年份
      // var monthToCompare = 9; // 需要比较的月份（注意月份从0开始计数）
      // var dayToCompare = 30; // 需要比较的日期

      // 创建一个新的Date对象并传入指定的年、月、日
      var dateToCheck = new Date(array, array1 - 1, array2);
      if (this.sendMet == 2) {
        this.startTimeRange2 = "00:00:00" + " - 23:59:59";
      } else {
        // 将两个日期进行比较
        if (
          dateToCheck.getFullYear() === currentTime.getFullYear() &&
          dateToCheck.getMonth() === currentTime.getMonth() &&
          dateToCheck.getDate() === currentTime.getDate()
        ) {
          console.log("该日期是今天");
          this.startTimeRange2 = dd + " - 23:59:59";
        } else {
          this.startTimeRange2 = "00:00:00" + " - 23:59:59";
          console.log("该日期不是今天");
        }
      }
    },
    changeTime2() {
      console.log("shuchu xianshi d shijain :", this.form.time);
    },
    changeDoctime(bb) {
      console.log("bb", bb);
      this.weekList = bb.join(",");
    },
    riqi(val) {
      console.log("riqi:", val);
    },
    sendMethod(val) {
      console.log("xianzhe:", val);
      this.sendMet = val;
      if (val == 1) {
        this.showOne = true;
        this.form.time = "";
        this.form.date = "";
      } else {
        this.showOne = false;
        this.form.time = "";
      }
    },
    everyData(aa) {
      // console.log("是否选择了每天：", aa);
      if (aa == 1) {
        this.form.date = "";
        this.isDate = true;
      } else {
        this.isDate = false;
        let newDate = new Date();
        let y = newDate.getFullYear();
        let MM = newDate.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = newDate.getDate();
        d = d < 10 ? "0" + d : d;
        let dates = y + "-" + MM + "-" + d;
        this.form.date = dates;
      }
    },
    getList() {
      let UniqueId = JSON.parse(localStorage.getItem("userIds"));
      console.log(UniqueId, "查看//////0000------------------------");
      if (UniqueId == null) {
        this.tableData = [];
        this.userIds = null;
        console.log("this.userIds:", this.userIds);
        return false;
      }
      let UseridData = UniqueId;
      // console.log(UseridData, "查看//////");
      this.userIds = UniqueId;
      console.log(this.userIds, "查看userid是否显示正确查看");
      apiShout.getLeaveMessages({ memberId: UseridData }).then((res) => {
        console.log(res, "查询数据");
        if (res.data.code === 200) {
          // if (this.wenSize == 1) {
          this.tableData = res.data.result;
          this.tableData.forEach((item) => {
            this.tableData.forEach((item, index) => {
              let ll = item.date + " " + item.time;
              let op = new Date(ll).getTime();
              // console.log("时间合并：", op);
              let ui = new Date().getTime();
              // console.log("当前时间：", ui);
              if (item.date != null) {
                if (item.status == "1" && op < ui) {
                  this.isResend = true;
                } else {
                  this.isResend = false;
                }
              } else {
                this.isResend = false;
              }
              this.$set(this.tableData[index], "resendVal", 0);
              this.$set(this.tableData[index], "isResend", this.isResend);
            });
            if (item.message.length > 5) {
              item.message = item.message.slice(0, 5) + "...";
            }

            // if(item.status=='1'&&op<ui&&item.date!=null){
            //   this.isResend=true;
            // }else{
            //   this.isResend=false;
            // }
          });
          // } else if (this.wenSize == 2) {
          //   this.tableData = res.data.result;
          //   this.tableData.forEach((item) => {
          //     item.resendVal = 1;
          //     //  console.log('字符个数：',item.message.length);
          //     if (item.message.length > 5) {
          //       item.message = item.message.slice(0, 5) + "...";
          //       // console.log("留言字数：", item.message);
          //     }
          //   });
          // }
        } else {
          this.$message.error(res.data.msg);
        }
        // else{
        //   this.$message.error(res.data.msg);
        // }
      });
    },
    dateText(val) {
      console.log(val, "日期");
    },
    dateTime(val) {
      console.log(val, "时间");
    },
    //列表
    tableList(val) {
      console.log(val, "查看点击出来的内容");
      this.messageId = val.id;
      // this.dialogVisible = true;
    },
    //音量
    volumes(val) {
      this.disableds = false;
      this.$confirm("是否更新此信息?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (val.status == 0) {
            val.status = 1;
          } else if (val.status == 1) {
            val.status = 0;
          }
          let UniqueIds = JSON.parse(localStorage.getItem("UniqueIdn"));
          let obj = {
            id: val.id,
            status: val.status,
            mainUniqueId: UniqueIds,
            memberId: this.userIds,
          };
          apiShout.updateLeaveMessages(obj).then((res) => {
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "更新成功!",
              });
            } else {
              this.$message.error(res.data.msg);
            }
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
      //   this.statusId = val.id;
      console.log(val, "列表单独点击的数据弹框");
    },
    resend(val) {
      this.$confirm("确认提交此信息?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (val.resendVal == 0) {
          //稍后发送
          apiShout.getresendLeave({ id: this.messageId }).then((res) => {
            console.log("重发提醒留言：", res.data);
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "重新发送成功!",
              });
              val.resendVal = 1;
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else if (val.resendVal == 1) {
          //取消发送
          apiShout.getcanREL({ id: this.messageId }).then((ss) => {
            console.log("取消重新发送：", ss.data);
            if (ss.data.code == 200) {
              this.$message({
                type: "success",
                message: "取消重新发送成功!",
              });
              val.resendVal = 0;
            } else {
              this.$message.error(ss.data.msg);
            }
          });
        }
      });
      // .catch(() => {
      //   this.$message({
      //     type: "info",
      //     message: "已取消操作！",
      //   });
      // });
    },
    openArticle(index) {
      this.currentIndex = index;
      console.log(index, "call_center.vue");
    },
    increaseTwo() {
      this.$confirm("是否保存此信息?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //   console.log(this.form.frequency, "是不是空");
          console.log(this.form, "默认展示");
          let UniqueIds = JSON.parse(localStorage.getItem("UniqueIdn"));
          this.form.mainUniqueId = UniqueIds;
          this.form.memberId = JSON.parse(localStorage.getItem("userIds"));
          console.log("上传数据：", this.form);
          let cn = Object.keys(this.form);
          console.log("key:", cn);
          if (cn.indexOf("time") == -1) {
            this.$message.error("请选择留言时间");
            return false;
          }
          if (cn.indexOf("date") == -1) {
            this.$message.error("请选择留言日期");
            return false;
          }
          if (this.form.frequency == 1) {
            this.form.date = "";
          }
          if (this.sendMet == "1") {
            //发送一次
            apiShout.insertLeaveMessages(this.form).then((res) => {
              console.log(res, "显示数据");
              if (res.data.code === 200) {
                this.$message({
                  type: "success",
                  message: "新增成功!",
                });
                this.form.equipmentId = null;
                this.form.date = "";
                this.form.time = "";
                this.form.message = "";
                this.form.frequency = "";
                this.newlyAdded = false;
                //   this.formList = true;
                //   this.tableHidden = false;
                this.getList();
              } else {
                this.$message.error(res.data.msg);
              }
            });
          } else {
            if (this.doc.doctime.length == 0) {
              this.$message.error("请选择发送时间");
              return false;
            }
            this.form.frequency = "1";
            this.form.date = "";
            //循环
            let numArray = this.doc.doctime.sort((a, b) => a - b);
            // console.log("输出排序的值：", numArray);
            this.form.week = numArray.join(",");
            apiShout.insertLeaveMessages(this.form).then((res) => {
              console.log(res, "显示数据Week");
              if (res.data.code === 200) {
                this.$message({
                  type: "success",
                  message: "新增成功!",
                });
                this.form.equipmentId = null;
                this.form.date = "";
                this.form.time = "";
                this.form.message = "";
                this.form.frequency = "";
                this.newlyAdded = false;
                //   this.formList = true;
                //   this.tableHidden = false;
                this.getList();
              } else {
                this.$message.error(res.data.msg);
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },

    //新增
    addList() {
      this.form.sendType = "1";
      this.showOne = true;
      this.doc.doctime = ["1", "2", "3", "4", "5", "6", "7"];
      // this.doc.doctime = [1, 2, 3, 4, 5, 6, 7];
      this.newlyAdded = true;
      this.isDate = false;
      this.form.frequency = "2";
      let newDate = new Date();
      let y = newDate.getFullYear();
      let MM = newDate.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = newDate.getDate();
      d = d < 10 ? "0" + d : d;
      let dates = y + "-" + MM + "-" + d;
      this.form.date = dates;
      var that = this;
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      that.gettime = hh + ":" + mf + ":" + ss;
      console.log(that.gettime);
      this.form.time = that.gettime;
      this.disableds = false;
      console.log(this.userIds, "查看userid是否显示正确查看222");
      if (this.userIds == null) {
        this.formOne = [];
        this.form.equipmentId = "";
        return false;
      }
      apiShout
        .getLeaveMessageEquipments({ memberId: this.userIds })
        .then((res) => {
          console.log(res, "查询设备");
          if (res.data.code === 200) {
            this.formOne = res.data.result;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //查询设备
    equipments(val) {
      console.log(val, "查看设备id");
    },
    handleClose() {
      this.newlyAdded = false;
      this.form.date = "";
      this.form.time = "";
    },
  },
};
</script>

<style lang="less" scoped>
.contentBox {
  height: auto;
  background: #fff;
  padding: 5px;
  margin: 12px 5px;
}
.headersTitle {
  font-size: 20px;
  font-weight: bold;
}
.headersAdd {
  padding: 8px 10px;
  border: 1px solid #333;
  margin-left: 15px;
  background: #f09c83;
  color: #fff;
}
/deep/ .el-form-item {
  margin-bottom: 0px;
}
/deep/ .el-input__inner {
  margin-left: 0px !important;
}
#text {
  font-size: 15px;
  padding-left: 6px;
}
/deep/ .el-card__body {
  padding: 8px;
  height: 160px;
  overflow-y: scroll;
}
.box-card {
  width: 100%;
  border: 1px solid #333;
}
.active {
  background: #e5f4f1;
}
/deep/ audio {
  width: 250px;
}
/deep/.el-dialog__headerbtn {
  font-size: 20px;
}
/deep/.el-dialog__wrapper {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
}
.senOne {
  margin-right: 10px;
}
.sendFirst {
  width: 85%;
}
.sendFirstOne {
  width: 56%;
  margin-left: 50px;
}
.senTwo {
  margin-right: 10px;
}
.zhouOne {
  display: flex;
  margin-top: 5px;
}
.zhou {
  font-size: 15px;
  margin-right: 5px;
  margin-top: 4px;
}
/deep/ .el-checkbox__label {
  padding-left: 5px;
}
/deep/ .el-checkbox {
  color: #606266;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
  margin-right: 5px;
}
</style>