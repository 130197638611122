<template>
  <div class="contentBox">
    <div class="headers">
      <span class="headersTitle">天气预报</span>
    </div>
    <div class="top">
      <!-- <el-select
        v-model="value"
        placeholder="请选择"
        style="width: 480px; margin-right: 10px"
        @change="xiaSelect"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
      <el-select
        v-model="valueTime"
        placeholder="请选择"
        style="width: 120px; margin-right: 10px"
        @change="timeSelect"
      >
        <el-option
          v-for="item in optionsTime"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button
        type="primary"
        class="oneButton"
        @click="sendMessage"
        :disabled="isClick"
        >重新发送</el-button
      >
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      value: "",
      options: [],
      valueTime: "",
      optionsTime: [
        {
          value: "1",
          label: "5分钟之后",
        },
        {
          value: "2",
          label: "10分钟之后",
        },
        {
          value: "3",
          label: "15分钟之后",
        },
      ],
      chuanTime: null,
      chuanContent: "",
      chuanType: "",
      isClick: false,
    };
  },
  created() {
    // this.getOneTime();
  },
  methods: {
    timeSelect(aa) {
      // console.log("shijian:", aa);
      if (aa == 1) {
        this.chuanTime = 5;
      } else if (aa == 2) {
        this.chuanTime = 10;
      } else if (aa == 3) {
        this.chuanTime = 15;
      }
    },
    sendMessage() {
      let aa = JSON.parse(localStorage.getItem("strequipmentIds"));
      let obj = {
        equipmentId: aa,
        time: this.chuanTime,
      };
      if (obj.equipmentId == null) {
        this.$message.error("设备ID不能为空");
        return;
      } else if (obj.time == "") {
        this.$message.error("时间不能为空");
        return;
      }
      apiShout.getweather(obj).then((res) => {
        console.log("重新发送：", res.data);
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "发送成功!",
          });
          this.isClick = true;
          this.value = "";
          this.valueTime = "";
          setTimeout(() => {
            this.isClick = false;
          }, 5000);
        }else{
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.contentBox {
  height: auto;
  background: #fff;
  padding: 5px;
  margin: 12px 5px;
}
.headersTitle {
  font-size: 20px;
  font-weight: bold;
}
.oneButton {
  padding: 8px 10px;
  border: 1px solid #333;
  margin-left: 15px;
  background: #f09c83;
  color: #fff;
}
.top {
  margin-top: 15px;
}
</style>